import { ColumnsType } from 'antd/lib/table'
import { IBICFormTask } from './types/FormValues'

export interface DistributionRow {
  visit: string
  tasks: { name: string }[]
}

export const useDistributionTableConfig: (data: DistributionRow[]) => ColumnsType<DistributionRow> = (data) => {
  const columns: ColumnsType<DistributionRow> = [
    {
      title: 'Besök',
      dataIndex: 'visit',
      key: 'visit',
      width: 150,
    },
  ]

  const maxTasks = Math.max(...data.map((row) => row.tasks.length))

  for (let i = 0; i < maxTasks; i++) {
    columns.push({
      title: `Insats ${i + 1}`,
      key: `task${i}`,
      width: 150,
      fixed: 'left',
      render: (_, obj) => {
        return obj.tasks[i]?.name
      },
    })
  }

  // Gutter column that fills the remaining space
  columns.push({
    title: '',
    key: 'gutter',
    width: 'auto',
    render: () => null,
  })

  return columns
}
