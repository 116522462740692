import React from 'react'
import { useHistory } from 'react-router-dom'

// Antd
import { Typography, Button, Divider } from 'antd'

interface flagListHeader {
  heading: string
  title: string | null
  description: string | null
  button: boolean
  disabled?: boolean
}

const FlagListHeader: React.FC<flagListHeader> = ({ title, description, heading, button, disabled }) => {
  const navigate = useHistory()

  return (
    <div style={{ marginBottom: 20 }}>
      <Typography.Title level={2}>{heading}</Typography.Title>
      <Divider style={{ border: '1px solid lightgrey' }} />
      {title !== null && <Typography.Title level={4}>{title}</Typography.Title>}
      {description !== null && (
        <div className="flaglist-description">
          <Typography.Text>
            {description.split('\n').map((l) => {
              return (
                <>
                  {l}
                  <br />
                </>
              )
            })}
          </Typography.Text>
        </div>
      )}

      {button && (
        <div className="flaglist-button" style={{ zIndex: 1, marginBottom: 10, right: '25px', position: 'absolute', marginTop: '20px' }}>
          <Button type="primary" onClick={() => navigate.push('/app/nytt-arende')} disabled={disabled}>
            Initiera uppföljning
          </Button>
        </div>
      )}
    </div>
  )
}

export default FlagListHeader
