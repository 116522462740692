import { AxiosInstance } from 'axios'
import { Dispatch } from 'redux'
import { IBrukare } from '../features/brukare'
import { ISetBrukareAction, setBrukareAction } from '../features/brukare/store/actions'

export const setBrukare = async (api: AxiosInstance, dispatch: Dispatch<ISetBrukareAction>, date?: { from: string; to: string }) => {
  let from
  let to

  if (date) {
    from = date.from
    to = date.to
  } else {
    from = '2020-01-01'
    to = '2021-12-31'
  }

  try {
    const brukare = await api.get<IBrukare[]>(`/v1/brukare/${localStorage.getItem('tenant_id')}/all?from=${from}&to=${to}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    if (brukare.data === []) {
      setBrukareAction('SET_BRUKARE', [], dispatch)
      return
    }

    const formattedData = brukare.data.map((item: IBrukare) => {
      console.log(item.förnamn)
      return {
        key: item.id,
        flagga: item.flagga,
        flaggnamn: item.flaggnamn,
        nivå: item.nivå,
        namn: item.förnamn + ' ' + item.efternamn,
        personnummer: item.personnummer,
        från: item.från,
        till: item.till,
        created: item.created,
        utförare: item.utförare,
      }
    })

    return setBrukareAction('SET_BRUKARE', formattedData, dispatch)
  } catch (err) {
    console.log(err)
  }
}
