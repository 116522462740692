import { Select, Spin } from 'antd'
import moment from 'moment'
import React, { ComponentProps } from 'react'
import { Line } from 'react-chartjs-2'
import { useNursingDementiaTotalTrendValues } from '../../../../api/benchmarking'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { getColorByIndex } from '../../util/colors'
import { getFontSize } from '../../util/fontSize'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { EmptySelector } from '../../components/EmptySelector'
import { NO_COMPARISON, STATDB_NAME } from '../../util/names'

export const NursingDementiaTotalTrend = ({ text }: { text: React.ReactNode }) => {
  const { data, isLoading } = useNursingDementiaTotalTrendValues()

  if (isLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }
  const graphOptions: ComponentProps<typeof Line>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
    },
  }

  const graphData: ComponentProps<typeof Line>['data'] = {
    labels: data.baselineValues.map((item) => moment(item.date).format('YYYYMM')),
    datasets: [
      {
        label: 'Totalt',
        borderColor: getColorByIndex(0).border,
        data: data.baselineValues.map((item, index) => ({ y: item.total, x: index })),
        pointRadius: 0,
      },
      {
        label: 'Omvårdnad',
        borderColor: getColorByIndex(1).border,
        data: data.baselineValues.map((item, index) => ({ y: item.omvårdnad, x: index })),
        pointRadius: 0,
      },
      {
        label: 'Demensboende',
        borderColor: getColorByIndex(2).border,
        data: data.baselineValues.map((item, index) => ({ y: item.demens, x: index })),
        pointRadius: 0,
      },
    ],
  }
  const legend = (
    <BenchmarkingLegend
      items={[
        { text: 'Totalt', borderColor: getColorByIndex(0).border, fillColor: getColorByIndex(0).fill },
        { text: 'Omvårdnad', borderColor: getColorByIndex(1).border, fillColor: getColorByIndex(1).fill },
        { text: 'Demensboende', borderColor: getColorByIndex(2).border, fillColor: getColorByIndex(2).fill },
      ]}
    />
  )
  const title = 'Antal boende för omvårdnad respektive demens'
  const graph: React.ReactNode = <Line options={graphOptions} data={graphData} />

  const dataSourceSelector = <EmptySelector value={STATDB_NAME} />
  const comparisonSelector = <EmptySelector value={NO_COMPARISON} />
  return <BenchmarkingContentRow graph={graph} text={text} title={title} legend={legend} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} />
}
