import { Table } from 'antd'
import tableHeaderYm from '../utils/TableConfig/tableHeaderYm'
import larmOchNattTableConfig from './utils/larmOchNattTableConfig'
import nattHjalpTableConfig from './utils/nattHjalpTableConfig'

type LarmOchNattProps = {
  larm: any[]
  larmuttryckningar: any[]
  beviljadeLarmNattInsatser: any[]
  utfordaNattinsatser: any[]
  larmNotHemtjanst: any[]
  larmHemtjanst: any[]
}

const LarmOchNatt = (data: LarmOchNattProps) => {
  const larmOptions = tableHeaderYm(data.larm, '')
  const nattOptions = tableHeaderYm(data.larm, '')
  const dataSource = larmOchNattTableConfig(data)
  const nattHjalpTableData = nattHjalpTableConfig(data)

  return (
    <>
      <Table columns={larmOptions} dataSource={dataSource} scroll={{ x: 0 }} pagination={false} size="small" />
      {/* In some situations larm are not distinguishable in the data so no reason to show an empty table */}
      {data.beviljadeLarmNattInsatser?.length > 0 && data.utfordaNattinsatser?.length > 0 && (
        <Table columns={nattOptions} dataSource={nattHjalpTableData} scroll={{ x: 0 }} pagination={false} size="small" />
      )}
    </>
  )
}

export default LarmOchNatt
