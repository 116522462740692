import { AppState } from '../../../store/store'

export const selectVolume = (state: AppState) => state.arendenReducer.volume

export const selectDistribution = (state: AppState) => state.arendenReducer.distribution

export const selectMyCases = (state: AppState) => state.arendenReducer.myCases

export const selectVolumeCases = (state: AppState) => state.arendenReducer.volume

export const selectArendenLoading = (state: AppState) => state.arendenReducer.loading

export const selectSingleCase = (state: AppState) => state.caseReducer.singleData

export const selectCase = (state: AppState) => state.caseReducer.data

export const selectUtanförteamstruktur = (state: AppState) => state.arendenReducer.utanför_teamstruktur

export const selectNyligenUtgångna = (state: AppState) => state.arendenReducer.nyligen_utgångna
