import { round } from '../../Dashboard/graphConfig'

const brukareFilter = (data: any) => {
  const res: any[] = []

  const nyaBrukare = data.nyaBrukare || []
  const timeOfNewBrukareCases = data.timeOfNewBrukareCases || []
  const avgAgeNewCases = data.avgAgeNewCases || []

  var newAvgAge: {
    [key: string]: any
  } = { name: 'Genomsnittlig ålder' }
  var formattedNyaBrukare: {
    [key: string]: any
  } = { name: 'Antal brukare' }
  var formattedTimeOfNewBrukareCases: {
    [key: string]: any
  } = { name: 'Genomsnittlig beställd tid' }

  nyaBrukare.forEach((item: any, index: number) => {
    formattedNyaBrukare[index] = Math.round(item[Object.keys(item)[0]])
    const utfordTidHours = timeOfNewBrukareCases[index] ? timeOfNewBrukareCases[index].hours : 0
    formattedTimeOfNewBrukareCases[index] = round(utfordTidHours, 1)
  })
  avgAgeNewCases.forEach((item: any, index: number) => {
    newAvgAge[index] = round(item.avgAge, 1)
  })

  res.push(formattedNyaBrukare)
  res.push(formattedTimeOfNewBrukareCases)
  res.push(newAvgAge)

  return res
}

export default brukareFilter
