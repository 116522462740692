import { IBICResolverResult, ICIBResolutionContext } from '../types'

export const resolveIBICPartialRule = ({ previousResult, task, allTasks, tasksPerDay }: ICIBResolutionContext): IBICResolverResult => {
  const rule = task.ibicCalculationRule
  if (rule.type !== 'IBICPartialRule') {
    console.error('Rule is not IBICPartialRule')
    return null
  }

  // Find first timepoint that can be shared with
  const firstThatCanBeSharedWith = previousResult.findIndex((timepoint) => {
    // Timepoint is not full and at least one task can be shared with
    return (
      // timepoint.tasks.length < tasksPerDay &&
      // Is already in the timepoint?
      !timepoint.tasks.some((t) => t.name === task.name) &&
      timepoint.tasks.every((t) => {
        // Rule can be shared with this task and level
        return rule.canBeSharedWith.some((sharedWith) => {
          return sharedWith.taskName === t.name && sharedWith.levels.includes(t.level ?? '')
        })
      })
    )
  })

  return firstThatCanBeSharedWith !== -1 ? firstThatCanBeSharedWith : null
}
