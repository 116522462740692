import React from 'react'
import { Spin } from 'antd'
import { ComponentProps } from 'react'
import { Bar } from 'react-chartjs-2'
import { useMunicipalityName, useNursingDementiaProportionValues } from '../../../../api/benchmarking'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { getFontSize } from '../../util/fontSize'
import { getColorByIndex } from '../../util/colors'
import { BORDER_WIDTH } from '../../util/constants'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { EmptySelector } from '../../components/EmptySelector'
import { NO_COMPARISON, STATDB_NAME } from '../../util/names'

export const NursingDementiaProportion = ({ text }: { text: React.ReactNode }) => {
  const { data: municipalityName, isLoading: isMunicipalityNameLoading } = useMunicipalityName()
  const { data, isLoading } = useNursingDementiaProportionValues()

  if (isLoading || isMunicipalityNameLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const graphOptions: ComponentProps<typeof Bar>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${Math.round(context.parsed.y)}%`
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        min: 0,
        max: 100,
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        stacked: true,
        min: 0,
        max: 100,
        ticks: {
          font: {
            size: getFontSize('sm'),
          },
          callback: (value) => {
            return `${value}%`
          },
        },
      },
    },
  }

  const graphData: ComponentProps<typeof Bar>['data'] = {
    labels: [municipalityName, 'Undre kvartil', 'Median', 'Övre kvartil'],
    datasets: [
      {
        label: 'Omvårdnad',
        backgroundColor: getColorByIndex(0).fill,
        borderColor: getColorByIndex(0).border,
        data: [data.baselineValues.omvårdnad, data.comparisonValues.quartile1.omvårdnad, data.comparisonValues.median.omvårdnad, data.comparisonValues.quartile3.omvårdnad],
        borderWidth: BORDER_WIDTH,
      },
      {
        label: 'Demens',
        backgroundColor: getColorByIndex(1).fill,
        borderColor: getColorByIndex(1).border,
        data: [data.baselineValues.demens, data.comparisonValues.quartile1.demens, data.comparisonValues.median.demens, data.comparisonValues.quartile3.demens],
        borderWidth: BORDER_WIDTH,
      },
    ],
  }
  const legend = (
    <BenchmarkingLegend
      items={[
        { text: 'Omvårdnad', borderColor: getColorByIndex(0).border, fillColor: getColorByIndex(0).fill },
        { text: 'Demens', borderColor: getColorByIndex(1).border, fillColor: getColorByIndex(1).fill },
      ]}
    />
  )

  const title = 'Fördelning mellan boende för omvårdnad respektive demens'
  const graph: React.ReactNode = <Bar options={graphOptions} data={graphData} />

  const dataSourceSelector = <EmptySelector value={STATDB_NAME} />
  const comparisonSelector = <EmptySelector value={'Fördelning'} />

  return <BenchmarkingContentRow graph={graph} text={text} title={title} legend={legend} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} />
}
