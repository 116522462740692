import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navigation from './navigation'
import SiderMenu from './components/menus/siderMenu'
import Login from './features/login/login'
import { useSelector } from 'react-redux'

// Antd
import { Layout } from 'antd'
import LoginRedirect from './features/loginRedirect/loginRedirect'
import notFoundPage from './features/404'
import ProtectedRoute from './utils/protectedRoutes'
import LogoutRedirect from './features/logoutRedirect/logoutRedirect'

const { Content, Footer } = Layout

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/callback/:kommun" component={LoginRedirect} />
        <Route exact path="/logout/:kommun" component={LogoutRedirect} />
        <Route
          exact
          path="/"
          component={() => (
            <Layout style={{ height: '100vh' }}>
              <Content className="site-content" style={{ margin: '24px 16px 0' }}>
                <Login />
              </Content>
            </Layout>
          )}
        />

        <Route
          exact
          path="/app/*"
          component={() => (
            <Layout style={{ minHeight: '100vh' }}>
              <SiderMenu />
              <Layout className="site-layout" style={{ backgroundColor: 'rgb(240, 240, 240' }}>
                <Content className="site-content" style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                  <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <ProtectedRoute />
                    <Navigation />
                  </div>
                </Content>
                {/* <Footer style={{textAlign: 'center'}}>Inrikta AB 2022</Footer> */}
              </Layout>
            </Layout>
          )}
        />

        <Route path="/*" component={notFoundPage} />
      </Switch>
    </Router>
  )
}

export default App
