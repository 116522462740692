import { DownSquareOutlined, UpSquareOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Table } from 'antd'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getCases } from '../../api/arendeApi'
import FlagListHeader from '../../components/header'
import { createCaseTableConfig } from '../../components/tablesConfig/caseTableConfig'
import { DataType } from '../../components/tablesConfig/filterconfig'

export type Case = {
  key: React.Key
  id: number
  visible: boolean
  ägare: { id: number; namn: string }
  flags: DataType[]
  handläggare: { id: number; namn: string }[]
  händelser: CaseLogs[]
  created: Date
  senastUppdaterad: Date
  status: string
}

export type CaseLogs = {
  id: number
  date: Date
  status: string
  type: string
  comment: string
  createdBy: { id: number; namn: string }
}

const getStatusDescription = (status: string) => {
  switch (status) {
    case 'Uppdaterat':
      return (
        <>
          Uppdaterat...
          <br />
          ...vilket betyder att ärendet är öppet och att det har skett en förändring i ärendet.
        </>
      )
    case 'Öppet':
      return (
        <>
          Öppet...
          <br />
          ...vilket betyder att ärendet är öppet.
        </>
      )
    case 'Stängt':
      return (
        <>
          Stängt...
          <br />
          ...vilket betyder att ärendet har hanterats och stängts.
        </>
      )
    default:
      return 'Okänd status'
  }
}

const ExpandedText = (record: Case) => {
  return (
    <>
      Initierad av: {record.ägare.namn}
      <br />
      Tilldelad: {record.handläggare.length ? record.handläggare.map((h) => h.namn).join(', ') : 'Ingen'}
      <br />
      Initierad: {moment(record.created).format('YYYY-MM-DD HH:mm')}
      <br />
      Senast uppdaterad: {moment(record.senastUppdaterad).format('YYYY-MM-DD HH:mm')}
      <br />
      Status: {getStatusDescription(record.status)}
    </>
  )
}

const Case = () => {
  const navigate = useHistory()

  const { isLoading, isError, data: cases } = useQuery(['cases'], getCases)

  if (isError || !cases) return null

  return (
    <>
      <FlagListHeader heading="Tilldelade avvikelser" title={null} description={null} button={false} />
      <Table
        columns={createCaseTableConfig(cases, navigate)}
        dataSource={cases}
        loading={isLoading}
        pagination={false}
        bordered
        size="small"
        expandable={{
          expandedRowRender: ExpandedText,
          expandIcon: ({ expanded, onExpand, record }) => {
            return expanded ? <UpSquareOutlined onClick={(e) => onExpand(record, e)} /> : <DownSquareOutlined onClick={(e) => onExpand(record, e)} />
          },
        }}
      />
    </>
  )
}

export default Case
