import { MunicipalityData } from '../../types/municipalityData'
import { IBICTaskWithRule } from '../calculation/IBICBeslutsstodCalculationView'
import { canBePlannedWith, isBothCoPlannable } from './canBePlannedWith'
import { resolveIBICAllRule } from './legacy/resolveIBICAllRule'
import { resolveIBICNoneRule } from './legacy/resolveIBICNoneRule'
import { resolveIBICPartialRule } from './legacy/resolveIBICPartialRule'
import { resolveIBICUndefinedRule } from './legacy/resolveIBICUndefinedRule'
import { IBICResolutionResult, ICIBResolutionContext } from './types'

export const resolveIBICCalculationRules = (allTasks: IBICTaskWithRule[]) => {
  let result: IBICResolutionResult = []

  const resolvers = {
    IBICUndefinedRule: resolveIBICUndefinedRule,
    IBICPartialRule: resolveIBICPartialRule,
    IBICAllRule: resolveIBICAllRule,
    IBICNoneRule: resolveIBICNoneRule,
  } as const

  allTasks.forEach((task) => {
    const resolver = resolvers[task.ibicCalculationRule.type]
    if (!resolver) {
      console.error(`Resolver for ${task.ibicCalculationRule.type} not found`)
      return
    }

    let allocationsLeft = task.timesPerDay ?? 0

    while (allocationsLeft > 0) {
      allocationsLeft--
      // const timepointIndexToAllocateTo = resolver(context)
      const timepointIndexToAllocateTo = result.findIndex((timepoint) => {
        return !timepoint.tasks.some((t) => t.name === task.name) && timepoint.tasks.every((t) => isBothCoPlannable(task, t))
      })

      console.log(`Allocating ${task.name} to ${timepointIndexToAllocateTo} using ${task.ibicCalculationRule.type}`)

      // Could not allocate to any timepoint so create a new
      if (timepointIndexToAllocateTo === -1 || result.length === 0) {
        result = result.concat([
          {
            tasks: [task],
          },
        ])
        continue
      }

      // Allocate to timepoint given by resolver
      result[timepointIndexToAllocateTo] = {
        tasks: result[timepointIndexToAllocateTo].tasks.concat(task),
      }
    }
  })

  return result
}
