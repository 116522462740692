import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './store'
import moment from 'moment'

interface brukareDateSlice {
  from: string
  to: string
}

const initialState = {
  from: moment().startOf('month').format('YYYY-MM-DD'),
  to: moment().endOf('month').format('YYYY-MM-DD'),
} as brukareDateSlice

const brukareDateSlice = createSlice({
  name: 'brukareDate',
  initialState,
  reducers: {
    setBrukareDate(state, action: PayloadAction<brukareDateSlice>) {
      return action.payload
    },
  },
})

export const getBrukareDateFrom = (state: AppState) => state.brukareDateReducer.from
export const getBrukareDateTo = (state: AppState) => state.brukareDateReducer.to
export const { setBrukareDate } = brukareDateSlice.actions
export default brukareDateSlice.reducer
