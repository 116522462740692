import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './store'
interface ISideMenuState {
  activeMenuItem: string
}

const initialState = {
  activeMenuItem: '',
} as ISideMenuState

const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    setActiveMenuItem(state, action: PayloadAction<string>) {
      state.activeMenuItem = action.payload
    },
  },
})

export const getActiveMenuItem = (state: AppState) => state.sideMenuReducer.activeMenuItem
export const { setActiveMenuItem } = sideMenuSlice.actions
export default sideMenuSlice.reducer
