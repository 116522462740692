import { useMutation, useQuery } from '@tanstack/react-query'
import { apiClient } from './useApi'

const getLifespansFromString = (lifespans: string) => {
  const { hours, minutes, seconds } = (() => {
    return {
      hours: RegExp(/(\d+)h/).exec(lifespans)?.[1] ?? '0',
      minutes: RegExp(/(\d+)m/).exec(lifespans)?.[1] ?? '0',
      seconds: RegExp(/(\d+)s/).exec(lifespans)?.[1] ?? '0',
    }
  })()

  return {
    hours: parseInt(hours),
    minutes: parseInt(minutes),
    seconds: parseInt(seconds),
  }
}
export const useTokenLifespans = (tenant?: string) =>
  useQuery(
    ['tokenLifespans'],
    async () =>
      await apiClient.getLifespans(tenant ?? localStorage.getItem('tenant_id') ?? '').then((res) => ({
        accessToken: getLifespansFromString(res.data.access_token),
        refreshToken: getLifespansFromString(res.data.refresh_token),
      }))
  )

export const useUpdateTokenLifespans = () =>
  useMutation(
    ['updateTokenLifespans'],
    async (lifespans: { accessToken?: string; refreshToken?: string }) =>
      await apiClient.setLifespans(localStorage.getItem('tenant_id') ?? '', { access_token: lifespans.accessToken, refresh_token: lifespans.refreshToken })
  )
