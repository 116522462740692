import addThousandSeparator from '../../utils/thousandSeperator'

interface NameEntry {
  [key: number]: number | string
  name: string
}

function tableConfigHours(data: { resurs: string; hours: number; month: string }[]): any[] {
  const uniqueDates = new Set(data.map(({ month }) => month))

  const dateToIndexMap = new Map(Array.from(uniqueDates).map((date, index) => [date, index]))

  const result: NameEntry[] = []

  const indexSums: NameEntry = {
    name: 'total',
  }

  for (const { resurs, hours, month } of data) {
    // Should not be possible to get undefined here
    const index = dateToIndexMap.get(month) || 0
    const nameEntry = result.find((entry) => entry.name === resurs)

    if (nameEntry) {
      nameEntry[index] = addThousandSeparator(Math.round(hours))
    } else {
      const newEntry: NameEntry = {
        name: resurs,
      }
      newEntry[index] = addThousandSeparator(Math.round(hours))
      result.push(newEntry)
    }

    indexSums[index] = ((indexSums[index] as number) || 0) + Math.round(hours)
  }

  for (const key in indexSums) {
    if (typeof indexSums[key] === 'number') {
      indexSums[key] = addThousandSeparator(Math.round(indexSums[key] as number))
    }
  }

  result.push(indexSums)

  return result
}

export default tableConfigHours
