import { ComponentProps } from 'react'
import { Line } from 'react-chartjs-2'

interface DashBoardOptions {
  title: string
  showPercent?: boolean
  grace?: string
  min?: number
  integer?: boolean
}

const dashBoardOptions = ({ title, showPercent = false, grace, min, integer }: DashBoardOptions) => {
  const options: ComponentProps<typeof Line>['options'] = {
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 14,
        },
      },
    },
    scales: {
      y: {
        type: 'linear',
        min,
        ticks: {
          precision: integer ? 0 : 1,
          callback: (value) => {
            if (showPercent) {
              return value + '%'
            }
            return value
          },
        },
        grace,
      },
    },

    maintainAspectRatio: false,
  }

  return options
}

export default dashBoardOptions
