import { AxiosInstance } from 'axios'
import { IUtförare } from '../store/utförareSlice'

export const setUtförareCases = async (api: AxiosInstance, from: string, to: string) => {
  try {
    const utförareCasesData = await api.get(`/v1/utforare/${localStorage.getItem('tenant_id')}/all?from=${from}&to=${to}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    if (utförareCasesData.data === []) return []

    const formattedData = utförareCasesData.data.map((item: IUtförare, index: number) => {
      if (item.brukare) {
        item.brukare = item.brukare.map((brukareFlag) => {
          return {
            ...brukareFlag,
            key: brukareFlag.id,
          }
        })
      } else item.brukare = []
      if (item.medarbetare) {
        item.medarbetare = item.medarbetare.map((medarbetareFlag) => {
          return {
            ...medarbetareFlag,
            key: medarbetareFlag.id,
          }
        })
      } else item.medarbetare = []
      return {
        ...item,
        key: index,
      }
    })

    return formattedData
  } catch (err) {
    console.log(err)
  }
}
