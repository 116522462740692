import { AppState } from './store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

interface UtforareFilterSlice {
  deviation: string[];
  flagLevel: number[];
  startDate: string
  endDate: string
}

const initialState: UtforareFilterSlice = {
  deviation: [],
  flagLevel: [],
  startDate: moment().date(-1).format().split('T')[0],
  endDate: moment().date(-5).format().split('T')[0]
};

const utforareFilterSlice = createSlice({
  name: 'utforareFilter',
  initialState,
  reducers: {
    setUtforareFilter(state, action: PayloadAction<Partial<UtforareFilterSlice>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const getUtforareFilter = (state: AppState) => state.utforareFilterReducer;
export const getDeviation = (state: AppState) => state.utforareFilterReducer.deviation;
export const getFlagLevel = (state: AppState) => state.utforareFilterReducer.flagLevel;
export const getUtforareStartDate = (state: AppState) => state.utforareFilterReducer.startDate
export const getUtforareEndDate = (state: AppState) => state.utforareFilterReducer.endDate
export const { setUtforareFilter } = utforareFilterSlice.actions;
export default utforareFilterSlice.reducer;

