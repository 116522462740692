import FlagListHeader from '../../components/header'

const Start = () => {
  return (
    <>
      <FlagListHeader
        heading="Start"
        title={''}
        description={`Välkommen till Pejlen!
Pejlen ska vara stöd för datadrivet förbättringsarbete.
Navigera genom menyraden till vänster för att komma in på de delar som du vill arbeta med.`}
        button={false}
      />
    </>
  )
}

export default Start
