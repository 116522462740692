import { Select, Spin } from 'antd'
import React, { ComponentProps, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useMunicipalityName, useResidencyPopulationProportionValues } from '../../../../api/benchmarking'
import { GetResidencyPopulationProportionValuesVariantEnum } from '../../../../gen/api'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { EmptySelector } from '../../components/EmptySelector'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { BASELINE_COLOR, MEDIAN_COLOR, QUARTILE_1_COLOR, QUARTILE_3_COLOR } from '../../util/colors'
import { BORDER_WIDTH } from '../../util/constants'
import { getFontSize } from '../../util/fontSize'

type VariantEnum = GetResidencyPopulationProportionValuesVariantEnum
const VariantEnum = GetResidencyPopulationProportionValuesVariantEnum

export const SäboPopulationProportion = ({ text }: { text: React.ReactNode }) => {
  const { data, isLoading: isValuesLoading, mutate } = useResidencyPopulationProportionValues()
  const { data: municipalityName, isLoading: isMunicipalityNameLoading } = useMunicipalityName()
  const [variation, setVariation] = useState<VariantEnum>(VariantEnum.Statdb)

  useEffect(() => {
    mutate(undefined)
  }, [])

  if (isValuesLoading || isMunicipalityNameLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const options: ComponentProps<typeof Bar>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.datasetIndex === 0 ? municipalityName : context.dataset.label}: ${Math.round(context.parsed.y * 10) / 10}%`
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
          callback: (value) => `${value}%`,
        },
      },
    },
  }

  const graphData: ComponentProps<typeof Bar>['data'] = {
    labels: [municipalityName, 'Undre kvartil', 'Median', 'Övre kvartil'],
    datasets: [
      {
        label: 'Antal timmar',
        data: [(data.baselineValues.value ?? 0) * 100, (data.comparisonValues.quartile1 ?? 0) * 100, (data.comparisonValues.median ?? 0) * 100, (data.comparisonValues.quartile3 ?? 0) * 100],
        backgroundColor: [BASELINE_COLOR.fill, QUARTILE_1_COLOR.fill, MEDIAN_COLOR.fill, QUARTILE_3_COLOR.fill],
        borderColor: [BASELINE_COLOR.border, QUARTILE_1_COLOR.border, MEDIAN_COLOR.border, QUARTILE_3_COLOR.border],
        borderWidth: BORDER_WIDTH,
      },
    ],
  }
  const legend = (
    <BenchmarkingLegend
      items={[
        { text: municipalityName, borderColor: BASELINE_COLOR.border, fillColor: BASELINE_COLOR.fill },
        { text: 'Undre kvartil', borderColor: QUARTILE_1_COLOR.border, fillColor: QUARTILE_1_COLOR.fill },
        { text: 'Median', borderColor: MEDIAN_COLOR.border, fillColor: MEDIAN_COLOR.fill },
        { text: 'Övre kvartil', borderColor: QUARTILE_3_COLOR.border, fillColor: QUARTILE_3_COLOR.fill },
      ]}
    />
  )

  const dataSourceSelector = (
    <Select
      value={variation}
      onChange={(value) => {
        setVariation(value)
        mutate(value)
      }}
      options={[
        { label: 'Verksamhetssystem', value: VariantEnum.Statdb },
        { label: 'Offentliga data', value: VariantEnum.Socialstyrelsen },
      ]}
      style={{ width: '100%' }}
    />
  )
  const graph = <Bar options={options} data={graphData} />
  const title = 'Andel i särskilt boende för kommunen och jämförelsegrupper, 80+'

  const comparisonSelector = <EmptySelector value={'Fördelning'} />
  return <BenchmarkingContentRow graph={graph} title={title} text={text} legend={legend} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} />
}
