import { Switch, Table, Tabs } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useApi } from '../../api/useApi'
import FlagListHeader from '../../components/header'
import { CreateFilterConfig, DataType } from '../../components/tablesConfig/filterconfig'
import { IBrukareFlag, IMedarbetareFlag, selectUtförareByName } from '../../store/utförareSlice'
import { filterConfig, splitParams } from '../../utils/filterConfig'
import { setCaseAction } from '../arende/store/caseReducer/actions'
import { getUtförareTab, setUtförareTab } from '../arendeFordelning/store/tabSlice'

export interface IRiskFlag {
  number: number
  name: string
  avg: string
  level_avg: number
}

export interface IMultiDataSet {
  label: string
  data: number[]
  borderColor: string
  backgroundColor: string
}

export interface IMultiChart {
  labels: string[]
  datasets: IMultiDataSet[]
}

const api_url = process.env.REACT_APP_API_URL

function generateOptions() {
  return {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    options: {
      maintainAspectRatio: false,
      indexAxis: 'y',
      scales: {
        y: {
          beginAtZero: true,
          ticks: { display: false },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        onClick: () => null,
        labels: {
          color: 'rgb(255, 99, 132)',
        },
      },
      title: {
        display: true,
        text: '',
      },
    },
  }
}

function filterFromParam(flaglevel: string, deviation: string, data: any, type: string) {
  var res: any = []
  var filterData
  if (type === 'medarbetare') {
    filterData = data.medarbetare
  }
  if (type === 'brukare') {
    filterData = data.brukare
  }
  if (flaglevel !== 'null' && deviation !== 'null') {
    filterData.forEach((item: any) => {
      if (item.flaggnamn === deviation && Number(item.nivå) === Number(flaglevel)) {
        res.push(item)
      }
    })
  } else if (flaglevel !== 'null') {
    filterData.forEach((item: any) => {
      if (Number(item.nivå) === Number(flaglevel)) {
        res.push(item)
      }
    })
  } else if (deviation !== 'null') {
    filterData.forEach((item: any) => {
      if (item.flaggnamn === deviation) {
        res.push(item)
      }
    })
  } else {
    return filterData
  }
  return res
}

const SingleUtforare = () => {
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
  const [data, setData] = useState<IMultiChart>({
    labels: [],
    datasets: [],
  })
  const [checked, setChecked] = useState<boolean>(false)
  const [avgDataSet, setAvgDataSet] = useState<boolean>(false)
  const [avgData, setAvgData] = useState<IRiskFlag[] | null>(null)
  const [filteredData, setFilteredData] = useState([])
  const [filteredBrukareData, setFilteredBrukareData] = useState([])
  const dispatch = useDispatch()
  const api = useApi()

  type Param = {
    utforare: string
    from: string
    to: string
    flaglevel: string
    deviation: string
  }

  const { utforare, from, to, flaglevel, deviation } = useParams<Param>()

  const deviationParams = filterConfig(splitParams(deviation))
  const utförareState = useSelector((state) => selectUtförareByName(state, utforare))[0]
  const currentTab = useSelector(getUtförareTab)
  useEffect(() => {
    setFilteredData(utförareState.medarbetare)
    setFilteredBrukareData(utförareState.brukare)
  }, [])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      selectedRows.length > 0 ? setBtnDisabled(false) : setBtnDisabled(true)
      setCaseAction('SET_CASE', selectedRows, null, dispatch)
    },
  }

  const onSwitchChange = (checked: boolean) => {
    setChecked(checked)
  }

  const getTitleString = (tab: string) => {
    switch (tab) {
      case 'tab-medarbetare':
        return 'Denna vy visar varje flagga som summerades i jämförelsebilden för en specifik utförare. Varje rad visar en flagga där medarbetare, typ av flagga och risknivå samt när flaggan skapades anges. Val av tidsperiod baseras på när flaggan skapades.'
      case 'tab-brukare':
        return 'Denna vy visar varje flagga som summerades i jämförelsebilden för en specifik utförare. Varje rad visar en flagga där brukarens namn, typ av flagga och risknivå samt när flaggan skapades anges. Val av tidsperiod baseras på när flaggan skapades'
      case 'tab-riskflaggor':
        return 'Denna vy visar flaggor som skapats under vald tidsperiod. Staplarna visar flaggor per brukare för en specifik utförare (ljusblå stapel) och genomsnittet i kommunen (mörkblå stapel) vilket ger en tydlig indikation ifall antalet flaggor avviker från det övergripande mönstret i kommunen. Val av tidsperiod baseras på när flaggan skapades.'
      default:
        return ''
    }
  }

  const getAvgData = useCallback(async () => {
    if (avgData !== null) return

    try {
      const data = await api.get(`/v1/utforare/${localStorage.getItem('tenant_id')}/average?from=${from}&to=${to}`, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
        },
      })

      setAvgData(data.data)
    } catch (e) {
      console.error(e)
    }
  }, [avgData])

  useEffect(() => {
    if (avgData) {
      setData(generateGraphData(avgData))
    }
  }, [checked, avgData])

  useEffect(() => {
    if (avgDataSet) return
    getAvgData()
  }, [])

  useEffect(() => {
    if (deviationParams.medarbetareDeviation[0] === 'null' && deviationParams.brukareDeviation[0] !== 'null') {
      dispatch(setUtförareTab('tab-brukare'))
    } else {
      dispatch(setUtförareTab('tab-medarbetare'))
    }
  }, [])

  function generateGraphData(data: IRiskFlag[]) {
    const graph: IMultiChart = {
      labels: data.map((item: IRiskFlag) => item.name),
      datasets: [
        {
          label: 'Antal flaggor',
          data: !checked
            ? data.map((item: IRiskFlag) => {
                const brukareFlags = utförareState.brukare.filter((flag: IBrukareFlag) => flag.flagga === item.number).length
                const medarbetareFlags = utförareState.medarbetare.filter((flag: IMedarbetareFlag) => flag.flagga === item.number).length

                return (brukareFlags + medarbetareFlags) / utförareState.brukare_count
              })
            : data.map((item: IRiskFlag) => {
                const brukareLevel = utförareState.brukare.filter((flag: IBrukareFlag) => flag.flagga === item.number).reduce((accumulator: number, flag: IBrukareFlag) => accumulator + flag.nivå, 0)
                const medarbetareLevel = utförareState.medarbetare
                  .filter((flag: IMedarbetareFlag) => flag.flagga === item.number)
                  .reduce((accumulator: number, flag: IMedarbetareFlag) => accumulator + flag.nivå, 0)

                return (brukareLevel + medarbetareLevel) / utförareState.brukare_count
              }),
          borderColor: 'rgb(51, 102, 204)',
          backgroundColor: 'rgb(51, 102, 204)',
        },
        {
          label: 'Snitt',
          data: !checked ? data.map((item: IRiskFlag) => Number(item.avg)) : data.map((item: IRiskFlag) => Number(item.level_avg)),
          borderColor: 'rgb(0, 0, 102)',
          backgroundColor: 'rgb(0, 0, 102)',
        },
      ],
    }
    return graph
  }

  return (
    <>
      <FlagListHeader heading={`Översikt - ${utforare}`} title={''} description={getTitleString(currentTab)} button={true} disabled={btnDisabled} />
      <Tabs defaultActiveKey={'tab-medarbetare'} activeKey={currentTab} onTabClick={(key: string) => dispatch(setUtförareTab(key))}>
        <Tabs.TabPane tab={'Personal'} key={'tab-medarbetare'}>
          <Table
            columns={CreateFilterConfig(filteredData, 'medarbetare', deviationParams.medarbetareDeviation, splitParams(flaglevel))}
            rowSelection={{ ...rowSelection }}
            scroll={{ x: 1000, y: 'calc(100vh - 400px)' }}
            dataSource={filteredData}
            pagination={false}
            bordered
            size="small"
            // onChange={handleChange}
            // loading={loadingState}
            rowClassName="table-row-cursor"
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Brukare'} key={'tab-brukare'}>
          <Table
            columns={CreateFilterConfig(filteredBrukareData, 'brukare', deviationParams.brukareDeviation, splitParams(flaglevel))}
            rowSelection={{ ...rowSelection }}
            scroll={{ x: 1000, y: 'calc(100vh - 400px)' }}
            dataSource={filteredBrukareData}
            pagination={false}
            size="small"
            bordered
            // onChange={handleChange}
            // loading={loadingState}
            rowClassName="table-row-cursor"
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event: any) => {
            //       event.target.id !== 'flagLink' &&
            //         navigate.push('/app/brukare/id=' + record.personnummer + '&namn=' + record.namn)
            //     },
            //   }
            // }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Flaggöversikt'} key={'tab-riskflaggor'}>
          <div className="sum-flags-box">
            <label>Summera risknivå</label>
            <Switch style={{ maxWidth: '50px' }} onChange={onSwitchChange} />
          </div>
          <Bar className="graph" options={generateOptions()} data={data} />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}

export default SingleUtforare
