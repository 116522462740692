import { Space } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { GetRoles200ResponseInner } from '../../gen/api'

export const createRoleTableConfig = (_data: GetRoles200ResponseInner[], _filters: any, navigate: any) => {
  const columns: ColumnsType<GetRoles200ResponseInner> = [
    {
      title: 'Namn',
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: false,
      sorter: (a, b) => a.name?.localeCompare(b.name || '') || 0,
    },
    {
      dataIndex: 'hantera',
      key: 'hantera',
      width: 125,
      render: (_, obj) => (
        <Space size="middle">
          <button className="table-edit-link" onClick={() => navigate.push(`/app/roller/${obj.id}`)}>
            Redigera
          </button>
        </Space>
      ),
    },
  ]

  return { columns }
}
