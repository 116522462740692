import { Spin } from 'antd'
import moment from 'moment'
import React, { ComponentProps } from 'react'
import { Line } from 'react-chartjs-2'
import { useDementiaStayLengthTrendValues } from '../../../../api/benchmarking'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { LINE_BASELINE_COLOR, LINE_MEDIAN_COLOR } from '../../util/colors'
import { BORDER_WIDTH } from '../../util/constants'
import { getFontSize } from '../../util/fontSize'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { EmptySelector } from '../../components/EmptySelector'
import { NO_COMPARISON, STATDB_NAME } from '../../util/names'

export const DementiaStayLengthTrend = ({ text }: { text: React.ReactNode }) => {
  const { data, isLoading } = useDementiaStayLengthTrendValues()

  if (isLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const graphOptions: ComponentProps<typeof Line>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Genomsnitt, demens: ${Math.round(context.parsed.y)}`
          },
        },
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    scales: {
      y: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
    },
  }

  const graphData: ComponentProps<typeof Line>['data'] = {
    labels: data.map((item) => moment(item.date).format('YYYYMM')),
    datasets: [
      {
        label: 'Genomsnitt',
        borderColor: LINE_BASELINE_COLOR.border,
        data: data.map((item, index) => ({ y: item.mean, x: index })),
        pointRadius: 0,
      },
      {
        label: 'Median',
        borderColor: LINE_MEDIAN_COLOR.border,
        data: data.map((item, index) => ({ y: item.median, x: index })),
        pointRadius: 0,
      },
    ],
  }

  const legend = (
    <BenchmarkingLegend
      items={[
        { text: 'Genomsnitt', borderColor: LINE_BASELINE_COLOR.border, fillColor: LINE_BASELINE_COLOR.fill },
        { text: 'Median', borderColor: LINE_MEDIAN_COLOR.border, fillColor: LINE_MEDIAN_COLOR.fill },
      ]}
    />
  )

  const title = 'Vistelsetid på demensboende, avslutade beslut'
  const graph: React.ReactNode = <Line options={graphOptions} data={graphData} />

  const dataSourceSelector = <EmptySelector value={STATDB_NAME} />
  const comparisonSelector = <EmptySelector value={NO_COMPARISON} />

  return <BenchmarkingContentRow graph={graph} text={text} title={title} legend={legend} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} />
}
