import { Button, Form, Input, notification } from 'antd'
import { useHistory } from 'react-router-dom'
import { useAddGuest } from '../../api/guest'
import BackButton from '../../components/buttons/backButton'
import FlagListHeader from '../../components/header'

const NewGuest = () => {
  const navigate = useHistory()
  const { mutateAsync: createGuestUser, isLoading } = useAddGuest()

  const onFinish = async (values: { username: string; password: string; personNumber: string }) => {
    try {
      const res = await createGuestUser(values)

      navigate.push(`/app/gäster/${res.data.username}`)
      notification.success({
        message: 'Gästkonto skapad',
        description: 'Gästkontot har skapats, kom ihåg att spara användarnamn och lösenord',
      })
    } catch (e) {
      notification.error({
        message: 'Gästkonto kunde inte skapas',
        description: 'Gästkontot kunde inte skapas',
      })
    }
  }

  return (
    <>
      <FlagListHeader title="" heading="Skapa Gästkonto" description="Skapa ett nytt gästkonto genom att fylla i fälten nedan." button={false} />
      <div style={{ maxWidth: '600px' }}>
        <Form
          name="new-guest"
          initialValues={{
            username: '',
            password: '',
            personNumber: '',
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item label="Användarnamn" name="username" rules={[{ required: true, message: 'Vänligen fyll i användarnamn' }]}>
            <Input type={'text'} placeholder="Användarnamn" />
          </Form.Item>
          <Form.Item label="Lösenord" name="password" rules={[{ required: true, message: 'Vänligen fyll i lösenord' }]}>
            <Input type={'password'} placeholder="Lösenord" />
          </Form.Item>
          <Form.Item
            label="Personnummer"
            name="personNumber"
            rules={[
              {
                required: true,
                message: 'Vänligen fyll i personnummer i formatet ÅÅÅÅMMDD-XXXX',
                pattern: new RegExp(/^[0-9]{8}-[0-9]{4}$/),
              },
            ]}
          >
            <Input type="text" placeholder="ÅÅÅÅMMDD-XXXX" />
          </Form.Item>

          <Form.Item>
            <div style={{ display: 'flex', gap: '6px' }}>
              <BackButton display="inline" />
              <Button type="primary" htmlType="submit" style={{ display: 'inline-block', marginTop: '20px' }} loading={isLoading}>
                Skapa
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default NewGuest
