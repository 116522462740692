import React from 'react'
import { getFontSize } from '../util/fontSize'

interface BenchmarkingContentRowProps {
  id?: string
  graph: React.ReactNode
  title?: string
  text: React.ReactNode
  dataSourceSelector?: React.ReactNode
  comparisonSelector?: React.ReactNode
  legend?: React.ReactNode
}

interface SettingsProps {
  label: string
  selector?: React.ReactNode
}

const Setting = ({ label, selector }: SettingsProps) => {
  if (!selector) {
    return null
  }

  return (
    <div className="flex max-w-sm items-center gap-2">
      <div className="min-w-[80px]">{label}</div>
      {selector}
    </div>
  )
}

const BenchmarkingContentRow = ({ id, graph, title, text, dataSourceSelector, comparisonSelector, legend }: BenchmarkingContentRowProps) => {
  return (
    <div
      className="grid w-full grid-cols-[6fr,4fr] gap-x-6 gap-y-2 rounded-md bg-white p-6"
      id={id}
      style={
        {
          height: 'calc(100vh - 208px)',
          containerType: 'size',
        } as any // Any since this react version does not support containerType
      }
    >
      <div className="flex w-full items-center justify-center text-center">
        <h1 style={{ fontSize: getFontSize('xl') }}>{title}</h1>
      </div>
      <div className="flex flex-col gap-2 pb-4">
        <Setting label="Datakälla:" selector={dataSourceSelector} />
        <Setting label="Jämförelse:" selector={comparisonSelector} />
      </div>
      <div className="flex w-full flex-col items-center justify-between">
        <div className="flex h-[70cqb] w-[60cqi] justify-center">{graph}</div>
        <div className="flex h-12 items-end">{legend}</div>
      </div>
      <div>{text}</div>
    </div>
  )
}

export default BenchmarkingContentRow
