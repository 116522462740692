import { Divider, Form, Typography } from 'antd'
import React from 'react'
import { SingleIBICLifeAreaTask } from './SingleIBICLifeAreaTask'
import { IBICFormTask } from './types/FormValues'
import { IBICMunicipalityData } from '../types/municipalityData'

type Props = {
  // TODO: Errors?
  // loadingErrors?: JSONLoadError[]

  municipalityData: IBICMunicipalityData
  lifeAreas: string[]
}

const IBICLifeAreaTasks: React.FC<Props> = ({ lifeAreas, municipalityData }) => {
  const getTasksForLifeArea = (lifeArea: string) => {
    return [...municipalityData.input.timepointTasks, ...municipalityData.input.extraTasks].filter((task) => task.lifeArea === lifeArea)
  }

  return (
    <>
      <div>
        {lifeAreas.map((lifeArea) => (
          <Form.List name={['lifeAreaData', lifeArea, 'tasks']} key={lifeArea} initialValue={[]}>
            {(values) => (
              <>
                <Typography.Title
                  style={{
                    display: 'flex',
                    marginTop: '40px',
                    marginBottom: '40px',
                  }}
                  level={3}
                >
                  {lifeArea}
                </Typography.Title>
                <Divider style={{ width: '100%', marginBlock: 12 }} />
                {getTasksForLifeArea(lifeArea).map((task, index) => (
                  <SingleIBICLifeAreaTask lifeArea={lifeArea} taskName={task.name} index={index} />
                ))}
              </>
            )}
          </Form.List>
        ))}
      </div>
    </>
  )
}

export default IBICLifeAreaTasks
