import { Table, Typography } from 'antd'
import { summaryTableConfig } from './summaryTableConfig'
import { IBICFormTask } from './types/FormValues'

interface IBICBeslutsstodSummaryProps {
  tasks?: IBICFormTask[]
}

export const IBICBeslutsstodSummary = ({ tasks }: IBICBeslutsstodSummaryProps) => {
  if (!tasks) {
    return <></>
  }

  return (
    <div className="flex flex-col gap-2">
      <Typography.Title level={4}>Sammanfattning</Typography.Title>
      <Table size="small" bordered columns={summaryTableConfig} dataSource={tasks} loading={false} pagination={false} />
    </div>
  )
}
