import { PlusOutlined } from '@ant-design/icons'
import { Button, Input, List, Popover, Space } from 'antd'
import React, { useState } from 'react'
import { IBICMunicipalityData } from '../../types/municipalityData'

type Props = {
  municipalityData: IBICMunicipalityData
  onChange: (municipalityData: IBICMunicipalityData) => void
}

const EditableLifeAreas = ({ municipalityData, onChange }: Props) => {
  const editableData = JSON.parse(JSON.stringify(municipalityData)) as IBICMunicipalityData
  const [newLifeAreaName, setNewLifeAreaName] = useState('')
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)

  const [closingTimeout, setClosingTimeout] = useState<NodeJS.Timeout | undefined>(undefined)

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewLifeAreaName(e.target.value)
  }

  const addItem = () => {
    editableData.input.lifeAreas.push(newLifeAreaName)
    onChange(editableData)
    setNewLifeAreaName('')
  }

  const deleteItem = (timepointName: string) => {
    editableData.input.lifeAreas = editableData.input.lifeAreas.filter((item) => item !== timepointName)
    onChange(editableData)
  }

  const onBlur = () => {
    setClosingTimeout(setTimeout(() => setPopoverOpen(false), 200))
  }

  const onFocus = () => {
    clearTimeout(closingTimeout)
  }

  const popoverContent = (
    <div tabIndex={1} onFocus={onFocus}>
      <List
        size="small"
        bordered
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space style={{ padding: '0 8px 4px' }}>
              <Input placeholder="Skapa ny tidpunkt" onChange={onNameChange} value={newLifeAreaName} />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem} onPointerDown={(e) => e.preventDefault()} disabled={!newLifeAreaName}>
                Lägg till
              </Button>
            </Space>
          </div>
        }
        dataSource={editableData.input.lifeAreas}
        renderItem={(lifeArea) => (
          <List.Item
            key={lifeArea}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>{lifeArea}</div>
            <Button onClick={() => deleteItem(lifeArea)} danger size="small" onPointerDown={(e) => e.preventDefault()}>
              Ta bort
            </Button>
          </List.Item>
        )}
      />
    </div>
  )

  if (!editableData) {
    return null
  }

  return (
    <div tabIndex={1} onBlur={onBlur}>
      <Popover title="Ändra livsområden" content={popoverContent} open={popoverOpen}>
        <Button onClick={() => setPopoverOpen(!popoverOpen)}>Ändra livsområden</Button>
      </Popover>
    </div>
  )
}

export default EditableLifeAreas
