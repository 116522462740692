import { useApi } from '../../api/useApi'

// Antd
import type { SelectProps } from 'antd'
import { Alert, Button, DatePicker, Select, Spin, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import { useQuery } from '@tanstack/react-query'
import { FilterValue } from 'antd/lib/table/interface'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'
import { setVolumeCases } from '../../api/arendeApi'
import FlagListHeader from '../../components/header'
import { getAnsvarigPersonId, getAnsvarigPersonName, setAnsvarigPerson } from '../../store/ansvarigPersonSlice'
import { getMe } from '../../store/organizationSlice'
import { IGetMyCasesAction } from '../arende/store/arendeReducer/actions'
import { fecthDistribution, fetchVolumeCases } from '../arendeFordelning/queries'
import { IVolumeData, createVolymuppföljningTableConfig } from './tableConfig'

export interface IVolymuppföljningTableEntry {
  key: React.Key
  id: number
  brukare: { id: number; namn: string }
  beslut: number
  beviljadTid: number
}

type Members = {
  value: string
  label: string
}

function sortDataByLabel(data: Members[]): Members[] {
  const sortedData = data?.slice().sort((a, b) => a.label.localeCompare(b.label))
  return sortedData
}

async function getTableData(startDate: string | null, endDate: string | null, selected_user: string, dispatch: Dispatch<IGetMyCasesAction>) {
  if (!startDate || !endDate) return
  return await setVolumeCases(useApi(), dispatch, startDate, endDate, selected_user, localStorage.getItem('hydra_id_token') || '')
}

type Props = {}

const VolumeTable = (props: Props) => {
  const { RangePicker } = DatePicker
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [tableData, setTableData] = useState<IVolumeData[] | null>([])
  const [datesPicked, setDatesPicked] = useState(false)
  const [fetchingData, setFetchingData] = useState(false)
  const [options, setOptions] = useState<SelectProps['options']>([])
  const [selected, setSelected] = useState<string[]>([])
  const [renderTable, setRenderTable] = useState<ColumnsType<IVolumeData> | undefined>([])
  const [members, setMembers] = useState<Members[]>([])
  const navigate = useHistory()
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({})

  const dispatch = useDispatch()

  const ansvarigPerson = useSelector(getAnsvarigPersonId)
  const ansvarigPersonName = useSelector(getAnsvarigPersonName)
  const me = useSelector(getMe)

  const handleChange = (value: any) => {
    setSelected(value)
  }

  const resetFilters = () => {
    setSelected([])
  }

  const resetTableFilters = () => {
    const resetFilters = { ...filters }
    Object.keys(resetFilters).forEach((key) => {
      resetFilters[key] = null
    })
    setFilters(resetFilters)
  }

  const handleMemberSelect = (value: any) => {
    const correctMember = myCasesData?.find((item) => item.ansvarig_personnummer === value)
    dispatch(setAnsvarigPerson({ ansvarigPersonId: correctMember ? correctMember.ansvarig_personnummer : '', ansvarigPersonName: correctMember ? correctMember.ansvarig_namn : '' }))
  }

  function columnsOption(startDate: string | null, endDate: string | null) {
    if (!startDate || !endDate) {
      setOptions([])
      return
    }
    const temp: SelectProps['options'] = []
    const start = moment(startDate)
    const end = moment(endDate)
    while (start.isBefore(end, 'day')) {
      temp?.push({ value: start.format('YYYY-MM'), label: start.format('YYYY-MM') })
      start.add(1, 'month')
    }
    setOptions(temp)
  }

  function handleDatesChange(range: any) {
    const start = range[0].startOf('month').format('YYYY-MM-DD')
    const end = range[1].endOf('month').format('YYYY-MM-DD')
    setStartDate(start)
    setEndDate(end)
  }

  function handleOpenChange(isOpen: boolean) {
    setDatesPicked(isOpen)
  }

  const { isLoading, isError, data, error } = useQuery(['volymuppföljning', startDate, endDate, ansvarigPerson], fetchVolumeCases)

  const { isError: isMyCasesError, data: myCasesData, error: myCasesError, isLoading: myCasesLoading } = useQuery(['mycases'], fecthDistribution)

  useEffect(() => {
    const selectOptions = myCasesData?.map((item) => {
      return { value: item.ansvarig_personnummer, label: item.ansvarig_namn }
    })
    setMembers(selectOptions as Members[])
  }, [myCasesData])

  useEffect(() => {
    columnsOption(startDate, endDate)
  }, [startDate, endDate])

  useEffect(() => {
    if (!isLoading && !isError) {
      setRenderTable(createVolymuppföljningTableConfig(data?.sort((a, b) => (a.brukare_namn < b.brukare_namn ? -1 : 1)) as IVolumeData[], selected, filters))
    }
  }, [selected, datesPicked, data, filters])

  return (
    <>
      <FlagListHeader heading={'Volymuppföljning' + ' - ' + ansvarigPersonName} title={null} description={null} button={false} />
      <Select
        style={{ minWidth: '20%', marginBottom: '20px', marginRight: '25px' }}
        value={ansvarigPerson ? { label: ansvarigPersonName, value: ansvarigPerson } : undefined}
        placeholder="Byt handläggare"
        options={sortDataByLabel(members) || []}
        onChange={handleMemberSelect}
      ></Select>
      <Select
        mode="multiple"
        maxTagCount={'responsive'}
        style={{ minWidth: '20%', maxHeight: '40px', marginBottom: '20px', marginRight: '25px' }}
        placeholder="Bortvalda kolumner"
        onChange={handleChange}
        options={options}
        value={selected}
      />
      <Button style={{ marginBottom: '20px', marginRight: '25px' }} onClick={resetFilters}>
        {' '}
        Rensa bortvalda kolumner
      </Button>
      <Button style={{ marginBottom: '20px', marginRight: '25px' }} onClick={resetTableFilters}>
        Rensa filter
      </Button>
      <RangePicker className="range-picker" style={{ width: '20%', marginBottom: '20px', marginRight: '25px' }} picker="month" onChange={handleDatesChange} onOpenChange={handleOpenChange} />
      {tableData !== null ? (
        <Spin spinning={myCasesLoading || isLoading}>
          <Table
            columns={renderTable}
            dataSource={data as IVolumeData[]}
            bordered
            rowClassName="table-row-cursor"
            size="small"
            loading={false}
            scroll={{ x: 1000, y: 'calc(100vh - 350px)' }}
            // Show only pagination numbers and not the page buttons
            pagination={{
              position: ['topRight'],
              pageSize: 10000,
              showTotal: (_, range) => {
                return `${range[1]} av ${data?.length ?? 0}`
              },
              className: 'table-pagination-hidden',
              showSizeChanger: false,
            }}
            onChange={(pagination, filters, sorter, { currentDataSource }) => {
              setFilters(filters)
            }}
          />
        </Spin>
      ) : (
        <Spin spinning={fetchingData}>
          <Alert message="Välj ett datumintervall" description="Information visas först när ett intervall är valt." type="info" />
        </Spin>
      )}
    </>
  )
}

export default VolumeTable
