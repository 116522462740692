import { useQuery } from '@tanstack/react-query'
import { AxiosInstance } from 'axios'

export const fetchNewCases = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/newCases?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchOrderedTime = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/orderedTime?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchBrukareCount = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/brukareCount?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchCanceledCases = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/canceledCases?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchBrukareBestalldTid = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/brukareBestalldTid?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchUtfordTid = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/utfordTid?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchBrukareMedBestalldTid = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/antalBrukareBeviljad?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchBrukareMedBeviljadTid = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/antalBrukareBeviljad?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchBestalldOmvardnad = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/bestalldOmvardnad?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchBestalldService = async (api: AxiosInstance, from: string, to: String) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/bestalldService?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchAmountBrukare = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/omfattandeBeslutBrukare?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchAmountOrderedTime = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/omfattandeBeslut?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchNyaBruakre = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/nyaBrukare?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchTimeOfNewBrukareCases = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/timeOfNewBrukareCases?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchAmountBrukareCanceledCases = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/ammountBrukareCanceledCases?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchTimeOfCanceledCases = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/timeOfCanceledCases?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchLarmuttryckningar = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/larmuttryckningar?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchUtfordaNattinsatser = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/utfordaNattinsatser?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchBevlijadeLarmNattinsattser = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/bevlijadeLarmNattinsattser?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchBestalldTidPerBrukare = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/bestalldTidPerBrukare?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchLarm = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/larm?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchAvgAge = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/avgAge?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchAvgAgeNewCases = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/avgAgeNewCases?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchAvgAgeCanceledCases = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/avgAgeCanceledCases?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchCanceledCasesByBrukare = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/canceledCasesByBrukare?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchNewCasesByBrukare = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/newCasesByBrukare?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchUtfordPerBrukare = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/utfordPerBrukare?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchUtfordTidPerBrukare = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/utfordTidPerBrukare?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchLarmHemtjanst = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/larmHemtjanst?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchLarmNotHemtjanst = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/larmNotHemtjanst?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}

export const fetchBeslutsomfattningPerInterval = async (api: AxiosInstance, from: string, to: string) => {
  const { data } = await api.get(`v1/benchmark/${localStorage.getItem('tenant_id')}/beslutsomfattningPerInterval?from=${from}&to=${to}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return data
}
