import { useHistory } from 'react-router-dom'
import FlagListHeader from '../../components/header'

// Antd
import { Button, Input, Table } from 'antd'
import { useEffect, useState } from 'react'
import { SearchTagsParams, useTags } from '../../api/municipality'
import { createTagsTableConfig } from '../../components/tablesConfig/tagsTableConfig'

const Tags = () => {
  const navigate = useHistory()
  const { data, mutate, isLoading } = useTags()

  const [{ search }, setPaginationOptions] = useState<SearchTagsParams>({
    search: '',
  })

  const [page, setPage] = useState(1)

  const [timeoutHandler, setTimeoutHandler] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    mutate({ search })
  }, [search])

  const tags = data?.data ?? []
  const total = data?.total ?? 0

  const { columns } = createTagsTableConfig(tags, {}, navigate)

  const [searchValue, setSearchValue] = useState('')

  return (
    <>
      <FlagListHeader heading="Taggar" title={null} description={null} button={false} />
      <div className="mb-3 flex gap-0.5">
        <Input
          placeholder="Sök taggar"
          onChange={(e) => {
            setSearchValue(e.target.value)
            if (timeoutHandler) clearTimeout(timeoutHandler)
            setTimeoutHandler(
              setTimeout(() => {
                setPaginationOptions((prev) => ({ ...prev, search: e.target.value }))
              }, 500)
            )
          }}
          style={{ width: 200 }}
          value={searchValue}
        />
        <Button
          type="primary"
          onClick={() => {
            navigate.push('/app/taggar/ny')
          }}
        >
          Skapa tagg
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        bordered
        loading={isLoading}
        dataSource={tags}
        rowClassName={'none-cursor'}
        pagination={{ total, current: page }}
        size="small"
        scroll={{ y: 'calc(100vh - 300px)', x: undefined }}
        onChange={(pagination) => {
          setPage(pagination.current ?? 1)
        }}
      />
    </>
  )
}

export default Tags
