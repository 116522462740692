import { Dispatch } from 'redux'
import { DataType } from '../../../components/tablesConfig/filterconfig'

export const SET_BRUKARE = 'SET_BRUKARE'
export const LOADING_BRUKARE = 'LOADING_BRUKARE'

export type ISetBrukareAction = {
  type: typeof SET_BRUKARE
  payload: {
    data: DataType[]
  }
}

export interface ILoadingAction {
  type: typeof LOADING_BRUKARE
  payload: {
    loading: boolean
  }
}

export const setBrukareAction = (type: string, data: DataType[], dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_BRUKARE,
    payload: {
      data: data,
    },
  })
  dispatch({
    type: LOADING_BRUKARE,
    payload: {
      loading: false,
    },
  })
}

export type Actions = ISetBrukareAction | ILoadingAction
