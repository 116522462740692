import { PlusOutlined } from '@ant-design/icons'
import { Button, Input, List, Popover, Space } from 'antd'
import React, { useState } from 'react'
import { Frequency } from '../types/municipalityData'

interface EditableLevelProps {
  frequencies: Frequency[]
  updateFrequencies: (task: Frequency[]) => void
  name: string
}

const EditableFrequencies = ({ frequencies, updateFrequencies, name }: EditableLevelProps) => {
  const [nameInputValue, setNameInputValue] = useState('')
  const [timesPerWeekInputValue, setTimesPerWeekInputValue] = useState<number | null>(null)

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)

  const [closingTimeout, setClosingTimeout] = useState<NodeJS.Timeout | undefined>(undefined)

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameInputValue(e.target.value)
  }

  const onTimesPerWeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimesPerWeekInputValue(e.target.valueAsNumber)
  }

  const addItem = () => {
    if (!nameInputValue || !timesPerWeekInputValue) return

    updateFrequencies([...frequencies, { name: nameInputValue, timesPerWeek: Math.round(1000 * timesPerWeekInputValue) / 1000 }])
    setNameInputValue('')
    setTimesPerWeekInputValue(0)
  }

  const deleteItem = (frequency: Frequency) => {
    updateFrequencies(frequencies.filter((item) => item.name !== frequency.name))
  }

  const onBlur = () => {
    setClosingTimeout(setTimeout(() => setPopoverOpen(false), 200))
  }

  const onFocus = () => {
    clearTimeout(closingTimeout)
  }

  const popoverContent = (
    <div tabIndex={1} onFocus={onFocus}>
      <List
        size="small"
        bordered
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space style={{ padding: '0 8px 4px' }}>
              <Input placeholder="Namn på frekvens" onChange={onNameChange} value={nameInputValue} />
              <Input type="number" placeholder="antal ggr per vecka" onChange={onTimesPerWeekChange} value={timesPerWeekInputValue ?? ''} />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem} onPointerDown={(e) => e.preventDefault()} disabled={!nameInputValue || !timesPerWeekInputValue}>
                Lägg till
              </Button>
            </Space>
          </div>
        }
        dataSource={frequencies}
        renderItem={(item) => (
          <List.Item
            key={item.name}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              {item.name} ({item.timesPerWeek} ggr/vecka)
            </div>
            <Button onClick={() => deleteItem(item)} danger size="small" onPointerDown={(e) => e.preventDefault()}>
              Ta bort
            </Button>
          </List.Item>
        )}
      />
    </div>
  )

  return (
    <div tabIndex={1} onBlur={onBlur}>
      <Popover title={`Frekvenser för '${name}'`} content={popoverContent} open={popoverOpen}>
        <Button style={{ width: '100%' }} onClick={() => setPopoverOpen(!popoverOpen)}>
          Frekvenser
        </Button>
      </Popover>
    </div>
  )
}

export default EditableFrequencies
