import addThousandSeparator from '../../utils/thousandSeperator'

const larmOchNattTableConfig = (data: any) => {
  const res: any[] = []

  const nyaBrukare = data.larm || []
  const larmuttryckningar = data.larmuttryckningar
  const larmHemtjanst = data.larmHemtjanst || []
  const larmNotHemtjanst = data.larmNotHemtjanst || []

  var formattedNyaBrukare: {
    [key: string]: any
  } = { name: 'Antal brukare med larm' }
  var formattedLarmHemtjanst: {
    [key: string]: any
  } = { name: 'Antal brukare med larm och hemtjänst' }
  var formattedLarm: {
    [key: string]: any
  } = { name: 'Antal brukare med enbart larm' }
  var formattedLarmUtryckningar: {
    [key: string]: any
  } = { name: 'Antal larmuttryckningar' }

  nyaBrukare.forEach((item: any, index: number) => {
    formattedNyaBrukare[index] = addThousandSeparator(Math.round(item.amount))
  })

  larmuttryckningar.forEach((item: any, index: number) => {
    formattedLarmUtryckningar[index] = addThousandSeparator(Math.round(item.amount))
  })

  larmHemtjanst.forEach((item: any, index: number) => {
    formattedLarmHemtjanst[index] = addThousandSeparator(Math.round(item.amount))
  })

  larmNotHemtjanst.forEach((item: any, index: number) => {
    formattedLarm[index] = addThousandSeparator(Math.round(item.amount))
  })

  res.push(formattedNyaBrukare)
  res.push(formattedLarmHemtjanst)
  res.push(formattedLarm)
  res.push(formattedLarmUtryckningar)

  return res
}

export default larmOchNattTableConfig
