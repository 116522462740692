import { IHandläggare } from '../../../store/organizationSlice'
import * as actions from './actions'
import { Actions } from './actions'

export type State = {
  data: IHandläggare[]
}

const initialState: State = {
  data: [],
}

const anvandareReducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case actions.SET_ANVANDARE:
      return (state = {
        ...state,
        data: action.payload.data,
      })
    default:
      return state
  }
}

export default anvandareReducer
