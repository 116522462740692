import { IDistributionData } from '../..'
import type { ColumnsType } from 'antd/es/table'
import { useSelector } from 'react-redux'
import { getHandläggare } from '../../../../store/organizationSlice'
import { Link } from 'react-router-dom'

export interface IYearMonthObject {
  ym: string
  till: string
  från: string
  bräknad_omfattning: number
  utförd_tid: number
  beräknad_omfattning_exakt: number
  från_exakt: string
  till_exakt: string
}

export const findHandlaggareByName = (name: string, anvandareState: any[]) => {
  if (name === 'Total') {
    return { team: { team_namn: 'Total' } }
  }
  const handlaggare = anvandareState.find((item: any) => item.namn === name)
  if (handlaggare !== undefined) {
    return handlaggare
  }
  return null
}

export const createTotalArendeFordelningTableConfig = (data: IDistributionData[]) => {
  const anvandareState = useSelector(getHandläggare)

  const columns: ColumnsType<IDistributionData> = [
    {
      title: 'Handläggare',
      dataIndex: '',
      key: 'handlaggare',
      showSorterTooltip: { title: 'Sortera på handläggare' },
      width: 150,
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.ansvarig_namn
          })
        )
      ).map((name) => {
        return {
          value: name,
          text: name,
        }
      }),
      sorter: (a, b) => a.ansvarig_namn.localeCompare(b.ansvarig_namn),
      render: (_, obj) => <b>{obj.ansvarig_namn}</b>,
      onFilter: (value, record) => record.ansvarig_namn === value || record.ansvarig_namn === 'Total',
      filterSearch: true,
    },
    {
      title: 'Team',
      dataIndex: '',
      key: 'team',
      width: 70,
      showSorterTooltip: { title: 'Sortera på team' },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.ansvarig_namn === '' ? null : findHandlaggareByName(d.ansvarig_namn, anvandareState)?.team.team_namn ?? null
          })
        )
      ).map((name) => {
        return {
          value: name,
          text: name,
        }
      }),
      sorter: (a, b) => findHandlaggareByName(a.ansvarig_namn, anvandareState).team.team_namn.localeCompare(findHandlaggareByName(b.ansvarig_namn, anvandareState).team.team_namn),
      render: (_, obj) => <b>{findHandlaggareByName(obj.ansvarig_namn, anvandareState).team.team_namn}</b>,
      onFilter: (value, record) => findHandlaggareByName(record.ansvarig_namn, anvandareState).team.team_namn === value || record.ansvarig_namn === 'Total',
      filterSearch: true,
    },
    {
      title: 'Antal hemtjänst ärenden',
      dataIndex: 'key',
      key: 'hemtjänst',
      showSorterTooltip: { title: 'Sortera på antal hemtjänst ärenden' },
      width: 70,
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.antal_hemtjänst
          })
        )
      ).map((name) => {
        return {
          value: name,
          text: name,
        }
      }),
      sorter: (a, b) => a.antal_hemtjänst - b.antal_hemtjänst,
      render: (_, obj) => <b>{String(obj.antal_hemtjänst)}</b>,
      onFilter: (value, record) => record.antal_hemtjänst === value,
      filterSearch: true,
    },
    {
      title: 'Säbo',
      dataIndex: 'key',
      key: 'säbo',
      width: 70,
      showSorterTooltip: { title: 'Sortera på antal säbo ärenden' },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.antal_säbo
          })
        )
      ).map((name) => {
        return {
          value: name,
          text: name,
        }
      }),
      sorter: (a, b) => a.antal_säbo - b.antal_säbo,
      render: (_, obj) => <b>{String(obj.antal_säbo)}</b>,
      onFilter: (value, record) => record.antal_säbo === value,
      filterSearch: true,
    },
    {
      title: 'Beviljade timmar per månad hemtjänst',
      dataIndex: 'key',
      key: 'beviljade',
      width: 100,
      showSorterTooltip: {
        title: 'Sortera på beviljade timmar per månad hemtjänst',
      },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.beviljade_timmar
          })
        )
      ).map((name) => {
        return {
          value: name,
          text: name,
        }
      }),
      sorter: (a, b) => a.beviljade_timmar - b.beviljade_timmar,
      render: (_, obj) => <b>{obj.beviljade_timmar?.toFixed(1)}</b>,
      onFilter: (value, record) => record.beviljade_timmar === value,
      filterSearch: true,
    },
    {
      title: 'Vårdtyngd/brukare/månad',
      dataIndex: 'key',
      key: 'vårdtyngd',
      width: 120,
      showSorterTooltip: { title: 'Sortera på vårdtyngd/brukare/månad' },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.vårdtyngd
          })
        )
      ).map((name) => {
        return {
          value: name,
          text: name,
        }
      }),
      sorter: (a, b) => a.vårdtyngd - b.vårdtyngd,
      render: (_, obj) => <b>{obj.vårdtyngd}</b>,
      onFilter: (value, record) => record.vårdtyngd === value,
      filterSearch: true,
    },
  ]

  return columns
}

export const createArendeFordelningTableConfig = (data: IDistributionData[], filters: any) => {
  const anvandareState = useSelector(getHandläggare)

  const columns: ColumnsType<IDistributionData> = [
    {
      title: 'Handläggare',
      dataIndex: '',
      key: 'handlaggare',
      width: 150,
      showSorterTooltip: { title: 'Ansvarig handläggare' },
      filteredValue: filters.handlaggare,
      fixed: 'left',
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.ansvarig_namn
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => a.ansvarig_namn.localeCompare(b.ansvarig_namn),
      render: (_, obj) => (
        <Link
          to={{
            pathname: `/app/mina-arenden`,
          }}
        >
          <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
            {obj.ansvarig_namn}
          </span>
        </Link>
      ),
      onFilter: (value, record) => record.ansvarig_namn === value || record.ansvarig_namn === 'Total',
      filterSearch: true,
    },
    {
      title: 'Team',
      dataIndex: '',
      key: 'team',
      width: 70,
      showSorterTooltip: { title: 'Det team som handläggaren tillhör' },
      filteredValue: filters.team,
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              if (findHandlaggareByName(d.ansvarig_namn, anvandareState) !== null) {
                return d.ansvarig_namn === '' ? null : findHandlaggareByName(d.ansvarig_namn, anvandareState).team.team_namn
              }
              return ''
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => {
        if (findHandlaggareByName(a.ansvarig_namn, anvandareState) !== null) {
          return findHandlaggareByName(a.ansvarig_namn, anvandareState).team.team_namn.localeCompare(findHandlaggareByName(b.ansvarig_namn, anvandareState).team.team_namn)
        }
        return ''
      },
      render: (_, obj) => {
        if (findHandlaggareByName(obj.ansvarig_namn, anvandareState) !== null) {
          return <>{findHandlaggareByName(obj.ansvarig_namn, anvandareState).team.team_namn}</>
        }
        return <></>
      },
      onFilter: (value, record) => {
        if (findHandlaggareByName(record.ansvarig_namn, anvandareState)) {
          return findHandlaggareByName(record.ansvarig_namn, anvandareState).team.team_namn === value || record.ansvarig_namn === 'Total'
        }
        return false
      },
      filterSearch: true,
    },
    {
      title: 'Hemtjänst',
      dataIndex: 'key',
      key: 'hemtjänst',
      width: 70,
      showSorterTooltip: { title: 'Antal brukare med beställda hemtjänstinsatser' },
      sorter: (a, b) => a.antal_hemtjänst - b.antal_hemtjänst,
      render: (_, obj) => <>{obj.antal_hemtjänst}</>,
    },
    {
      title: 'Säbo',
      dataIndex: 'key',
      key: 'säbo',
      width: 70,
      showSorterTooltip: { title: 'Antal brukare med beställda insatser om särskilt boende' },
      sorter: (a, b) => a.antal_säbo - b.antal_säbo,
      render: (_, obj) => <>{obj.antal_säbo}</>,
    },
    {
      title: 'Totala timmar hemtjänst',
      dataIndex: 'key',
      key: 'beviljade',
      width: 100,
      showSorterTooltip: {
        title: 'Antal totalt beställda timmar hemtjänst per månad',
      },
      sorter: (a, b) => a.beviljade_timmar - b.beviljade_timmar,
      render: (_, obj) => <>{obj.beviljade_timmar?.toFixed(1)}</>,
    },
    {
      title: 'Timmar hemtjänst per brukare',
      dataIndex: 'key',
      key: 'vårdtyngd',
      width: 120,
      showSorterTooltip: { title: 'Genomsnittligt antal beställda timmar hemtjänst per månad per brukare' },
      sorter: (a, b) => a.vårdtyngd - b.vårdtyngd,
      render: (_, obj) => <>{obj.vårdtyngd}</>,
      onFilter: (value, record) => record.vårdtyngd === value,
    },
  ]

  return columns
}
