import React, { useState, useEffect } from 'react'

// Antd
import { Modal } from 'antd'

type Props = {
  closeModal?: boolean
  cancelModal(): void
  modalText: { text: string; loading: string }
  sendData(): void
}

const CaseModal: React.FC<Props> = ({ closeModal, cancelModal, modalText, sendData }) => {
  const [visible, setVisible] = useState(true)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [text, setModalText] = useState(modalText.text)

  const handleOk = () => {
    setModalText(modalText.loading)
    setConfirmLoading(true)
    sendData()
  }

  useEffect(() => {
    if (closeModal) {
      setVisible(false)
      setConfirmLoading(false)
      cancelModal()
    }
  }, [closeModal, cancelModal])

  const handleCancel = () => {
    setVisible(false)
    cancelModal()
  }

  return (
    <>
      <Modal title="Initiera uppföljning" open={visible} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel} okText="Bekräfta" cancelText="Avbryt">
        {text}
      </Modal>
    </>
  )
}

export default CaseModal
