import { useCallback, useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import SmallLogo from '../../images/pejlen-icon.svg'
import Logo from '../../images/pejlen_liggande.svg'

// Antd
import { BarChartOutlined, LineChartOutlined, LogoutOutlined, SettingOutlined, TeamOutlined, WarningOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Button, Layout, Menu } from 'antd'
import cryptoRandomString from 'crypto-random-string'
import { useDispatch, useSelector } from 'react-redux'
import { useApi } from '../../api/useApi'
import { setAnsvarigPerson } from '../../store/ansvarigPersonSlice'
import { getMe, setMe, setOrganization } from '../../store/organizationSlice'
import { getActiveMenuItem, setActiveMenuItem } from '../../store/sideMenuSlice'
import siderMenuPermissions from '../../utils/siderMenuPermissions'
import { PermissionTypes } from './utils/generateSideMenuChildren'
import { siderMenuItem } from './utils/siderMenuItem'
import { useMunicipalityName } from '../../api/benchmarking'
const { Sider } = Layout

const hydra_url = process.env.REACT_APP_HYDRA_URL
const api_url = process.env.REACT_APP_API_URL

type MenuItem = Required<MenuProps>['items'][number]

const shouldDefaultClosed = (pathname: string) => {
  // Benchmarking starts collapsed
  if (pathname.includes('/app/benchmarking') && !pathname.includes('/app/benchmarking/introduction')) {
    return true
  }
  if (pathname.includes('/app/hemtjanst')) {
    return true
  }
  return false
}

const siderMenu = withRouter(({ history }) => {
  const { data: municipalityName } = useMunicipalityName()
  const navigate = useHistory()
  const api = useApi()
  const me = useSelector(getMe)
  const [collapsed, setCollapsed] = useState(shouldDefaultClosed(navigate.location.pathname))

  const selectedMenuItem = useSelector(getActiveMenuItem)

  const items: MenuItem[] = [
    siderMenuPermissions(me, 'flags')
      ? ({
          label: 'Avvikelser',
          icon: <WarningOutlined />,
          children: [
            siderMenuItem([PermissionTypes.utförare], me.permissions, '/app/utforare', 'Utförare'),
            siderMenuItem([PermissionTypes.personal], me.permissions, '/app/medarbetare', 'Personal'),
            siderMenuItem([PermissionTypes.brukare], me.permissions, '/app/brukare', 'Brukare'),
            siderMenuItem([PermissionTypes.uppföljningslista], me.permissions, '/app/arenden', 'Tilldelade avvikelser'),
          ],
        } as MenuItem)
      : null,
    siderMenuPermissions(me, 'uppföljning_statistik')
      ? ({
          label: 'Uppföljning/statistik',
          icon: <BarChartOutlined />,
          children: [siderMenuItem([PermissionTypes.uppföljning_statistik], me.permissions, '/app/hemtjanst', municipalityName)],
        } as MenuItem)
      : null,
    siderMenuPermissions(me, 'benchmarking')
      ? ({
          label: 'Benchmarking',
          icon: <LineChartOutlined />,
          children: [
            siderMenuItem([PermissionTypes.benchmarking], me.permissions, '/app/benchmarking/introduction', 'Introduktion'),
            siderMenuItem([PermissionTypes.benchmarking], me.permissions, '/app/benchmarking', 'Volym'),
          ],
        } as MenuItem)
      : null,
    siderMenuPermissions(me, 'handläggning', ['view_beslutsstöd'])
      ? ({
          label: 'Handläggning',
          icon: <TeamOutlined />,
          children: [
            siderMenuItem([PermissionTypes.ärendefördelning, PermissionTypes.utfanför_teamstruktur], me.permissions, 'översikt-parent', 'Översikt', undefined, [
              siderMenuItem([PermissionTypes.ärendefördelning], me.permissions, '/app/arendefordelning', 'Ärendefördelning'),
              siderMenuItem([PermissionTypes.utfanför_teamstruktur], me.permissions, '/app/utanfor-teamstruktur', 'Utanför struktur'),
            ]),
            siderMenuItem([PermissionTypes.mina_ärenden, PermissionTypes.volymuppföljning, PermissionTypes.nyligen_utgångna], me.permissions, 'handläggning-parent', 'Handläggare', undefined, [
              siderMenuItem([PermissionTypes.mina_ärenden], me.permissions, '/app/mina-arenden', 'Mina ärenden'),
              siderMenuItem([PermissionTypes.volymuppföljning], me.permissions, '/app/volymuppfoljning', 'Volymuppföljning'),
              siderMenuItem([PermissionTypes.nyligen_utgångna], me.permissions, '/app/nyligen-utganga', 'Nyligen utgångna'),
            ]),
            siderMenuPermissions(me, 'beslutsstöd') && siderMenuItem([PermissionTypes.view_beslutsstöd], me.permissions, '/app/beslutsstod', 'Beslutsstöd'),
            siderMenuPermissions(me, 'ibic_beslutsstöd') && siderMenuItem([PermissionTypes.view_ibic_beslutsstöd], me.permissions, '/app/ibic-beslutsstod', 'IBIC Beslutsstöd'),
          ],
        } as MenuItem)
      : null,
    siderMenuPermissions(me, 'användare')
      ? ({
          label: 'Inställningar',
          icon: <SettingOutlined />,
          children: [
            siderMenuItem([PermissionTypes.användarhantering], me.permissions, '/app/anvandare', 'Användare'),
            siderMenuItem([PermissionTypes.administration], me.permissions, '/app/taggar', 'Taggar'),
            siderMenuItem([PermissionTypes.administration], me.permissions, '/app/gäster', 'Gäster'),
            siderMenuItem([PermissionTypes.administration], me.permissions, '/app/roller', 'Roller'),
            siderMenuItem([PermissionTypes.administration], me.permissions, '/app/övrigt', 'Övrigt'),
          ],
        } as MenuItem)
      : null,
  ]

  const handleClick = () => {
    const state = cryptoRandomString({ length: 32 })
    localStorage.setItem('state', state)
    const postLogoutRedirectUri = (() => {
      if (process.env.NODE_ENV === 'development') {
        return 'http://127.0.0.1:5001/logout/dev'
      }
      return `https://pejlen.inrikta.se/logout/${localStorage.getItem('tenant')}`
    })()

    window.location.href = `${hydra_url}/oauth2/sessions/logout?id_token_hint=${localStorage.getItem('hydra_id_token')}&state=${state}&post_logout_redirect_uri=${postLogoutRedirectUri}`
  }

  const dispatch = useDispatch()

  const getOrganization = useCallback(async () => {
    try {
      const data = await api.get(`/v1/handlaggare/${localStorage.getItem('tenant_id')}/structure`, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
        },
      })

      dispatch(setOrganization(data.data))
    } catch (e) {
      console.error(e)
    }
  }, [])

  const getMyself = useCallback(async () => {
    try {
      const data = await api.get(`/v1/handlaggare/${localStorage.getItem('tenant_id')}/me?id_token=${localStorage.getItem('hydra_id_token')}`, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
        },
      })

      dispatch(setMe(data.data))
      dispatch(setAnsvarigPerson({ ansvarigPersonId: data.data.personnummer, ansvarigPersonName: data.data.namn }))
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    getOrganization()
    getMyself()
  }, [])

  useEffect(() => {
    if (shouldDefaultClosed(navigate.location.pathname)) {
      setCollapsed(true)
    }
  }, [navigate.location.pathname])

  return (
    <Sider
      theme="light"
      breakpoint={shouldDefaultClosed(navigate.location.pathname) ? undefined : 'lg'}
      collapsedWidth="60px"
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      //className="menu"
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        top: 0,
        left: 0,
      }}
    >
      <div>
        {collapsed ? (
          <div className="small-logo">
            <img src={SmallLogo} width="30" height="30" />
          </div>
        ) : (
          <div className="logo">
            <img src={Logo} alt="Inrikta logga" width={'90%'} />
          </div>
        )}
        <Menu
          mode="inline"
          selectedKeys={[selectedMenuItem]}
          defaultSelectedKeys={[window.location.pathname]}
          style={{ width: collapsed ? '60px' : '200px' }}
          onClick={(a) => {
            dispatch(setActiveMenuItem(a.key))
            history.push(`${a.key}`)
          }}
          items={items}
        />
      </div>
      <div className="menu-button-wrapper">
        {collapsed ? (
          <LogoutOutlined style={{ paddingLeft: '8px' }} onClick={handleClick} />
        ) : (
          <>
            <Button className="button-no-hover" style={{ paddingLeft: '40px' }} type="text" onClick={handleClick}>
              <LogoutOutlined />
              Logga ut
            </Button>
          </>
        )}
      </div>
    </Sider>
  )
})

export default siderMenu
