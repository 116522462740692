import { Spin } from 'antd'
import moment from 'moment'
import React, { ComponentProps } from 'react'
import { Line } from 'react-chartjs-2'
import { useHomeCarePerformedHoursTrendMixedValues, useMunicipalityName } from '../../../../api/benchmarking'
import { GetHomeCareOrderedHoursTrendMixedValues200ResponseComparisonValuesInner } from '../../../../gen/api'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { EmptySelector } from '../../components/EmptySelector'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { BASELINE_COLOR, LINE_BASELINE_COLOR, LINE_MEDIAN_COLOR, LINE_QUARTILE_1_COLOR, LINE_QUARTILE_3_COLOR, MEDIAN_COLOR, QUARTILE_1_COLOR, QUARTILE_3_COLOR } from '../../util/colors'
import { getFontSize } from '../../util/fontSize'
import { STATDB_NAME } from '../../util/names'

export const HomeCarePerformedHoursTrendMixed = ({ text }: { text: React.ReactNode }) => {
  const { data: municipalityName, isLoading: isMunicipalityNameLoading } = useMunicipalityName()
  const { data, isLoading } = useHomeCarePerformedHoursTrendMixedValues()

  if (isLoading || isMunicipalityNameLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const graphOptions: ComponentProps<typeof Line>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${context.parsed.y.toFixed(1)} h`
          },
        },
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
    },
  }

  const ComparisonMap = new Map<string | undefined, GetHomeCareOrderedHoursTrendMixedValues200ResponseComparisonValuesInner>()
  data.comparisonValues.forEach((item) => {
    if (!item.date) {
      return
    }

    ComparisonMap.set(item.date, item)
  })

  const graphData: ComponentProps<typeof Line>['data'] = {
    labels: data.baselineValues.map((item) => moment(item.date).format('YYYYMM')),
    datasets: [
      {
        label: municipalityName,
        borderColor: LINE_BASELINE_COLOR.border,
        data: data.baselineValues.map((item, index) => ({ y: item.value, x: index })),
        pointRadius: 0,
      },
      {
        label: 'Undre kvartil',
        borderColor: LINE_QUARTILE_1_COLOR.border,
        data: data.baselineValues.map((_, index) => ({ y: ComparisonMap.get(data.baselineValues[index].date)?.quartile1 ?? 0, x: index })),
        pointRadius: 0,
      },
      {
        label: 'Median',
        borderColor: LINE_MEDIAN_COLOR.border,
        data: data.baselineValues.map((_, index) => ({ y: ComparisonMap.get(data.baselineValues[index].date)?.median ?? 0, x: index })),
        pointRadius: 0,
      },
      {
        label: 'Övre kvartil',
        borderColor: LINE_QUARTILE_3_COLOR.border,
        data: data.baselineValues.map((_, index) => ({ y: ComparisonMap.get(data.baselineValues[index].date)?.quartile3 ?? 0, x: index })),
        pointRadius: 0,
      },
    ],
  }
  const title = 'Totalt antal timmar utförd hemtjänst per månad och brukare'
  const legend = (
    <BenchmarkingLegend
      items={[
        { text: municipalityName, borderColor: BASELINE_COLOR.border, fillColor: BASELINE_COLOR.fill },
        { text: 'Undre kvartil', borderColor: QUARTILE_1_COLOR.border, fillColor: QUARTILE_1_COLOR.fill },
        { text: 'Median', borderColor: MEDIAN_COLOR.border, fillColor: MEDIAN_COLOR.fill },
        { text: 'Övre kvartil', borderColor: QUARTILE_3_COLOR.border, fillColor: QUARTILE_3_COLOR.fill },
      ]}
    />
  )
  const graph: React.ReactNode = <Line options={graphOptions} data={graphData} />

  const dataSourceSelector = <EmptySelector value={STATDB_NAME} />
  const comparisonSelector = <EmptySelector value={'Fördelning'} />

  return <BenchmarkingContentRow graph={graph} text={text} title={title} legend={legend} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} />
}
