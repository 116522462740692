import { useHistory } from 'react-router-dom'
import FlagListHeader from '../../components/header'
import { Button, Table } from 'antd'
import { useRoles } from '../../api/permissions'
import { createRoleTableConfig } from '../../components/tablesConfig/roleTableConfig'

const Roles = () => {
  const navigate = useHistory()
  const { data, isLoading } = useRoles()

  if (!data) return null

  const { columns } = createRoleTableConfig(data, {}, navigate)

  return (
    <>
      <FlagListHeader heading="Roller" title={null} description={null} button={false} />
      <div className="mb-3 flex gap-0.5">
        <Button
          type="primary"
          onClick={() => {
            navigate.push('/app/roller/ny')
          }}
        >
          Skapa ny roll
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.name || ''}
        bordered
        loading={isLoading}
        dataSource={data}
        rowClassName={'none-cursor'}
        size="small"
        scroll={{ y: 'calc(100vh - 300px)', x: undefined }}
      />
    </>
  )
}

export default Roles
