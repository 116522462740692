export interface NameEntry {
  [key: string]: number | string
  name: string
}

function tableConfig(data: { resurs: string; amount: number; ym: string }[]): any[] {
  const uniqueDates = new Set(data.map(({ ym }) => ym))

  const dateToIndexMap = new Map(Array.from(uniqueDates).map((date, index) => [date, index]))

  const result: NameEntry[] = []

  for (const { resurs, amount, ym } of data.sort((a, b) => a.resurs.localeCompare(b.resurs))) {
    // Should not be possible to get undefined here
    const index = dateToIndexMap.get(ym) || 0
    const nameEntry = result.find((entry) => entry.name === resurs)

    if (nameEntry) {
      nameEntry[index] = amount
    } else {
      const newEntry: NameEntry = {
        name: resurs,
      }
      newEntry[index] = amount
      result.push(newEntry)
    }
  }

  const sortedResult = result.sort((a, b) => a.name.localeCompare(b.name))

  const indexSums: NameEntry = {
    name: 'total',
  }

  for (const { amount, ym } of data) {
    const index = dateToIndexMap.get(ym) || 0
    indexSums[index] = ((indexSums[index] as number) || 0) + amount
  }

  sortedResult.push(indexSums)

  return sortedResult
}

export default tableConfig
