import { Table } from 'antd'
import tableHeaderYm from '../utils/TableConfig/tableHeaderYm'
import tableConfig from './utils/tableConfig'
import divideIndexes from './utils/tableConfigAvg'
import tableConfigHours from './utils/tableConfigHours'

const StatistikPerutförare = (data: any) => {
  const antalBrukare = tableHeaderYm(data.bestalldTidPerBrukare, 'Antal brukare med beställd tid')
  const utfordTidPerBrukareheader = tableHeaderYm(data.bestalldTidPerBrukare, 'Beställd tid')
  const avgheader = tableHeaderYm(data.bestalldTidPerBrukare, 'Beställd tid per brukare')
  const antalBrukareData = tableConfig(data.bestalldTidPerBrukare)
  const utfordTidPerBrukareData = tableConfigHours(data.utfordTidPerBrukare)
  const avgData = divideIndexes(antalBrukareData, utfordTidPerBrukareData)

  return (
    <>
      <Table columns={antalBrukare} dataSource={antalBrukareData} scroll={{ x: 500, y: 300 }} pagination={false} size="small" />
      <Table columns={utfordTidPerBrukareheader} dataSource={utfordTidPerBrukareData} scroll={{ x: 500, y: 300 }} pagination={false} size="small" />
      <Table columns={avgheader} dataSource={avgData} scroll={{ x: 500, y: 300 }} pagination={false} size="small" />
    </>
  )
}

export default StatistikPerutförare
