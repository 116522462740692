import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../store'
import { ExtraTask, MunicipalityData } from '../../features/beslutsstod/types/municipalityData'
import { ensureBaseIntegrity } from './ensureBaseIntegrity'

interface MunicipalityState {
  municipalityData: MunicipalityData | null
}

const initialState: MunicipalityState = {
  municipalityData: null,
}

const municipalitySlice = createSlice({
  name: 'municipality',
  initialState,
  reducers: {
    setMunicipality(state, action: PayloadAction<MunicipalityState>) {
      state.municipalityData = action.payload.municipalityData
      if (!state.municipalityData) return

      state.municipalityData = ensureBaseIntegrity(state.municipalityData)

      state.municipalityData.input.timepointTasks = state.municipalityData.input.timepointTasks.map((task) => {
        delete (task as any).ibicCalculationRules
        return task
      })

      console.log(state.municipalityData?.input)
    },
  },
})

export const getMunicipality = (state: AppState) => state.municipalityReducer.municipalityData
export const { setMunicipality } = municipalitySlice.actions
export default municipalitySlice.reducer
