import { Dispatch } from 'redux'
import { IDistributionData } from '../../../arendeFordelning'
import { IMyCasesData2 } from '../../../minaArenden/tableConfig'
import { INyligenUtgångnaData } from '../../../nyligenUtgångna/tableConfig'
import { IUtanförTeamstrukturData } from '../../../utanforTeamStruktur/tableConfig'
import { IVolumeData } from '../../../volymuppfoljning/tableConfig'

export const SET_ARENDEN = 'SET_ARENDEN'
export const LOADING_ARENDEN = 'LOADING_ARENDEN'
export const SET_DISTRIBUTION = 'SET_DISTRIBUTION'
export const LOADING_DISTRIBUTION = 'LOADING_DISTRIBUTION'
export const SET_MY_CASES = 'GET_MY_CASES'
export const LOADING_MY_CASES = 'LOADING_MY_CASES'
export const SET_VOLUME = 'SET_VOLUME'
export const LOADING_VOLUME = 'LOADING_VOLUME'
export const SET_UTANFÖR_TEAMSTRUKTUR = 'SET_UTANFÖR_TEAMSTRUKTUR'
export const SET_NYLIGEN_UTGÅNGNA = 'SET_NYLIGEN_UTGÅNGNA'

export type IGetVolumeAction = {
  type: typeof SET_VOLUME
  payload: {
    data: IVolumeData[]
  }
}

export interface ILoadingVolumeAction {
  type: typeof LOADING_VOLUME
  payload: {
    loading: boolean
  }
}

export type IGetDistributionAction = {
  type: typeof SET_DISTRIBUTION
  payload: {
    data: IDistributionData[]
  }
}

export interface ILoadingDistributionAction {
  type: typeof LOADING_DISTRIBUTION
  payload: {
    loading: boolean
  }
}

export type IGetMyCasesAction = {
  type: typeof SET_MY_CASES
  payload: {
    data: IMyCasesData2[]
  }
}

export interface ILoadigMyCasesAction {
  type: typeof LOADING_MY_CASES
  payload: {
    data: boolean
  }
}

export type ISetUtanförTeamstrukturAction = {
  type: typeof SET_UTANFÖR_TEAMSTRUKTUR
  payload: {
    data: IUtanförTeamstrukturData[]
  }
}

export type ISetNyligenUtgångnaAction = {
  type: typeof SET_NYLIGEN_UTGÅNGNA
  payload: {
    data: INyligenUtgångnaData[]
  }
}

export interface ILoadingAction {
  type: typeof LOADING_ARENDEN
  payload: {
    loading: boolean
  }
}

export const setVolumeAction = (type: string, data: IVolumeData[], dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_VOLUME,
    payload: {
      data: data,
    },
  })
  dispatch({
    type: LOADING_VOLUME,
    payload: {
      loading: false,
    },
  })
}

export const setDistributionAction = (type: string, data: IDistributionData[], dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_DISTRIBUTION,
    payload: {
      data: data,
    },
  })
  dispatch({
    type: LOADING_DISTRIBUTION,
    payload: {
      loading: false,
    },
  })
}

export const setMyCasesAction = (type: string, data: IMyCasesData2[], dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_MY_CASES,
    payload: {
      data: data,
    },
  })
  dispatch({
    type: LOADING_MY_CASES,
    payload: {
      loading: false,
    },
  })
  dispatch({
    type: LOADING_ARENDEN,
    payload: {
      loading: false,
    },
  })
}

export const setUtanförTeamstrukturAction = (type: string, data: IUtanförTeamstrukturData[], dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_UTANFÖR_TEAMSTRUKTUR,
    payload: {
      data: data,
    },
  })
}

export const setNyligenUtgångaAction = (type: string, data: INyligenUtgångnaData[], dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_NYLIGEN_UTGÅNGNA,
    payload: {
      data: data,
    },
  })
}

export type Actions =
  | ILoadingAction
  | IGetDistributionAction
  | ILoadingDistributionAction
  | IGetMyCasesAction
  | ILoadigMyCasesAction
  | IGetVolumeAction
  | ILoadingVolumeAction
  | ISetUtanförTeamstrukturAction
  | ISetNyligenUtgångnaAction
