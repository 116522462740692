import { Select, Spin } from 'antd'
import React, { ComponentProps, useEffect } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { useHomeCareVolumeAgeGroupTrendValues } from '../../../../api/benchmarking'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { getFontSize } from '../../util/fontSize'
import { getColorByIndex } from '../../util/colors'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import moment from 'moment'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { EmptySelector } from '../../components/EmptySelector'
import { STATDB_NAME, NO_COMPARISON } from '../../util/names'

export const HomeCareVolumeAgeGroupTrend = ({ text }: { text: React.ReactNode }) => {
  const { data, isLoading, mutate } = useHomeCareVolumeAgeGroupTrendValues()

  useEffect(() => {
    mutate(undefined)
  }, [])

  if (isLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const graphOptions: ComponentProps<typeof Line>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return context.parsed.y.toString()
          },
          title: (context) => {
            return context[0].dataset.label + ' år:'
          },
        },
      },
    },

    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
    },
  }
  const graphData: ComponentProps<typeof Line>['data'] = {
    labels: data[0].values.map((item) => moment(item.date).format('YYYYMM')),
    datasets: data.map((item, index) => ({
      fill: true,
      label: item.ageRange,
      backgroundColor: getColorByIndex(index).fill,
      borderColor: getColorByIndex(index).border,
      data: item.values.map((value) => value.value),
      pointRadius: 0,
    })),
  }
  const legend = (
    <BenchmarkingLegend
      items={data.map((item, index) => ({
        text: item.ageRange,
        fillColor: getColorByIndex(index).fill,
        borderColor: getColorByIndex(index).border,
      }))}
    />
  )
  const title = 'Antal individer med hemtjänst per åldersgrupp'
  const graph: React.ReactNode = <Line options={graphOptions} data={graphData} />

  const dataSourceSelector = <EmptySelector value={STATDB_NAME} />
  const comparisonSelector = <EmptySelector value={'Fördelning'} />

  return <BenchmarkingContentRow graph={graph} text={text} title={title} legend={legend} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} />
}
