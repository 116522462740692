import { Dispatch } from 'redux'
import { DataType } from '../../../components/tablesConfig/filterconfig'

export const SET_MEDARBETARE = 'SET_MEDARBETARE'
export const LOADING_MEDARBETARE = 'LOADING_MEDARBETARE'

export type ISetMedarbetareAction = {
  type: typeof SET_MEDARBETARE
  payload: {
    data: DataType[]
  }
}

export interface ILoadingAction {
  type: typeof LOADING_MEDARBETARE
  payload: {
    loading: boolean
  }
}

export const setMedarbetareAction = (type: string, data: DataType[], dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_MEDARBETARE,
    payload: {
      data: data,
    },
  })
  dispatch({
    type: LOADING_MEDARBETARE,
    payload: {
      loading: false,
    },
  })
}

export type Actions = ISetMedarbetareAction | ILoadingAction
