import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './store'
import moment from 'moment'

interface personalDateSlice {
  from: string
  to: string
}

const initialState = {
  from: moment().startOf('month').format('YYYY-MM-DD'),
  to: moment().endOf('month').format('YYYY-MM-DD'),
} as personalDateSlice

const personalDateSlice = createSlice({
  name: 'personalDate',
  initialState,
  reducers: {
    setPersonalDate(state, action: PayloadAction<personalDateSlice>) {
      return action.payload
    },
  },
})

export const getPersonalDateFrom = (state: AppState) => state.personalDateReducer.from
export const getPersonalDateTo = (state: AppState) => state.personalDateReducer.to
export const { setPersonalDate } = personalDateSlice.actions
export default personalDateSlice.reducer
