import { ContentColumn } from '..'
import BenchmarkingContentRow from '../components/BenchmarkingContentRow'

const treeStructureImage = (
  <div className="flex justify-center">
    <img src="https://via.placeholder.com/350" alt="Benchmarking Tree Structure" />
  </div>
)

const description = (
  <div className="text-gray-500">
    <p>
      The Benchmarking module is a tool that allows you to compare your performance against your peers. It is a powerful tool that can help you identify areas of improvement and set realistic goals
      for your business.
    </p>
    <p>
      The Benchmarking module is a tool that allows you to compare your performance against your peers. It is a powerful tool that can help you identify areas of improvement and set realistic goals
      for your business.
    </p>
  </div>
)

const benchmarkingLanding: ContentColumn[] = [{ content: <BenchmarkingContentRow graph={treeStructureImage} text={description} />, pageTitle: 'Benchmarking Landing' }]

export { benchmarkingLanding }
