import { Space } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { ExtendedTag } from '../../gen/api'
import { SearchTag } from '../../api/municipality'

export const createTagsTableConfig = (_data: SearchTag[], _filters: any, navigate: any) => {
  const columns: ColumnsType<SearchTag> = [
    {
      title: 'Namn',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      showSorterTooltip: false,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Kommuner',
      dataIndex: 'numMunicipalities',
      key: 'name',
      width: 125,
      showSorterTooltip: false,
      sorter: (a, b) => a.numMunicipalities - b.numMunicipalities,
    },
    {
      dataIndex: 'hantera',
      key: 'hantera',
      width: 125,
      render: (_, obj) => (
        <Space size="middle">
          <button className="table-edit-link" onClick={() => navigate.push(`/app/taggar/${obj.id}`)}>
            Redigera
          </button>
        </Space>
      ),
    },
  ]

  return { columns }
}
