import { ReactNode } from 'react'
import { MunicipalityData } from '../types/municipalityData'

const getFrequencyValueOther = (taskName: string, frequency: number | undefined, municipalityData: MunicipalityData, weeksPerMonth: number, selection: string) => {
  if (selection === '-') return undefined

  const correspondingTask = municipalityData.input.extraTasks.find((task) => task.name === taskName)

  // if there are no frequencies for this task, and the frequency was 1, we assume that the level has a normalized standardTime for the month
  if (correspondingTask?.frequencies?.length === 0) {
    return frequency === 1 ? undefined : frequency
  }

  if (!frequency) return undefined

  return Math.round((1000 * frequency) / weeksPerMonth) / 1000
}

const getTimepointFrequencyValue = (frequency: number | undefined, selection: string) => {
  if (selection === '-') return undefined

  return frequency || undefined
}

export interface JSONLoadError {
  message: ReactNode
  errorType: 'exists' | 'frequency' | 'selection'
  taskType?: 'other' | 'timepoint'
  taskName?: string
}

const getErrorsForSingleOtherTask = (taskName: string, taskData: any, municipalityData: MunicipalityData, weeksPerMonth: number) => {
  const errors: JSONLoadError[] = []
  const frequencyValue = getFrequencyValueOther(taskName, taskData.frequency, municipalityData, weeksPerMonth, taskData.selection)

  // Make sure all extraTasks 1. exists, 2. have a frequency that exists, 3. have a selection that exists

  // 1. exists
  if (!municipalityData.input.extraTasks.find((t) => t.name === taskName)) {
    errors.push({
      message: (
        <>
          Övrig insats vid namn <b>{taskName} </b>(Frekvens: <b>{frequencyValue} </b>ggr/vecka, Nivå: <b>{taskData.selection}</b>) finns inte och har därför <b>ignorerats</b>. Vänligen välj en giltig
          insats nedan.
        </>
      ),
      errorType: 'exists',
      taskName,
      taskType: 'other',
    })
    return errors
  }

  // 2. have a frequency that exists

  if (frequencyValue !== undefined || taskData.frequency !== 1) {
    const correspondingTask = municipalityData.input.extraTasks.find((t) => t.name === taskName)

    if (correspondingTask?.frequencies?.findIndex((f) => f.timesPerWeek === frequencyValue) === -1) {
      // errors.push(`Frekvensen för övriga insatsen ${taskName} är inte giltig. (Frekvensen är ${frequencyValue} ggr/vecka) Vänligen välj en giltig frekvens nedan.`)
      errors.push({
        message: (
          <>
            Frekvensen för övriga insatsen <b>{taskName} </b>(Frekvens: <b>{frequencyValue} </b>ggr/vecka) är inte giltig. Insatsen har laddats in <b>utan frekvens</b>. Vänligen välj en giltig
            frekvens nedan.
          </>
        ),
        errorType: 'frequency',
        taskName,
        taskType: 'other',
      })
    }
  }
  // 3. have a selection that exists
  if (taskData.selection !== '-' && !municipalityData.input.extraTasks.find((t) => t.name === taskName)?.selections.includes(taskData.selection)) {
    // errors.push(`Nivån för övriga insatsen ${taskName} är inte giltig. (Nivån är ${taskData.selection}) Vänligen välj en giltig nivå nedan.`)
    errors.push({
      message: (
        <>
          Nivån för övriga insatsen <b>{taskName} </b>(Nivå: <b>{taskData.selection}</b>) är inte giltig. Insatsen har laddats in <b>utan nivå</b>. Vänligen välj en giltig nivå nedan.
        </>
      ),
      errorType: 'selection',
      taskName,
      taskType: 'other',
    })
  }

  return errors
}

const getErrorsForSingleTimepointTask = (timepoint: string, taskName: string, taskData: any, municipalityData: MunicipalityData) => {
  const errors: JSONLoadError[] = []
  const frequencyValue = getTimepointFrequencyValue(taskData.frequency, taskData.selection)

  // Make sure all timepointTasks 1. have timepoints that exists, 2. have a frequency that exists, 3. have a selection that exists

  // 1. exists
  if (municipalityData.input.timepointTasks.findIndex((t) => t.name === taskName) === -1) {
    errors.push({
      message: (
        <>
          Tidpunktsinsatsen <b>{timepoint}</b> - <b>{taskName} </b>(Frekvens: <b>{frequencyValue} </b>ggr/veckan, Nivå: <b>{taskData.selection}</b>) finns inte och har därför <b>ignorerats</b>.
          Vänligen välj en giltig insats nedan.
        </>
      ),
      errorType: 'exists',
      taskName,
      taskType: 'timepoint',
    })
    return errors
  }

  // 2. have a frequency that exists

  if (frequencyValue !== undefined) {
    const correspondingTask = municipalityData.input.timepointTasks.find((t) => t.name === taskName)

    if (correspondingTask?.frequencies?.findIndex((f) => f.timesPerWeek === frequencyValue) === -1) {
      errors.push({
        message: (
          <>
            Frekvensen för tidpunktsinsatsen <b>{timepoint}</b> - <b>{taskName}</b> (Frekvens: <b>{frequencyValue}</b> ggr/vecka) är inte giltig. Vänligen välj en giltig frekvens nedan.
          </>
        ),
        errorType: 'frequency',
        taskName,
        taskType: 'timepoint',
      })
    }
  }

  // 3. have a selection that exists
  if (taskData.selection !== '-' && !municipalityData.input.timepointTasks.find((t) => t.name === taskName)?.selections.includes(taskData.selection)) {
    errors.push({
      message: (
        <>
          Nivån för tidpunktsinsatsen <b>{timepoint}</b> - <b>{taskName}</b> (Nivå: <b>{taskData.selection}</b>) är inte giltig. Vänligen välj en giltig nivå nedan.
        </>
      ),
      errorType: 'selection',
      taskName,
      taskType: 'timepoint',
    })
  }

  return errors
}

const getErrorsForTimepoint = (timepoint: string, timepointData: any, municipalityData: MunicipalityData, weeksPerMonth: number) => {
  const errors: JSONLoadError[] = []

  if (!municipalityData.input.timepoints.includes(timepoint)) {
    const parentError = (
      <>
        Tidpunkten <b>{timepoint}</b> finns inte och därför kan inte följande tidpunktsinsatser laddas in:
      </>
    )

    const childrenErrors = Object.entries(timepointData).map(([task, taskData]: any) => {
      return (
        <>
          <b>{task}</b> (Frekvens: <b>{taskData.frequency}</b>, Nivå: <b>{taskData.selection}</b>)
        </>
      )
    })

    if (!childrenErrors.length) {
      return [] as JSONLoadError[]
    }

    errors.push({
      message: (
        <>
          {parentError}
          <ul className="ml-4">
            {childrenErrors.map((error) => (
              <li>- {error}</li>
            ))}
          </ul>
        </>
      ),
      errorType: 'exists',
    })
  }
  return errors
}

function jsonLoader(data: Record<string, any>, municipalityData: MunicipalityData) {
  const weeksPerMonth = data.weeksPerMonth ?? 4

  const result: any = {
    weeksOff: 0,
    shared: data.shared ?? false,
    other: {},
    checkboxes: {},
  }

  const errors: JSONLoadError[] = []

  for (const key of Object.keys(data)) {
    if (key === 'extraTasks') {
      const task = data[key]
      for (const item in task) {
        let frequencyValue = getFrequencyValueOther(item, task[item].frequency, municipalityData, weeksPerMonth, task[item].selection)
        let level = task[item].selection !== '-' ? task[item].selection : undefined
        if (!level && frequencyValue === undefined) {
          continue
        }

        const taskErrors = getErrorsForSingleOtherTask(item, task[item], municipalityData, weeksPerMonth)
        if (taskErrors.length) {
          errors.push(...taskErrors)
          if (taskErrors.find((error) => error.errorType === 'exists')) {
            continue
          }
          if (taskErrors.find((error) => error.errorType === 'frequency')) {
            frequencyValue = undefined
          }
          if (taskErrors.find((error) => error.errorType === 'selection')) {
            level = undefined
          }
        }

        result.other[item] = {
          Frekvens: frequencyValue,
          Nivå: level,
          Dubbelbemanning: task[item].double ?? false,
        }
      }
    } else if (key === 'timepointTasks') {
      for (const timepoint in data.timepointTasks) {
        const timepointErrors = getErrorsForTimepoint(timepoint, data.timepointTasks[timepoint], municipalityData, weeksPerMonth)

        result[timepoint] = { timepoints: {} }
        const allTaskErrors: JSONLoadError[] = []
        for (const task in data.timepointTasks[timepoint]) {
          let frequencyValue = getTimepointFrequencyValue(data.timepointTasks[timepoint][task].frequency, data.timepointTasks[timepoint][task].selection)
          let level = data.timepointTasks[timepoint][task].selection !== '-' ? data.timepointTasks[timepoint][task].selection : undefined

          if (!level && frequencyValue === undefined) {
            continue
          }

          const taskErrors = getErrorsForSingleTimepointTask(timepoint, task, data.timepointTasks[timepoint][task], municipalityData)
          if (taskErrors.length) {
            allTaskErrors.push(...taskErrors)
            if (taskErrors.find((error) => error.errorType === 'exists')) {
              continue
            }
            if (taskErrors.find((error) => error.errorType === 'frequency')) {
              frequencyValue = undefined
            }
            if (taskErrors.find((error) => error.errorType === 'selection')) {
              level = undefined
            }
          }

          result[timepoint].timepoints[task] = {
            Besök: frequencyValue,
            Nivå: level,
            Dubbelbemanning: data.timepointTasks[timepoint][task].double,
          }
        }

        // Only show timepoint errors if there were any and we have atleast one non-ignored timepoint task (all will get exists error if timepoint doesn't exist).
        if (timepointErrors.length && Object.keys(data.timepointTasks[timepoint]).length !== 0) {
          errors.push(...timepointErrors)
          // In this case delete timepoint entry
          delete result[timepoint]
          continue
        }

        // We want to show task errors if there is no timepoint error and there are task errors
        if (allTaskErrors.length && timepointErrors.length === 0) {
          errors.push(...allTaskErrors)
        }
      }
    }
  }

  return { result, errors }
}

export default jsonLoader
