import SingleObject from '../../components/singleObject'

const obj = {
  key: 3939,
  namn: 'Kerstin liljevald',
  personNummer: '123456-1111',
  flagga: 3,
  nivå: 7,
  flaggnamn: 'x',
  till: new Date('2022-04-24'),
  från: new Date('2022-08-26'),
  created: new Date('2022-05-23'),
}

const SingleHandlaggare = () => {
  return <SingleObject obj={obj} heading={'Brukare'} />
}

export default SingleHandlaggare
