import React from 'react'
import FlagListHeader from '../../components/header'
import { DataType } from '../../components/tablesConfig/filterconfig'

// Antd
import ArendeFordelningTable from './components/tables/ArendeFordelningTable'

export type ICase = {
  key: React.Key
  id: number
  visible: boolean
  ägare: { id: number; namn: string }
  flags: DataType[]
  handläggare: { id: number; namn: string }[]
  händelser: ICaseLogs[]
  created: Date
  senastUppdaterad: Date
  status: string
}

export type ICaseLogs = {
  id: number
  date: Date
  status: string
  type: string
  comment: string
  createdBy: { id: number; namn: string }
}

export interface IDistributionData {
  title: any
  ansvarig_personnummer: string
  ansvarig_namn: string
  beviljade_timmar: number
  antal_hemtjänst: number
  antal_säbo: number
  vårdtyngd: number
}

export interface IMyCasesData {
  brukare: string
  ansvarig_personnummer: string
  insatskategorityp: string
  från: string
  till: string
  nästa_genomförandeplan: string
  nästa_kvalitetsfrågor: string
  senaste_hembesök: string
  omfattning: number
  dubbelbemanning: boolean
}

export interface IVerksamhet {
  till: string // Sträng med datum
  från: string
  beslutsid: number
  förlängning: boolean
}

const ArendeFordelning = () => {
  return (
    <>
      <FlagListHeader heading={'Ärendefördelning'} title={null} description={null} button={false} />
      <ArendeFordelningTable />
    </>
  )
}

export default ArendeFordelning
