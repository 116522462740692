import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useApi } from '../../api/useApi'
import BackButton from '../../components/buttons/backButton'
import FlagListHeader from '../../components/header'
import { selectAnvandare } from './store/selectors'

// Antd
import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Switch } from 'antd'
import { addHandlägagre, getHandlaggare, getRoles, getTeam } from '../../store/organizationSlice'
const { Option } = Select

type Props = {}

const NewAnvandare = (props: Props) => {
  const dispatch = useDispatch()
  const navigate = useHistory()
  const api = useApi()

  // Redux state
  const anvandarState = useSelector(getHandlaggare)
  const teams = useSelector(getTeam)
  const roles = useSelector(getRoles)

  useEffect(() => {
    if (anvandarState && anvandarState.length === 0) {
      // setAnvandare(api, dispatch)
    }
  }, [anvandarState, api, dispatch])

  const onFinish = async (values: any) => {
    console.log(values)

    // Send to DB
    try {
      await api.post(`/v1/handlaggare/${localStorage.getItem('tenant_id')}/new`, values, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
        },
      })
    } catch (e) {
      console.error(e)
    }
    dispatch(addHandlägagre(values))
    navigate.goBack()
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <>
      <FlagListHeader title="" heading="Skapa användare" description="Skapa en användare genom att fylla i fälten nedan, klicka sedan på spara." button={false} />
      <>
        <Form name="new-user" initialValues={{ remember: true, active: true, in_structure: true }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" layout="vertical">
          <div className="newuser-input-wrapper">
            <div className="input-wrapper-flex">
              <Form.Item label="Namn" name="namn" rules={[{ required: true, message: 'Vänligen fyll i hela namnet' }]}>
                <Input type={'text'} placeholder="Namn" />
              </Form.Item>
              <Form.Item
                label="Personnummer"
                name="personnummer"
                rules={[
                  {
                    required: true,

                    validator: (rule, value) => {
                      console.log(anvandarState)
                      if (anvandarState.find((item) => item.personnummer === value) !== undefined) {
                        return Promise.reject('Användaren med detta personnummer finns redan')
                      }
                      if (!value.match(/^[0-9]{8}-[0-9]{4}$/)) {
                        return Promise.reject('Vänligen fyll i personnummer i formatet ÅÅÅÅMMDD-XXXX')
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <Input type="text" placeholder="ÅÅÅÅMMDD-XXXX" />
              </Form.Item>
              <Form.Item
                label="E-postadress"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Vänligen fyll i e-postadress',
                    type: 'email',
                  },
                ]}
              >
                <Input placeholder="E-postadress" />
              </Form.Item>
            </div>
            <div className="input-wrapper-flex">
              <Form.Item
                label="Roll"
                name="role"
                tooltip={{ title: 'Användarens roll styr vilka vyer som hen har tillgång till.', icon: <InfoCircleOutlined /> }}
                rules={[
                  {
                    required: true,
                    message: 'Vänligen välj en roll',
                  },
                ]}
              >
                <Select placeholder={''} className="select">
                  {roles.map((item: { role_name: string; permissions: string[]; modules: string[] }) => {
                    return <Option value={item.role_name}>{item.role_name}</Option>
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Team"
                name="team"
                tooltip={{ title: 'Användarens team styr vilket team som användaren tillhör.', icon: <InfoCircleOutlined /> }}
                rules={[
                  {
                    required: true,
                    message: 'Vänligen välj ett team',
                  },
                ]}
              >
                <Select placeholder={''} className="select">
                  {teams.map((item: { team_id: number; team_namn: string; avdelning: number }) => {
                    return <Option value={item.team_id}>{item.team_namn}</Option>
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Behörighet"
                name="scope"
                tooltip={{ title: 'Användarens behörighet styr vilka team och därmed vilka handläggare och ärenden som användaren har tillgång till.', icon: <InfoCircleOutlined /> }}
                rules={[
                  {
                    required: true,
                    message: 'Vänligen välj behörigheter',
                  },
                ]}
              >
                <Select mode="multiple" placeholder={''} className="select">
                  {teams.map((item: { team_id: number; team_namn: string; avdelning: number }) => {
                    return <Option value={item.team_id}>{item.team_namn}</Option>
                  })}
                </Select>
              </Form.Item>
            </div>
            <Form.Item
              label="Status"
              name="active"
              tooltip={{
                title: 'Justera om användarens status ska vara aktiv eller inaktiv. Om användaren är aktiv kan hen logga in i Pejlen.',
                icon: <InfoCircleOutlined />,
              }}
            >
              <Switch defaultChecked={true} />
            </Form.Item>
            <Form.Item
              label="I struktur"
              name="in_structure"
              tooltip={{
                title: 'Justera om användaren ska vara i strukturen eller inte genom att klicka på knappen. Om användaren är med i strukturen, visas hens ärenden under vyn Ärendefördelning.',
                icon: <InfoCircleOutlined />,
              }}
            >
              <Switch defaultChecked={true} />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', gap: '6px' }}>
            <BackButton display="inline" />
            <Button type="primary" htmlType="submit" style={{ display: 'inline-block', marginTop: '20px' }}>
              Spara
            </Button>
          </div>
        </Form>
      </>
    </>
  )
}

export default NewAnvandare
