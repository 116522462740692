import { AxiosInstance } from 'axios'
import { Dispatch } from 'redux'
import { flagData } from '../components/singleObject'
import { setFlagsAction } from '../components/singleDeviation/store/actions'
import { DataType } from '../components/tablesConfig/filterconfig'

export const setFlags = async (api: AxiosInstance, dispatch: Dispatch, type: string, key: string) => {
  const name = type === 'brukare' ? 'personnummer' : 'name'

  try {
    const flags = await api.get(`/v1/${type}/${localStorage.getItem('tenant_id')}/${name}/${key}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    const formattedData = flags.data.map((item: any) => {
      return {
        key: item.id,
        id: item.id,
        flagga: item.flagga,
        flaggnamn: item.flaggnamn,
        nivå: item.nivå,
        från: item.från,
        till: item.till,
        created: item.created,
        utförare: item.utförare,
      }
    })

    return setFlagsAction('SET_FLAGS', formattedData, dispatch)
  } catch (err) {
    console.log(err)
  }
}
