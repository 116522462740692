import { Button, Checkbox, Form, Input, Popconfirm, Select, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import React from 'react'
import { useSelector } from 'react-redux'
import { getMunicipality } from '../../store/beslutsstod/municipalitySlice'
import EditableFrequencies from './components/editableFrequencies'
import EditableLevel from './components/editableLevel'
import { Frequency, IBICMunicipalityData, IBICTimepointTask, MunicipalityData, TimepointTask } from './types/municipalityData'
import { formatTimesPerDay } from './IBIC/utils/formatTimesPerDay'

type Props<MuncipalityDataFormat extends MunicipalityData | IBICMunicipalityData> = {
  municipalityData: MuncipalityDataFormat
  onChange: (municipalityData: MuncipalityDataFormat) => void
  onLifeAreaChange?: (lifeArea: string, task: string) => void
}

const EditTimepointsTasks = <MuncipalityDataFormat extends MunicipalityData | IBICMunicipalityData>({ municipalityData, onChange }: Props<MuncipalityDataFormat>) => {
  const editableData = JSON.parse(JSON.stringify(municipalityData)) as MuncipalityDataFormat

  type CorrespondingTask = MuncipalityDataFormat['input']['timepointTasks'][number]

  const updateTask = (task: CorrespondingTask) => {
    const index = editableData.input.timepointTasks.findIndex((item) => item.name === task.name)
    if (index === -1) return
    editableData.input.timepointTasks[index] = task
    onChange(editableData)
  }

  const updateName = (event: React.ChangeEvent<HTMLInputElement>, task: TimepointTask) => {
    const index = editableData.input.timepointTasks.findIndex((item) => item.name === task.name)
    if (index === -1) return
    editableData.input.timepointTasks[index].name = event.target.value
    onChange(editableData)
  }

  const updateFrequencies = (frequencies: Frequency[], task: CorrespondingTask) => {
    updateTask({ ...task, frequencies })
  }

  const updateDouble = (event: CheckboxChangeEvent, task: CorrespondingTask) => {
    updateTask({ ...task, double: event.target.checked })
  }

  const updateShared = (event: CheckboxChangeEvent, task: CorrespondingTask) => {
    updateTask({ ...task, shared: event.target.checked })
  }

  const updateTimesPerDayOptions = (timesPerDayOptions: number[], task: CorrespondingTask) => {
    updateTask({ ...task, timesPerDayOptions: timesPerDayOptions.sort((a, b) => a - b) })
  }

  const onLifeAreaChange = (lifeArea: string, task: CorrespondingTask) => {
    updateTask({ ...task, lifeArea })
  }

  return (
    <Form.List name={['tidpunkter', 'timepoints']}>
      {() => (
        <>
          <Typography.Title
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '40px',
              marginBottom: '40px',
            }}
            level={3}
          >
            Tidpunktsinsatser
          </Typography.Title>
          {/* Types shouldn't be needed, need to update ts version */}
          {(municipalityData.input.timepointTasks as CorrespondingTask[]).map((task, index: number) => (
            <div className={'ibicCalculationRules' in task ? 'decision-wrapper-edit-ibic' : 'decision-wrapper-edit'} key={index}>
              <div>
                <Form.Item>
                  <Input onChange={(e) => updateName(e, task)} defaultValue={task.name}></Input>
                </Form.Item>
              </div>

              {'timesPerDayOptions' in task && (
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Välj antal gånger per dag"
                  value={(task as IBICTimepointTask).timesPerDayOptions}
                  maxTagCount={0}
                  listHeight={1000}
                  onChange={(value) => updateTimesPerDayOptions(value, task)}
                  maxTagPlaceholder={(values) => `${values.length} alternativ valda`}
                >
                  {Array.from({ length: 9 }, (_, i) => i + 1).map((option) => (
                    <Select.Option key={option} value={option}>
                      {formatTimesPerDay(option)}
                    </Select.Option>
                  ))}
                </Select>
              )}

              {'lifeArea' in task && 'lifeAreas' in municipalityData.input && (
                <div>
                  <Select style={{ width: '100%' }} placeholder="Välj livsområde" value={(task as IBICTimepointTask).lifeArea} onChange={(value) => onLifeAreaChange(value, task)}>
                    {municipalityData.input.lifeAreas.map((lifeArea) => (
                      <Select.Option key={lifeArea} value={lifeArea}>
                        {lifeArea}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}

              <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights}>
                <div style={{ marginLeft: '8px' }}>
                  {task.frequencies && (
                    <Form.Item name={[task.name, 'Frekvens']}>
                      <EditableFrequencies frequencies={task.frequencies} updateFrequencies={(frequencies) => updateFrequencies(frequencies, task)} name={task.name} />
                    </Form.Item>
                  )}
                </div>
              </Form.Item>

              <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights}>
                <Form.Item name={[task.name, 'Nivå']}>
                  <EditableLevel
                    name={task.name}
                    selections={task.selections}
                    currentTask={task as IBICTimepointTask}
                    updateTask={updateTask}
                    ibicCalculationRules={'ibicCalculationRules' in task ? (task as IBICTimepointTask).ibicCalculationRules : undefined}
                    allTasks={[...municipalityData.input.timepointTasks, ...municipalityData.input.extraTasks]}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item name={[task.name, 'Dubbelbemanning']}>
                <Checkbox checked={task.double} onChange={(e) => updateDouble(e, task)}>
                  Kan dubbelbemannas
                </Checkbox>
              </Form.Item>

              <Form.Item name={[task.name, 'Kan delas']}>
                <Checkbox checked={task.shared} onChange={(e) => updateShared(e, task)}>
                  Kan delas
                </Checkbox>
              </Form.Item>
              <Popconfirm
                title={`Är du säker på att du vill ta bort insatsen '${task.name}'?`}
                okText="Bekräfta"
                cancelText="Avbryt"
                onConfirm={() => {
                  const newTasks = municipalityData.input.timepointTasks.filter((item) => item.name !== task.name)
                  // dispatch(setMunicipality({ municipalityData: { ...municipalityData, input: { ...municipalityData.input, timepointTasks: newTasks } } }))
                  onChange({ ...municipalityData, input: { ...municipalityData.input, timepointTasks: newTasks } })
                }}
              >
                <Button type="primary" danger>
                  Ta bort
                </Button>
              </Popconfirm>
            </div>
          ))}
          <div className="flex items-center justify-center">
            <Button
              type="primary"
              // TODO: Fix
              onClick={() => {
                const getUniqueName = (name: string) => {
                  const names = (municipalityData.input.timepointTasks as CorrespondingTask[]).map((item) => item.name)
                  return names.includes(name) ? `${name} (${names.filter((item) => item.includes(name)).length})` : name
                }
                const newTask: CorrespondingTask = {
                  name: getUniqueName('Ny insats'),
                  frequencies: [],
                  selections: [],
                  double: false,
                  shared: false,
                  description: '',
                  standardTimes: {},
                  selectionDescriptions: {},
                  hasFrequency: true,
                }

                if ('lifeAreas' in municipalityData.input) {
                  ;(newTask as IBICTimepointTask).lifeArea = municipalityData.input.lifeAreas[0]
                }

                const newTasks = [...municipalityData.input.timepointTasks, newTask]
                // dispatch(setMunicipality({ municipalityData: { ...municipalityData, input: { ...municipalityData.input, timepointTasks: newTasks } } }))
                onChange({ ...municipalityData, input: { ...municipalityData.input, timepointTasks: newTasks } })
              }}
            >
              Lägg till ny insats
            </Button>
          </div>
        </>
      )}
    </Form.List>
  )
}

export default EditTimepointsTasks
