import { AxiosInstance } from 'axios'
import { Dispatch } from 'redux'
import { IMedarbetare } from '../features/medarbetare'
import { ISetMedarbetareAction, setMedarbetareAction } from '../features/medarbetare/store/actions'

export const setMedarbetare = async (api: AxiosInstance, dispatch: Dispatch<ISetMedarbetareAction>, date?: { from: string; to: string }) => {
  let from
  let to

  if (date) {
    from = date.from
    to = date.to
  } else {
    from = '2020-01-01'
    to = '2021-12-31'
  }

  try {
    const medarbetare = await api.get<IMedarbetare[]>(`/v1/medarbetare/${localStorage.getItem('tenant_id')}/all?from=${from}&to=${to}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    if (medarbetare.data === []) {
      setMedarbetareAction('SET_MEDARBETARE', [], dispatch)
      return
    }

    const formattedData = medarbetare.data.map((item: IMedarbetare) => {
      return {
        key: item.id,
        flagga: item.flagga,
        flaggnamn: item.flaggnamn,
        nivå: item.nivå,
        namn: item.namn,
        från: item.från,
        till: item.till,
        created: item.created,
        utförare: item.utförare,
      }
    })

    return setMedarbetareAction('SET_MEDARBETARE', formattedData, dispatch)
  } catch (err) {
    console.log(err)
  }
}

export const setSingleMedarbetare = async (api: AxiosInstance, dispatch: Dispatch<ISetMedarbetareAction>, name: string) => {
  try {
    const singleMedarbetare = await api.get<IMedarbetare[]>(`/v1/medarbetare/${localStorage.getItem('tenant_id')}/name/${name}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    return singleMedarbetare.data
  } catch (err) {
    console.log(err)
  }
}
