import { useMutation, useQuery } from '@tanstack/react-query'
import { apiClient } from './useApi'

const splitDurationValue = (durationS: number) => {
  const hours = Math.floor(durationS / 3600)
  const minutes = Math.floor((durationS % 3600) / 60)
  const seconds = durationS % 60

  return {
    hours,
    minutes,
    seconds,
  }
}

export const useAllowedAuthenticationMethods = (tenant?: string) =>
  useQuery(['allowedAuthenticationMethods'], async () => await apiClient.getAllowedAuthenticationMethods(tenant ?? localStorage.getItem('tenant_id') ?? '').then((res) => res.data))
export const useAllAuthenticationMethods = () =>
  useQuery(['allAuthenticationMethods'], async () => await apiClient.getAllAuthenticationMethods(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useDeleteAuthenticationMethod = () =>
  useMutation(['deleteAuthenticationMethod'], async (id: string) => await apiClient.removeAuthenticationMethod(localStorage.getItem('tenant_id') ?? '', id))
export const useAddAuthenticationMethod = () =>
  useMutation(['addAuthenticationMethod'], async (method: string) => await apiClient.addAuthenticationMethod(localStorage.getItem('tenant_id') ?? '', { authenticationMethod: method }))

export const useMaxAuthDuration = () =>
  useQuery(
    ['maxAuthDuration'],
    async () => await apiClient.getMaxAuthDuration(localStorage.getItem('tenant_id') ?? '').then((res) => (res.data.durationS ? splitDurationValue(res.data.durationS) : undefined))
  )
export const useSetMaxAuthDuration = () =>
  useMutation(
    ['setMaxAuthDuration'],
    async ({ hours, minutes, seconds }: { hours: number; minutes: number; seconds: number }) =>
      await apiClient.setMaxAuthDuration(localStorage.getItem('tenant_id') ?? '', { durationS: hours * 3600 + minutes * 60 + seconds })
  )
export const useRemoveMaxAuthDuration = () => useMutation(['removeMaxAuthDuration'], async () => await apiClient.removeMaxAuthDuration(localStorage.getItem('tenant_id') ?? ''))
