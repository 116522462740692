import { useMutation, useQuery } from '@tanstack/react-query'
import { apiClient } from './useApi'

export const useMe = () => useQuery(['myself'], async () => await apiClient.getMe(localStorage.getItem('tenant_id') ?? '', localStorage.getItem('hydra_id_token') ?? '').then((res) => res.data))

export const useRole = (roleId: number) => useQuery(['role', roleId], async () => await apiClient.getRole(localStorage.getItem('tenant_id') ?? '', roleId).then((res) => res.data))
export const useUpdateRole = () =>
  useMutation(['updateRole'], async ({ roleId, name }: { roleId: number; name: string }) => await apiClient.updateRole(localStorage.getItem('tenant_id') ?? '', roleId, { name }))
export const useDeleteRole = () => useMutation(['deleteRole'], async (roleId: number) => await apiClient.deleteRole(localStorage.getItem('tenant_id') ?? '', roleId))

export const useRoles = () => useQuery(['roles'], async () => await apiClient.getRoles(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))
export const useModules = () => useQuery(['modules'], async () => await apiClient.getModules(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))
export const usePermissions = () => useQuery(['permissions'], async () => await apiClient.getPermissions(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useAddRole = () => useMutation(['addRole'], async ({ name }: { name: string }) => await apiClient.addRole(localStorage.getItem('tenant_id') ?? '', { name }))

export const useAddModuleToRole = () =>
  useMutation(['addModuleToRole'], async ({ roleId, moduleId }: { roleId: number; moduleId: number }) => await apiClient.addModuleToRole(localStorage.getItem('tenant_id') ?? '', { roleId, moduleId }))
export const useRemoveModuleFromRole = () =>
  useMutation(
    ['removeModuleFromRole'],
    async ({ roleId, moduleId }: { roleId: number; moduleId: number }) => await apiClient.removeModuleFromRole(localStorage.getItem('tenant_id') ?? '', { roleId, moduleId })
  )
export const useAddPermissionOverride = () =>
  useMutation(
    ['addPermissionOverride'],
    async ({ roleId, permissionId, action }: { roleId: number; permissionId: number; action: 'add' | 'remove' }) =>
      await apiClient.addPermissionOverride(localStorage.getItem('tenant_id') ?? '', { roleId, permissionId, action })
  )
export const useRemovePermissionOverride = () =>
  useMutation(
    ['removePermissionOverride'],
    async ({ roleId, permissionId }: { roleId: number; permissionId: number }) => await apiClient.removePermissionOverride(localStorage.getItem('tenant_id') ?? '', { roleId, permissionId })
  )

export const useAddAssignableRole = () =>
  useMutation(
    ['addAssignableRole'],
    async ({ roleId, assignableRoleId }: { roleId: number; assignableRoleId: number }) => await apiClient.setAssignableRole(localStorage.getItem('tenant_id') ?? '', { roleId, assignableRoleId })
  )
export const useRemoveAssignableRole = () =>
  useMutation(
    ['removeAssignableRole'],
    async ({ roleId, assignableRoleId }: { roleId: number; assignableRoleId: number }) => await apiClient.removeAssignableRole(localStorage.getItem('tenant_id') ?? '', { roleId, assignableRoleId })
  )
