import { Link, useLocation, useParams } from 'react-router-dom'
import BackButton from '../../components/buttons/backButton'
import FlagListHeader from '../../components/header'

// Antd
import { useQuery } from '@tanstack/react-query'
import { Spin, Typography } from 'antd'
import { fetchFlagTexts } from './query'
const { Text, Title } = Typography

interface CustomizedState {
  flagname: string
  riskniva: number
}

const SingleDeviation = () => {
  type Param = { flag: string; id: string }
  const location = useLocation()
  const state = location.state as CustomizedState
  const { flag, id } = useParams<Param>()

  const { isLoading: isTextLoading, data: textData } = useQuery(['flagtexts', flag, id], fetchFlagTexts)

  if (isTextLoading) {
    return <Spin />
  }

  const flagInformation: Record<number, string | JSX.Element | undefined> = {
    2: `Utförandegraden är ett mått som beskriver hur stor andel av den beviljade tiden som utförts. Eftersom den utförda tiden varierar naturligt månad till månad, gör utförandegraden det också. Detta resulterar i en varierande ersättning till utföraren, som får en större ersättning om utförandegraden är hög. 
       Om utförandegraden är hög över tid och varierar onaturligt lite över flera månader, kan det vara en indikation på att utföraren maximerar antal timmar som personal spenderar hos brukaren.`,
    7: (
      <>
        Det är eftersträvansvärt att en brukare inte får hjälp av allt för många personal men ett avvikande lågt antal kan vara ett tecken på att utföraren har objektsanställd personal.
        <br />
        Avvikelsen fångar enbart brukare som haft besök under minst 15 dagar i månaden.
      </>
    ),
    8: (
      <>
        När en personal besöker väldigt få eller till och med endast en brukare kan det vara svårt att bibehålla en professionell relation, vilket kan leda till svårigheter kring transparens mellan
        parterna brukare, utförare och kommun. Om en personal är anställd i syfte att endast besöka en brukare räknas det som en objektsanställning. Sådana anställningar är inte tillåtna.
        <br />
        Avvikelsen fångar enbart personal som arbetat minst 8 dagar i varje enskild månad.
      </>
    ),
    9: 'Restiden är den tid som går mellan att ett besök avslutas till att nästa besök påbörjas. Utföraren får inte ersättning för denna tid utan endast för den tid som personal är hos brukaren. I och med att brukarna som en personal ska besöka bor på olika ställen, varierar restiden naturligt. Om en personal har en hög andel med väldigt korta restider, kan det finnas skäl att undersöka ärendet närmre, då utföraren gagnas av att hålla ned restider och maximera besökslängd. Viktigt att ha med sig är dock att det inte behöver vara fel att en personal har korta restider, då det finns exempel där brukarna bor inom ett mindre, tätbebyggt område.',
    10: 'Restiden är den tid som går mellan att ett besök avslutas till att nästa besök påbörjas. Utföraren får inte ersättning för denna tid utan endast för den tid som personal är hos brukaren. Om restiden är negativ, det vill säga om nästa besök påbörjas innan det tidigare besöket avslutas, räknas detta som ett överlappande besök. Om en personal har överlappande besök, kan det finnas skäl att undersöka ärendet närmre, då utföraren gagnas av att hålla ned restider och maximera besökslängd. Ett överlappande besök kan ha flera anledningar. Personalen kan ha använt taggen i bostaden på ett otillåtet sätt eller efterregistrerat tid på ett otillåtet sätt, men det kan också bero på systemfel.',
    15: 'Hur mycket en personal får arbeta per dygn regleras i Arbetstidslagen och innebär att alla arbetstagare ska ha minst elva timmars sammanhängande ledighet under varje period om 24 timmar (dygnsvila).  Avvikelse får göras tillfälligtvis, om det föranleds av något särskilt förhållande som inte har kunnat förutses av arbetsgivaren, under förutsättning att arbetstagaren ges motsvarande kompensationsledighet. I den dygnsvila som alla arbetstagare har rätt till ska tiden mellan midnatt och klockan 5 ingå. Avvikelse får göras, om arbetet med hänsyn till dess art, allmänhetens behov eller andra särskilda omständigheter måste bedrivas mellan midnatt och klockan 5. Om en personal inte har en elva timmars sammanhängande ledighet över en period om 24 timmar, bryter arbetsgivaren mot Arbetstidslagen. Arbetstidslagen kan avtalas bort genom kollektivavtal. Kollektivavtalet kan då antingen ersätta hela lagen eller  vissa delar av den.',
    16: 'Hur mycket en personal får arbeta per vecka regleras i Arbetstidslagen och innebär att alla arbetstagare ska ha minst 36 timmars sammanhängande ledighet under varje period om sju dagar (veckovila). Till  veckovilan räknas inte beredskapstid då arbetstagaren får uppehålla sig utanför arbetsstället men ska stå till arbetsgivarens förfogande för att utföra arbete när behov uppkommer. Avvikelse från första stycket får  göras tillfälligtvis, om det föranleds av något särskilt förhållande som inte har kunnat förutses av arbetsgivaren. Sådan avvikelse får göras endast under förutsättning att arbetstagaren ges motsvarande kompensationsledighet. Om en personal inte har en 36 timmars sammanhängande ledighet över en period om sju dagar, bryter arbetsgivaren mot Arbetstidslagen. Arbetstidslagen kan avtalas bort genom kollektivavtal.  Kollektivavtalet kan då antingen ersätta hela lagen eller vissa delar av den.',
    17: 'Hur mycket sammanlagt ordinarie arbetstid och övertid en personal får arbeta över en period om fyra veckor regleras i Arbetstidslagen och innebär att den ordinarie arbetstiden får uppgå till högst 40 timmar i veckan. När det behövs får arbetstiden uppgå till 40 timmar i veckan i genomsnitt för en tid av högst fyra veckor. Utöver ordinarie arbetstid kan personalen arbeta övertid. När det finns särskilt behov av ökad arbetstid, får övertid tas ut med högst 48 timmar per arbetstagare under en tid av fyra veckor. Om en personal arbetar över 208 timmar över en period om fyra veckor, bryter arbetsgivaren mot Arbetstidslagen. Arbetstidslagen kan avtalas bort genom kollektivavtal. Kollektivavtalet kan då antingen ersätta hela lagen eller vissa delar av den.',
  }

  const getBrukareLink = (name: string) => {
    const brukare = textData?.brukare?.find((b) => `${b.förnamn} ${b.efternamn}` === name)
    if (!brukare) return <>{name}</>
    return (
      <>
        <Link
          to={{
            pathname: `/app/brukare/id=${brukare?.personnummer}&namn=${name}`,
          }}
        >
          {name}
        </Link>
      </>
    )
  }

  const getPersonalLink = (name: string) => {
    return (
      <>
        <Link
          to={{
            pathname: `/app/medarbetare/${name}`,
          }}
        >
          {name}
        </Link>
      </>
    )
  }

  const addOccurenceLinks = (description: string, occurenceNames: string[], getOccurenceLink: (name: string) => JSX.Element) => {
    const filteredOccurenceNames = occurenceNames.filter((name) => description.includes(name))
    if (filteredOccurenceNames.length === 0) return description

    let tempString = description

    const resList = []
    // Splits the string so names are in their own element with $$ around them
    filteredOccurenceNames.forEach((occurence) => {
      const index = tempString.indexOf(occurence)
      const before = tempString.slice(0, index)
      const after = tempString.slice(index + occurence.length)
      resList.push(before)
      resList.push(`$$${occurence}$$`)
      tempString = after
    })
    resList.push(tempString)
    const res = resList.map((str) => {
      if (str.startsWith('$$')) {
        const name = str.slice(2, -2)
        return getOccurenceLink(name)
      } else {
        return <>{str}</>
      }
    })
    return res
  }

  const getFormattedDescription = (description: string, flagNumber: number) => {
    switch (flagNumber) {
      case 7:
        return addOccurenceLinks(description, textData?.brukare?.map((b) => `${b.förnamn} ${b.efternamn}`) ?? [], getBrukareLink)
      case 8:
        return addOccurenceLinks(description, textData?.personal?.map((p) => p.namn) ?? [], getPersonalLink)
      default:
        return description
    }
  }

  const getTemplateColumns = (tableData: string[][]) => {
    const columns = tableData[0].length
    return `repeat(${columns - 1}, fit-content(200px)) 1fr`
  }

  if (textData) {
    return (
      <>
        <FlagListHeader title={`Flagga för ${state.flagname} med risknivå ${state.riskniva}`} heading="Beskrivning av avvikelse" description="" button={false} />
        <Title level={5}>Varför flaggan uppstått</Title>
        <Text>
          {textData.description?.split('\n').map((a) => {
            return (
              <>
                {getFormattedDescription(a, parseInt(flag))}
                <br />
              </>
            )
          })}
        </Text>
        {textData.tableData && (
          <div className="mb-[28px] block">
            <div style={{ display: 'grid', gridTemplateColumns: getTemplateColumns(textData.tableData), maxWidth: '600px', columnGap: '16px' }}>
              {textData.tableData.flatMap((row) => row.map((cell) => <Text>{cell}</Text>))}
            </div>
          </div>
        )}
        <Title level={5}>Om avvikelse</Title>
        <Text>
          {textData.info
            ? textData.info?.split('\n').map((a) => (
                <>
                  {a}
                  <br />
                </>
              ))
            : flagInformation[parseInt(flag)]}
        </Text>
        <BackButton />
      </>
    )
  } else {
    return <></>
  }
}

export default SingleDeviation
