import axios from 'axios'
import { DefaultApi } from '../gen/api'
import qs from 'qs'

let _refreshing: Promise<any> | null = null

export const useApi = (addAuthorizationHeader?: true) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (addAuthorizationHeader) {
    axiosInstance.interceptors.request.use((config) => {
      const token = localStorage.getItem('hydra_access_token')
      const newConfig = config ?? {}
      if (token) {
        if (!newConfig.headers) newConfig.headers = {}
        newConfig.headers.Authorization = `Bearer ${token}`
      }
      return config
    })
  }

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status !== 401 || window.location.pathname === '/app/logout_redirect') {
        return Promise.reject(error)
      }

      // Here we wanna swap the refresh token for a new access token
      // and then retry the original request
      const hydra_url = process.env.REACT_APP_HYDRA_URL

      try {
        _refreshing ??= (async () => {
          const hydra_login_data = await axios.post(
            hydra_url + '/oauth2/token',
            qs.stringify({
              grant_type: 'refresh_token',
              refresh_token: localStorage.getItem('hydra_refresh_token'),
              client_id: localStorage.getItem('tenant_id'),
            })
          )
          localStorage.setItem('hydra_access_token', hydra_login_data.data.access_token)
          localStorage.setItem('hydra_refresh_token', hydra_login_data.data.refresh_token)
        })().finally(() => {
          _refreshing = null
        })

        await _refreshing

        // Retry the original request
        delete error.config.headers['Authorization']
        delete error.config.headers['authorization']
        const accessToken = localStorage.getItem('hydra_access_token')
        error.config.headers['Authorization'] = `Bearer ${accessToken}`
      } catch (e) {
        window.location.pathname = '/app/logout_redirect'
        return Promise.reject(error)
      }

      try {
        return await axiosInstance.request(error.config)
      } catch (e: any) {
        // If we still get a 401, we should log out, otherwise we should just return the error so it can be handled by the caller
        if (e.response?.status === 401) {
          window.location.pathname = '/app/logout_redirect'
        }
        return Promise.reject(e)
      }
    }
  )
  return axiosInstance
}

export const apiClient = new DefaultApi(undefined, undefined, useApi(true))
