import { Dispatch } from 'redux'
import { DataType } from '../../../../components/tablesConfig/filterconfig'
import { IFlags } from '../../../../components/singleObject'

export const SET_CASE = 'SET_CASE'
export const LOADING_CASE = 'LOADING_CASE'

export type ISetCaseAction = {
  type: typeof SET_CASE
  payload: {
    data?: DataType[]
    singleData?: IFlags
  }
}

export interface ILoadingAction {
  type: typeof LOADING_CASE
  payload: {
    loading: boolean
  }
}

export const setCaseAction = (type: string, data: DataType[] | null, singleData: IFlags | null, dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_CASE,
    payload: {
      data: data,
      singleData: singleData,
    },
  })
  dispatch({
    type: LOADING_CASE,
    payload: {
      loading: false,
    },
  })
}

export type Actions = ISetCaseAction | ILoadingAction
