import type { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import { formatDate } from '../../Dashboard/graphConfig'

export interface DataType {
  key: React.Key
  date: string
  amount: number
  name: string
}
type Data = {
  ym: string
  amount: number | any
}
function getUniqueDates(dataset: any[]): string[] {
  const uniqueDates: string[] = []

  // Iterate through each date in the dataset
  for (const dateStr of dataset) {
    // Check if the date is already in the uniqueDates array
    if (!uniqueDates.includes(dateStr.ym)) {
      uniqueDates.push(dateStr.ym) // Add the date to the uniqueDates array
    }
  }

  return uniqueDates.sort((a, b) => moment(a).diff(moment(b)))
}

const tableHeaderYm = (data: Data[], title?: string): ColumnsType<DataType> => {
  const res: ColumnsType<DataType> = [
    {
      width: 400,
      dataIndex: 'name',
      title: title,
      key: 'key',
      fixed: 'left',
    },
  ]
  const uniqueDates = getUniqueDates(data)
  uniqueDates.forEach((item, index) =>
    res.push({
      width: 105,
      title: formatDate(moment(item).locale('sv').format('ll')),
      dataIndex: index,
      key: index,
    })
  )

  const whiteSpaceColumn = {
    title: '',
    dataIndex: 'whiteSpace',
    key: 'whiteSpace',
    fixed: 'right',
  } as const

  res.push(whiteSpaceColumn)
  return res
}

export default tableHeaderYm
