import { MenuProps } from 'antd'
import { PermissionTypes } from './generateSideMenuChildren'

type MenuItem = Required<MenuProps>['items'][number]

type Child = {
  label: React.ReactNode | string
  key: React.Key | string
  icon?: React.ReactNode
  children?: MenuItem[]
  type?: 'group'
}

export const siderMenuItem = (required: PermissionTypes[], permissions: PermissionTypes[], key?: string, icon?: any, label?: string, children?: MenuItem[]) => {
  if (children !== undefined && children.length > 0) {
    if (required.some((r) => permissions.some((p) => p === r))) {
      return {
        label,
        key,
        icon,
        children,
      } as MenuItem
    }
  }
  if (required.every((req) => permissions.some((per) => per === req))) {
    return {
      label,
      key,
      icon,
      children,
    } as MenuItem
  }

  return null
}
