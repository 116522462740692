import React from 'react'
import { Typography } from 'antd'
import { CalculationOutputUnit } from './types/municipalityData'
import { createTimeString } from './utils/createTimeString'

export interface ResultData {
  totalExDouble?: number
  hoursPerWeekPerTimepoint: Record<string, number>
  hoursPerWeekTimepointTasks: number
  hoursPerMonthTimepointTasks: number
  hoursPerDayTimepointTasks: number
  hoursPerMonthExtraTasks: number
  hoursPerMonthTimePointsTasksDouble: number
  totalHoursPerMonth: number
  hoursPerMonthSpecificExtraTasks: Record<string, number>
  hoursPerMonthSpecificExtraTasksDouble: Record<string, number>
  timepointTasks: string[]
  extraTasks: string[]
}

interface Props {
  result: ResultData | null
  unit: CalculationOutputUnit
  type?: 'secondary'
  ibic?: boolean
}

const ShowResults: React.FC<Props> = ({ result, unit, type, ibic }) => {
  if (result) {
    const totalExDouble = result.totalExDouble ?? result.totalHoursPerMonth - result.hoursPerMonthTimePointsTasksDouble
    const displayedFields = [
      {
        label: 'Total tid exklusive dubbelbemanning',
        value: totalExDouble,
      },
      {
        label: 'Total tid dubbelbemanning',
        value: result.totalHoursPerMonth - totalExDouble,
      },
      {
        label: 'Total tid per månad (Inklusive dubbelbemanning)',
        value: result.totalHoursPerMonth,
      },
    ]

    if (!ibic) {
      displayedFields.push(
        {
          label: 'Tid för personlig vård per månad',
          value: result.hoursPerMonthTimepointTasks,
        },
        {
          label: 'Tid för övriga insatser per månad',
          value: result.hoursPerMonthExtraTasks,
        }
      )
    }

    return (
      <div className="grid grid-cols-2">
        <div>
          <Typography className="font-bold">Sammanställd tid</Typography>
          {displayedFields.map((field) =>
            type === 'secondary' ? (
              <Typography.Paragraph type="secondary" key={field.label}>{`${field.label}: ${createTimeString(field.value, unit)}`}</Typography.Paragraph>
            ) : (
              <Typography key={field.label}>{`${field.label}: ${createTimeString(field.value, unit)}`}</Typography>
            )
          )}
        </div>
        {result.hoursPerMonthSpecificExtraTasks && Object.keys(result.hoursPerMonthSpecificExtraTasks).length > 0 && (
          <div>
            <Typography className="font-bold">Tid per månad individuellt beräknade övriga insatser</Typography>
            {Object.entries(result.hoursPerMonthSpecificExtraTasks).map(([key, value]) => (
              <Typography style={{ fontWeight: 'normal' }} key={key}>
                {`${key}: ${createTimeString(value + (result.hoursPerMonthSpecificExtraTasksDouble[key] ?? 0), unit)}`}

                {/* Potential dubbelbemanning */}
                {result.hoursPerMonthSpecificExtraTasksDouble[key] && ` (varav: ${createTimeString(result.hoursPerMonthSpecificExtraTasksDouble[key], unit)} dubbelbemanning)`}
              </Typography>
            ))}
          </div>
        )}
      </div>
    )
  }
  return null
}

export default ShowResults
