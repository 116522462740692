import { useMutation } from '@tanstack/react-query'
import { FormattedJsonData } from '../../../features/beslutsstod/utils/jsonFormatter'
import { apiClient } from '../../useApi'
import { BeslutsstodCalculationResult } from '../beslutsstod'

interface CalculateIBICPayload {
  formattedData: FormattedJsonData
}

export const useCalculateIBIC = () => {
  return useMutation({
    mutationFn: async ({ formattedData }: CalculateIBICPayload) => {
      const res = await apiClient.calculateIBIC(localStorage.getItem('tenant_id') as string, { result: formattedData })
      return res.data as BeslutsstodCalculationResult
    },
  })
}
