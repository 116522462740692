import { FontSize, getFontSize } from '../util/fontSize'

export interface BenchmarkingLegendItem {
  fillColor: string
  borderColor: string
  text: string | number | undefined | React.ReactNode | null
}

export interface BenchmarkingLegendProps {
  orientation?: 'horizontal' | 'vertical'
  items: BenchmarkingLegendItem[]
  fontSize?: FontSize
}

export const BenchmarkingLegend = ({ orientation = 'horizontal', items, fontSize = 'md', className, ...rest }: BenchmarkingLegendProps & React.HTMLAttributes<HTMLDivElement>) => {
  const orientationClass = orientation === 'vertical' ? 'flex-col' : 'flex-row'

  return (
    // Concatenate the className prop so it doesnt get overwritten
    <div className={`${className} flex ${orientationClass} gap-2`} {...rest}>
      {items.map((item, index) => (
        <div key={index} className="flex items-center gap-2">
          <div className="h-3 w-3" style={{ backgroundColor: item.fillColor, border: `2px solid ${item.borderColor}` }} />
          <span style={{ fontSize: getFontSize(fontSize) }}>{item.text}</span>
        </div>
      ))}
    </div>
  )
}
