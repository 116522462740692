import { Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import shortenText from '../arendeFordelning/components/tableUtils'

export interface IUtanförTeamstrukturData {
  personnummer: string
  ansvarig_person: string
  förnamn: string
  efternamn: string
  processid: number
  insatskategorityp: string
  till: string
  upprättad: string
  mark: string
}

export const createUtanförTeamstrukturTableConfig = (data: IUtanförTeamstrukturData[], filters: any) => {
  const columns: ColumnsType<IUtanförTeamstrukturData> = [
    {
      title: 'Handläggare',
      dataIndex: '',
      key: 'handläggare',
      width: 250,
      fixed: 'left',
      filteredValue: filters.handläggare,
      showSorterTooltip: { title: 'Sortera på handläggare' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.ansvarig_person
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => a.ansvarig_person.localeCompare(b.ansvarig_person),
      render: (_, obj) => (
        <Tooltip title={obj.ansvarig_person}>
          <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
            {shortenText(obj.ansvarig_person)}
          </span>
        </Tooltip>
      ),
      onFilter: (value, record) => record.ansvarig_person === value,
      filterSearch: true,
    },
    {
      title: 'Personnummer',
      dataIndex: '',
      key: 'personnummer',
      filteredValue: filters.personnummer,
      width: 160,
      showSorterTooltip: { title: 'Sortera på personnummer' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.personnummer
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => a.personnummer.localeCompare(b.personnummer),
      render: (_, obj) => (
        <>
          <Tooltip title={obj.personnummer}>
            <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
              {shortenText(obj.personnummer, 15)}
            </span>
          </Tooltip>
        </>
      ),
      onFilter: (value, record) => record.personnummer === value,
      filterSearch: true,
    },
    {
      title: 'Namn',
      dataIndex: '',
      key: 'namn',
      width: 250,
      filteredValue: filters.namn,
      showSorterTooltip: { title: 'Sortera på namn' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.förnamn + ' ' + d.efternamn
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => (a.förnamn + ' ' + a.efternamn).localeCompare(b.förnamn + ' ' + b.efternamn),
      render: (_, obj) => (
        <Tooltip title={obj.förnamn + ' ' + obj.efternamn}>
          <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
            {shortenText(obj.förnamn + ' ' + obj.efternamn)}
          </span>
        </Tooltip>
      ),
      onFilter: (value, record) => record.förnamn + ' ' + record.efternamn === value,
      filterSearch: true,
    },
    {
      title: 'Typ',
      dataIndex: '',
      key: 'typ',
      width: 150,
      filteredValue: filters.typ,
      showSorterTooltip: { title: 'Sortera på typ' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.mark
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => a.mark.localeCompare(b.mark),
      render: (_, obj) => <>{obj.mark}</>,
      onFilter: (value, record) => record.mark === value,
      filterSearch: true,
    },
    {
      title: 'ProcessID',
      dataIndex: '',
      key: 'processid',
      width: 150,
      filteredValue: filters.processid,
      showSorterTooltip: { title: 'Sortera på processID' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.processid
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => a.processid - b.processid,
      render: (_, obj) => <>{obj.processid}</>,
      onFilter: (value, record) => record.processid === value,
      filterSearch: true,
    },
    {
      title: 'Insatskategori',
      dataIndex: '',
      key: 'insatskategori',
      width: 200,
      filteredValue: filters.insatskategori,
      showSorterTooltip: { title: 'Sortera på insatskategori' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.insatskategorityp || 'Inget värde'
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => (a.insatskategorityp || 'Inget värde').localeCompare(b.insatskategorityp || 'Inget värde'),
      render: (_, obj) => <>{obj.insatskategorityp}</>,
      onFilter: (value, record) => (record.insatskategorityp || 'Inget värde') === value,
      filterSearch: true,
    },
    {
      title: 'Initieringsdatum',
      dataIndex: '',
      key: 'initieringsdatum',
      width: 200,
      filteredValue: filters.initieringsdatum,
      showSorterTooltip: { title: 'Sortera på initiersingsdatum' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              if (d.upprättad) {
                return moment(d.upprättad).format('YYYY-MM-DD')
              } else {
                return 'Inget värde'
              }
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name === 'Inget värde' ? name : moment(name).format('YYYY-MM-DD'),
            }
          }),
      ],
      sorter: (a, b) => {
        if (!a.upprättad) {
          return 1
        } else if (!b.upprättad) {
          return -1
        } else {
          return new Date(a.upprättad).valueOf() - new Date(b.upprättad).valueOf() > 0 ? 1 : -1
        }
      },
      render: (_, obj) => {
        if (obj.upprättad) {
          return <>{moment(obj.upprättad).format('YYYY-MM-DD')}</>
        }
      },
      onFilter: (value, record) => moment(record.upprättad).format('YYYY-MM-DD') === value,
      filterSearch: true,
    },
    {
      title: 'Beslut t.o.m',
      dataIndex: '',
      key: 'till',
      width: 150,
      filteredValue: filters.till,
      showSorterTooltip: { title: 'Sortera på beslut till' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              if (d.till) {
                return moment(d.till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(d.till).format('YYYY-MM-DD') : 'Tillsvidare'
              } else {
                return 'Inget värde'
              }
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => {
        if (!a.till) return -1
        if (!b.till) return 1
        return (moment(a.till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(a.till).format('YYYY-MM-DD') : 'Tillsvidare').localeCompare(
          moment(b.till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(b.till).format('YYYY-MM-DD') : 'Tillsvidare'
        )
      },
      render: (_, obj) => {
        if (obj.till) return moment(obj.till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(obj.till).format('YYYY-MM-DD') : 'Tillsvidare'
        return <></>
      },
      onFilter: (value, record) => {
        if (record.till) return (moment(record.till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(record.till).format('YYYY-MM-DD') : 'Tillsvidare') === value
        else return 'Inget värde' === value
      },
      filterSearch: true,
    },
  ]

  return columns
}
