import { Button, Divider, Form, Input, Popconfirm, Select, Space, Spin, Table, Tag, Transfer, notification } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useDeleteGuest, useEditGuest, useGuest } from '../../api/guest'
import { apiClient } from '../../api/useApi'
import BackButton from '../../components/buttons/backButton'
import FlagListHeader from '../../components/header'
import { getTeam } from '../../store/organizationSlice'
import NoMatchingUser from './NoMatchingUser'
import {
  useAddAssignableRole,
  useAddModuleToRole,
  useAddPermissionOverride,
  useDeleteRole,
  useModules,
  usePermissions,
  useRemoveAssignableRole,
  useRemoveModuleFromRole,
  useRemovePermissionOverride,
  useRole,
  useRoles,
  useUpdateRole,
} from '../../api/permissions'
import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { GetRoles200ResponseInner } from '../../gen/api'

export function getTeamNameById(id: number | undefined) {
  const teams = useSelector(getTeam)

  if (id) {
    let name = ''
    teams.forEach((item: { team_id: number; team_namn: string; avdelning: number }) => {
      if (item.team_id === id) {
        name = item.team_namn
      }
    })
    return name
  }
  return ''
}

const SingleRole = () => {
  type Param = { name: string }
  const { name } = useParams<Param>()

  const [chosenModules, setChosenModules] = useState<Set<number>>(new Set())
  const { data: role, isLoading: isRoleLoading, refetch } = useRole(parseInt(name))
  const { data: modules, isLoading: isModulesLoading } = useModules()
  const { mutateAsync: updateRole } = useUpdateRole()
  const { mutateAsync: deleteRole } = useDeleteRole()
  const { mutateAsync: addModuleToRole } = useAddModuleToRole()
  const { mutateAsync: deleteModuleFromRole } = useRemoveModuleFromRole()
  const queryClient = useQueryClient()

  const navigate = useHistory()

  useEffect(() => {
    if (!role) return

    console.log(role.modules)
    setChosenModules(new Set(role.modules?.map((item) => item.id || 0)))
  }, [role, role?.modules?.length])

  interface RecordType {
    key: string
    title: string
    description: string
    chosen: boolean
  }

  const filterOption = (inputValue: string, option: RecordType) => {
    return option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  }

  const handleChange = async (newTargetKeys: string[]) => {
    try {
      console.log(newTargetKeys)
      await new Promise((resolve) => {
        const toAdd = newTargetKeys.filter((item) => !chosenModules.has(parseInt(item)))
        const toDelete = Array.from(chosenModules).filter((item) => !newTargetKeys.includes(item.toString()))
        console.log(toAdd, toDelete)

        toAdd.forEach(async (item) => {
          await addModuleToRole({ roleId: role?.id || 0, moduleId: parseInt(item) })
        })

        toDelete.forEach(async (item) => {
          await deleteModuleFromRole({ roleId: role?.id || 0, moduleId: item })
        })

        resolve(null)
      })
      await refetch()
      queryClient.invalidateQueries(['role'])
      notification.success({
        message: 'Moduler uppdaterade',
        description: 'Modulerna har uppdaterats',
      })
    } catch (e) {
      notification.error({
        message: 'Moduler kunde inte uppdateras',
        description: 'Modulerna kunde inte uppdateras',
      })
    }
  }

  const onDeleteClick = async (id: number) => {
    try {
      await deleteRole(id)
      navigate.push('/app/roller')
      notification.success({
        message: 'Rollen raderad',
        description: 'Rollen har raderats',
      })
    } catch (e) {
      notification.error({
        message: 'Rollen kunde inte raderas',
        description: 'Rollen kunde inte raderas',
      })
    }
  }

  const onFinish = async (values: { name: string }) => {
    try {
      if (!role?.id) return
      await updateRole({ roleId: role.id, name: values.name })
      await refetch()
      queryClient.invalidateQueries(['role'])
      notification.success({
        message: 'Rollen uppdaterad',
        description: 'Rollen har uppdaterats',
      })
    } catch (e) {
      notification.error({
        message: 'Rollen kunde inte uppdateras',
        description: 'Rollen kunde inte uppdateras',
      })
    }
  }

  return (
    <>
      <FlagListHeader
        title=""
        heading="Redigera rollen"
        description="Redigera en rollen genom att ändra informationen i fälten nedan, klicka sedan på spara. Tänk på att spara lösenordet om det ändras. Om användarnamn måste uppdateras, skapa en ny användare och radera den gamla."
        button={false}
      />

      {isRoleLoading && <Spin />}
      {!isRoleLoading && role && (
        <div className="flex gap-2 max-lg:flex-col lg:justify-between">
          <Form
            name="role-edit"
            initialValues={{
              name: role.name,
            }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            className="flex items-end gap-6"
          >
            <Form.Item label="Namn" name="name">
              <Input type={'text'} placeholder="Användarnamn" />
            </Form.Item>
            <Form.Item>
              <div style={{ display: 'flex', gap: '6px' }}>
                <BackButton display="inline" />
                <Button type="primary" htmlType="submit" style={{ display: 'inline-block', marginTop: '20px' }} loading={false}>
                  Spara
                </Button>
                <Popconfirm title="Säker att du vill radera denna rollen?" okText="Bekräfta" cancelText="Avbryt" onConfirm={() => role.id !== undefined && onDeleteClick(role.id)}>
                  <Button type="primary" danger style={{ display: 'inline-block', marginTop: '20px' }} loading={isRoleLoading}>
                    Radera
                  </Button>
                </Popconfirm>
              </div>
            </Form.Item>
          </Form>

          <div>
            {role.permissions && (
              <>
                <h2 className="text-lg">Permissions</h2>
                <div className="flex flex-wrap gap-2 lg:max-w-[600px] xl:max-w-[800px]">
                  {role.permissions.map((item) => (
                    <Tag style={{ minWidth: 0 }}>{item.name}</Tag>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <Divider type="horizontal" style={{ width: '100%' }} />

      <div className="grid grid-cols-[repeat(auto-fill,minmax(450px,1fr))] gap-4">
        {isModulesLoading && (
          <div className="flex h-[350px] w-[500px] items-center justify-center">
            <Spin />
          </div>
        )}
        {!isModulesLoading && modules && (
          <div>
            <h2 className="text-lg">Moduler</h2>
            <Transfer
              dataSource={modules.map((item) => ({
                key: item.id?.toString() ?? '',
                title: item.name ?? '',
                description: item.name ?? '',
                chosen: chosenModules.has(item.id ?? 0),
              }))}
              showSearch
              filterOption={filterOption}
              // targetKeys={tag.municipalities.map((item) => item.id)}
              targetKeys={Array.from(chosenModules).map((item) => item.toString())}
              onChange={handleChange}
              render={(item) => item.title}
              listStyle={{
                width: 250,
                height: 350,
              }}
              titles={['Tillgängliga', 'Valda']}
            />
          </div>
        )}
        {role && <CanAssignRole role={role} refetch={refetch} />}
        {role && <RoleOverrides role={role} refetch={refetch} />}
      </div>
    </>
  )
}

interface CanAssignRoleProps {
  role: GetRoles200ResponseInner
  refetch: () => void
}

const CanAssignRole = ({ role, refetch }: CanAssignRoleProps) => {
  const { data: allRoles, isLoading: isRolesLoading } = useRoles()
  const { mutateAsync: addAssignableRole, isLoading: isPermissionOverrideLoading } = useAddAssignableRole()
  const { mutateAsync: deleteAssignableRole, isLoading: isDeletePermissionOverrideLoading } = useRemoveAssignableRole()

  const canEditUsers = role.permissions?.findIndex((item) => item.name === 'användarhantering') !== -1

  const onFinish = async (values: { assignableRole: number }) => {
    try {
      if (!role.id) return

      await addAssignableRole({ roleId: role.id, assignableRoleId: values.assignableRole })
      refetch()
      notification.success({
        message: 'Rollrelation skapad',
        description: 'Rollrelation har skapats',
      })
    } catch (e) {
      console.error(e)
      notification.error({
        message: 'Rollrelation kunde inte skapas',
        description: 'Rollrelation kunde inte skapas',
      })
    }
  }

  const handleDelete = async (values: { roleId: number; assignableRoleId: number }) => {
    try {
      await deleteAssignableRole({ roleId: role.id, assignableRoleId: values.assignableRoleId })
      refetch()
      notification.success({
        message: 'Rollrelation raderad',
        description: 'Rollrelation har raderats',
      })
    } catch (e) {
      console.error(e)
      notification.error({
        message: 'Rollrelation kunde inte raderas',
        description: 'Rollrelation kunde inte raderas',
      })
    }
  }

  return (
    <div>
      <div className="flex justify-between">
        <h2 className="text-lg">Roller som kan ges ut</h2>
        <div>
          <Form
            name="new-assignable-role"
            initialValues={{
              name: role.name,
            }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            className="flex items-end gap-2"
          >
            <Form.Item label="Roll" name="assignableRole">
              <Select placeholder="Roll" optionFilterProp="children" style={{ width: '200px' }}>
                {allRoles
                  ?.filter((item) => role.assignableRoles?.findIndex((r) => r.id === item.id) === -1)
                  .map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <div style={{ display: 'flex', gap: '6px' }}>
                <Button type="primary" htmlType="submit" style={{ display: 'inline-block', marginTop: '20px' }} loading={isPermissionOverrideLoading}>
                  Lägg till
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>

      {!canEditUsers && <p>Rollen behöver 'användarhantering' för att redigera användare</p>}

      {canEditUsers && (
        <Table
          columns={[
            {
              title: 'Rollnamn',
              dataIndex: 'name',
              key: 'name',
            },
            {
              dataIndex: 'hantera',
              key: 'hantera',
              width: 125,
              render: (_, obj) => (
                <Space size="middle">
                  <Popconfirm
                    title="Säker att du vill radera denna roll?"
                    okText="Bekräfta"
                    cancelText="Avbryt"
                    onConfirm={() => role.id !== undefined && obj.id !== undefined && handleDelete({ roleId: role.id, assignableRoleId: obj.id })}
                  >
                    <Button danger type="primary" className="table-edit-link">
                      Ta bort
                    </Button>
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
          dataSource={role.assignableRoles}
          rowKey={(record) => record.id || ''}
          bordered
          size="small"
          loading={isDeletePermissionOverrideLoading}
        />
      )}
    </div>
  )
}

interface RoleOverrideProps {
  role: GetRoles200ResponseInner
  refetch: () => void
}
const RoleOverrides = ({ role, refetch }: RoleOverrideProps) => {
  const { data: allPermissions, isLoading: isPermissionsLoading } = usePermissions()
  const { mutateAsync: addPermissionOverride, isLoading: isPermissionOverrideLoading } = useAddPermissionOverride()
  const { mutateAsync: deletePermissionOverride, isLoading: isDeletePermissionOverrideLoading } = useRemovePermissionOverride()

  if (!role) return null

  const onFinish = async (values: { permission: number; action: 'add' | 'remove' }) => {
    try {
      if (!role.id) return

      await addPermissionOverride({ roleId: role.id, permissionId: values.permission, action: values.action })
      refetch()
      notification.success({
        message: 'Override skapad',
        description: 'Override har skapats',
      })
    } catch (e) {
      console.error(e)
      notification.error({
        message: 'Override kunde inte skapas',
        description: 'Override kunde inte skapas',
      })
    }
  }

  const handleDelete = async (values: { roleId: number; permissionId: number }) => {
    try {
      await deletePermissionOverride(values)
      refetch()
      notification.success({
        message: 'Override raderad',
        description: 'Override har raderats',
      })
    } catch (e) {
      console.error(e)
      notification.error({
        message: 'Override kunde inte raderas',
        description: 'Override kunde inte raderas',
      })
    }
  }

  return (
    <div>
      <div className="flex justify-between">
        <h2 className="text-lg">Overrides</h2>
        <div>
          <Form
            name="new-permission-override"
            initialValues={{
              name: role.name,
            }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            className="flex items-end gap-2"
          >
            <Form.Item label="Permission" name="permission">
              <Select placeholder="Permission" optionFilterProp="children" style={{ width: '200px' }}>
                {allPermissions?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Action" name="action">
              <Select placeholder="Action" optionFilterProp="children" style={{ width: '100px' }}>
                {['add', 'remove'].map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <div style={{ display: 'flex', gap: '6px' }}>
                <Button type="primary" htmlType="submit" style={{ display: 'inline-block', marginTop: '20px' }} loading={isPermissionOverrideLoading}>
                  Skapa
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Table
        columns={[
          {
            title: 'Permission namn',
            dataIndex: 'permissionName',
            key: 'permissionName',
            render: (_, obj) => `${obj.permissionName}`,
          },
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
          },
          {
            dataIndex: 'hantera',
            key: 'hantera',
            width: 125,
            render: (_, obj) => (
              <Space size="middle">
                <Popconfirm
                  title="Säker att du vill radera override?"
                  okText="Bekräfta"
                  cancelText="Avbryt"
                  onConfirm={() => role.id !== undefined && obj.permissionId !== undefined && handleDelete({ roleId: role.id, permissionId: obj.permissionId })}
                >
                  <Button danger type="primary" className="table-edit-link">
                    Ta bort
                  </Button>
                </Popconfirm>
              </Space>
            ),
          },
        ]}
        dataSource={role.overrides}
        rowKey={(record) => record.permissionId || ''}
        bordered
        size="small"
        loading={isDeletePermissionOverrideLoading}
      />
    </div>
  )
}

export default SingleRole
