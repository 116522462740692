import addThousandSeparator from '../../utils/thousandSeperator'

const dataFiltering = (data: any) => {
  const res: any[] = []

  const bestalldTid = data.bestalldTid || []
  const utfordTid = data.utfordTid || []
  const antalBrukare = data.antalBrukare || []
  const antalBrukareUtfordTid = data.antalBrukareUtfordTid || []
  const approvedTime = data.approvedTime || []
  const brukareBestalldTid = data.brukareBestalldTid || []
  var formattedBestalldTid: {
    [key: string]: any
  } = { name: 'Beställd tid' }
  var formattedBrukareCount: {
    [key: string]: any
  } = { name: 'Antal brukare med beställd tid' }
  var formattedUtfordTid: {
    [key: string]: any
  } = { name: 'Utförd tid' }
  var antalBrukareMedUtfordTid: {
    [key: string]: any
  } = { name: 'Antal brukare med utförd tid' }
  var formattedUtförandegrad: {
    [key: string]: any
  } = { name: 'Utförandegrad' }
  var bestalldTidPerBrukare: {
    [key: string]: any
  } = { name: 'Beställd tid per brukare' }
  var utfordTidPerBrukare: {
    [key: string]: any
  } = { name: 'Utförd tid per brukare' }

  bestalldTid.forEach((item: any, index: number) => {
    formattedBestalldTid[index] = addThousandSeparator(Math.round(item.hours))
    const utfordTidHours = utfordTid[index] ? utfordTid[index].hours : 0
    const bestalldTidHours = item.hours || 1
    formattedUtförandegrad[index] = ((utfordTidHours / bestalldTidHours) * 100).toFixed(1) + '%'
  })
  approvedTime.forEach((item: any, index: number) => {
    const bestalldTid = brukareBestalldTid[index] ? brukareBestalldTid[index].amount : 1
    const approved = item.hours || 1
    bestalldTidPerBrukare[index] = (approved / bestalldTid).toFixed(1)
  })
  utfordTid.forEach((item: any, index: number) => {
    formattedUtfordTid[index] = addThousandSeparator(Math.round(item.hours))
    const bestalldTid = brukareBestalldTid[index] ? brukareBestalldTid[index].amount : 1
    utfordTidPerBrukare[index] = (item.hours / bestalldTid).toFixed(1)
  })
  antalBrukare.forEach((item: any, index: number) => {
    formattedBrukareCount[index] = addThousandSeparator(item.amount)
  })
  antalBrukareUtfordTid.forEach((item: any, index: number) => {
    antalBrukareMedUtfordTid[index] = addThousandSeparator(item.amount)
  })

  res.push(formattedBestalldTid)
  res.push(formattedBrukareCount)
  res.push(formattedUtfordTid)
  res.push(antalBrukareMedUtfordTid)
  res.push(formattedUtförandegrad)
  res.push(bestalldTidPerBrukare)
  res.push(utfordTidPerBrukare)
  return res
}

export default dataFiltering
