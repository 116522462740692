import { Button, Col, Divider, Form, Input, Popconfirm, Row, Space, Spin, Timeline, Typography } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Case } from '.'
import { closeCase, getCases, updateCase } from '../../api/arendeApi'
import { useApi } from '../../api/useApi'
import BackButton from '../../components/buttons/backButton'
import FlagListHeader from '../../components/header'
import { DataType } from '../../components/tablesConfig/filterconfig'
import CaseModal from './components/caseModal/CaseModal'
import { useQuery, useQueryClient } from '@tanstack/react-query'
const { Text } = Typography
const { TextArea } = Input

export type INewLog = {
  id?: number
  createdBy?: number // change to user
  comment: string
}

const SingleCase = () => {
  const [textInput, setTextInput] = useState<string>('')
  const [currentCase, setCurrentCase] = useState<Case>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>()
  type Param = { id: string }
  const { id } = useParams<Param>()
  const getId = parseInt(id)
  const api = useApi()

  const { isLoading, data: cases } = useQuery(['cases'], getCases)
  const queryClient = useQueryClient()

  useEffect(() => {
    const findCase = cases?.find((item) => item.id === getId)
    setCurrentCase(findCase)
  }, [cases, getId])

  const editHandler = async () => {
    setLoading(true)

    const newLog: INewLog = {
      id: currentCase?.id,
      createdBy: currentCase?.ägare.id, // change to current user
      comment: textInput,
    }

    const response = await updateCase(api, newLog)
    if (response) {
      setLoading(false)
      queryClient.invalidateQueries(['cases'])

      response.created = new Date(response.created)
      setCurrentCase(response)
    }
  }

  const closeHandler = async () => {
    const newLog: INewLog = {
      id: currentCase?.id,
      createdBy: currentCase?.ägare.id, // change to current user
      comment: textInput,
    }

    const response = await closeCase(api, newLog)

    if (response) {
      setSuccess(true)
      response.created = new Date(response.created)
      response.senastUppdaterad = new Date(response.senastUppdaterad)
      setCurrentCase(response)
    }
  }

  const getColor = (status: string) => {
    switch (status) {
      case 'öppet':
        return 'red'
      case 'Uppdaterat':
        return 'grey'
      case 'Stängt':
        return 'red'
    }
  }

  const getStatus = (type: string) => {
    switch (type) {
      case 'created':
        return 'Uppföljningen skapades'
      case 'comment':
        return 'Anteckning'
      case 'closed':
    }
  }

  const deleteKey = (key: React.Key) => {
    console.log(key)
  }

  return (
    <>
      <FlagListHeader title="" heading="Redigera uppföljning" description="" button={false} />
      <Row className="case-wrapper">
        <Col
          span={16}
          className="case-column"
          style={{
            padding: '20px 30px 0 0',
          }}
        >
          <div>
            <Text strong>Uppföljningsnummer: </Text>
            <Text>{currentCase?.key}</Text>
          </div>
          <div>
            <Text strong>Skapad av: </Text>
            <Text>{currentCase?.ägare.namn}</Text>
          </div>
          <div>
            <Text strong>Synlighet: </Text>
            <Text>{currentCase?.visible ? 'Publik' : 'Dold'}</Text>
          </div>
          <div>
            <Text strong>Skapades: </Text>
            <Text>{currentCase?.created.toLocaleDateString()}</Text>
          </div>
          <div>
            <Text strong>Avvikelser: </Text>
            {currentCase?.flags.map((item, index) => (
              <div
                key={index}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Text>
                  <Link
                    to={{
                      pathname: `/app/avvikelse/flag=${item.type}&id=${item.id}`,
                      state: { flagname: item.namn, riskniva: item.nivå },
                    }}
                  >
                    {item.namn}
                  </Link>
                </Text>
                <Text>Risknivå: {item.nivå}</Text>
                <Text>Skapad: {new Date(item.created).toLocaleDateString()}</Text>
                <Popconfirm title="Säker att du vill radera denna avvikelse?" okText="Bekräfta" cancelText="Avbryt" onConfirm={() => deleteKey(currentCase.id)}>
                  <button className="table-edit-link">Radera</button>
                </Popconfirm>
              </div>
            ))}
          </div>
          {currentCase?.status === 'Stängt' ? (
            <>
              <div style={{ marginTop: 40 }}>
                <Text strong>Uppföljningen hanterades den {moment(currentCase?.senastUppdaterad).format('YYYY-DD-MM, HH:mm:ss')}</Text>
              </div>
              <Divider />
              <BackButton />
            </>
          ) : (
            <>
              <Divider />
              <Form onFinish={editHandler} autoComplete="off">
                <Text strong>Anteckning</Text>
                <Form.Item rules={[{ required: true, message: 'Anteckning krävs.' }]} name="note">
                  <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 20 }} onChange={(e) => setTextInput(e.target.value)} />
                </Form.Item>
                <BackButton display="inline" />
                <Space style={{ float: 'right', marginTop: 20 }}>
                  <Button htmlType="submit" loading={loading}>
                    Spara ändringar
                  </Button>
                  <Button type="primary" onClick={() => setShowModal(true)}>
                    Stäng uppföljning
                  </Button>
                </Space>
              </Form>
            </>
          )}
        </Col>

        <Col span={8} style={{ padding: 40 }} className="case-column">
          <Timeline>
            {currentCase?.händelser.map((item, index) => (
              <Timeline.Item key={index} color={getColor(item.status)}>
                <p>
                  {getStatus(item.type)} {new Date(item.date).toLocaleDateString()}
                </p>
                <p>{item.comment}</p>
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
      </Row>

      {isLoading && <Spin size="large" style={{ position: 'absolute', top: '50%', left: '50%' }} />}

      {showModal && (
        <CaseModal
          closeModal={success}
          sendData={closeHandler}
          cancelModal={() => setShowModal(false)}
          modalText={{
            text: 'Är du säker på att du vill stänga denna uppföljning?',
            loading: 'Hanterar uppföljningen..',
          }}
        />
      )}
    </>
  )
}

export default SingleCase
