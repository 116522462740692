import { Dispatch } from 'redux'

export const SET_TABLEFILTER = 'SET_TABLEFILTER'

export type ISetTableFilterAction = {
  type: typeof SET_TABLEFILTER
  payload: {
    data: any
  }
}

export const setTableFilterAction = (type: string, filter: any, sorter: any, dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_TABLEFILTER,
    payload: {
      data: { filter: filter, sorter: sorter },
    },
  })
}

export type Actions = ISetTableFilterAction
