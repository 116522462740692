import { IBICResolverResult, ICIBResolutionContext } from '../types'

// Undefined rule => always first timepoint
export const resolveIBICUndefinedRule = ({ previousResult, task, allTasks, tasksPerDay }: ICIBResolutionContext): IBICResolverResult => {
  const firstThatDoesntHaveTaskAlready = previousResult.findIndex((timepoint) => {
    return !timepoint.tasks.some((t) => t.name === task.name)
    // &&  timepoint.tasks.length < tasksPerDay
  })

  return firstThatDoesntHaveTaskAlready !== -1 ? firstThatDoesntHaveTaskAlready : null
}
