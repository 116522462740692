import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './store'

interface brukareFilterSlice {
  data: any
}

const initialState = {} as brukareFilterSlice

const brukareFilterSlice = createSlice({
  name: 'brukareFilter',
  initialState,
  reducers: {
    setBrukareFilter(state, action: PayloadAction<brukareFilterSlice>) {
      return action.payload
    },
  },
})

export const getBrukareFilter = (state: AppState) => state.brukareFilterReducer.data
export const { setBrukareFilter } = brukareFilterSlice.actions
export default brukareFilterSlice.reducer
