import Title from 'antd/lib/typography/Title'
import FlagListHeader from '../../components/header'
import AuthMethodSettings from './AuthMethodSettings'
import Paragraph from 'antd/lib/typography/Paragraph'
import { Card } from 'antd'
import TokenSettings from './TokenSettings'

const MiscSettings = () => {
  const SETTINGS = [
    {
      header: 'Autentiseringsmetoder',
      component: <AuthMethodSettings />,
    },
    {
      header: 'Tokens',
      component: <TokenSettings />,
    },
  ]

  return (
    <>
      <FlagListHeader heading="Övriga inställningar" title={null} description="" button={false} />
      <div className="grid grid-cols-[repeat(auto-fill,minmax(500px,1fr))] gap-4">
        {SETTINGS.map((setting, index) => (
          <Card key={index}>
            <Title level={2}>{setting.header}</Title>
            {setting.component}
          </Card>
        ))}
      </div>
    </>
  )
}

export default MiscSettings
