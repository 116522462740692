import { DatePicker, Spin, Tabs } from 'antd'
import { useState } from 'react'
import { useApi } from '../../api/useApi'
import FlagListHeader from '../../components/header'
import renderSwitch from './utils/renderSwitch'
import { useBenchmarkData } from './utils/TableConfig/useBenchmarkData'

const Hemtjanst = () => {
  const [activeTab, setActiveTab] = useState<string>('tab-1')
  const { data, mutate, isLoading } = useBenchmarkData()

  const { RangePicker } = DatePicker

  const api = useApi()

  const handleDatesChange = (range: any) => {
    const startDate = range[0].startOf('month').format().split('T')[0]
    const endDate = range[1].endOf('month').format().split('T')[0]
    mutate({ api, from: startDate, to: endDate })
  }
  return (
    <>
      <RangePicker className="range-picker" picker="month" onChange={handleDatesChange} />
      <Spin spinning={isLoading}>
        <Tabs defaultActiveKey={'tab-1'} activeKey={activeTab} onTabClick={(key: string) => setActiveTab(key)}>
          <Tabs.TabPane tab={'Dashboard'} key={'tab-1'} />
          <Tabs.TabPane tab={'Övergripande statistik'} key={'tab-2'} />
          <Tabs.TabPane tab={'Omfattande beslut'} key={'tab-4'} />
          <Tabs.TabPane tab={'Förändringar i brukarunderlag'} key={'tab-5'} />
          <Tabs.TabPane tab={'Statistik per utförare'} key={'tab-6'} />
          {/* Temporarily disabled */}
          {/* <Tabs.TabPane tab={'Förändringar i brukarunderlag per utförare'} key={'tab-7'} /> */}
          <Tabs.TabPane tab={'Larm och natt'} key={'tab-8'} />
        </Tabs>

        {renderSwitch(activeTab, data)}
      </Spin>
    </>
  )
}

export default Hemtjanst
