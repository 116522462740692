import type { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import { formatDate } from '../../Dashboard/graphConfig'

export interface DataType {
  key: React.Key
  date: string
  amount: number
  name: string
}

type Data = {
  ym?: string
  month?: string
  amount: number | any
}

const tableHeader = (data: Data[], title?: string): ColumnsType<DataType> => {
  const res: ColumnsType<DataType> = [
    {
      width: 400,
      dataIndex: 'name',
      title: title,
      key: 'key',
      fixed: 'left',
    },
  ]
  data.forEach((item, index) =>
    res.push({
      width: 105,
      title: formatDate(
        moment(item.ym ?? item.month)
          .locale('sv')
          .format('ll')
      ),
      dataIndex: index,
      key: index,
    })
  )

  const whiteSpaceColumn = {
    title: '',
    dataIndex: 'whiteSpace',
    key: 'whiteSpace',
    fixed: 'right',
  } as const

  res.push(whiteSpaceColumn)
  return res
}

export default tableHeader
