import { Typography } from 'antd'
import { TimepointTask, ExtraTask } from '../types/municipalityData'

export interface SelectionDescriptionProps {
  task: TimepointTask | ExtraTask
}

export const SelectionDescription = ({ task }: SelectionDescriptionProps) => {
  return (
    <div>
      {Object.entries(task.selectionDescriptions).map(([level, desc]) => (
        <span key={level}>
          <Typography.Text strong>{level}: </Typography.Text>
          {desc}
          <br />
        </span>
      ))}
    </div>
  )
}
