import FlagListHeader from '../../components/header'

const BenchmarkingIntroduction = () => {
  return (
    <>
      <FlagListHeader heading="Introduktion" title={''} description={null} button={false} disabled={true} />
      <div className="grid w-full grid-cols-[1fr,1fr] gap-x-6 gap-y-2 rounded-md bg-white">
        <div>
          <b>Bakgrund</b>
          <br />
          Att beskriva volymer inom äldreomsorgen är en viktig del av att beskriva den samlade resursanvändningen i verksamheten. Volymer kan definieras som exempelvis antalet brukare, antalet timmar
          eller antalet vårddygn. Dessa kan i sin tur beskrivas utifrån olika delkomponenter. Det visar sig snabbt att volymbegreppet är relativt komplext. Många kommuner lyfter därför att detta är en
          svår men viktig fråga att hantera, som ofta behöver hanteras i samband med budgetarbetet.
          <br /> <br />
          Det finns en tydlig koppling mellan volymer och effektiviteten i verksamheten. Ett väldigt förenklat exempel skulle kunna vara: Kommun A levererar hemtjänst med 30 timmar per brukare och
          månad. Kommun B behöver 40 timmar. Vid en närmre jämförelse visar det sig att båda kommunerna har ungefär samma kvalitetsnivåer. Kommun B kan lära av Kommun A och minska sin resursanvändning
          med 25 procent.
          <br /> <br />
          Jämförelser med andra kommuner kan med andra ord ge tydliga indikationer till hur verksamheten kan förbättras. Det är sällan så enkelt som exemplet ovan ger sken av. Det finns flera
          beroenden, exempelvis där kommuner kan ha olika fördelning mellan särskilt boende och ordinärt boende. Utan förståelse för volymerna i verksamheten blir det dock svårt att förhålla sig till
          varför budgeten går ihop eller inte.
          <br /> <br />
          Volymuppföljning med jämförelser är ett kraftfullt verktyg för att effektivisera äldreomsorgen. Det är en förutsättning för att vi ska klara de demografiska utmaningarna i en åldrande
          befolkning.
        </div>
        <div>
          <b>Syfte och mål</b>
          <br />
          Benchmarkingen ska ge kommunerna ett användbart verktyg för verksamhetsutveckling genom:
          <br /> <br />
          <ul className="list-inside list-disc">
            <li>bättre förståelse för volymer och hur de påverkar övergripande effektivitet</li>
            <li>jämförelser med andra kommuner för att ge tydliga indikationer om förbättringsområden</li>
            <li>nedbrutna analyser som ger förståelse för vad som driver volymer</li>
          </ul>
          <br />
          <b>Metod</b>
          <br />
          Benchmarking är en metod för att systematiskt skapa lärande från goda exempel eller förebilder. Grunden i metoden bygger på jämförelser med andra verksamheter. Syftet är att få insikter som
          kan omvandlas till förbättringar som stärker effektiviteten i den egna verksamheten.
          <br /> <br />
          Jämförelserna i denna analys genomförs på ett strukturerat sätt. Huvuddelen av analyserna i denna rapport hämtas från kommunens verksamhetssystem. För att möjliggöra jämförelser kommer alla
          data att genomgå samma kvalitetssäkringsprocess, där bland annat jämförelsestörande underlag rensas. Måtten kan därför skilja något från kommunen egna uppföljning i syfte att möjliggöra
          relevanta jämförelser. De mätningar som görs med publika data är ofta behäftade med fel eller görs ofta på för övergripande nivå.
          <br /> <br />
          Volymmått kan kombineras med kvalitetsmått för att på så vis beskriva effektivitet. Jämförelserna kan visa på samband mellan resursanvändning, kvalitet och effektivitet.
        </div>
      </div>
    </>
  )
}

export default BenchmarkingIntroduction
