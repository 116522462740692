import { useMutation } from '@tanstack/react-query'
import { AxiosInstance } from 'axios'
import {
  fetchAmountOrderedTime,
  fetchAvgAge,
  fetchAvgAgeCanceledCases,
  fetchAvgAgeNewCases,
  fetchBeslutsomfattningPerInterval,
  fetchBestalldTidPerBrukare,
  fetchBevlijadeLarmNattinsattser,
  fetchBrukareBestalldTid,
  fetchCanceledCases,
  fetchLarm,
  fetchLarmHemtjanst,
  fetchLarmNotHemtjanst,
  fetchLarmuttryckningar,
  fetchNewCases,
  fetchOrderedTime,
  fetchTimeOfCanceledCases,
  fetchTimeOfNewBrukareCases,
  fetchUtfordPerBrukare,
  fetchUtfordTid,
  fetchUtfordTidPerBrukare,
  fetchUtfordaNattinsatser,
} from '../../../../api/hemtjänstApi'

export const useBenchmarkData = () =>
  useMutation(
    ['benchmarkData'],
    // api: AxiosInstance, from: string, to: string
    async ({ api, from, to }: { api: AxiosInstance; from: string; to: string }) => {
      return [
        await fetchNewCases(api, from, to),
        await fetchOrderedTime(api, from, to),
        // await fetchBrukareCount(api, from, to),
        await fetchCanceledCases(api, from, to),
        await fetchBrukareBestalldTid(api, from, to),
        await fetchUtfordTid(api, from, to),
        // await fetchBestalldOmvardnad(api, from, to),
        // await fetchBestalldService(api, from, to),
        // await fetchBrukareMedBestalldTid(api, from, to),
        // await fetchBrukareMedBeviljadTid(api, from, to),
        // await fetchAmountBrukare(api, from, to),
        await fetchAmountOrderedTime(api, from, to),
        // await fetchNyaBruakre(api, from, to),
        await fetchTimeOfNewBrukareCases(api, from, to),
        await fetchTimeOfCanceledCases(api, from, to),
        await fetchAvgAge(api, from, to),
        await fetchAvgAgeNewCases(api, from, to),
        await fetchAvgAgeCanceledCases(api, from, to),
        await fetchUtfordPerBrukare(api, from, to),
        await fetchLarm(api, from, to),
        await fetchLarmuttryckningar(api, from, to),
        await fetchUtfordaNattinsatser(api, from, to),
        await fetchBevlijadeLarmNattinsattser(api, from, to),
        await fetchBestalldTidPerBrukare(api, from, to),
        await fetchUtfordTidPerBrukare(api, from, to),
        // await fetchCanceledCasesByBrukare(api, from, to),
        // await fetchNewCasesByBrukare(api, from, to),
        await fetchLarmHemtjanst(api, from, to),
        await fetchLarmNotHemtjanst(api, from, to),
        await fetchBeslutsomfattningPerInterval(api, from, to),
      ]
    }
  )
