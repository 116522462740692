import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useApi } from '../../api/useApi'
import SingleObject from '../../components/singleObject'
import { setFlags } from '../../api/flagApi'

const SingleBrukare = () => {
  type Param = { id: string; namn: string }
  const { id, namn } = useParams<Param>()
  const dispatch = useDispatch()
  const api = useApi()
  console.log(id)
  useEffect(() => {
    setFlags(api, dispatch, 'brukare', id)
  }, [id, api, dispatch])

  return <SingleObject obj={{ namn }} heading={`Brukare - ${namn}`} />
}

export default SingleBrukare
