import { ColumnsType } from 'antd/lib/table'
import { IBICFormTask } from './types/FormValues'

export const summaryTableConfig: ColumnsType<IBICFormTask> = [
  {
    title: 'Livsområde',
    dataIndex: 'lifeArea',
    key: 'lifeArea',
    width: 150,
  },
  {
    title: 'Insats',
    dataIndex: 'name',
    key: 'name',
    width: 150,
    fixed: 'left',
  },
  {
    title: 'Nivå',
    dataIndex: 'level',
    key: 'level',
    width: 150,
  },
  {
    title: 'Tillfällen per dag',
    dataIndex: 'timesPerDay',
    key: 'timesPerDay',
    width: 150,
  },
  {
    title: 'Dagar per vecka',
    dataIndex: 'daysPerWeek',
    key: 'daysPerWeek',
    width: 150,
  },
  {
    title: 'Dubbelbemanning',
    dataIndex: 'Dubbelbemanning',
    key: 'Dubbelbemanning',
    width: 150,
    render: (_, obj) => (obj.double ? 'Ja' : 'Nej'),
  },
]
