import { ContentColumn } from '../..'
import { DementiaStayLengthTrend } from './DementiaStayLengthTrend'
import { MeanNursingDementiaTotalStayLength } from './MeanNursingDementiaTotalStayLength'
import { MedianNursingDementiaTotalStayLength } from './MedianNursingDementiaTotalStayLength'
import { NursingStayLengthTrend } from './NursingStayLengthTrend'
import { TotalStayLengthTrend } from './TotalStayLengthTrend'

export const benchmarkingStayLength: ContentColumn[] = [
  {
    onlyRenderIfBenchmark: 'nursingDementiaTotalStayLength',
    content: (
      <MeanNursingDementiaTotalStayLength
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Genomsnittlig vistelsetid på särskilt boende per inriktning',
  },
  {
    onlyRenderIfBenchmark: 'nursingDementiaTotalStayLength',
    content: (
      <MedianNursingDementiaTotalStayLength
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Median vistelsetid på särskilt boende per inriktning',
  },
  {
    onlyRenderIfBenchmark: 'totalStayLengthTrend',
    content: (
      <TotalStayLengthTrend
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Vistelsetid på särskilt boende, trend',
  },
  {
    onlyRenderIfBenchmark: 'nursingStayLengthTrend',
    content: (
      <NursingStayLengthTrend
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Vistelsetid på omvårdnadsboende, trend',
  },
  {
    onlyRenderIfBenchmark: 'dementiaStayLengthTrend',
    content: (
      <DementiaStayLengthTrend
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Vistelsetid på demensboende, trend',
  },
]
