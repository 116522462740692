import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './store'
interface ansvarigPersonState {
  ansvarigPersonId: string
  ansvarigPersonName: string
}

const initialState = {
  ansvarigPersonId: '',
  ansvarigPersonName: '',
} as ansvarigPersonState

const ansvarigPersonSlice = createSlice({
  name: 'ansvarigPerson',
  initialState,
  reducers: {
    setAnsvarigPerson(state, action: PayloadAction<ansvarigPersonState>) {
      return action.payload
    },
  },
})

export const getAnsvarigPersonId = (state: AppState) => state.ansvarigPersonReducer.ansvarigPersonId
export const getAnsvarigPersonName = (state: AppState) => state.ansvarigPersonReducer.ansvarigPersonName
export const { setAnsvarigPerson } = ansvarigPersonSlice.actions
export default ansvarigPersonSlice.reducer
