import { IDistributionData } from '../../../arendeFordelning'
import { IMyCasesData2 } from '../../../minaArenden/tableConfig'
import { INyligenUtgångnaData } from '../../../nyligenUtgångna/tableConfig'
import { IUtanförTeamstrukturData } from '../../../utanforTeamStruktur/tableConfig'
import { IVolumeData } from '../../../volymuppfoljning/tableConfig'
import * as actions from './actions'
import { Actions } from './actions'

export type State = {
  loading: boolean
  distribution: IDistributionData[]
  myCases: IMyCasesData2[]
  volume: IVolumeData[]
  utanför_teamstruktur: IUtanförTeamstrukturData[]
  nyligen_utgångna: INyligenUtgångnaData[]
}

const initialState: State = {
  loading: true,
  distribution: [],
  myCases: [],
  volume: [],
  utanför_teamstruktur: [],
  nyligen_utgångna: [],
}

const arendenReducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case actions.SET_VOLUME:
      return (state = {
        ...state,
        volume: action.payload.data,
      })
    case actions.LOADING_VOLUME:
      return (state = {
        ...state,
        loading: action.payload.loading,
      })
    case actions.SET_DISTRIBUTION:
      return (state = {
        ...state,
        distribution: action.payload.data,
      })
    case actions.LOADING_DISTRIBUTION:
      return (state = {
        ...state,
        loading: action.payload.loading,
      })
    case actions.SET_MY_CASES:
      return (state = {
        ...state,
        myCases: action.payload.data,
      })
    case actions.LOADING_ARENDEN:
      return (state = {
        ...state,
        loading: action.payload.loading,
      })
    case actions.SET_UTANFÖR_TEAMSTRUKTUR:
      return (state = {
        ...state,
        utanför_teamstruktur: action.payload.data,
      })
    case actions.SET_NYLIGEN_UTGÅNGNA:
      return (state = {
        ...state,
        nyligen_utgångna: action.payload.data,
      })
    default:
      return state
  }
}

export default arendenReducer
