import { Select, Spin } from 'antd'
import { ComponentProps, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { useMunicipalityName, useVolumeProportionAgeGroupValues } from '../../../../api/benchmarking'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { getFontSize } from '../../util/fontSize'
import { BASELINE_COLOR, getColorByIndex, getComparisonColor } from '../../util/colors'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { BORDER_WIDTH } from '../../util/constants'
import { baseGraphOptions } from '../../util/baseGraphOptions'

export const VolumeProportionAgeGroup = ({ text }: { text: React.ReactNode }) => {
  const { data: municipalityName, isLoading: isMunicipalityNameLoading } = useMunicipalityName()
  const { data, isLoading, mutate } = useVolumeProportionAgeGroupValues()

  useEffect(() => {
    mutate({})
  }, [])

  if (isLoading || isMunicipalityNameLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const baselineOptions: ComponentProps<typeof Bar>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${Math.round(context.parsed.y)}%`
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        min: 0,
        max: 100,
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
        title: {
          display: true,
          text: municipalityName,
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        stacked: true,
        min: 0,
        max: 100,
        ticks: {
          font: {
            size: getFontSize('md'),
          },
          callback: (value) => `${value}%`,
        },
      },
    },
  }

  const comparisonOptions: ComponentProps<typeof Bar>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${Math.round(context.parsed.y)}%`
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        min: 0,
        max: 100,
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
        title: {
          display: true,
          text: 'Nationellt',
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        stacked: true,
        min: 0,
        max: 100,
        ticks: {
          font: {
            size: getFontSize('md'),
          },
          callback: (value) => `${value}%`,
        },
      },
    },
  }
  const baselineGraphData: ComponentProps<typeof Bar>['data'] = {
    labels: data.baselineValues.map((item) => item.ageRange),
    datasets: [
      {
        label: 'Särskilt boende',
        data: data.baselineValues.map((item) => item.säbo),
        backgroundColor: getColorByIndex(0).fill,
        borderColor: getColorByIndex(0).border,
        borderWidth: BORDER_WIDTH,
      },
      {
        label: 'Hemtjänst',
        data: data.baselineValues.map((item) => item.hemtjänst),
        backgroundColor: getColorByIndex(1).fill,
        borderColor: getColorByIndex(1).border,
        borderWidth: BORDER_WIDTH,
      },
    ],
  }
  const comparisonGraphData: ComponentProps<typeof Bar>['data'] = {
    labels: data.comparisonValues.map((item) => item.ageRange),
    datasets: [
      {
        label: 'Särskilt boende',
        data: data.comparisonValues.map((item) => item.säbo),
        backgroundColor: getColorByIndex(0).fill,
        borderColor: getColorByIndex(0).border,
        borderWidth: BORDER_WIDTH,
      },
      {
        label: 'Hemtjänst',
        data: data.comparisonValues.map((item) => item.hemtjänst),
        backgroundColor: getColorByIndex(1).fill,
        borderColor: getColorByIndex(1).border,
        borderWidth: BORDER_WIDTH,
      },
    ],
  }

  const dataSourceSelector = (
    <Select
      value={data.currentDataSource}
      onChange={(value) => {
        mutate({ datasource: value, grouping: undefined })
      }}
      options={data.dataSources.map((ds) => ({
        label: ds,
        value: ds,
      }))}
      style={{ width: '100%' }}
    />
  )

  const comparisonSelector = (
    <Select
      value={data.currentGrouping}
      onChange={(value) => {
        mutate({ grouping: value, datasource: data.currentVariation.comparison?.datasource?.name })
      }}
      options={data.groupings.map((grouping) => ({
        label: grouping,
        value: grouping,
      }))}
      style={{ width: '100%' }}
    />
  )

  const graph: React.ReactNode = (
    <div className="flex w-full items-center justify-center gap-2">
      <div className="h-full w-[30cqi]">
        <Bar options={baselineOptions} data={baselineGraphData} />
      </div>
      <div className="h-full w-[30cqi]">
        <Bar options={comparisonOptions} data={comparisonGraphData} />
      </div>
    </div>
  )
  const legend = (
    <BenchmarkingLegend
      items={[
        {
          text: 'Särskilt boende',
          fillColor: getColorByIndex(0).fill,
          borderColor: getColorByIndex(0).border,
        },
        {
          text: 'Hemtjänst',
          fillColor: getColorByIndex(1).fill,
          borderColor: getColorByIndex(1).border,
        },
      ]}
    />
  )

  const title = 'Fördelning mellan insatser i ordinärt boende respektive särskilt boende per åldersgrupp'
  return <BenchmarkingContentRow graph={graph} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} text={text} title={title} legend={legend} />
}
