// Antd
import type { ColumnsType } from 'antd/es/table'
import { Button, Select, SelectProps, Spin, Table, Typography } from 'antd'
import { ReactChild, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMyCases } from '../arende/store/selectors'
import { getAnsvarigPersonId, getAnsvarigPersonName, setAnsvarigPerson } from '../../store/ansvarigPersonSlice'
import { FilterValue } from 'antd/lib/table/interface'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import { fetchMycases } from '../arendeFordelning/queries'
import { fecthDistribution } from '../arendeFordelning/queries'
import FlagListHeader from '../../components/header'
import { createMinaArendenTableConfig, IMyCasesData2 } from './tableConfig'
import { useMe } from '../../api/permissions'
import { useMunicipalityName } from '../../api/benchmarking'
import { HIDDEN_COLUMNS } from './constants'

export interface IMinaArendenTableEntry {
  key: React.Key
  id: number
  brukare: { id: number; namn: string }
  handläggare: { id: number; namn: string }
  ny_process: string
  från: string
  till: string
  nästaGenomförandePlan: string
  nästaKvalitetsfrågor: string
  senasteHembesök: string
  utförare: string
  omfattning: number
  dubbelbemmaning: boolean
}

export interface footnote {
  number: string
  content: string
}

export type Members = {
  value: string
  label: string
}

function sortDataByLabel(data: Members[]): Members[] {
  const sortedData = data?.slice().sort((a, b) => a.label.localeCompare(b.label))
  return sortedData
}

const MinaArendenTable = () => {
  const [selected, setSelected] = useState<string[]>([])
  const [renderTable, setRenderTable] = useState<ColumnsType<IMyCasesData2> | undefined>([])
  const ansvarigPersonName = useSelector(getAnsvarigPersonName)
  const ansvarigPersonId = useSelector(getAnsvarigPersonId)
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({})
  const [members, setMembers] = useState<Members[]>([])
  const dispatch = useDispatch()

  const { data: municipalityName } = useMunicipalityName()

  const handleChange = (value: any) => {
    setSelected(value)
  }

  const handleMemberSelect = (value: any) => {
    resetTableFilters()
    const correctMember = myCasesData?.find((item) => item.ansvarig_personnummer === value)
    dispatch(setAnsvarigPerson({ ansvarigPersonId: correctMember ? correctMember.ansvarig_personnummer : '', ansvarigPersonName: correctMember ? correctMember.ansvarig_namn : '' }))
  }

  const resetFilters = () => {
    setSelected([])
  }

  const resetTableFilters = () => {
    const resetFilters = { ...filters }
    Object.keys(resetFilters).forEach((key) => {
      resetFilters[key] = null
    })
    setFilters(resetFilters)
    console.log(outsideStructureData)
  }

  let {
    isLoading: isOutsideStructureLoading,
    isError: isOutsideStructureError,
    data: outsideStructureData,
    error: outsideStructureError,
  } = useQuery(['outsideStructure', ansvarigPersonId, moment().format('YYYY-MM-DD')], fetchMycases)

  const { isError: isMyCasesError, data: myCasesData, error: myCasesError } = useQuery(['mycases'], fecthDistribution)

  useEffect(() => {}, [isOutsideStructureLoading, outsideStructureData])

  useEffect(() => {
    setRenderTable(createMinaArendenTableConfig(outsideStructureData || [], selected, filters, municipalityName))
  }, [selected, filters, isOutsideStructureLoading])

  if (isOutsideStructureError) console.error(isOutsideStructureError)
  if (isMyCasesError) console.error(isMyCasesError)

  useEffect(() => {
    const selectOptions = myCasesData?.map((item) => {
      return { value: item.ansvarig_personnummer, label: item.ansvarig_namn }
    })
    setMembers(selectOptions as Members[])
  }, [myCasesData])

  const options: SelectProps['options'] = [
    'Namn',
    'Personnummer',
    'Öppen process',
    'Hemtjänst',
    'Hemtjänst tid',
    'Trygghetslarm',
    'Dagverksamhet',
    'Korttids/Växelvård',
    'Uppföljning säbo',
    'Utförare',
    'Delar hushåll',
  ]
    .map((item) => {
      return { value: item, label: item }
    })
    .filter((item) => {
      return !municipalityName || !HIDDEN_COLUMNS[municipalityName]?.includes(item.value)
    })

  outsideStructureData?.sort((a, b) => (a.namn < b.namn ? -1 : 1))
  outsideStructureData = (outsideStructureData || []).map((o, i) => {
    return { ...o, key: 'key-' + i }
  })
  return (
    <>
      <FlagListHeader heading={'Mina ärenden' + ' - ' + ansvarigPersonName} title={null} description={null} button={false} />
      <Select
        style={{ minWidth: '20%', marginBottom: '20px', marginRight: '25px' }}
        placeholder="Byt handläggare"
        value={ansvarigPersonId ? { label: ansvarigPersonName, value: ansvarigPersonId } : undefined}
        options={sortDataByLabel(members)}
        onChange={handleMemberSelect}
      />
      <Select
        mode="multiple"
        maxTagCount={'responsive'}
        style={{ minWidth: '20%', maxHeight: '40px', marginBottom: '20px', marginRight: '25px' }}
        placeholder="Bortvalda kolumner"
        onChange={handleChange}
        options={options}
        value={selected}
      />
      <Button style={{ marginBottom: '20px', marginRight: '25px' }} onClick={resetFilters}>
        {' '}
        Rensa bortvalda kolumner
      </Button>
      <Button style={{ marginBottom: '20px', marginRight: '25px' }} onClick={resetTableFilters}>
        Rensa filter
      </Button>
      <Spin spinning={isOutsideStructureLoading}>
        <>
          <Table
            columns={renderTable}
            dataSource={outsideStructureData || []}
            bordered
            size="small"
            rowClassName="table-row-cursor"
            loading={false}
            scroll={{ x: 'calc(100vw - 260px)', y: 'calc(100vh - 250px)' }}
            // Show only pagination numbers and not the page buttons
            pagination={{
              position: ['topRight'],
              pageSize: 10000,
              showTotal: (_, range) => {
                return `${range[1]} av ${outsideStructureData?.length ?? 0}`
              },
              className: 'table-pagination-hidden',
              showSizeChanger: false,
            }}
            onChange={(pagination, filters, sorter, { currentDataSource }) => {
              Object.keys(filters).map((key) => {
                if (filters[key]?.includes('Välj alla')) {
                  if (key === 'personnummer') {
                    filters[key] = Array.from(
                      new Set(
                        (outsideStructureData || []).map((d) => {
                          return d.personnummer || 'Inget värde'
                        })
                      )
                    )
                  } else if (key === 'namn') {
                    filters[key] = Array.from(
                      new Set(
                        (outsideStructureData || []).map((d) => {
                          return d.namn || 'Inget värde'
                        })
                      )
                    )
                  } else if (key === 'öppen-process') {
                    filters[key] = Array.from(
                      new Set(
                        (outsideStructureData || []).map((d) => {
                          if (d.ny_process && d.ny_process.length > 0 && d.ny_process[0] !== undefined) {
                            return d.ny_process[0]
                          } else {
                            return 'Inget värde'
                          }
                        })
                      )
                    )
                  } else if (key === 'hemtjänst') {
                    filters[key] = Array.from(
                      new Set(
                        (outsideStructureData || []).map((d) => {
                          if (d.hemtjänst && d.hemtjänst.length > 0 && d.hemtjänst[0].till !== undefined) {
                            return d.hemtjänst[0].till
                          } else {
                            return 'Inget värde'
                          }
                        })
                      )
                    )
                  } else if (key === 'hemtjänst-tid') {
                    filters[key] = Array.from(
                      new Set(
                        (outsideStructureData || []).map((d) => {
                          return d.omfattning_månad?.toFixed(1) || 'Inget värde'
                        })
                      )
                    )
                  } else if (key === 'trygghetslarm') {
                    filters[key] = Array.from(
                      new Set(
                        (outsideStructureData || []).map((d) => {
                          if (d.trygghetslarm && d.trygghetslarm.length > 0 && d.trygghetslarm[0].till !== undefined) {
                            return moment(d.trygghetslarm[0].till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(d.trygghetslarm[0].till).format('YYYY-MM-DD') : 'Tillsvidare'
                          } else {
                            return 'Inget värde'
                          }
                        })
                      )
                    )
                  } else if (key === 'dagverksamhet') {
                    filters[key] = Array.from(
                      new Set(
                        (outsideStructureData || []).map((d) => {
                          if (d.dagverksamhet && d.dagverksamhet.length > 0 && d.dagverksamhet[0].till !== undefined) {
                            return d.dagverksamhet[0].till
                          } else {
                            return 'Inget värde'
                          }
                        })
                      )
                    )
                  } else if (key === 'korttidsboende') {
                    filters[key] = Array.from(
                      new Set(
                        (outsideStructureData || []).map((d) => {
                          if (d.korttidsboende && d.korttidsboende.length > 0 && d.korttidsboende[0].till !== undefined) {
                            return d.korttidsboende[0].till
                          } else {
                            return 'Inget värde'
                          }
                        })
                      )
                    )
                  } else if (key === 'uppföljning-säbo') {
                    filters[key] = Array.from(
                      new Set(
                        (outsideStructureData || []).map((d) => {
                          if (moment(d.säbo_fv).isBefore(moment('1980-01-01'))) return 'Ej verkställd'
                          return d.nästa_kvalitetsfrågor || 'Inget värde'
                        })
                      )
                    )
                  } else if (key === 'utförare') {
                    filters[key] = Array.from(
                      new Set(
                        (outsideStructureData || [])
                          .map((d) => {
                            return d.utförare_hemtjänst || d.utförare_säbo || d.utförare_dagverksamhet || d.utförare_korttidsboende || d.utförare_trygghetslarm || []
                          })
                          .flat()
                      )
                    )
                  } else if (key === 'hushåll') {
                    filters[key] = Array.from(
                      new Set(
                        (outsideStructureData || []).map((d) => {
                          return d.hushåll.length > 0 ? d.hushåll[0].medlem : 'Inget värde'
                        })
                      )
                    )
                  }
                }
              })
              setFilters(filters)
            }}
          />
        </>
      </Spin>
    </>
  )
}

export default MinaArendenTable
