import { useMutation, useQuery } from '@tanstack/react-query'
import { SearchTags200ResponseDataInner, Tag } from '../gen/api'
import { apiClient } from './useApi'

export interface SearchTagsParams {
  search?: string
}

export type SearchTag = SearchTags200ResponseDataInner
export const useMunicipalities = () => useQuery(['municipalities'], async () => await apiClient.getMunicipalities(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))
export const useAddMunicipalitiesToTag = () =>
  useMutation(['addMunicipalitiesToTag'], async ({ tagId, ids }: { tagId: string; ids: string[] }) => await apiClient.addMunicipalitiesToTag(localStorage.getItem('tenant_id') ?? '', tagId, ids))

export const useTags = () => useMutation(['tags'], async ({ search }: SearchTagsParams) => await apiClient.searchTags(localStorage.getItem('tenant_id') ?? '', search).then((res) => res.data))

export const useTag = (tagId: string) => useQuery(['tag', tagId], async () => await apiClient.getTag(localStorage.getItem('tenant_id') ?? '', tagId).then((res) => res.data))

export const useEditTag = () => useMutation(['editTag'], async ({ id, name }: Tag) => await apiClient.editTag(localStorage.getItem('tenant_id') ?? '', id, name).then((res) => res.data))
