import BrukareUnderlag from '../BrukareUnderlag/brukareUnderlag'
import BrukareUnderlagPerUtforare from '../BrukareUnderlagPerUtforare/brukareunderlagPerUtforare'
import Dashboard from '../Dashboard/dashboard'
import Genonsnitt from '../Genomsnitt/genomsnitt'
import LarmOchNatt from '../LarmOchNatt/larmOchNatt'
import OmfattandeBeslut from '../OmfattandeBeslut/omfattandeBeslut'
import StatistikPerutförare from '../StatistikPerUtförare/statistikPerutförare'
import OvergripandeStatistik from '../overgripandeStatistik/OvergripandeStatistik'

export default function renderSwitch(tab: string, data: any[] | undefined) {
  if (!data)
    return (
      <div className="h-96">
        <h1>Vänligen välj ett från och till datum</h1>
      </div>
    )

  const [
    newCases,
    approvedTime,
    canceledCases,
    amountBrukare,
    utfordTid,
    amountOrderedTime,
    timeOfNewBrukareCases,
    timeOfCanceledCases,
    avgAge,
    avgAgeNewCases,
    avgAgeCanceledCases,
    utfordPerBrukare,
    larm,
    larmuttryckningar,
    utfordaNattinsatser,
    beviljadeLarmNattInsatser,
    bestalldTidPerBrukare,
    utfordTidPerBrukare,
    larmHemtjanst,
    larmNotHemtjanst,
    beslutsomfattningPerInterval,
  ] = data

  switch (tab) {
    case 'tab-1':
      return (
        <Dashboard
          newCases={newCases}
          approvedTime={approvedTime}
          // brukareCount={brukareCount}
          canceledCases={canceledCases}
          brukareBestalldTid={amountBrukare}
          utfordTid={utfordTid}
          amountOrderedTime={amountOrderedTime}
          avgAge={avgAge}
        />
      )

    case 'tab-2':
      return (
        <OvergripandeStatistik
          bestalldTid={approvedTime}
          utfordTid={utfordTid}
          antalBrukare={amountBrukare}
          antalBrukareUtfordTid={utfordPerBrukare}
          brukareBestalldTid={amountBrukare}
          approvedTime={approvedTime}
        />
      )

    // case 'tab-3':
    //   return (
    //     <Genonsnitt
    //       bestalldTid={approvedTime}
    //       utfordTid={utfordTid}
    //       antalBrukare={brukareCount}
    //       newCases={newCases}
    //       omvardnad={omvardnad}
    //       service={service}
    //       amountBestalldTid={amountBestalldTid}
    //       amountBeviljadTid={amountBeviljadTid}
    //     />
    //   )

    case 'tab-4':
      return <OmfattandeBeslut amountBrukare={amountBrukare} amountOrderedTime={amountOrderedTime} brukareCount={amountBrukare} beslutsomfattningPerInterval={beslutsomfattningPerInterval} />

    case 'tab-5':
      return (
        <BrukareUnderlag
          nyaBrukare={newCases}
          timeOfNewBrukareCases={timeOfNewBrukareCases}
          timeOfCanceledCases={timeOfCanceledCases}
          avgAgeNewCases={avgAgeNewCases}
          avgAgeCanceledCases={avgAgeCanceledCases}
          canceledCases={canceledCases}
        />
      )

    case 'tab-6':
      return <StatistikPerutförare bestalldTidPerBrukare={bestalldTidPerBrukare} utfordTidPerBrukare={utfordTidPerBrukare} />

    // case 'tab-7':
    //   return <BrukareUnderlagPerUtforare newCasesByBrukare={newCasesByBrukare} canceledCasesByBrukare={canceledCasesByBrukare} />

    case 'tab-8':
      return (
        <LarmOchNatt
          larm={larm}
          larmuttryckningar={larmuttryckningar}
          beviljadeLarmNattInsatser={beviljadeLarmNattInsatser}
          utfordaNattinsatser={utfordaNattinsatser}
          larmNotHemtjanst={larmNotHemtjanst}
          larmHemtjanst={larmHemtjanst}
        />
      )
    default:
      return <h1>No data</h1>
  }
}
