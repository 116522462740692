import * as actions from './actions'
import { Actions } from './actions'
import { flagData } from '../../singleObject'
import { DataType } from '../../tablesConfig/filterconfig'

export type State = {
  flags: DataType[] | []
}

const initialState: State = {
  flags: [],
}

const flagReducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case actions.SET_FLAGS:
      return {
        ...state,
        flags: action.payload.flags,
      }
    default:
      return state
  }
}

export { actions }
export default flagReducer
