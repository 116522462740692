import { Button, DatePicker, Select, Spin, Switch } from 'antd'
import { CategoryScale, Chart, registerables } from 'chart.js'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useApi } from '../../api/useApi'
import { setUtförareCases } from '../../api/utförareApi'
import FlagListHeader from '../../components/header'
import { getDeviation, getFlagLevel, getUtforareEndDate, getUtforareStartDate, setUtforareFilter } from '../../store/utforareFilterSlice'
import { IBrukareFlag, IMedarbetareFlag, IUtförare, getUtförare, setUtförare } from '../../store/utförareSlice'
Chart.register(...registerables)
Chart.register(CategoryScale)

export interface IDataSet {
  labels: string
  data: number[]
  borderColor: string
  backgroundColor: string
}

export interface IChart {
  labels: string[]
  datasets: IDataSet[]
}

interface IMenu {
  value: string | number
  label: string | number
}

function createGraphY(data: any) {
  const labels: string[] = []
  data.forEach((element: any) => {
    labels.push(element.namn)
  })
  return labels
}

function uniqueLevelItems(data: any) {
  const allDeviations: number[] = data.map((utförare: IUtförare) => {
    return [
      ...(utförare.brukare ? utförare.brukare.map((brukare: IBrukareFlag) => brukare.nivå) : []),
      ...(utförare.medarbetare ? utförare.medarbetare.map((medarbetare: IMedarbetareFlag) => medarbetare.nivå) : []),
    ]
  })

  const unique = Array.from(new Set(allDeviations.flat()))
  return unique.sort((n1, n2) => n1 - n2)
}

function uniqueDeviation(data: any) {
  const allDeviations: string[] = data.map((utförare: IUtförare) => {
    return [
      ...(utförare.brukare ? utförare.brukare.map((brukare: IBrukareFlag) => brukare.flaggnamn) : []),
      ...(utförare.medarbetare ? utförare.medarbetare.map((medarbetare: IMedarbetareFlag) => medarbetare.flaggnamn) : []),
    ]
  })

  const res = Array.from(new Set(allDeviations.flat()))
  return res
}

function generateGraphData(labels: string[], data: number[], checked: boolean) {
  const graph: IChart = {
    labels: labels,
    datasets: [
      {
        labels: checked ? 'Summa av risknivåer' : 'Antal flaggor',
        data: data,
        borderColor: 'rgb(64,125,60)',
        backgroundColor: 'rgb(64,125,60)',
      },
    ],
  }

  return graph
}

function generateMenu(deviations: number[]) {
  const menuItems: IMenu[] = []
  deviations.forEach((numb) => {
    menuItems.push({ value: Number(numb), label: Number(numb) })
  })
  return menuItems
}

function generateDeviationMenu(deviations: string[]) {
  const menuItems: IMenu[] = []
  deviations.forEach((numb) => {
    menuItems.push({ value: String(numb), label: String(numb) })
  })
  return menuItems
}

async function getTableData(startDate: string, endDate: string) {
  const data = await setUtförareCases(useApi(), startDate, endDate)
  return data
}

function Utforare() {
  const { RangePicker } = DatePicker
  const [ammountData, setAmmountData] = useState<IChart>({
    labels: [],
    datasets: [
      {
        labels: 'Antal flaggor',
        data: [],
        borderColor: 'rgb(64,125,60)',
        backgroundColor: 'rgb(64,125,60)',
      },
    ],
  })
  const [brukareData, setBrukareData] = useState<IChart>({
    labels: [],
    datasets: [
      {
        labels: 'Antal flaggor per brukare',
        data: [],
        borderColor: 'rgb(64,125,60)',
        backgroundColor: 'rgb(64,125,60)',
      },
    ],
  })
  const [checked, setChecked] = useState(false)
  const [deviation, setDeviation] = useState<string[]>(useSelector(getDeviation))
  const [flagLevel, setFlagLevel] = useState<number[]>(useSelector(getFlagLevel))
  const [startDate, setStartDate] = useState(useSelector(getUtforareStartDate))
  const [endDate, setEndDate] = useState(useSelector(getUtforareEndDate))
  const [datesPicked, setDatesPicked] = useState(false)
  const [fetchingData, setFetchingData] = useState(false)
  // const [data, setData] = useState<IUtförare[]>()
  const dispatch = useDispatch()
  const navigate = useHistory()

  const data = useSelector(getUtförare)
  const dateFormat = 'YYYY/MM/DD'

  function handleDatesChange(range: any) {
    const start = range[0].startOf('month').format().split('T')[0]
    const end = range[1].endOf('month').format().split('T')[0]
    setStartDate(start)
    setEndDate(end)
    dispatch(setUtforareFilter({ deviation: deviation, flagLevel: flagLevel, startDate: start, endDate: end }))
  }

  function handleOpenChange(isOpen: boolean) {
    setDatesPicked(isOpen)
  }

  const handleLevelChange = (value: any) => {
    setFlagLevel(value.map(Number))
    dispatch(setUtforareFilter({ deviation: deviation, flagLevel: value.map(Number), startDate: startDate, endDate: endDate }))
  }

  const handleDeviationChange = (value: any) => {
    setDeviation(value)
    dispatch(setUtforareFilter({ deviation: value, flagLevel: flagLevel, startDate: startDate, endDate: endDate }))
  }

  const resetAllFilters = () => {
    setDeviation([])
    setFlagLevel([])
    dispatch(setUtforareFilter({ deviation: [], flagLevel: [] }))
  }

  const onSwitchChange = (checked: boolean) => {
    setChecked(checked)
  }

  function generateOptions(graphflagga: number, checked: boolean) {
    let text = ''
    if (graphflagga == 1) {
      text = checked ? 'Summa av risknivåer' : 'Antal flaggor'
    } else if (graphflagga == 2) {
      text = checked ? 'Summa av risknivåer per brukare' : 'Antal flaggor per brukare'
    }

    return {
      indexAxis: 'y' as const,
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      options: {
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          y: {
            beginAtZero: true,
            ticks: { display: false },
          },
        },
      },
      onClick: function (e: any, activeEls: any) {
        if (activeEls.length > 0) {
          let dataIndex = activeEls[0].index
          let label = e.chart.data.labels[dataIndex]
          navigate.push(`/app/utforare/utforare=${label}&from=${startDate}&to=${endDate} &flaglevel=${flagLevel.length > 0 ? flagLevel : null} &deviation=${deviation.length > 0 ? deviation : null}`)
        }
      },
      plugins: {
        legend: {
          display: false,
          onClick: () => null,
          labels: {
            color: 'rgb(255, 99, 132)',
          },
        },
        title: {
          display: true,
          text,
        },
      },
    }
  }

  useEffect(() => {
    if (data) {
      const labels = createGraphY(data)
      const xAxis1 = createGraphAmmountX(data)
      const xAxis2 = createGraphAmmountBrukareX(data)

      setAmmountData(generateGraphData(labels, xAxis1, checked))
      setBrukareData(generateGraphData(labels, xAxis2, checked))
    }
  }, [data])

  function createGraphAmmountX(data: any) {
    const graphData: number[] = []
    if (deviation.length > 0 && flagLevel.length > 0) {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn) && flagLevel.includes(innerElement.nivå)) {
              temp += 1
            }
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn) && flagLevel.includes(Number(innerElement.nivå))) {
              temp += 1
            }
          })
        }
        graphData.push(temp)
      })
    } else if (deviation.length > 0) {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn)) {
              temp += 1
            }
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn)) {
              temp += 1
            }
          })
        }
        graphData.push(temp)
      })
    } else if (flagLevel.length > 0) {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare.length > 0 && element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            if (flagLevel.includes(innerElement.nivå)) {
              temp += 1
            }
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            if (flagLevel.includes(innerElement.nivå)) {
              temp += 1
            }
          })
        }
        graphData.push(temp)
      })
    } else {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            temp += 1
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            temp += 1
          })
        }
        graphData.push(temp)
      })
    }
    return graphData
  }

  function createGraphAmmountBrukareX(data: any) {
    const graphData: number[] = []
    if (deviation.length > 0 && flagLevel.length > 0) {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn) && flagLevel.includes(innerElement.nivå)) {
              temp += 1
            }
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn) && flagLevel.includes(innerElement.nivå)) {
              temp += 1
            }
          })
        }
        graphData.push(temp / element.brukare_count)
      })
    } else if (deviation.length > 0) {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn)) {
              temp += 1
            }
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn)) {
              temp += 1
            }
          })
        }
        graphData.push(temp / element.brukare_count)
      })
    } else if (flagLevel.length > 0) {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            if (flagLevel.includes(innerElement.nivå)) {
              temp += 1
            }
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            if (flagLevel.includes(innerElement.nivå)) {
              temp += 1
            }
          })
        }
        graphData.push(temp / element.brukare_count)
      })
    } else {
      data.forEach((element: any) => {
        var temp = 0
        if (element.brukare) {
          temp += element.brukare.length
          //graphData.push(element.brukare.length / element.brukare_count)
        }
        if (element.medarbetare) {
          temp += element.medarbetare.length
        }
        graphData.push(temp / element.brukare_count)
      })
    }
    return graphData
  }

  function createGraphLevelX(data: any) {
    const graphData: number[] = []
    if (deviation.length > 0 && flagLevel.length > 0) {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn) && flagLevel.includes(innerElement.nivå)) {
              temp += innerElement.nivå
            }
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn) && flagLevel.includes(innerElement.nivå)) {
              temp += innerElement.nivå
            }
          })
        }
        graphData.push(temp)
      })
    } else if (deviation.length > 0) {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn)) {
              temp += innerElement.nivå
            }
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn)) {
              temp += innerElement.nivå
            }
          })
        }
        graphData.push(temp)
      })
    } else if (flagLevel.length > 0) {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            if (flagLevel.includes(innerElement.nivå)) {
              temp += innerElement.nivå
            }
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            if (flagLevel.includes(innerElement.nivå)) {
              temp += innerElement.nivå
            }
          })
        }
        graphData.push(temp)
      })
    } else {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            temp += innerElement.nivå
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            temp += innerElement.nivå
          })
        }
        graphData.push(temp)
      })
    }
    return graphData
  }

  function createGraphLevelBrukareX(data: any) {
    const graphData: number[] = []
    if (deviation.length > 0 && flagLevel.length > 0) {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn) && flagLevel.includes(innerElement.nivå)) {
              temp += innerElement.nivå
            }
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn) && flagLevel.includes(innerElement.nivå)) {
              temp += innerElement.nivå
            }
          })
        }
        graphData.push(temp / element.brukare_count)
      })
    } else if (deviation.length > 0) {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn)) {
              temp += innerElement.nivå
            }
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            if (deviation.includes(innerElement.flaggnamn)) {
              temp += innerElement.nivå
            }
          })
        }
        graphData.push(temp / element.brukare_count)
      })
    } else if (flagLevel.length > 0) {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            if (flagLevel.includes(innerElement.nivå)) {
              temp += innerElement.nivå
            }
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            if (flagLevel.includes(innerElement.nivå)) {
              temp += innerElement.nivå
            }
          })
        }
        graphData.push(temp / element.brukare_count)
      })
    } else {
      data.forEach((element: any) => {
        let temp = 0
        if (element.brukare) {
          element.brukare.forEach((innerElement: any) => {
            temp += innerElement.nivå
          })
        }
        if (element.medarbetare) {
          element.medarbetare.forEach((innerElement: any) => {
            temp += innerElement.nivå
          })
        }
        graphData.push(temp / element.brukare_count)
      })
    }
    return graphData
  }

  useEffect(() => {
    if (!data) return

    if (checked) {
      const labels = createGraphY(data)
      const xAxis1 = createGraphLevelX(data)
      const xAxis2 = createGraphLevelBrukareX(data)
      setAmmountData(generateGraphData(labels, xAxis1, checked))
      setBrukareData(generateGraphData(labels, xAxis2, checked))
    } else {
      const labels = createGraphY(data)
      const xAxis1 = createGraphAmmountX(data)
      const xAxis2 = createGraphAmmountBrukareX(data)
      setAmmountData(generateGraphData(labels, xAxis1, checked))
      setBrukareData(generateGraphData(labels, xAxis2, checked))
    }
  }, [checked, deviation, flagLevel, data])

  useEffect(() => {
    const fetchData = async () => {
      setFetchingData(true)
      const data = await getTableData(startDate, endDate)

      setFetchingData(false)
      dispatch(setUtförare(data))
    }
    if (!datesPicked) {
      fetchData()
    }
  }, [datesPicked])

  return (
    <>
      <FlagListHeader
        heading={'Utförare'}
        title=""
        description="Denna vy visar en sammanställning av valda flaggor som skapats under vald tidsperiod och uppdelat på varje utförare. Detta ger en översikt för att kunna jämföra hur många flaggor som uppstår hos respektive utförare, både i absoluta tal och per brukare som valt utföraren. Generellt är färre flaggor (per brukare) ett mer önskvärt utfall. Detta gäller särskilt flaggor med högre risknivå. Val av tidsperiod baseras på när flaggan skapades."
        button={false}
      />
      <div className="flex gap-[25px]">
        {/* <Dropdown overlay={menu}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              Välj avvikelseområde
              
            </Space>
          </a>
        </Dropdown>
        */}
        <RangePicker
          style={{ height: '32px' }}
          defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
          picker="month"
          onChange={handleDatesChange}
          onOpenChange={handleOpenChange}
        />
        {data ? (
          <>
            <Select
              placeholder={'Välj avvikelse'}
              style={{ minWidth: '20%' }}
              maxTagCount={'responsive'}
              mode="multiple"
              value={deviation}
              onChange={handleDeviationChange}
              options={generateDeviationMenu(uniqueDeviation(data))}
              //value={deviation}
            />

            <Select
              placeholder={'Välj flaggnivå'}
              style={{ minWidth: '20%' }}
              maxTagCount={'responsive'}
              mode="multiple"
              value={flagLevel}
              onChange={handleLevelChange}
              options={generateMenu(uniqueLevelItems(data))}
              //value={flagLevel}
            />
            <Button onClick={resetAllFilters}>Rensa filter</Button>
          </>
        ) : (
          <>
            <Select placeholder={'Välj avvikelse'} style={{ width: 120 }} disabled={true} />

            <Select placeholder={'Välj risknivå'} style={{ width: 120 }} disabled={true} />
          </>
        )}

        <div className="flex items-center gap-2">
          <label>Summera risknivå</label>
          <Switch style={{ maxWidth: '50px' }} onChange={onSwitchChange} />
        </div>
      </div>

      <Spin spinning={fetchingData}>
        {data && (
          <>
            <Bar className="graph" options={generateOptions(1, checked)} data={ammountData} />
            <Bar className="graph" options={generateOptions(2, checked)} data={brukareData} />
          </>
        )}
      </Spin>
    </>
  )
}

export default Utforare
