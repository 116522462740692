import { Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import shortenText from '../arendeFordelning/components/tableUtils'

export interface INyligenUtgångnaData {
  personnummer: string
  förnamn: string
  efternamn: string
  insatskategorityp: string
  till: string
  processid: number
}

export const createNyligenUtgångnaTableConfig = (data: INyligenUtgångnaData[], filters: any) => {
  const columns: ColumnsType<INyligenUtgångnaData> = [
    {
      title: 'Namn',
      dataIndex: '',
      key: 'namn',
      width: 250,
      fixed: 'left',
      filteredValue: filters.namn,
      showSorterTooltip: { title: 'Sortera på namn' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.förnamn + ' ' + d.efternamn || 'Inget värde'
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => (a.förnamn + ' ' + a.efternamn || 'Inget värde').localeCompare(b.förnamn + ' ' + b.efternamn || 'Inget värde'),
      render: (_, obj) => (
        <Tooltip title={obj.förnamn + ' ' + obj.efternamn || 'Inget värde'}>
          <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
            {shortenText(obj.förnamn + ' ' + obj.efternamn || 'Inget värde')}
          </span>
        </Tooltip>
      ),
      onFilter: (value, record) => (record.förnamn + ' ' + record.efternamn || 'Inget värde') === value,
      filterSearch: true,
    },
    {
      title: 'Personnummer',
      dataIndex: '',
      key: 'personnummer',
      fixed: 'left',
      filteredValue: filters.personnummer,
      showSorterTooltip: { title: 'Sortera på personnummer' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.personnummer || 'Inget värde'
            })
          )
        )
          .sort()
          .map((pn) => {
            return {
              value: pn,
              text: pn,
            }
          }),
      ],
      sorter: (a, b) => (a || 'Inget värde').personnummer.localeCompare(b.personnummer || 'Inget värde'),
      render: (_, obj) => (
        <>
          <Tooltip title={obj.personnummer}>
            <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
              {shortenText(obj.personnummer, 15)}
            </span>
          </Tooltip>
        </>
      ),
      onFilter: (value, record) => record.personnummer === value,
      filterSearch: true,
    },
    {
      title: 'Insatskategori',
      dataIndex: '',
      key: 'insatskategori',
      filteredValue: filters.insatskategori,
      showSorterTooltip: { title: 'Sortera på insatskategori' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.insatskategorityp || 'Inget värde'
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => (a.insatskategorityp || 'Inget värde').localeCompare(b.insatskategorityp || 'Inget värde'),
      render: (_, obj) => <>{obj.insatskategorityp || 'Inget värde'}</>,
      onFilter: (value, record) => (record.insatskategorityp || 'Inget värde') === value,
      filterSearch: true,
    },
    {
      title: 'Beslut t.o.m',
      dataIndex: '',
      key: 'till',
      filteredValue: filters.till,
      showSorterTooltip: { title: 'Sortera på beslut till' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return moment(d.till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(d.till).format('YYYY-MM-DD') : 'Tillsvidare'
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) =>
        (moment(a.till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(a.till).format('YYYY-MM-DD') : 'Tillsvidare').localeCompare(
          moment(b.till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(b.till).format('YYYY-MM-DD') : 'Tillsvidare'
        ),
      render: (_, obj) => <>{moment(obj.till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(obj.till).format('YYYY-MM-DD') : 'Tillsvidare'}</>,
      onFilter: (value, record) => (moment(record.till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(record.till).format('YYYY-MM-DD') : 'Tillsvidare') === value,
      filterSearch: true,
    },
    {
      title: 'ProcessID',
      dataIndex: '',
      key: 'processid',
      filteredValue: filters.processid,
      showSorterTooltip: { title: 'Sortera på processID' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.processid || 'Inget värde'
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => {
        if (!a.processid) return -1
        else if (!b.processid) return 1
        else return a.processid - b.processid
      },
      render: (_, obj) => <>{obj.processid}</>,
      onFilter: (value, record) => (record.processid || 'Inget värde') === value,
      filterSearch: true,
    },
  ]

  return columns
}
