import { Button, Form, Input, notification } from 'antd'
import { useHistory } from 'react-router-dom'
import { useAddGuest } from '../../api/guest'
import BackButton from '../../components/buttons/backButton'
import FlagListHeader from '../../components/header'
import { useAddRole } from '../../api/permissions'

const NewRole = () => {
  const navigate = useHistory()
  const { mutateAsync: addRole, isLoading } = useAddRole()

  const onFinish = async (values: { name: string }) => {
    try {
      const res = await addRole(values)

      navigate.push(`/app/roller/${res.data.id}`)
      notification.success({
        message: 'Roll skapad',
        description: 'Roll har skapats',
      })
    } catch (e) {
      notification.error({
        message: 'Roll kunde inte skapas',
        description: 'Roll kunde inte skapas',
      })
    }
  }

  return (
    <>
      <FlagListHeader title="" heading="Skapa Roll" description="Skapa ett en ny roll genom att fylla i fälten nedan." button={false} />
      <div style={{ maxWidth: '600px' }}>
        <Form
          name="new-role"
          initialValues={{
            username: '',
            password: '',
            personNumber: '',
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item label="Namn" name="name" rules={[{ required: true, message: 'Vänligen fyll i rollnamn' }]}>
            <Input type={'text'} placeholder="Namn" />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', gap: '6px' }}>
              <BackButton display="inline" />
              <Button type="primary" htmlType="submit" style={{ display: 'inline-block', marginTop: '20px' }} loading={isLoading}>
                Skapa
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default NewRole
