import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover } from 'antd'

const NoMatchingUser = ({ personNumber }: { personNumber: string }) => {
  return (
    <Popover overlayClassName="decision-popover" content={`Skapa en användare med samma personnummer som denna gäst har (${personNumber}), annars kommer inte användaren att kunna logga in.`}>
      <div className="flex items-center gap-2">
        <div className="text-red-500">INGEN MATCHANDE ANVÄNDARE</div>
        <InfoCircleOutlined />
      </div>
    </Popover>
  )
}

export default NoMatchingUser
