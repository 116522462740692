import { useQuery } from '@tanstack/react-query'
import { Button, Spin, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { FilterValue } from 'antd/lib/table/interface'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import FlagListHeader from '../../components/header'
import { fetchOutsideStructure } from '../arendeFordelning/queries'
import { IUtanförTeamstrukturData, createUtanförTeamstrukturTableConfig } from './tableConfig'

type Props = {}

const UtanförTeamstrkturTable = (props: Props) => {
  const navigate = useHistory()
  const [renderTable, setRenderTable] = useState<ColumnsType<IUtanförTeamstrukturData> | undefined>([])
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({})

  let { isLoading, isError, data, error } = useQuery(['outsideStructure'], fetchOutsideStructure)

  const resetTableFilters = () => {
    const resetFilters = { ...filters }
    Object.keys(resetFilters).forEach((key) => {
      resetFilters[key] = null
    })
    setFilters(resetFilters)
  }

  useEffect(() => {
    setRenderTable(createUtanförTeamstrukturTableConfig(data?.sort((a, b) => (a.ansvarig_person < b.ansvarig_person ? -1 : 1)) || [], filters))
  }, [filters, data])

  if (isError) console.error(error)

  if (!data) data = [] as IUtanförTeamstrukturData[]
  data?.sort((a, b) => (a.ansvarig_person < b.ansvarig_person ? -1 : 1))
  return (
    <>
      <FlagListHeader heading={'Utanför struktur'} title={null} description={null} button={false} />
      <Spin spinning={isLoading}>
        <Button style={{ marginBottom: '20px', marginRight: '25px' }} onClick={resetTableFilters}>
          Rensa filter
        </Button>
        <Table
          columns={renderTable}
          dataSource={data}
          size="small"
          rowClassName="table-row-cursor"
          loading={false}
          bordered
          // Show only pagination numbers and not the page buttons
          pagination={{
            position: ['topRight'],
            pageSize: 10000,
            showTotal: (_, range) => {
              return `${range[1]} av ${data?.length ?? 0}`
            },
            className: 'table-pagination-hidden',
            showSizeChanger: false,
          }}
          scroll={{ x: 'calc(100vw - 260px)', y: 'calc(100vh - 280px)' }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {},
            }
          }}
          onChange={(pagination, filters, sorter, { currentDataSource }) => {
            Object.keys(filters).map((key) => {
              if (filters[key]?.includes('Välj alla')) {
                if (key === 'handläggare') {
                  filters[key] = Array.from(
                    new Set(
                      (data || []).map((d) => {
                        return d.ansvarig_person || 'Inget värde'
                      })
                    )
                  )
                } else if (key === 'personnummer') {
                  filters[key] = Array.from(
                    new Set(
                      (data || []).map((d) => {
                        return d.personnummer || 'Inget värde'
                      })
                    )
                  )
                } else if (key === 'namn') {
                  filters[key] = Array.from(
                    new Set(
                      (data || []).map((d) => {
                        return d.förnamn + ' ' + d.efternamn || 'Inget värde'
                      })
                    )
                  )
                } else if (key === 'typ') {
                  filters[key] = Array.from(
                    new Set(
                      (data || []).map((d) => {
                        return d.mark || 'Inget värde'
                      })
                    )
                  )
                } else if (key === 'processid') {
                  filters[key] = Array.from(
                    new Set(
                      (data || []).map((d) => {
                        return d.processid || 'Inget värde'
                      })
                    )
                  )
                } else if (key === 'insatskategori') {
                  filters[key] = Array.from(
                    new Set(
                      (data || []).map((d) => {
                        return d.insatskategorityp || 'Inget värde'
                      })
                    )
                  )
                } else if (key === 'initieringsdatum') {
                  filters[key] = Array.from(
                    new Set(
                      (data || []).map((d) => {
                        return moment(d.upprättad).format('YYYY-MM-DD') || 'Inget värde'
                      })
                    )
                  )
                } else if (key === 'till') {
                  filters[key] = Array.from(
                    new Set(
                      (data || []).map((d) => {
                        return moment(d.till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(d.till).format('YYYY-MM-DD') : 'Tillsvidare'
                      })
                    )
                  )
                }
              }
            })
            setFilters(filters)
          }}
        />
      </Spin>
    </>
  )
}

export default UtanförTeamstrkturTable
