import { useMutation, useQuery } from '@tanstack/react-query'
import { apiClient } from '../../useApi'
import { IBICMunicipalityData } from '../../../features/beslutsstod/types/municipalityData'

interface UpdateIBICMunicipalityDataPayload {
  municipalityData: IBICMunicipalityData
  description: string
}

export const useUpdateIBICMunicipalityData = () => {
  return useMutation({
    mutationFn: async ({ municipalityData, description }: UpdateIBICMunicipalityDataPayload) => {
      const res = await apiClient.newIBIC(localStorage.getItem('tenant_id') as string, {
        config: municipalityData,
        description: description,
      })
      return res.data
    },
  })
}
