import { useEffect, useRef } from 'react'

const VerticalSegmented = ({ options, value, onChange }: { options: { label: string; value: string }[]; value: string; onChange: (value: string) => void }) => {
  const MAX_OPTIONS = 5.5
  const ITEM_HEIGHT = 22

  const itemsRef = useRef<Array<HTMLAnchorElement | null>>([])

  useEffect(() => {
    const index = options.findIndex((option) => option.value === value)
    if (index === -1) return
    itemsRef.current[index]?.scrollIntoView({ block: 'center' })
  }, [value])

  return (
    <div
      className="overflow-y-auto pr-[15px]"
      style={{
        height: ITEM_HEIGHT * MAX_OPTIONS,
      }}
    >
      <div className="flex flex-col">
        {options.map((option, index) => (
          <a
            key={option.value}
            ref={(el) => {
              itemsRef.current[index] = el
            }}
            onClick={() => {
              onChange(option.value)
              itemsRef.current[index]?.scrollIntoView({ block: 'center' })
            }}
            className={`${option.value === value ? 'text-[#1890ff]' : 'text-black'} cursor-pointer hover:text-[#1890ff]`}
          >
            <div>
              {index + 1} - {option.label}
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}

interface BenchmarkingPage {
  id: number
  title: string
  scrollIntoView: () => void
}

interface BenchmarkingPageSelectorProps {
  pages: BenchmarkingPage[]
  activeTabId: string
  onTabChange: (tab: string) => void
}

const BenchmarkingPageSelector = ({ pages, activeTabId, onTabChange }: BenchmarkingPageSelectorProps) => {
  useEffect(() => {
    const hash = window.location.hash
    if (hash) {
      onTabChange(hash.substring(1))
    } else {
      if (pages.length > 0) onTabChange(pages[0].id.toString())
    }
  }, [])

  useEffect(() => {
    const pageId = pages.find((page) => page.id.toString() === activeTabId)
    if (!pageId) return
    pageId.scrollIntoView()
  }, [activeTabId])

  return (
    <VerticalSegmented
      options={pages.map((page) => ({ label: page.title, value: page.id.toString() }))}
      value={activeTabId}
      onChange={(value) => value.toString() !== activeTabId && onTabChange(value.toString())}
    />
  )
}

export default BenchmarkingPageSelector
