import { useApi } from '../../api/useApi'

const api = useApi()

export interface IFlagTexts {
  info: string | null
  description: string
  personal?: {
    id: number
    namn: string
  }[]
  tableData?: string[][]
  brukare?: {
    id: number
    personnummer: string
    förnamn: string
    efternamn: string
  }[]
}

export const fetchFlagTexts = async ({ queryKey }: any): Promise<IFlagTexts> => {
  const [_key, flag, id] = queryKey
  return await api
    .get(`/v1/text/${localStorage.getItem('tenant_id')}/flag/${flag}/${id}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })
    .then((res) => res.data)
}
