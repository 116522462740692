import { useHistory } from 'react-router-dom'
import FlagListHeader from '../../components/header'

// Antd
import { Button, Table } from 'antd'
import { useGuests } from '../../api/guest'
import { createGuestsTableConfig } from '../../components/tablesConfig/guestTableConfig'

const Guests = () => {
  const navigate = useHistory()
  const { data, isLoading } = useGuests()

  if (!data) return null

  const { columns } = createGuestsTableConfig(data, {}, navigate)

  return (
    <>
      <FlagListHeader
        heading="Gäster"
        title={null}
        description='Gästkonton används för gästinloggning (användarnamn + lösenord). Gästkonton kopplas mot en "Användare" som man kommer in på efter inloggning.
        Det användaren som är kopplat till gästkontot bör ha väldigt begränsade rättigheter för att inte kunna se persondata eller annan känslig information.'
        button={false}
      />
      <div className="mb-3 flex gap-0.5">
        <Button
          type="primary"
          onClick={() => {
            navigate.push('/app/gäster/ny')
          }}
        >
          Skapa nytt gästkonto
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.username}
        bordered
        loading={isLoading}
        dataSource={data}
        rowClassName={'none-cursor'}
        size="small"
        scroll={{ y: 'calc(100vh - 300px)', x: undefined }}
      />
    </>
  )
}

export default Guests
