import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IHandläggareFull } from '../components/tablesConfig/userTableConfig'
import { AppState } from './store'

export interface IHandläggare {
  id: number
  namn: string
  email: string
  personnummer: string
  active: boolean
  in_structure: boolean
  scope: number[]
  team: number
  permissions: string[]
  modules: string[]
  role: string
}

export interface ITeam {
  team_id: number
  team_namn: string
  handläggare: IHandläggare[]
  avdelning: number
}

export interface IAvdelning {
  avdelning_id: number
  avdelning_namn: string
  teams: ITeam[]
}

export interface IRole {
  role_name: string
  permissions: string[]
  modules: string[]
}

export interface IOrganizationState {
  tree: IAvdelning[]
  flat: {
    handläggare: IHandläggare[]
    team: ITeam[]
    avdelningar: IAvdelning[]
  }
  me: IHandläggare
  roles: []
}

const initialState: IOrganizationState = {
  tree: [],
  flat: {
    handläggare: [],
    team: [],
    avdelningar: [],
  },
  me: {
    id: 0,
    namn: '',
    email: '',
    personnummer: '',
    active: true,
    in_structure: true,
    scope: [],
    team: 0,
    permissions: [],
    modules: [],
    role: '',
  },
  roles: [],
}

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization(state, action: PayloadAction<IOrganizationState>) {
      state.tree = action.payload.tree
      state.flat = action.payload.flat
      state.roles = action.payload.roles
    },
    setMe(state, action: PayloadAction<IHandläggare>) {
      state.me = action.payload
    },
    addHandlägagre(state, action: PayloadAction<IHandläggare>) {
      state.flat.handläggare.push(action.payload)
    },
    updateHandläggare(state, action: PayloadAction<IHandläggare>) {
      state.flat.handläggare = state.flat.handläggare.map((item: IHandläggare) => {
        if (item.id !== action.payload.id) {
          return item
        }
        return {
          ...item,
          ...action.payload,
        }
      })
    },
  },
})

export const getOrganization = (state: AppState) => state.organizationReducer.tree
export const getHandläggare = (state: AppState): IHandläggareFull[] => {
  return state.organizationReducer.flat.handläggare.map((handläggare) => {
    return {
      id: handläggare.id,
      namn: handläggare.namn,
      email: handläggare.email,
      personnummer: handläggare.personnummer,
      active: handläggare.active,
      in_structure: handläggare.in_structure,
      scope: handläggare.scope.map((team) => {
        return {
          team_namn: state.organizationReducer.flat.team.filter((item) => item.team_id === team)[0]?.team_namn || '',
          team_id: team,
        }
      }),
      team: {
        team_namn: state.organizationReducer.flat.team.filter((item) => item.team_id === handläggare.team)[0]?.team_namn || '',
        team_id: handläggare.id,
      },
      role: handläggare.role,
    }
  })
}
export const getTeamNameById = (state: AppState, id: number) => state.organizationReducer.flat.team.find((item) => item.team_id === id)?.team_namn || ''
export const getTeam = (state: AppState) => state.organizationReducer.flat.team
export const getAvdelning = (state: AppState) => state.organizationReducer.flat.avdelningar
export const getHandlaggare = (state: AppState) => state.organizationReducer.flat.handläggare
export const getRoles = (state: AppState) => state.organizationReducer.roles
export const getMe = (state: AppState) => state.organizationReducer.me
export const { setOrganization, addHandlägagre, updateHandläggare, setMe } = organizationSlice.actions
export default organizationSlice.reducer
