import { Dispatch } from 'redux'
import { AppState } from './store'

export const SET_USER = 'SET_USER'

export type ISetUserAction = {
  type: typeof SET_USER
  payload: {
    data: IUser
  }
}

interface IUser {
  username: string
  token: string
}

export const setAnvandareAction = (type: string, data: IUser[], dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_USER,
    payload: {
      data: data,
    },
  })
}

type Actions = ISetUserAction

export type State = {
  username: string | null
  token: string | null
}

const initialState: State = {
  username: null,
  token: null,
}

const userReducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        username: action.payload.data.username,
        token: action.payload.data.token,
      }
    default:
      return state
  }
}

export const selectUser = (state: AppState) => {
  return {
    username: state.userReducer.username,
    token: state.userReducer.token,
  }
}
export default userReducer
