import { Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import { IYearMonthObject } from '../arendeFordelning/components/tables/tableConfig'
import shortenText from '../arendeFordelning/components/tableUtils'
import { IData } from '../arendeFordelning/types/arandeFordelningTypes'

export interface IVolumeData {
  beräknad_omfattning_månad: number
  beslutsid: number
  brukare_namn: string
  brukare_personnummer: string
  från: string
  till: string
  data: Array<IYearMonthObject>
}

export const createVolymuppföljningTableConfig = (data: IVolumeData[], selected: string[], filters: any) => {
  let columns: ColumnsType<IVolumeData> = []
  let utfordTidChildren: ColumnsType<IVolumeData> = []
  if (data && data.length > 0) {
    columns = [
      {
        title: 'Namn',
        dataIndex: '',
        key: 'namn',
        width: 250,
        fixed: 'left',
        filteredValue: filters.namn,
        showSorterTooltip: { title: 'Sortera på namn' },
        filters: Array.from(
          new Set(
            data.map((d) => {
              return d.brukare_namn
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
        sorter: (a, b) => a.brukare_namn.localeCompare(b.brukare_namn),
        render: (_, obj) => (
          <Tooltip title={obj.brukare_namn}>
            <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
              {shortenText(obj.brukare_namn)}
            </span>
          </Tooltip>
        ),
        onFilter: (value, record) => record.brukare_namn === value,
        filterSearch: true,
      },
      {
        title: 'Personnummer',
        dataIndex: '',
        key: 'personnummer',
        width: 175,
        filteredValue: filters.personnummer,
        showSorterTooltip: { title: 'Sortera på personnummer' },
        filters: Array.from(
          new Set(
            data.map((d) => {
              return d.brukare_personnummer
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
        sorter: (a, b) => a.brukare_personnummer.localeCompare(b.brukare_personnummer),
        render: (_, obj) => (
          <>
            <Tooltip title={obj.brukare_personnummer}>
              <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
                {shortenText(obj.brukare_personnummer, 15)}
              </span>
            </Tooltip>
          </>
        ),
        onFilter: (value, record) => record.brukare_personnummer === value,
        filterSearch: true,
      },
      {
        title: 'Beslut',
        dataIndex: '',
        key: 'beslut',
        width: 130,
        filteredValue: filters.beslut,
        showSorterTooltip: { title: 'Sortera på beslut' },
        filters: Array.from(
          new Set(
            data.map((d) => {
              return d.beslutsid
            })
          )
        )
          .sort(function (a, b) {
            return Number(a) - Number(b)
          })
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
        sorter: (a, b) => a.beslutsid - b.beslutsid,
        render: (_, obj) => <>{obj.beslutsid}</>,
        onFilter: (value, record) => record.beslutsid === value,
        filterSearch: true,
      },
      {
        title: 'Hemtjänst tid',
        dataIndex: '',
        key: 'hemtjanst_tid',
        showSorterTooltip: { title: 'Sortera på beställd tid' },
        width: 150,
        sorter: (a, b) => a.beräknad_omfattning_månad - b.beräknad_omfattning_månad,
        render: (_, obj) => <>{Math.ceil(obj.beräknad_omfattning_månad)}</>,
        onFilter: (value, record) => record.beräknad_omfattning_månad === value,
        filterSearch: true,
      },
    ]
  }

  if (data && data.length === 0) {
    return
  }

  const dateParser = moment(data[0].från)
  const dateLimit = moment(data[0].till).format('YYYY-MM')

  while (dateParser.format('YYYY-MM') !== dateLimit) {
    const tempDate = moment(dateParser)
    if (!selected.includes(dateParser.format('YYYY-MM'))) {
      utfordTidChildren.push({
        title: dateParser.format('YYYY-MM'),
        dataIndex: '',
        key: dateParser.format('YYYY- MM'),
        width: 120,
        showSorterTooltip: { title: 'Sortera på utförandegrad' },
        sorter: (a, b, sortOrder) => {
          const aItem: IData | undefined = a.data.find((item) => {
            return item.ym === tempDate.format('YYYY-MM-DD')
          })
          const bItem: IData | undefined = b.data.find((item) => {
            return item.ym === tempDate.format('YYYY-MM-DD')
          })
          if (sortOrder === 'ascend') {
            if (aItem && bItem) {
              return Math.ceil((Math.ceil(aItem.utförd_tid) / Math.ceil(a.beräknad_omfattning_månad)) * 100) - Math.ceil((Math.ceil(bItem.utförd_tid) / Math.ceil(b.beräknad_omfattning_månad)) * 100)
            } else if (aItem) {
              return -1
            } else if (!bItem) {
              return 1
            } else {
              return 0
            }
          } else {
            if (!aItem && !bItem) {
              return -Infinity
            } else if (!aItem) {
              return -1
            } else if (!bItem) {
              return 1
            } else {
              return Math.ceil((Math.ceil(aItem.utförd_tid) / Math.ceil(a.beräknad_omfattning_månad)) * 100) - Math.ceil((Math.ceil(bItem.utförd_tid) / Math.ceil(b.beräknad_omfattning_månad)) * 100)
            }
          }
        },
        render: (_, obj) => {
          const item: IYearMonthObject | undefined = obj.data.find((item) => {
            return item.ym === tempDate.format('YYYY-MM-DD')
          })

          if (item !== undefined) {
            return (
              <>
                {' '}
                {Math.ceil(item.utförd_tid)} {`(${Math.ceil((Math.ceil(item.utförd_tid) / Math.ceil(item.beräknad_omfattning_exakt)) * 100)} %)`}{' '}
              </>
            )
          } else {
            return <>-</>
          }
        },
      })
    }
    dateParser.add(1, 'month')
  }
  if (!selected.includes(dateParser.format('YYYY-MM'))) {
    const tempDate = moment(dateParser)
    utfordTidChildren.push({
      title: dateParser.format('YYYY-MM'),
      dataIndex: dateParser.format('YYYY-MM'),
      key: dateParser.format('YYYY-MM'),
      width: 120,
      showSorterTooltip: { title: 'Sortera på utförandegrad' },
      sorter: (a, b, sortOrder) => {
        const aItem: IData | undefined = a.data.find((item) => {
          return item.ym === tempDate.format('YYYY-MM-DD')
        })
        const bItem: IData | undefined = b.data.find((item) => {
          return item.ym === tempDate.format('YYYY-MM-DD')
        })
        if (sortOrder === 'ascend') {
          if (aItem && bItem) {
            return Math.ceil((Math.ceil(aItem.utförd_tid) / Math.ceil(a.beräknad_omfattning_månad)) * 100) - Math.ceil((Math.ceil(bItem.utförd_tid) / Math.ceil(b.beräknad_omfattning_månad)) * 100)
          } else if (aItem) {
            return -1
          } else if (!bItem) {
            return 1
          } else {
            return 0
          }
        } else {
          if (!aItem && !bItem) {
            return -Infinity
          } else if (!aItem) {
            return -1
          } else if (!bItem) {
            return 1
          } else {
            return Math.ceil((Math.ceil(aItem.utförd_tid) / Math.ceil(a.beräknad_omfattning_månad)) * 100) - Math.ceil((Math.ceil(bItem.utförd_tid) / Math.ceil(b.beräknad_omfattning_månad)) * 100)
          }
        }
      },
      render: (_, obj) => {
        const item:
          | {
              ym: string
              till: string
              från: string
              bräknad_omfattning: number
              utförd_tid: number
            }
          | undefined = obj.data.find((item) => item.ym === dateParser.format('YYYY-MM-DD'))

        if (item !== undefined) {
          return (
            <>
              {Math.ceil(item.utförd_tid)} {`(${Math.ceil((Math.ceil(item.utförd_tid) / Math.ceil(obj.beräknad_omfattning_månad)) * 100)} %)`}
            </>
          )
        } else {
          return <>-</>
        }
      },
    })
  }

  columns.push({
    title: 'Utförd tid och utförandegrad',
    dataIndex: '',
    key: dateParser.format('YYYY- MM'),
    width: 220,
    children: utfordTidChildren,
  })

  return columns
}
