import { useQuery } from '@tanstack/react-query'
import { apiClient } from '../../useApi'

export const useIBICMunicipalityData = () => {
  return useQuery({
    queryKey: ['municipalityData'],
    queryFn: async () => {
      const res = await apiClient.municipalityIBIC(localStorage.getItem('tenant_id') as string)
      return res.data
    },
    refetchOnWindowFocus: false,
  })
}
