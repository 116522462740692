import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { postCase } from '../../../../api/arendeApi'
import { useApi } from '../../../../api/useApi'
import FlagListHeader from '../../../../components/header'
import { setCaseAction } from '../../store/caseReducer/actions'
import { selectCase, selectSingleCase } from '../../store/selectors'
import CaseModal from '../caseModal/CaseModal'

// Antd
import { Button, Divider, Form, Input, Select, Space, Typography } from 'antd'
import { DataType } from '../../../../components/tablesConfig/filterconfig'
import { getAvdelning, getHandlaggare, getTeam } from '../../../../store/organizationSlice'
const { Text } = Typography
const { TextArea } = Input
const { Option } = Select

export type NewCase = {
  id_token: string
  visible: boolean
  flags: Array<{ flagId: React.Key; flagType: number }> | undefined
  handläggare: Array<number>
  team: Array<number>
  avdelning: Array<number>
  comment: string
}

const CreateCase = () => {
  const Navigate = useHistory()
  const dispatch = useDispatch()
  const api = useApi()
  const [caseNotes, setCaseNotes] = useState<string>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<number[]>([])
  const [selectedHandlaggare, setSelectedHandlaggare] = useState<number[]>([])
  const [selectedAvdelning, setSelectedAvdelning] = useState<number[]>([])

  // Redux states
  const caseState = useSelector(selectCase)
  const singleCaseState = useSelector(selectSingleCase)
  const teamState = useSelector(getTeam)
  const handlaggareState = useSelector(getHandlaggare)
  const avdelningState = useSelector(getAvdelning)

  const teamOptions = teamState.map((item) => {
    return { label: item.team_namn, value: item.team_id }
  })
  const handlaggareOptions = handlaggareState.map((item) => {
    return { label: item.namn, value: item.id }
  })
  const avdelningOptions = avdelningState.map((item) => {
    return { label: item.avdelning_namn, value: item.avdelning_id }
  })

  console.log('team', selectedTeam)
  console.log('handläggare', selectedHandlaggare)
  console.log('avdelning', selectedAvdelning)

  const handleChange = (value: boolean) => {
    setSelectedValue(value)
  }

  const handleTeamChange = (value: number[]) => {
    setSelectedTeam(value)
  }
  const handleHandlaggareChange = (value: number[]) => {
    setSelectedHandlaggare(value)
  }
  const handleAvdelningChange = (value: number[]) => {
    setSelectedAvdelning(value)
  }

  const caseArray = singleCaseState?.flaggor.map((item) => {
    return {
      ...item,
      namn: singleCaseState.namn,
    }
  })

  const flagArray =
    caseState && caseState !== null
      ? caseState.map((item: DataType) => {
          return {
            flagId: item.key,
            flagType: item.flagga,
          }
        })
      : singleCaseState?.flaggor.map((item) => {
          return { flagId: item.key, flagType: item.flagga }
        })

  const sendData = () => {
    const newCase: NewCase = {
      id_token: localStorage.getItem('hydra_id_token') || '',
      visible: selectedValue,
      flags: flagArray,
      handläggare: selectedHandlaggare,
      comment: caseNotes ? caseNotes : '',
      team: selectedTeam,
      avdelning: selectedAvdelning,
    }

    setCase(newCase)
  }

  const setCase = async (newCase: NewCase) => {
    const response = await postCase(api, newCase)

    if (response.response?.status === 404) {
      alert('Error, tyvärr gick det inte att initiera uppföljningen. Försök igen.')
      setSuccess(true)
      return
    }
    if (response) {
      setSuccess(true)
      Navigate.push('/app/arenden')
    }
  }

  const goBackHandler = () => {
    setCaseAction('SET_CASE', null, null, dispatch)
    Navigate.goBack()
  }

  const headerDescription =
    'För att initiera en uppföljning, välj en eller flera användare och skriv en uppdragsbeskrivning. Du kan använda roll och team för att lättare hitta den användare du vill ska ta emot uppföljningen.'

  return (
    <>
      <FlagListHeader heading="Initiera uppföljning" title="" description={headerDescription} button={false} />
      <Space direction="vertical" className="createcase-wrapper">
        <Text>Avvikelser:</Text>
        {caseState !== null ? (
          <Space direction="vertical">
            {caseState?.map((c) => (
              <div key={c.key}>
                <Space align="start" key={c.key} className="createcase-flags">
                  <p>Gällande: {c.namn},</p>
                  <p>Flagga: {c.flaggnamn},</p>
                  <p>Risknivå: {c.nivå},</p>
                  <p>
                    Period: {new Date(c.från).toLocaleDateString()} - {new Date(c.till).toLocaleDateString()},
                  </p>
                  <p>Datum: {new Date(c.created).toLocaleDateString()}</p>
                </Space>
                <Divider className="createcase-divider" />
              </div>
            ))}
          </Space>
        ) : (
          caseArray?.map((c) => (
            <Space align="start" key={c.key}>
              <p>Gällande: {c.namn},</p>
              <p>Flagga: {c.flagga},</p>
              <p>Risknivå: {c.nivå},</p>
              <p>Period: {`${c.från} - ${c.till}`},</p>
              <p>Datum: {new Date(c.created).toLocaleDateString()}</p>
            </Space>
          ))
        )}
        <Text strong>Synlighet</Text>
        <Select defaultValue={false} onChange={handleChange}>
          <Option value={false}>Dold</Option>
          <Option value={true}>Publik</Option>
        </Select>
        <Text strong>Team</Text>
        <Select mode="multiple" style={{ width: '20%' }} placeholder="Välj team" onChange={handleTeamChange} options={teamOptions} />
        <Text strong>Handläggare</Text>
        <Select mode="multiple" style={{ width: '20%' }} placeholder="Välj handläggare" onChange={handleHandlaggareChange} options={handlaggareOptions} />
        <Text strong>Avdelning</Text>
        <Select mode="multiple" style={{ width: '20%' }} placeholder="Välj avdelning" onChange={handleAvdelningChange} options={avdelningOptions} />
        <Text strong>Uppdragsbeskrivning</Text>
        <Form onFinish={() => setShowModal(true)} autoComplete="off">
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Beskrivning av uppföljningen krävs.',
              },
            ]}
            name="note"
          >
            <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 20 }} onChange={(e) => setCaseNotes(e.target.value)} />
          </Form.Item>
          <div style={{ display: 'flex', gap: '6px' }}>
            <Button onClick={goBackHandler}>Tillbaka</Button>
            <Button type="primary" htmlType="submit">
              Skapa
            </Button>
          </div>
        </Form>
      </Space>
      {showModal && (
        <CaseModal
          closeModal={success}
          cancelModal={() => setShowModal(false)}
          sendData={sendData}
          modalText={{
            text: 'Är du säker på att du vill skapa denna uppföljning?',
            loading: 'Uppföljningen skapas..',
          }}
        />
      )}
    </>
  )
}

export default CreateCase
