import { Select, Spin } from 'antd'
import { ComponentProps, useEffect } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useCostValues, useMunicipalityName, useVolumeValues } from '../../../../api/benchmarking'
import { getDoughnutColorByIndex } from '../../util/colors'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { getFontSize } from '../../util/fontSize'
import { BORDER_WIDTH } from '../../util/constants'
import { baseGraphOptions } from '../../util/baseGraphOptions'

export const VolumeDistribution = ({ text }: { text: React.ReactNode }) => {
  const { data: municipalityName, isLoading: isMunicipalityNameLoading } = useMunicipalityName()
  const { data: volumeData, isLoading: isValuesLoading, mutate: mutateVolume } = useVolumeValues()
  const { data: costData, isLoading: isCostLoading } = useCostValues()

  useEffect(() => {
    mutateVolume({})
  }, [])

  if (isValuesLoading || isCostLoading || isMunicipalityNameLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!volumeData || !costData) {
    return <></>
  }
  const volumeBaselineOptions: ComponentProps<typeof Doughnut>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const total = context.dataset.data.reduce((a, b) => a + b, 0)
            const percentage = Math.round(((context.parsed as number) / total) * 100)
            return `${percentage}%`
          },
        },
      },
    },
  }

  const volumeComparisonOptions: ComponentProps<typeof Doughnut>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const total = context.dataset.data.reduce((a, b) => a + b, 0)
            const percentage = Math.round(((context.parsed as number) / total) * 100)
            return `${percentage}%`
          },
        },
      },
    },
  }
  const volumeBaselineData = {
    labels: ['Särskilt boende', 'Hemtjänst'],
    datasets: [
      {
        label: 'brukare',
        data: [volumeData.baselineValues.säbo?.value, volumeData.baselineValues.hemtjänst?.value],
        backgroundColor: [getDoughnutColorByIndex(0).fill, getDoughnutColorByIndex(1).fill],
        borderColor: [getDoughnutColorByIndex(0).border, getDoughnutColorByIndex(1).border],
        borderWidth: BORDER_WIDTH,
      },
    ],
  }
  const volumeComparisonData = {
    labels: ['Särskilt boende', 'Hemtjänst'],
    datasets: [
      {
        label: 'brukare',
        data: [volumeData.comparisonValues.säbo?.value, volumeData.comparisonValues.hemtjänst?.value],
        backgroundColor: [getDoughnutColorByIndex(0).fill, getDoughnutColorByIndex(1).fill],
        borderColor: [getDoughnutColorByIndex(0).border, getDoughnutColorByIndex(1).border],
        borderWidth: BORDER_WIDTH,
      },
    ],
  }
  const costBaselineOptions: ComponentProps<typeof Doughnut>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => {
            const total = context.dataset.data.reduce((a, b) => a + b, 0)
            const percentage = Math.round(((context.parsed as number) / total) * 100)
            return `${percentage}%`
          },
        },
      },
    },
  }

  const costComparisonOptions: ComponentProps<typeof Doughnut>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const total = context.dataset.data.reduce((a, b) => a + b, 0)
            const percentage = Math.round(((context.parsed as number) / total) * 100)
            return `${percentage}%`
          },
        },
      },
    },
  }

  const costBaslineData = {
    labels: ['Hemtjänst', 'Särskilt boende', 'Övrigt'],
    datasets: [
      {
        label: 'kr/inv',
        data: [costData.baselineValues.säbo?.value, costData.baselineValues.hemtjänst?.value, costData.baselineValues.rest?.value],
        backgroundColor: [getDoughnutColorByIndex(0).fill, getDoughnutColorByIndex(1).fill, getDoughnutColorByIndex(2).fill],
        borderColor: [getDoughnutColorByIndex(0).border, getDoughnutColorByIndex(1).border, getDoughnutColorByIndex(2).border],
        borderWidth: BORDER_WIDTH,
      },
    ],
  }
  const costComparisonData = {
    labels: ['Hemtjänst', 'Särskilt boende', 'Övrigt'],
    datasets: [
      {
        label: 'kr/inv',
        data: [costData.comparisonValues.säbo?.value, costData.comparisonValues.hemtjänst?.value, costData.comparisonValues.rest?.value],
        backgroundColor: [getDoughnutColorByIndex(0).fill, getDoughnutColorByIndex(1).fill, getDoughnutColorByIndex(2).fill],
        borderColor: [getDoughnutColorByIndex(0).border, getDoughnutColorByIndex(1).border, getDoughnutColorByIndex(2).border],
        borderWidth: BORDER_WIDTH,
      },
    ],
  }

  const dataSourceSelector = (
    <Select
      value={volumeData.currentDataSource}
      onChange={(value) => {
        mutateVolume({ datasource: value })
      }}
      options={volumeData.dataSources.map((ds: string) => ({
        label: ds,
        value: ds,
      }))}
      style={{ width: '100%' }}
    />
  )
  const comparisonSelector = (
    <Select
      value={volumeData.currentGrouping}
      onChange={(value) => {
        mutateVolume({ grouping: value, datasource: volumeData.currentVariation.comparison?.datasource?.name })
      }}
      options={volumeData.groupings.map((grouping) => ({
        label: grouping,
        value: grouping,
      }))}
      style={{ width: '100%' }}
    />
  )

  const graph: React.ReactNode = (
    <div className="flex h-full w-full flex-col items-center justify-between">
      <h1 className="text-xl">Andel av brukare</h1>

      <div className="flex w-full justify-evenly">
        <div className="flex max-h-[22cqb] flex-col">
          <h3 className="text-center" style={{ fontSize: getFontSize('md') }}>
            {municipalityName}
          </h3>
          <Doughnut options={volumeBaselineOptions} data={volumeBaselineData} />
        </div>
        <div className="flex max-h-[22cqb] flex-col">
          <h3 className="text-center" style={{ fontSize: getFontSize('md') }}>
            Nationellt
          </h3>
          <Doughnut options={volumeComparisonOptions} data={volumeComparisonData} />
        </div>
      </div>
      <div className="flex justify-center">
        <h1 className="text-xl">Andel av kostnader</h1>
      </div>
      <div className="flex w-full justify-evenly">
        <div className="flex max-h-[22cqb] flex-col">
          <h3 className="text-center" style={{ fontSize: getFontSize('md') }}>
            {municipalityName}
          </h3>
          <Doughnut options={costBaselineOptions} data={costBaslineData} />
        </div>
        <div className="flex max-h-[22cqb] flex-col">
          <h3 className="text-center" style={{ fontSize: getFontSize('md') }}>
            Nationellt
          </h3>
          <Doughnut options={costComparisonOptions} data={costComparisonData} />
        </div>
      </div>
    </div>
  )
  const legend = (
    <BenchmarkingLegend
      items={['Särskilt boende', 'Hemtjänst', 'Övrigt'].map((item, index) => ({
        fillColor: getDoughnutColorByIndex(index).fill,
        borderColor: getDoughnutColorByIndex(index).border,
        text: item,
      }))}
    />
  )
  const title = 'Fördelning av volymer inom äldreomsorgen'
  return <BenchmarkingContentRow graph={graph} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} title={title} text={text} legend={legend} />
}
