import React from 'react'
import { useHistory } from 'react-router-dom'

// Antd
import { Button } from 'antd'

type Props = {
  display?: string
}

export const BackButton: React.FC<Props> = ({ display }) => {
  const navigate = useHistory()
  return (
    <div className="single-page-button" style={display === 'inline' ? { display: 'inline-block', marginTop: '20px' } : { display: 'block' }}>
      <Button onClick={() => navigate.goBack()}>Tillbaka</Button>
    </div>
  )
}

export default BackButton
