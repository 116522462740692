const amountOrderedFilter = (data: any) => {
  const res: any[] = []
  var zero: {
    [key: string]: any
  } = { name: '0-5 h' }
  var five: {
    [key: string]: any
  } = { name: '5-10 h' }
  var ten: {
    [key: string]: any
  } = { name: '10-25 h' }
  var twentyfive: {
    [key: string]: any
  } = { name: '25-50 h' }
  var fifty: {
    [key: string]: any
  } = { name: '50-100 h' }
  var hundred: {
    [key: string]: any
  } = { name: '100-150 h' }
  var hundredFifty: {
    [key: string]: any
  } = { name: '150-180 h' }
  var hundredEighty: {
    [key: string]: any
  } = { name: '180+ h' }
  data.forEach((item: any, index: number) => {
    const total =
      item.less_than_five +
      item.less_than_ten +
      item.less_than_twentyfive +
      item.less_than_fifty +
      item.less_than_one_hundred +
      item.less_than_one_hundred_fifty +
      item.less_than_one_hundred_eighty +
      item.more_than_one_hundred_eighty
    zero[index] = Math.round((item.less_than_five / total) * 100) + '%'
    five[index] = Math.round((item.less_than_ten / total) * 100) + '%'
    ten[index] = Math.round((item.less_than_twentyfive / total) * 100) + '%'
    twentyfive[index] = Math.round((item.less_than_fifty / total) * 100) + '%'
    fifty[index] = Math.round((item.less_than_one_hundred / total) * 100) + '%'
    hundred[index] = Math.round((item.less_than_one_hundred_fifty / total) * 100) + '%'
    hundredFifty[index] = Math.round((item.less_than_one_hundred_eighty / total) * 100) + '%'
    hundredEighty[index] = Math.round((item.more_than_one_hundred_eighty / total) * 100) + '%'
  })
  res.push(zero)
  res.push(five)
  res.push(ten)
  res.push(twentyfive)
  res.push(fifty)
  res.push(hundred)
  res.push(hundredFifty)
  res.push(hundredEighty)
  return res
}

export default amountOrderedFilter
