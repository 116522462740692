import { useCallback, useState } from 'react'

interface UseLifeAreasOptions {
  lifeAreas: string[]
}

export const useLifeAreas = ({ lifeAreas }: UseLifeAreasOptions) => {
  const [checkedLifeAreas, setCheckedLifeAreas] = useState<string[]>([])

  const onChange = useCallback(
    (value: string[]) => {
      const sorted = value.sort((a, b) => {
        const aIndex = lifeAreas.findIndex((area) => area === a)
        const bIndex = lifeAreas.findIndex((area) => area === b)
        return aIndex - bIndex
      })

      setCheckedLifeAreas(sorted)
    },
    [lifeAreas]
  )

  return {
    checkedLifeAreas,
    onChange,
  }
}
