import { Table } from 'antd'
import tableHeader from '../utils/TableConfig/tableHeader'
import dataFiltering from './utils/dataFiltering'

const OvergripandeStatistik = (data: any) => {
  const amountData = dataFiltering(data)
  const columns = tableHeader(data.bestalldTid)

  return (
    <>
      <Table columns={columns} dataSource={amountData} scroll={{ x: 0 }} pagination={false} size="small" />
    </>
  )
}

export default OvergripandeStatistik
