import { InfoCircleOutlined } from '@ant-design/icons'
import { Checkbox, Divider, Form, Popover, Select, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { getMunicipality } from '../../../store/beslutsstod/municipalitySlice'
import { SelectionDescription } from './SelectionDescription'
import { IBICFormTask } from './types/FormValues'
import { formatTimesPerDay } from './utils/formatTimesPerDay'
import { useMemo } from 'react'

const { Option } = Select

interface SingleIBICLifeAreaTaskProps {
  lifeArea: string
  // Needed to target correct Form.List item
  index: number
  taskName: string
}

export const SingleIBICLifeAreaTask = ({ lifeArea, taskName, index }: SingleIBICLifeAreaTaskProps) => {
  const municipalityData = useSelector(getMunicipality)

  if (!municipalityData) {
    return null
  }

  const task = useMemo(() => {
    if (!municipalityData) return null

    return [...municipalityData.input.timepointTasks.map((task) => ({ ...task, type: 'timepoint' })), ...municipalityData.input.extraTasks.map((task) => ({ ...task, type: 'extra' }))].find(
      (task) => task.name === taskName
    )
  }, [municipalityData, taskName])

  if (!task) {
    // console.error(`Task ${taskName} not found in municipality data`)
    return <>Task {taskName} not found in municipality data</>
  }

  return (
    <>
      <div className="decision-wrapper-ibic" key={index}>
        <div>
          <Typography.Text>{taskName}</Typography.Text>
        </div>

        <Form.Item name={[index, 'lifeArea']} noStyle initialValue={lifeArea} hidden />
        <Form.Item name={[index, 'name']} noStyle initialValue={taskName} hidden />

        <div style={{ display: 'flex', alignItems: 'between', width: '120px' }}>
          <Form.Item name={[index, 'level']} noStyle initialValue={null}>
            <Select bordered={false} placeholder="Välj nivå" allowClear>
              {task.selections.map((item) => (
                <Option value={item} key={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {municipalityData.input.formSettings.showDescription && (
            <Popover overlayClassName="decision-popover" title={task.description} content={<SelectionDescription task={task} />} className="ml-1">
              <InfoCircleOutlined />
            </Popover>
          )}
        </div>

        <div style={{ width: '150px' }}>
          {task.type !== 'extra' && (
            <Form.Item name={[index, 'timesPerDay']} noStyle initialValue={null}>
              <Select bordered={false} placeholder="Tillfällen per dag" allowClear listHeight={1000}>
                {Array.from({ length: 9 }, (_, i) => i + 1).map((frequency) => (
                  <Option formItemName={frequency.toString()} value={frequency} key={frequency}>
                    {formatTimesPerDay(frequency)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </div>
        <div style={{ width: '160px' }}>
          {!!task.frequencies?.length && (
            <Form.Item name={[index, 'daysPerWeek']} noStyle initialValue={null}>
              <Select bordered={false} placeholder="Dagar i veckan" allowClear listHeight={1000}>
                {task.frequencies?.map((frequency) => (
                  <Option formItemName={frequency.name} value={frequency.timesPerWeek} key={frequency.name}>
                    {frequency.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </div>
        <div>
          {task.double && (
            <Form.Item name={[index, 'double']} valuePropName="checked" noStyle initialValue={false}>
              <Checkbox>Dubbelbemanning</Checkbox>
            </Form.Item>
          )}
        </div>
      </div>
      <Divider style={{ width: '100%', marginBlock: 12 }} />
    </>
  )
}
