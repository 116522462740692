import { Space } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { GetGuestUsers200ResponseInner } from '../../gen/api'
import NoMatchingUser from '../../features/guests/NoMatchingUser'

export const createGuestsTableConfig = (_data: GetGuestUsers200ResponseInner[], _filters: any, navigate: any) => {
  const columns: ColumnsType<GetGuestUsers200ResponseInner> = [
    {
      title: 'Användarnamn',
      dataIndex: 'username',
      key: 'username',
      showSorterTooltip: false,
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Personnummer',
      dataIndex: 'personNumber',
      key: 'personNumber',
      fixed: 'left',
      showSorterTooltip: false,
      sorter: (a, b) => a.personNumber.localeCompare(b.personNumber),
    },
    {
      title: 'Har användare',
      dataIndex: 'hasUser',
      key: 'hasUser',
      showSorterTooltip: false,
      sorter: (a, b) => Number(b.hasUser) - Number(a.hasUser),
      render: (has, guest) => (has ? 'Ja' : <NoMatchingUser personNumber={guest.personNumber} />),
    },
    {
      dataIndex: 'hantera',
      key: 'hantera',
      width: 125,
      render: (_, obj) => (
        <Space size="middle">
          <button className="table-edit-link" onClick={() => navigate.push(`/app/gäster/${obj.username}`)}>
            Redigera
          </button>
        </Space>
      ),
    },
  ]

  return { columns }
}
