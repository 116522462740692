import React from 'react'
import { Spin } from 'antd'
import moment from 'moment'
import { ComponentProps } from 'react'
import { Chart } from 'react-chartjs-2'
import { useCapacityInflowOutflowTrendValues, useNursingDementiaTotalTrendValues } from '../../../../api/benchmarking'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { getFontSize } from '../../util/fontSize'
import { LINE_BASELINE_COLOR, getColorByIndex } from '../../util/colors'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { BORDER_WIDTH } from '../../util/constants'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { EmptySelector } from '../../components/EmptySelector'
import { STATDB_NAME, NO_COMPARISON } from '../../util/names'

export const CapacityInflowOutflowTrend = ({ text }: { text: React.ReactNode }) => {
  const { data, isLoading } = useCapacityInflowOutflowTrendValues()

  if (isLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const graphOptions: ComponentProps<typeof Chart>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.label}: ${context.parsed.y}`
          },
          title: (context) => {
            return `Avslutade beslut`
          },
        },
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      x: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
    },
  }

  const graphData: ComponentProps<typeof Chart>['data'] = {
    labels: data.baselineValues.map((item) => moment(item.date).format('YYYYMM')),
    datasets: [
      {
        label: 'Totalt',
        type: 'line',
        borderColor: LINE_BASELINE_COLOR.border,
        data: data.baselineValues.map((item, index) => ({ y: item.capacity, x: index })),
        pointRadius: 0,
        yAxisID: 'y',
      },
      {
        label: 'Inflöde',
        type: 'bar',
        borderColor: getColorByIndex(1).border,
        backgroundColor: getColorByIndex(1).fill,
        data: data.baselineValues.map((item, index) => ({ y: item.inflow, x: index })),
        yAxisID: 'y1',
        borderWidth: BORDER_WIDTH,
      },
      {
        label: 'Utföde',
        type: 'bar',
        borderColor: getColorByIndex(2).border,
        backgroundColor: getColorByIndex(2).fill,
        data: data.baselineValues.map((item, index) => ({ y: item.outflow, x: index })),
        yAxisID: 'y1',
        borderWidth: BORDER_WIDTH,
      },
    ],
  }
  const legend = (
    <BenchmarkingLegend
      items={[
        { text: 'Totalt', borderColor: LINE_BASELINE_COLOR.border, fillColor: LINE_BASELINE_COLOR.fill },
        { text: 'Inflöde', borderColor: getColorByIndex(1).border, fillColor: getColorByIndex(1).fill },
        { text: 'Utföde', borderColor: getColorByIndex(2).border, fillColor: getColorByIndex(2).fill },
      ]}
    />
  )

  const title = 'Utveckling av antal boende över tid samt nya respektive avslutade beslut'
  const graph: React.ReactNode = <Chart type="bar" options={graphOptions} data={graphData} />
  const dataSourceSelector = <EmptySelector value={STATDB_NAME} />
  const comparisonSelector = <EmptySelector value={NO_COMPARISON} />
  return <BenchmarkingContentRow graph={graph} text={text} title={title} legend={legend} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} />
}
