import { useQuery } from '@tanstack/react-query'
import { Select, Spin, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { FilterValue } from 'antd/lib/table/interface'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'
import { setNyligenUtgånga } from '../../api/arendeApi'
import { useApi } from '../../api/useApi'
import FlagListHeader from '../../components/header'
import { getAnsvarigPersonId, getAnsvarigPersonName, setAnsvarigPerson } from '../../store/ansvarigPersonSlice'
import { fecthDistribution, fetchNyligenUtgångna } from '../arendeFordelning/queries'
import { INyligenUtgångnaData, createNyligenUtgångnaTableConfig } from './tableConfig'

const DATE_GAP = 14

async function getdata(dispatch: Dispatch<any>, ansvarig_person: string, date_gap: number) {
  return await setNyligenUtgånga(useApi(), dispatch, ansvarig_person, date_gap)
}

type Members = {
  value: string
  label: string
}

function sortDataByLabel(data: Members[]): Members[] {
  const sortedData = data?.slice().sort((a, b) => a.label.localeCompare(b.label))
  return sortedData
}

const NyligenUtgångnaTable = () => {
  const navigate = useHistory()
  const dispatch = useDispatch()
  const [dataFetched, setDatafetched] = useState<boolean>(false)
  const [renderTable, setRenderTable] = useState<ColumnsType<INyligenUtgångnaData> | undefined>([])
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({})
  const [members, setMembers] = useState<Members[]>([])
  const [currentDataLength, setCurrentDataLength] = useState<number>(0)
  const ansvarigPersonName = useSelector(getAnsvarigPersonName)

  const ansvarigPerson = useSelector(getAnsvarigPersonId)

  const { isLoading, isError, data, error } = useQuery(['nyligen_utgångna', ansvarigPerson, DATE_GAP], fetchNyligenUtgångna)

  useEffect(() => {
    setRenderTable(createNyligenUtgångnaTableConfig(data?.sort((a, b) => (a.förnamn < b.förnamn ? -1 : 1)) || [], filters))
  }, [filters, data])

  const handleMemberSelect = (value: any) => {
    const correctMember = myCasesData?.find((item) => item.ansvarig_personnummer === value)
    dispatch(setAnsvarigPerson({ ansvarigPersonId: correctMember ? correctMember.ansvarig_personnummer : '', ansvarigPersonName: correctMember ? correctMember.ansvarig_namn : '' }))
  }

  const { isError: isMyCasesError, data: myCasesData, error: myCasesError, isLoading: myCasesLoading } = useQuery(['mycases'], fecthDistribution)

  useEffect(() => {
    const selectOptions = myCasesData?.map((item) => {
      return { value: item.ansvarig_personnummer, label: item.ansvarig_namn }
    })
    setMembers(selectOptions as Members[])
  }, [myCasesData])

  useEffect(() => {
    setCurrentDataLength(data ? data.length : 0)
  }, [isLoading, data])

  if (data === undefined) return <></>
  data?.sort((a, b) => (a.förnamn < b.förnamn ? -1 : 1))
  return (
    <>
      <FlagListHeader
        heading={'Nyligen utgångna' + ' - ' + ansvarigPersonName}
        title={null}
        description={
          'Denna vy visar beslut som nyligen har gått ut och visas under 14 dagar efter det att beslutet gått ut. Trygghetslarm visas under två månader efter det att beslutet gått ut. Beslutets insatskategori visas endast om brukaren inte har ett nuvarande beslut med den insatskategorin.'
        }
        button={false}
      />
      <Spin spinning={isLoading && myCasesLoading}>
        <Select
          style={{ minWidth: '20%', marginBottom: '20px', marginRight: '25px' }}
          placeholder="Byt handläggare"
          value={ansvarigPerson ? { label: ansvarigPersonName, value: ansvarigPerson } : undefined}
          options={sortDataByLabel(members)}
          onChange={handleMemberSelect}
        />
        <p style={{ display: 'inline', right: 0, position: 'absolute', marginBottom: '20px', marginRight: '25px' }}>{currentDataLength + ' av ' + data?.length}</p>
        <Table
          columns={renderTable}
          dataSource={data || []}
          rowClassName="table-row-cursor"
          loading={false}
          bordered
          size="small"
          pagination={false}
          scroll={{ x: 1000, y: 'calc(100wh - 250px)' }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {},
            }
          }}
          onChange={(pagination, filters, sorter, { currentDataSource }) => {
            setCurrentDataLength(currentDataSource.length)
            Object.keys(filters).map((key) => {
              if (filters[key]?.includes('Välj alla')) {
                if (key === 'personnummer') {
                  filters[key] = Array.from(
                    new Set(
                      data.map((d) => {
                        return d.personnummer || 'Inget värde'
                      })
                    )
                  )
                } else if (key === 'namn') {
                  filters[key] = Array.from(
                    new Set(
                      data.map((d: any) => {
                        return d.förnamn + ' ' + d.efternamn || 'Inget värde'
                      })
                    )
                  )
                } else if (key === 'insatskategori') {
                  filters[key] = Array.from(
                    new Set(
                      data.map((d) => {
                        if (d.insatskategorityp) {
                          return d.insatskategorityp
                        } else {
                          return 'Inget värde'
                        }
                      })
                    )
                  )
                } else if (key === 'till') {
                  filters[key] = Array.from(
                    new Set(
                      data.map((d) => {
                        if (d.till) {
                          return moment(d.till).format('YYYY-MM-DD')
                        } else {
                          return 'Inget värde'
                        }
                      })
                    )
                  )
                } else if (key === 'processid') {
                  filters[key] = Array.from(
                    new Set(
                      data.map((d) => {
                        return d.processid || 'Inget värde'
                      })
                    )
                  )
                }
              }
            })
            setFilters(filters)
          }}
        />
      </Spin>
    </>
  )
}

export default NyligenUtgångnaTable
