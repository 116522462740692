import React from 'react'
import FlagListHeader from '../../components/header'

const Handlaggare: React.FC = () => (
  <>
    <FlagListHeader heading="Översikt/Avvikelser" title={'Handläggare'} description={'Lorem ipsum dolor soplor config lostig braaa pu ka'} button={true} />
    {/* <Table
      columns={createFilterConfig(data, "handlaggare")}
      rowSelection={{}}
      dataSource={data}
      onChange={() => { }}
    /> */}
  </>
)

export default Handlaggare
