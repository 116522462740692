import { useMutation, useQuery } from '@tanstack/react-query'
import { apiClient, useApi } from './useApi'
import { GetHomeCareProportionAgeGroupValuesVariantEnum, GetResidencyProportionAgeGroupValuesVariantEnum, GetResidencyPopulationProportionValuesVariantEnum } from '../gen/api'

export const useMunicipalityName = () => useQuery(['municipalityName'], async () => await apiClient.getMunicipalityName(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

interface BenchmarkingParams {
  grouping?: string
  datasource?: string
  variationId?: string
}

export const useVolumeValues = () =>
  useMutation(
    ['volumeValues'],
    async ({ grouping, datasource, variationId }: BenchmarkingParams) =>
      await apiClient.getVolumeValues(localStorage.getItem('tenant_id') ?? '', variationId, datasource, grouping).then((res) => res.data)
  )
export const useCostValues = () => useQuery(['costValues'], async () => await apiClient.getCostValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useVolumeAgeGroupValues = () =>
  useMutation(
    ['volumeAgeGroupValues'],
    async ({ grouping, datasource, variationId }: BenchmarkingParams) =>
      await apiClient.getVolumeAgeGroupValues(localStorage.getItem('tenant_id') ?? '', variationId, datasource, grouping).then((res) => res.data)
  )

export const useVolumeProportionAgeGroupValues = () =>
  useMutation(
    ['volumeProportionAgeGroupValues'],
    async ({ grouping, datasource, variationId }: BenchmarkingParams) =>
      await apiClient.getVolumeProportionAgeGroupValues(localStorage.getItem('tenant_id') ?? '', variationId, datasource, grouping).then((res) => res.data)
  )

export const useResidencyCapacityAgeGroupValues = () =>
  useMutation(
    ['residencyCapacityAgeGroupValues'],
    async ({ grouping, datasource, variationId }: BenchmarkingParams) =>
      await apiClient.getResidencyCapacityAgeGroupValues(localStorage.getItem('tenant_id') ?? '', variationId, datasource, grouping).then((res) => res.data)
  )

export const useHomeCareVolumeAgeGroupValues = () =>
  useMutation(
    ['homeCareVolumeAgeGroupVolumeValues'],
    async ({ grouping, datasource, variationId }: BenchmarkingParams) =>
      await apiClient.getHomeCareVolumeAgeGroupValues(localStorage.getItem('tenant_id') ?? '', variationId, datasource, grouping).then((res) => res.data)
  )

export const useResidencyCapacityAgeGroupTrendValues = () =>
  useMutation(
    ['residencyCapacityAgeGroupTrendValues'],
    async ({ grouping, datasource, variationId }: BenchmarkingParams) =>
      await apiClient.getResidencyCapacityAgeGroupTrendValues(localStorage.getItem('tenant_id') ?? '', variationId, datasource, grouping).then((res) => res.data)
  )

export const useHomeCareOrderedHoursPerPersonValues = () =>
  useMutation(
    ['homeCareOrderedHoursPerPersonValues'],
    async ({ grouping, datasource, variationId }: BenchmarkingParams) =>
      await apiClient.getHomeCareOrderedHoursPerPersonValues(localStorage.getItem('tenant_id') ?? '', variationId, datasource, grouping).then((res) => res.data)
  )

export const useHomeCareProportionValues = () =>
  useMutation(
    ['homeCareProportionValues'],
    async ({ grouping, datasource, variationId }: BenchmarkingParams) =>
      await apiClient.getHomeCareProportionValues(localStorage.getItem('tenant_id') ?? '', variationId, datasource, grouping).then((res) => res.data)
  )

export const useNursingDementiaProportionValues = () =>
  useQuery(['nursingDementiaProportionValues'], async () => await apiClient.getNursingDementiaProportionValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useNursingDementiaTotalTrendValues = () =>
  useQuery(['nursingDementiaTotalTrendValues'], async () => await apiClient.getNursingDementiaTotalTrendValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useCapacityInflowOutflowTrendValues = () =>
  useQuery(['capacityInflowOutflowTrendValues'], async () => await apiClient.getCapacityInflowOutflowTrendValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useInflowProportionAgeGroupTrendValues = () =>
  useQuery(['inflowProportionAgeGroupTrendValues'], async () => await apiClient.getInflowProportionAgeGroupTrendValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useMedianNursingDementiaStayLengthValues = () =>
  useQuery(['medianNursingDementiaStayLengthValues'], async () => await apiClient.getNursingDementiaTotalStayLengthValues(localStorage.getItem('tenant_id') ?? '', 'median').then((res) => res.data))

export const useMeanNursingDementiaStayLengthValues = () =>
  useQuery(['meanNursingDementiaStayLengthValues'], async () => await apiClient.getNursingDementiaTotalStayLengthValues(localStorage.getItem('tenant_id') ?? '', 'mean').then((res) => res.data))

export const useNursingStayLengthTrendValues = () =>
  useQuery(['nursingStayLengthTrendValues'], async () => await apiClient.getStayLengthTrendValues(localStorage.getItem('tenant_id') ?? '', 'nursing').then((res) => res.data))

export const useDementiaStayLengthTrendValues = () =>
  useQuery(['dementiaStayLengthTrendValues'], async () => await apiClient.getStayLengthTrendValues(localStorage.getItem('tenant_id') ?? '', 'dementia').then((res) => res.data))

export const useTotalStayLengthTrendValues = () =>
  useQuery(['totalStayLengthTrendValues'], async () => await apiClient.getStayLengthTrendValues(localStorage.getItem('tenant_id') ?? '', 'total').then((res) => res.data))

export const useResidencyProportionAgeGroupValues = () =>
  useMutation(
    ['residencyProportionAgeGroupValues'],
    async (variation?: GetResidencyProportionAgeGroupValuesVariantEnum) =>
      await apiClient.getResidencyProportionAgeGroupValues(localStorage.getItem('tenant_id') ?? '', variation).then((res) => res.data)
  )

export const useHomeCareProportionAgeGroupValues = () =>
  useMutation(
    ['homeCareProportionAgeGroupValues'],
    async (variation?: GetHomeCareProportionAgeGroupValuesVariantEnum) =>
      await apiClient.getHomeCareProportionAgeGroupValues(localStorage.getItem('tenant_id') ?? '', variation).then((res) => res.data)
  )

export const useHomeCareVolumeAgeGroupTrendValues = () =>
  useMutation(['homeCareVolumeAgeGroupTrendValues'], async () => await apiClient.getHomeCareVolumeAgeGroupTrendValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useInflowAgeGroupValues = () => useMutation(['inflowAgeGroupValues'], async () => await apiClient.getInflowAgeGroupValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useHomeCareOrderedHoursValues = () =>
  useQuery(['homeCareOrderedHoursValues'], async () => await apiClient.getHomeCareOrderedHoursValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useHomeCarePerformedHoursValues = () =>
  useQuery(['homeCarePerformedHoursValues'], async () => await apiClient.getHomeCarePerformedHoursValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useHomeCareOrderedHoursTrendMixedValues = () =>
  useQuery(['homeCareOrderedHoursTrendMixedValues'], async () => await apiClient.getHomeCareOrderedHoursTrendMixedValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useHomeCarePerformedHoursTrendMixedValues = () =>
  useQuery(['homeCarePerformedHoursTrendMixedValues'], async () => await apiClient.getHomeCarePerformedHoursTrendMixedValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useHomeCareOrderedHoursAgeGroupValues = () =>
  useQuery(['homeCareOrderedHoursAgeGroupValues'], async () => await apiClient.getHomeCareOrderedHoursAgeGroupValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useHomeCareOrderedHoursProportionIntervalValues = () =>
  useQuery(
    ['homeCareOrderedHoursProportionIntervalValues'],
    async () => await apiClient.getHomeCareOrderedHoursProportionIntervalValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data)
  )

export const useHomeCareOrderedHoursIntervalComparisonValues = () =>
  useQuery(
    ['homeCareOrderedHoursIntervalComparisonValues'],
    async () => await apiClient.getHomeCareOrderedHoursIntervalComparisonValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data)
  )

export const useResidencyPopulationProportionValues = () =>
  useMutation(
    ['residencyPopulationProportionValues'],
    async (variation?: GetResidencyPopulationProportionValuesVariantEnum) =>
      await apiClient.getResidencyPopulationProportionValues(localStorage.getItem('tenant_id') ?? '', variation).then((res) => res.data)
  )

export const useVolumeInsatsMixValues = () => useQuery(['volumeInsatsMixValues'], async () => await apiClient.getVolumeInsatsMixValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))
export const useVolumeQualityMixValues = () =>
  useQuery(['volumeQualityMixValues'], async () => await apiClient.getVolumeQualityMixValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))
export const useCostQualityMixValues = () => useQuery(['costQualityMixValues'], async () => await apiClient.getCostQualityMixValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))
export const useHomeCareResidencyCostMixValues = () =>
  useQuery(['homeCareResidencyCostMixValues'], async () => await apiClient.getHomeCareResidencyCostMixValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useHomeCareOrderedHoursIntervalValues = () =>
  useQuery(['homeCareOrderedHoursIntervalValues'], async () => await apiClient.getHomeCareOrderedHoursIntervalValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useHomeCareHoursTrendValues = () =>
  useQuery(['homeCareHoursTrendValues'], async () => await apiClient.getHomeCareHoursTrendValues(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useSupportedBenchmarks = () => useQuery(['supportedBenchmarks'], async () => await apiClient.getSupportedBenchmarks(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))
