export interface IfilterConfig {
  brukareDeviation: string[]
  medarbetareDeviation: string[]
}

export function splitParams(params: string) {
  const paramsList = params.split(',').map((item: string) => item.trim())
  return paramsList
}

export function filterConfig(flags: string[]) {
  if (flags[0] === 'null') return { brukareDeviation: ['null'], medarbetareDeviation: ['null'] }

  const filters: IfilterConfig = {
    brukareDeviation: [],
    medarbetareDeviation: [],
  }

  flags.map((item) => {
    if (item === 'Hög kontinuitet' || item === 'Hög utförandegrad') {
      filters.brukareDeviation.push(item)
    } else {
      filters.medarbetareDeviation.push(item)
    }
  })
  return filters
}
