import { useEffect } from 'react'

const LogoutRedirect: React.FC = () => {
  useEffect(() => {
    const redirectUrl = (() => {
      const protocol = window.location.protocol
      const host = window.location.host
      const tenant = localStorage.getItem('tenant')

      // We want to redirect to the subdomain of the tenant, to redirect to the correct app
      if (tenant && host === 'pejlen.inrikta.se') return `${protocol}//${tenant}.${host}/`
      return '/'
    })()

    localStorage.clear()
    window.location.assign(redirectUrl)
  }, [])

  return <></>
}

export default LogoutRedirect
