import { Timeline, Tooltip } from 'antd'
import moment from 'moment'
import { Description } from '../../../api/beslutsstod/beslutsstod'

interface TimelineTooltipProps {
  descriptions: Description[]
}

interface TimelineEvent {
  title: string
  description: string
}

const TimelineTooltip = ({ descriptions }: TimelineTooltipProps) => {
  const timelineData: TimelineEvent[] = Object.entries(descriptions).map((item) => {
    return {
      title: moment(item[1].created).locale('sv').format('ll'),
      description: item[1].description,
    }
  })

  return (
    <Tooltip title="Timeline" className="timelineTooltip">
      <Timeline className="timelineTooltip">
        {timelineData.map((item, index) => (
          <Timeline.Item key={index}>
            <strong>{item.title}</strong>
            <p>{item.description && item.description !== '' ? item.description : 'Ingen beskrivning'}</p>
          </Timeline.Item>
        ))}
      </Timeline>
    </Tooltip>
  )
}

export default TimelineTooltip
