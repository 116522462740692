import { DataType, getColor } from './filterconfig'
import { flagData } from '../singleObject'

// Antd
import { Table, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { FlagFilled } from '@ant-design/icons'

export const createSingleTableConfig = (data: DataType[], path: string) => {
  const info = '-'

  const columns: ColumnsType<DataType> = [
    {
      title: 'Avvikelse',
      dataIndex: 'deviation',
      key: 'deviation',
      showSorterTooltip: { title: 'Sortera på avvikelse' },
      width: 180,
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.flaggnamn
          })
        )
      )
        .map((name) => {
          return {
            value: name,
            text: 'Avvikelse ' + name,
          }
        })
        .sort((a, b) => a.value.localeCompare(b.value)),
      sorter: (a, b) => a.flaggnamn.localeCompare(b.flaggnamn),
      render: (_, obj) => <>{obj.flaggnamn}</>,
      onFilter: (value, record) => record.flaggnamn === value,
      filterSearch: true,
    },
    {
      title: 'Risknivå',
      dataIndex: 'risk',
      key: 'risk',
      showSorterTooltip: { title: 'Sortera på risknivå' },
      width: 150,
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.nivå
          })
        )
      )
        .map((name) => {
          return {
            value: name,
            text: name,
          }
        })
        .sort((a, b) => a.value - b.value),
      sorter: (a, b) => a.nivå - b.nivå,
      render: (_, obj) => (
        <>
          {obj.nivå} <FlagFilled style={{ color: getColor(obj.nivå) }} />
        </>
      ),
      onFilter: (value, record) => record.nivå === value,
      filterSearch: true,
    },
    {
      title: 'Utförare',
      dataIndex: 'utförare',
      key: 'utförare',
      showSorterTooltip: { title: 'Sortera på utförare' },
      filters: Array.from(
        new Set(
          data
            .map((d) => {
              return d.utförare?.map((u) => u.utförare_namn)
            })
            .flat()
        )
      )
        .map((name) => {
          return {
            value: name ? name : '',
            text: name,
          }
        })
        .sort((a, b) => a.value.localeCompare(b.value)),
      render: (_, a) => {
        return a ? (
          a.utförare?.map((u) => {
            return (
              <>
                {u.utförare_namn}
                <br />
              </>
            )
          })
        ) : (
          <>Ingen utförare</>
        )
      },
      sorter: (a, b) => {
        if (!a.utförare) return 1
        if (!b.utförare) return -1
        return a.utförare[0].utförare_namn.localeCompare(b.utförare[0].utförare_namn)
      },
      onFilter: (value: any, record) => {
        if (!record.utförare) return false
        return record.utförare.includes(value)
      },
      filterSearch: true,
    },
    {
      title: 'Period',
      dataIndex: 'periodStart',
      key: 'periodStart',
      showSorterTooltip: { title: 'Sortera på period' },
      filterSearch: false,
      sorter: (a, b) => {
        const from = new Date(a.från)
        const to = new Date(b.från)
        return from.getTime() - to.getTime()
      },
      render: (_, obj) => {
        return (
          <>
            {path === 'brukare' ? (
              <>
                {obj.från} - {obj.till}
              </>
            ) : (
              <>
                {new Date(obj.från).toLocaleDateString()} - {new Date(obj.till).toLocaleDateString()}
              </>
            )}
          </>
        )
      },
    },
    {
      title: 'Skapad',
      dataIndex: 'created',
      key: 'created',
      filters: Array.from(
        new Set(
          data.map((d) => {
            return new Date(d.created).toLocaleDateString()
          })
        )
      ).map((name) => {
        return {
          value: name,
          text: name,
        }
      }),
      sorter: (a, b) => {
        return new Date(a.created).getTime() - new Date(b.created).getTime()
      },
      filterSearch: true,
      onFilter: (value, record) => {
        return new Date(record.created).toLocaleDateString() === value
      },
      showSorterTooltip: { title: 'Sortera på när avvikelsen skapades' },
      render: (_, obj) => {
        return <>{new Date(obj.created).toLocaleDateString()}</>
      },
    },
    Table.SELECTION_COLUMN,
  ]

  return columns
}
