export const FONT_EXTRA_LARGE = 24
export const FONT_LARGE = 20
export const FONT_MEDIUM = 14
export const FONT_SMALL = 12
export const FONT_EXTRA_SMALL = 10

export type FontSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs' | number

export const getFontSize = (fontSize: FontSize | undefined) => {
  if (typeof fontSize === 'number') {
    return fontSize
  }
  switch (fontSize) {
    case 'xl':
      return FONT_EXTRA_LARGE
    case 'lg':
      return FONT_LARGE
    case 'md':
      return FONT_MEDIUM
    case 'sm':
      return FONT_SMALL
    case 'xs':
      return FONT_EXTRA_SMALL
    default:
      return FONT_MEDIUM
  }
}
