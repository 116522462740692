import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../../../store/store'

interface tabState {
  utförareTab: string
  volymuppföljningTab: string
}

const initialState = {
  utförareTab: 'tab-medarbetare',
  volymuppföljningTab: 'tab-1',
} as tabState

const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    setUtförareTab(state, action: PayloadAction<string>) {
      state.utförareTab = action.payload
    },
    setVolymuppföljningTab(state, action: PayloadAction<string>) {
      state.volymuppföljningTab = action.payload
    },
  },
})

export const getUtförareTab = (state: AppState) => state.tabReducer.utförareTab
export const getVolymuppföljningTab = (state: AppState) => state.tabReducer.volymuppföljningTab
export const { setUtförareTab, setVolymuppföljningTab } = tabSlice.actions
export default tabSlice.reducer
