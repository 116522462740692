import { InfoCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Checkbox, Form, Input, InputNumber, Popover, Select, Table, notification } from 'antd'
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BeslutsstodCalculationResult, calculateBeslutsstod, saveBeslutsstod } from '../../../../api/beslutsstod/beslutsstod'
import { useApi } from '../../../../api/useApi'
import { getIBICMunicipality } from '../../../../store/beslutsstod/ibicMunicipalitySlice'
import ShowResults from '../../showResults'
import { IBICCalculationRule, IBICMunicipalityData, MunicipalityData } from '../../types/municipalityData'
import jsonFormatter from '../../utils/jsonFormatter'
import { validatePersonnummer } from '../../utils/validatePersonnummer'
import { DistributionRow, useDistributionTableConfig } from '../distributionTableConfig'
import { IBICBeslutsstodSummary } from '../IBICBeslutsstodSummary'
import IBICLifeAreaTasks from '../IBICLifeAreaTasks'
import { resolveIBICCalculationRules } from '../resolvers/resolveIBICCalculationRules'
import { IBICResolutionResult } from '../resolvers/types'
import { IBICFormTask } from '../types/FormValues'
import { useLifeAreas } from '../useLifeAreas'
import { getMunicipality } from '../../../../store/beslutsstod/municipalitySlice'
import { useCalculateIBIC } from '../../../../api/beslutsstod/ibic/useCalculateIBIC'
const api = useApi()

type Props = {}

export interface IBICTaskWithRule extends IBICFormTask {
  ibicCalculationRule: IBICCalculationRule
}

const IBICBeslutsstodCalculationView: React.FC<Props> = (props) => {
  const [summaryData, setSummaryData] = useState<IBICFormTask[] | undefined>(undefined)
  const [distributionData, setDistributionData] = useState<DistributionRow[] | undefined>(undefined)
  const distributionTableConfig = useDistributionTableConfig(distributionData ?? [])
  const { mutateAsync: calculate } = useCalculateIBIC()

  const [timepointsPerDayRestrictions, setTimepointsPerDayRestrictions] = useState<{
    min: number
    max: number
  }>()

  const [form] = Form.useForm()
  const [result, setResult] = useState<BeslutsstodCalculationResult | null>(null)
  const [submittingErrors, setSubmittingErrors] = useState<SubmitError[] | null>(null)
  const municipalityData = useSelector(getIBICMunicipality)
  const legacyMunicipalityData = useSelector(getMunicipality)

  const { checkedLifeAreas, onChange: onLifeAreasChange } = useLifeAreas({ lifeAreas: municipalityData?.input.lifeAreas ?? [] })

  const [notificationInstance, contextHolder] = notification.useNotification()
  const [visitsPerDay, setVisitsPerDay] = useState<number>(0)

  interface SubmitError {
    message: ReactNode
    reason: 'frequency' | 'level'
  }

  interface FormValues {
    shared?: boolean
    weeksOff?: number
    lifeAreaData: {
      [key: string]: { tasks: IBICFormTask[] }
    }
  }

  const getTasksWithRules = (
    tasks: IBICFormTask[],
    municipalityData: IBICMunicipalityData
  ): {
    otherTasks: IBICFormTask[]
    timepointTasks: IBICTaskWithRule[]
  } => {
    const timepointTasks = tasks
      .map((task) => {
        const taskData = municipalityData.input.timepointTasks.find((t) => t.name === task.name)
        if (!taskData) {
          return null
        }

        return {
          ...task,
          ibicCalculationRule: taskData.ibicCalculationRules?.[task.level ?? ''] ?? { type: 'IBICUndefinedRule' },
        }
      })
      .filter((task) => task !== null) as IBICTaskWithRule[]

    const otherTasks = tasks.filter((task) => municipalityData.input.extraTasks.some((t) => t.name === task.name))

    return {
      otherTasks,
      timepointTasks,
    }
  }

  // If the user wishes more tasksPerDay than we currently have, we need to reallocate the undefined tasks to new timepoints
  const rellocateUndefinedTasks = (previousResult: IBICResolutionResult, tasksPerDay: number) => {
    let currentTimepoints = previousResult.length
    console.log(`Reallocating (${tasksPerDay} - ${currentTimepoints} = ${tasksPerDay - currentTimepoints}) timepoints`)

    // Move undefined tasks from tasks where there are atleast one other task
    while (currentTimepoints < tasksPerDay) {
      // Find any timepoint with undefined tasks
      const timepointIndex = previousResult.findIndex((timepoint) => {
        return timepoint.tasks.some((task) => task.ibicCalculationRule.type === 'IBICUndefinedRule') && timepoint.tasks.length > 1
      })

      if (timepointIndex === -1) {
        console.error('No undefined tasks found, taskPerDay is too high')
        return previousResult
      }

      const undefinedTask = previousResult[timepointIndex].tasks.find((task) => task.ibicCalculationRule.type === 'IBICUndefinedRule')

      if (!undefinedTask) {
        console.error('No undefined tasks found, something went very wrong')
        return previousResult
      }

      const newTimepoint = {
        tasks: [undefinedTask],
      }

      previousResult = previousResult.map((timepoint, index) => {
        if (index === timepointIndex) {
          return {
            tasks: timepoint.tasks.filter((task) => task.name !== undefinedTask.name),
          }
        }
        return timepoint
      })

      previousResult.push(newTimepoint)

      currentTimepoints = previousResult.length
    }

    return previousResult
  }

  // This will be the minimum amount of timepoints per day (if used directly after calculation)
  const getMinTimepointsPerDay = (resolutionResult: IBICResolutionResult) => {
    return resolutionResult.length
  }

  // TODO: This is not correct
  // Assume all undefined tasks are on individual timepoints
  const getMaxTimepointsPerDay = (resolutionResult: IBICResolutionResult) => {
    // const getMaxTimepointsPerDay = (resolutionResult) => {

    const currentTimepoints = resolutionResult.length

    const moveableUndefinedTasks = resolutionResult.reduce((acc, timepoint) => {
      const moveableTasks = timepoint.tasks.filter((task) => task.ibicCalculationRule.type === 'IBICUndefinedRule')
      console.log(`moveableTasks ${moveableTasks.length} for timepoint ${timepoint}`)

      if (moveableTasks.length === 0) {
        return acc
      }

      const tasksForTimepoint = timepoint.tasks.length
      // As long as there will be atleast one task left on the timepoint, we can move all undefined tasks
      if (tasksForTimepoint > 1) {
        return acc + moveableTasks.length
      }
      // Can move all except one
      return acc + (moveableTasks.length - 1)
    }, 0)

    return Math.min(currentTimepoints + moveableUndefinedTasks, 9)
  }

  const flattenTasks = (values: FormValues) => {
    console.log('values', values)
    return Object.values(values.lifeAreaData)
      .flatMap((v: { tasks: IBICFormTask[] }) => {
        if (v && v.tasks) {
          return v.tasks
        }
      })
      .filter((task) => task !== undefined && task.level) as IBICFormTask[]
  }

  const formatDataToLegacyFormat = (ibicResult: IBICResolutionResult, otherTasks: IBICFormTask[], legacyMunicipalityData: MunicipalityData, mode: 'calculate' | 'save' = 'calculate') => {
    const res: Record<string, any> = {
      other: {},
    }

    otherTasks.forEach((task) => {
      res.other[task.name] = {
        Frekvens: task.daysPerWeek,
        Nivå: task.level,
        Dubbelbemanning: task.double,
      }
    })

    ibicResult.forEach((timepoint, index) => {
      const incrementalName = `Besök ${index + 1}`
      const timepointName = mode === 'save' ? legacyMunicipalityData.input.timepoints[index] ?? incrementalName : incrementalName
      res[timepointName] = {
        timepoints: {},
      }

      timepoint.tasks.forEach((task) => {
        res[timepointName].timepoints[task.name] = {
          Besök: task.daysPerWeek,
          Nivå: task.level,
          Dubbelbemanning: task.double,
        }
      })
    })

    return res
  }

  const getFormattedFormValues = (values: FormValues) => {
    if (!municipalityData) return { otherTasks: [], timepointTasks: [] }

    const flatTasks = flattenTasks(values)

    return getTasksWithRules(flatTasks, municipalityData)
  }

  const getAllocatedTasks = (values: FormValues) => {
    if (!municipalityData) return { otherTasks: [], allocatedTasks: [] }

    const { otherTasks, timepointTasks } = getFormattedFormValues(values)

    const resolvedTasks = resolveIBICCalculationRules(timepointTasks)

    const reallocatedTasks = rellocateUndefinedTasks(resolvedTasks, visitsPerDay)

    return { otherTasks, allocatedTasks: reallocatedTasks }
  }

  const onFinish = async (values: FormValues) => {
    if (!municipalityData) return
    if (!legacyMunicipalityData) return

    console.log('values', values)
    const { otherTasks, allocatedTasks } = getAllocatedTasks(values)
    if (!allocatedTasks) {
      return
    }
    const distributionData = allocatedTasks.map((timepoint, index) => {
      return {
        visit: `Besök ${index + 1}`,
        tasks: timepoint.tasks,
      }
    })
    setDistributionData(distributionData)

    const legacyFormattedData = formatDataToLegacyFormat(allocatedTasks, otherTasks, legacyMunicipalityData)
    const formatedValues = jsonFormatter(legacyFormattedData, values.weeksOff ?? 0, municipalityData)

    console.log('formatedValues', formatedValues)

    const response = await calculate({ formattedData: formatedValues })
    setResult(response)
  }

  const activeLifeAreas = useMemo(() => {
    if (!municipalityData) return []

    return municipalityData.input.lifeAreas.filter((v) => checkedLifeAreas.includes(v))
  }, [checkedLifeAreas])

  useEffect(() => {
    activeLifeAreas.forEach((area, index) => {
      console.log('usEffect, lifeAreas changed', form.getFieldsValue())

      const key = ['lifeAreaData', area]

      const currentTasks = form.getFieldValue(key)
      console.log('currentTasks', key, currentTasks)

      if (currentTasks) {
        return
      }
      const tasks = [...(municipalityData?.input.timepointTasks ?? []), ...(municipalityData?.input.extraTasks ?? [])].filter((task) => task.lifeArea === area)

      form.setFieldValue(key, {
        name: area,
        tasks: tasks.map((task) => {
          const taskVal: IBICFormTask = {
            name: task.name,
            lifeArea: area,
            level: null,
            timesPerDay: null,
            daysPerWeek: null,
            double: false,
          }
          return taskVal
        }),
      })
    })
  }, [activeLifeAreas])

  const onSummarize = useCallback(() => {
    if (!municipalityData) {
      return
    }
    const flatTasks = flattenTasks(form.getFieldsValue() as FormValues)
    console.log('flatTasks', flatTasks)

    const { allocatedTasks } = getAllocatedTasks(form.getFieldsValue() as FormValues)

    console.log('allocatedTasks', allocatedTasks)

    if (allocatedTasks.length > 9) {
      notificationInstance.error({
        message: 'För många besök per dag',
        description: 'Vald kombination av insatser, nivåer och tillfällen per dag resulterar i ett för högt antal hemtjänstbesök per dag',
      })
      return
    }

    setSummaryData(flatTasks)

    const minTimepointsPerDay = getMinTimepointsPerDay(allocatedTasks)
    const maxTimepointsPerDay = getMaxTimepointsPerDay(allocatedTasks)

    setTimepointsPerDayRestrictions({
      min: minTimepointsPerDay,
      max: maxTimepointsPerDay,
    })
  }, [municipalityData])

  useEffect(() => {
    if (!timepointsPerDayRestrictions) {
      return
    }

    if (!visitsPerDay || visitsPerDay < timepointsPerDayRestrictions.min || visitsPerDay > timepointsPerDayRestrictions.max) {
      setVisitsPerDay(timepointsPerDayRestrictions.min)
    }
  }, [timepointsPerDayRestrictions])

  const [pnr, setPnr] = useState('')
  const [pid, setPid] = useState('')
  const [note, setNote] = useState('')

  const onPnrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPnr(event.target.value)
  }
  const onPidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPid(event.target.value)
  }
  const onNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value)
  }

  const onSave = async () => {
    if (!municipalityData) return
    if (!legacyMunicipalityData) return
    try {
      const formValues = form.getFieldsValue() as FormValues

      const { otherTasks, allocatedTasks } = getAllocatedTasks(formValues)
      if (!allocatedTasks) {
        return
      }

      const legacyFormattedData = formatDataToLegacyFormat(allocatedTasks, otherTasks, legacyMunicipalityData, 'save')
      const formatedValues = jsonFormatter(legacyFormattedData, formValues.weeksOff ?? 0, municipalityData)

      if (!pid || !pnr) {
        notificationInstance.error({
          message: 'Vänligen skriv en ett personnummer och ett process id och försök igen',
        })
        return null
      }
      const validatedPersonnummer = validatePersonnummer(pnr)
      if (validatedPersonnummer === null) {
        notificationInstance.error({
          message: 'Personnumret är inte giltigt',
        })
        return null
      }
      const saveRes = await saveBeslutsstod(api, formatedValues, pid, validatedPersonnummer, false, note)
      const host = window.location.host
      const protocol = window.location.protocol

      window.open(`${protocol}//${host}/app/beslutsstod/${saveRes.id}`, '_blank')

      notificationInstance.success({
        message: 'Beräkningen har sparats',
      })
    } catch (error) {
      console.error(error)
      notificationInstance.error({
        message: 'Beräkningen kunde inte sparas',
      })
    }
  }

  return (
    <>
      {contextHolder}
      <Form form={form} name={'dynamic-form'} onFinish={onFinish}>
        <div>
          <div className="flex items-center gap-1">
            <Select
              mode="multiple"
              allowClear
              style={{ minWidth: '225px' }}
              placeholder="Välj livsområden"
              value={checkedLifeAreas}
              onChange={onLifeAreasChange}
              options={municipalityData?.input.lifeAreas.map((area) => ({ label: area, value: area }))}
            />
          </div>

          <div className="flex flex-col gap-5">
            {municipalityData && <IBICLifeAreaTasks lifeAreas={activeLifeAreas} municipalityData={municipalityData} />}
            {!!activeLifeAreas.length && (
              <div>
                <Button type="primary" htmlType="button" onClick={onSummarize}>
                  Sammanfatta
                </Button>
              </div>
            )}

            <IBICBeslutsstodSummary tasks={summaryData} />
          </div>
          <div>
            <div className="mt-10 flex flex-col gap-2">
              <div className="flex flex-col gap-10">
                {summaryData && (
                  <div className="flex flex-col justify-end">
                    <div>
                      {municipalityData && municipalityData.input.formSettings.showShared !== false ? (
                        <Form.Item name={['shared']} valuePropName="checked">
                          <Checkbox>
                            {municipalityData.input.formSettings.nameShared}{' '}
                            <Popover overlayClassName="decision-popover" title={municipalityData.input.formSettings.nameShared} content={municipalityData.input.formSettings.descriptionShared}>
                              <InfoCircleOutlined />
                            </Popover>
                          </Checkbox>
                        </Form.Item>
                      ) : (
                        <></>
                      )}
                      {municipalityData && municipalityData.input.formSettings.showWeeksOff !== false ? (
                        <Form.Item name={['weeksOff']}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                            <InputNumber type="number" addonAfter="veckor" min={0} max={4} defaultValue={0} style={{ maxWidth: '150px' }} />
                            {municipalityData.input.formSettings.nameWeeksOff}
                            <Popover overlayClassName="decision-popover" title={municipalityData.input.formSettings.nameWeeksOff} content={municipalityData.input.formSettings.descriptionWeeksOff}>
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div>
                      <Form.Item name={'visitsPerDay'}>
                        <p>
                          Vid hur många tillfällen/hemtjänstbesök per dag behöver brukaren få insatser utförda?
                          <br />
                          Utgå från behoven inom personlig vård, förflyttning samt bereda måltider under Hemliv och tillsyn och dusch under Trygghet.
                          {/* TODO: Remove */}
                          {/* ({timepointsPerDayRestrictions?.min}-{timepointsPerDayRestrictions?.max}) */}
                        </p>
                        {/* <Input
                          placeholder="Antal besök per dag"
                          type="number"
                          className="max-w-[150px]"
                          min={timepointsPerDayRestrictions?.min}
                          max={timepointsPerDayRestrictions?.max}
                          defaultValue={timepointsPerDayRestrictions?.min}
                          onChange={(e) => setVisitsPerDay(parseInt(e.target.value))}
                          value={visitsPerDay}
                        /> */}

                        <InputNumber
                          placeholder="Antal besök per dag"
                          type="number"
                          min={timepointsPerDayRestrictions?.min}
                          max={timepointsPerDayRestrictions?.max}
                          defaultValue={timepointsPerDayRestrictions?.min}
                          style={{ maxWidth: '150px' }}
                          onChange={(v) => (v !== null ? setVisitsPerDay(v) : timepointsPerDayRestrictions?.min)}
                          value={visitsPerDay}
                        />
                      </Form.Item>
                    </div>
                    <Button type="primary" htmlType="submit" className="w-[150px]">
                      Beräkna
                    </Button>
                  </div>
                )}

                {result && municipalityData?.input.calculationOutputSettings.unit && (
                  <div className="mt-2">
                    <ShowResults result={result} unit={municipalityData?.input.calculationOutputSettings.unit} ibic />
                  </div>
                )}

                {distributionData && <Table tableLayout="auto" size="small" className="mb-5" bordered columns={distributionTableConfig} dataSource={distributionData} pagination={false} />}

                {/* TODO: Decide if this should exist */}
                {/* {result && (
                  <div className="flex flex-col justify-end">
                    <div style={{ display: 'grid', flexDirection: 'column' }}>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <Form.Item name={['Personnummer']}>
                          <Input onChange={onPnrChange} placeholder="Personnummer" />
                        </Form.Item>
                        <Form.Item name={['Process id']}>
                          <Input onChange={onPidChange} placeholder="Process id" />
                        </Form.Item>
                      </div>
                      <Form.Item name={['Note']} className="max-w-[365px]">
                        <Input.TextArea onChange={onNoteChange} placeholder="Kommentar" rows={4} style={{ resize: 'none' }} />
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item name={['Draft']} valuePropName="checked">
                        <Checkbox>Spara som utkast</Checkbox>
                      </Form.Item>
                      <Form.Item noStyle>
                        <Button type="primary" htmlType="button" onClick={onSave}>
                          Spara och öppna i vanliga beslutsstödet
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                )} */}
              </div>
              {submittingErrors && (
                <div>
                  <Alert
                    className="w-[365px]"
                    message="Ofullständig inmatning"
                    description={
                      <>
                        <ul>
                          {submittingErrors.map((error, index) => (
                            <li key={index}>- {error.message}</li>
                          ))}
                        </ul>
                      </>
                    }
                    type="warning"
                    closable
                    showIcon
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}

export default IBICBeslutsstodCalculationView
