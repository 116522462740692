import { Table } from 'antd'
import tableHeader from '../utils/TableConfig/tableHeader'
import amountBrukareFilter from './utils/amountBrukareFilter'
import amountOrderedFilter from './utils/amountOrderedFilter'
import amountOmfattandeFilter from './utils/amuntOmfattandeFilter'

const OmfattandeBeslut = (data: any) => {
  const columnsOrdered = tableHeader(data.amountBrukare, 'Andel av beställd tid. Intervall av beställd tid')
  const columnsBrukare = tableHeader(data.amountBrukare, 'Andel av brukare. Intervall av beställd tid')
  const columnsOmfattande = tableHeader(data.amountBrukare, 'Antal beslut av omfattande karaktär')
  // const columnsOver150 = tableHeader(data.amountBrukare, 'Antal beslut över 150 h som varat i intervallet... *')
  const amountBrukare = amountBrukareFilter(data.amountOrderedTime, data.brukareCount)
  const amountOrdered = amountOrderedFilter(data.beslutsomfattningPerInterval)
  const amountOmfattade = amountOmfattandeFilter(data.amountBrukare, data.amountOrderedTime)

  return (
    <>
      <Table columns={columnsBrukare} dataSource={amountBrukare} scroll={{ x: 0 }} pagination={false} size="small" />
      <Table columns={columnsOrdered} dataSource={amountOrdered} scroll={{ x: 0 }} pagination={false} size="small" />
      <Table columns={columnsOmfattande} dataSource={amountOmfattade} scroll={{ x: 0 }} pagination={false} size="small" />
    </>
  )
}

export default OmfattandeBeslut
