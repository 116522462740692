import moment from 'moment'
import { IDistributionData } from '.'
import { useApi } from '../../api/useApi'
import { IMyCasesData2 } from '../minaArenden/tableConfig'
import { INyligenUtgångnaData } from '../nyligenUtgångna/tableConfig'
import { IUtanförTeamstrukturData } from '../utanforTeamStruktur/tableConfig'
import { IVolumeData } from '../volymuppfoljning/tableConfig'

const api = useApi()

interface IBeslutObject {
  giltigt_till: string
  giltigt_från: string
  beslut_id: number
  förlängning: boolean
}

interface IFormattedData {
  personnummer: string
  namn: string
  ansvarig_person: string
  utförare_dagverksamhet: Array<string>
  dagverksamhet: Array<IBeslutObject>
  utförare_trygghetslarm: Array<string>
  trygghetslarm: Array<IBeslutObject>
  utförare_korttidsboende: Array<string>
  korttidsboende: Array<IBeslutObject>
  utförare_hemtjänst: Array<string>
  hemtjänst: Array<IBeslutObject>
  omfattning_månad: number
  dubbelbemanning: boolean
  natthjälp: boolean
  utförare_säbo: Array<string>
  nästa_kvalitetsfrågor: string
  säbo_fv: string
  säbo_till: string
  ny_process: Array<string>
  medlemmar: Array<string>
  kontaktperson_tillfällen: number
  boendestöd: boolean
  hemtjänst_boende: boolean
}

export const fetchMycases = async ({ queryKey }: any): Promise<IMyCasesData2[]> => {
  const [_key, personnummer, date] = queryKey
  const myCasesData = await api.get(`/v1/case/${localStorage.getItem('tenant_id')}/other2?date=${date}&other_personnummer=${personnummer}&id_token=${localStorage.getItem('hydra_id_token')}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })

  const formattedData = myCasesData.data.map((item: IFormattedData, index: number) => {
    const np = item.ny_process
    const ht = item.hemtjänst
      ? item.hemtjänst
          .sort((h1: IBeslutObject, h2: IBeslutObject) => {
            return moment(h1.giltigt_till).diff(h2.giltigt_till)
          })
          .map((h: IBeslutObject) => {
            return { beslutid: h.beslut_id, förlängning: h.förlängning, till: h.giltigt_till, från: h.giltigt_från }
          })
      : null
    const tl = item.trygghetslarm
      ? item.trygghetslarm
          .sort((h1: IBeslutObject, h2: IBeslutObject) => {
            return moment(h1.giltigt_till).diff(h2.giltigt_till)
          })
          .map((t: IBeslutObject) => {
            return { beslutid: t.beslut_id, förlängning: t.förlängning, till: t.giltigt_till, från: t.giltigt_från }
          })
      : null
    const kb = item.korttidsboende
      ? item.korttidsboende
          .sort((h1: IBeslutObject, h2: IBeslutObject) => {
            return moment(h1.giltigt_till).diff(h2.giltigt_till)
          })
          .map((k: IBeslutObject) => {
            return { beslutid: k.beslut_id, förlängning: k.förlängning, till: k.giltigt_till, från: k.giltigt_från }
          })
      : null
    const dv = item.dagverksamhet
      ? item.dagverksamhet
          .sort((h1: IBeslutObject, h2: IBeslutObject) => {
            return moment(h1.giltigt_till).diff(h2.giltigt_till)
          })
          .map((d: IBeslutObject) => {
            return { beslutid: d.beslut_id, förlängning: d.förlängning, till: d.giltigt_till, från: d.giltigt_från }
          })
      : null

    const res = {
      ...item,
      ny_process: np,
      hushåll: item.medlemmar ? item.medlemmar : [],
      hemtjänst: ht,
      trygghetslarm: tl,
      korttidsboende: kb,
      dagverksamhet: dv,
      key: index,
    }

    return res
  })

  return formattedData
}

export const fecthDistribution = async ({ queryKey }: any) => {
  const [_key] = queryKey
  const distributionData = await api.get<IDistributionData[]>(
    `/v1/case/${localStorage.getItem('tenant_id')}/distribution?från=${new Date()}&till=${new Date()}&id_token=${localStorage.getItem('hydra_id_token')}`,
    {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    }
  )

  const formattedData = distributionData.data.map((item: IDistributionData, index: number) => {
    return {
      ...item,
      vårdtyngd: Math.round(item.vårdtyngd * 10) / 10,
      beviljade_timmar: Math.round(item.beviljade_timmar * 10) / 10,
      key: index,
    }
  })

  return formattedData
}

export const fetchOutsideStructure = async ({ queryKey }: any) => {
  const [_key] = queryKey
  try {
    const data = await api.get<IUtanförTeamstrukturData[]>(`/v1/case/${localStorage.getItem('tenant_id')}/outside_structure`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    console.log(data.data)
    return data.data
  } catch (error) {
    console.error(error)
  }
}

export const fetchVolumeCases = async ({ queryKey }: any) => {
  const [_key, from, to, personnummer] = queryKey
  if (!from || !to) return []

  const volumeCasesData = await api.get<IVolumeData[]>(
    `/v1/case/${localStorage.getItem('tenant_id')}/volume2/month/other?från=${from}&till=${to}&other_personnummer=${personnummer}&id_token=${localStorage.getItem('hydra_id_token')}`,
    {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    }
  )

  const formattedData = volumeCasesData.data.map((item: IVolumeData, index: number) => {
    return {
      ...item,
    }
  })

  return formattedData
}

export const fetchNyligenUtgångna = async ({ queryKey }: any) => {
  const [_key, ansvarig_person, date_gap] = queryKey
  const data = await api.get<INyligenUtgångnaData[]>(`/v1/case/${localStorage.getItem('tenant_id')}/recently_expired?ansvarig_person=${ansvarig_person}&date_gap=${date_gap}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })

  return data.data
}
