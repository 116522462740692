import { Case } from '../../features/arende'
import { DataType } from './filterconfig'

// Antd
import type { ColumnsType } from 'antd/es/table'
import { Space, Table, Tooltip } from 'antd'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

export const createCaseTableConfig = (data: Case[], navigate: ReturnType<typeof useHistory>) => {
  const columns: ColumnsType<Case> = [
    {
      title: 'Uppföljningnummer',
      dataIndex: 'key',
      key: 'key',
      showSorterTooltip: { title: 'Sortera på Uppföljningsid' },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.key
          })
        )
      ).map((name) => {
        return {
          value: name,
          text: name,
        }
      }),
      sorter: (a, b) => a.id - b.id,
      render: (_, obj) => <>{obj.key}</>,
      onFilter: (value, record) => record.key === value,
      filterSearch: true,
    },
    {
      title: 'Initierad av',
      dataIndex: 'ägare',
      key: 'ägare',
      showSorterTooltip: { title: 'Sortera på initierare' },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.ägare.namn
          })
        )
      )
        .map((name) => {
          return {
            value: name,
            text: name,
          }
        })
        .sort((a, b) => a.value.localeCompare(b.value)),
      render: (_, obj) => <>{obj.ägare.namn}</>,
      sorter: (a, b) => a.ägare.namn.localeCompare(b.ägare.namn),
      onFilter: (value, record) => record.ägare.namn.includes(value + ''),
      filterSearch: true,
    },
    {
      title: 'Tilldelad',
      dataIndex: 'handläggare',
      key: 'handläggare',
      showSorterTooltip: { title: 'Sortera på handläggare' },
      filters: Array.from(
        new Set(
          data.flatMap((d) => {
            return d.handläggare.map((h) => h.namn)
          })
        )
      )
        .map((name) => {
          return {
            value: name,
            text: name,
          }
        })
        .sort((a, b) => a.value.localeCompare(b.value)),
      render: (_, obj) => <>{obj.handläggare.length > 0 ? 'Flera' : obj.handläggare.length === 1 ? obj.handläggare[0].namn : 'Ingen'}</>,
      sorter: (a, b) => b.handläggare.length - a.handläggare.length,
      onFilter: (value, record) => record.handläggare.map((h) => h.namn).includes(value + ''),
      filterSearch: true,
    },
    {
      title: 'Initierad',
      dataIndex: 'created',
      key: 'created',
      defaultSortOrder: 'descend',
      showSorterTooltip: { title: 'Sortera på datum' },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return moment(d.created).format('YYYY-MM-DD')
          })
        )
      ).map((name) => {
        return {
          value: name,
          text: name,
        }
      }),
      sorter: (a, b) => a.created.getTime() - b.created.getTime(),
      render: (_, obj) => <>{moment(obj.created).format('YYYY-MM-DD')}</>,
      onFilter: (value, record) => moment(record.created).format('YYYY-MM-DD') === value,
      filterSearch: true,
    },
    {
      title: 'Senast uppdaterad',
      dataIndex: 'edited',
      key: 'edited',
      filters: Array.from(
        new Set(
          data.map((d) => {
            return moment(d.senastUppdaterad).format('YYYY-MM-DD')
          })
        )
      ).map((name) => {
        return {
          value: name,
          text: name,
        }
      }),
      sorter: (a, b) => a.senastUppdaterad.getTime() - b.senastUppdaterad.getTime(),
      onFilter: (value, record) => {
        return moment(record.senastUppdaterad).format('YYYY-MM-DD') === value
      },
      filterSearch: true,
      render: (_, obj) => {
        return <>{moment(obj.senastUppdaterad).format('YYYY-MM-DD')}</>
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: { title: 'Sortera på status' },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.status
          })
        )
      ).map((name) => {
        return {
          value: name,
          text: name,
        }
      }),
      sorter: (a, b) => {
        return a.status.localeCompare(b.status)
      },
      onFilter: (value, record) => record.status.includes(value + ''),
      filterSearch: true,
      render: (_, obj) => <>{obj.status}</>,
    },
    Table.EXPAND_COLUMN,
    {
      dataIndex: 'uppdatera',
      key: 'uppdatera',
      render: (_, record) => (
        <Space size="middle">
          <button className="table-edit-link" onClick={() => navigate.push(`/app/arenden/${record.key}`)}>
            Uppdatera
          </button>
        </Space>
      ),
    },
  ]

  return columns
}
