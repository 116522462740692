import * as actions from './actions'
import { Actions } from './actions'

export type State = {
  data: any
}

const initialState: State = {
  data: {},
}

const tableFilterReducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case actions.SET_TABLEFILTER:
      return (state = {
        ...state,
        data: action.payload.data,
      })
    default:
      return state
  }
}

export default tableFilterReducer
