import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Antd
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { FlagFilled } from '@ant-design/icons'
import { getPersonalFilter } from '../../store/personalFilterSlice'

export const getColor = (risk: number) => {
  switch (risk) {
    case 1:
      return 'yellow'
    case 2:
      return 'orange'
    case 3:
      return 'red'
    case 4:
      return 'black'
    default:
      return 'green'
  }
}

export interface DataType {
  key: React.Key
  flagga: number
  flaggnamn: string
  type?: number
  nivå: number
  namn: string
  personnummer?: string
  från: Date
  till: Date
  created: Date
  utförare: {
    utförare_id: number
    utförare_namn: string
  }[]
}

export const CreateFilterConfig = (data: DataType[]) => {
  const filterState = useSelector(getPersonalFilter)
  const columns: ColumnsType<DataType> = [
    {
      title: 'Namn',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      fixed: 'left',
      filteredValue: filterState ? filterState.filter.name : null,
      showSorterTooltip: { title: 'Sortera på namn' },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.namn
          })
        )
      )
        .map((name) => {
          return {
            value: name,
            text: name,
          }
        })
        .sort((a, b) => a.value.localeCompare(b.value)),
      render: (_, coworker) => (
        <Link
          to={{
            pathname: `/app/medarbetare/${coworker.namn}`,
          }}
        >
          {coworker.namn}
        </Link>
      ),
      sorter: (a, b) => a.namn.localeCompare(b.namn),
      onFilter: (value: any, record) => {
        return record.namn.includes(value + '')
      },
      filterSearch: true,
    },
    // path === 'brukare' ?    {
    //   title: 'Personnummer',
    //   dataIndex: 'personnummer',
    //   key: 'personnummer',
    //   showSorterTooltip: { title: 'Sortera på personnummer' },
    //   filters: Array.from(
    //     new Set(
    //       data.map((d) => {
    //         return d.personnummer
    //       })
    //     )
    //   )
    //     .map((name) => {
    //       return {
    //         value: name ? name : '',
    //         text: name,
    //       }
    //     })
    //     .sort((a, b) => a.value.localeCompare(b.value)),
    //   render: (_, coworker) =>
    //     coworker.personnummer && <>{coworker.personnummer}</>,
    //   sorter: (a, b) => a.personnummer!.localeCompare(b.personnummer!),
    //   onFilter: (value, record) => record.personnummer!.includes(value + ''),
    //   filterSearch: true,
    // }: {},
    {
      title: 'Avvikelse',
      dataIndex: 'deviation',
      key: 'deviation',
      width: 170,
      filteredValue: filterState ? filterState.filter.deviation : null,
      showSorterTooltip: { title: 'Sortera på avvikelse' },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.flaggnamn
          })
        )
      )
        .map((name) => {
          return {
            value: name,
            text: name,
          }
        })
        .sort((a, b) => a.value.localeCompare(b.value)),
      sorter: (a, b) => a.flaggnamn.localeCompare(b.flaggnamn),
      render: (_, obj) => (
        <Link
          to={{
            pathname: `/app/avvikelse/flag=${obj.flagga}&id=${obj.key}`,
            state: { flagname: obj.flaggnamn, riskniva: obj.nivå },
          }}
          id="flagLink"
        >
          {obj.flaggnamn}
        </Link>
      ),
      onFilter: (value, record) => {
        return record.flaggnamn === value
      },
      filterSearch: true,
    },
    {
      title: 'Risknivå',
      dataIndex: 'risk',
      key: 'risk',
      width: 150,
      filteredValue: filterState ? filterState.filter.risk : null,
      showSorterTooltip: { title: 'Sortera på risknivå' },
      sorter: (a, b) => a.nivå - b.nivå,
      render: (_, obj) => (
        <>
          {obj.nivå} <FlagFilled style={{ color: getColor(obj.nivå) }} />
        </>
      ),
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.nivå
          })
        )
      )
        .map((name) => {
          return {
            value: name,
            text: name,
          }
        })
        .sort((a, b) => a.value - b.value),
      onFilter: (value, record) => record.nivå === value,
      filterSearch: true,
    },
    {
      title: 'Utförare',
      dataIndex: 'utförare',
      key: 'utförare',
      filteredValue: filterState ? filterState.filter.utförare : null,
      showSorterTooltip: { title: 'Sortera på utförare' },
      filters: Array.from(
        new Set(
          data
            .map((d) => {
              return d.utförare?.map((u) => u.utförare_namn)
            })
            .flat()
        )
      )
        .map((name) => {
          return {
            value: name ? name : '',
            text: name,
          }
        })
        .sort((a, b) => a.value.localeCompare(b.value)),
      render: (_, a) => {
        return a ? (
          a.utförare?.map((u) => {
            return (
              <>
                {u.utförare_namn}
                <br />
              </>
            )
          })
        ) : (
          <>Ingen utförare</>
        )
      },
      sorter: (a, b) => {
        if (!a.utförare) return 1
        if (!b.utförare) return -1
        return a.utförare[0].utförare_namn.localeCompare(b.utförare[0].utförare_namn)
      },
      onFilter: (value: any, record: any) => {
        if (!record.utförare) return false
        return record.utförare[0].utförare_namn === value
      },
      filterSearch: true,
    },
    {
      title: 'Period',
      dataIndex: 'periodStart',
      key: 'periodStart',
      filterSearch: true,
      showSorterTooltip: { title: 'Sortera på period' },
      sorter: (a, b) => {
        const from = new Date(a.från)
        const to = new Date(b.från)
        return from.getTime() - to.getTime()
      },
      render: (_, obj) => {
        return (
          <>
            {
              <>
                {new Date(obj.från).toLocaleDateString()} - {new Date(obj.till).toLocaleDateString()}
              </>
            }
          </>
        )
      },
    },
    {
      title: 'Skapad i körning',
      dataIndex: 'created',
      key: 'created',
      // filters: Array.from(
      //   new Set(
      //     data.map((d) => {
      //       return new Date(d.created).toLocaleDateString()
      //     })
      //   )
      // ).map((name) => {
      //   return {
      //     value: name,
      //     text: name,
      //   }
      // }),
      sorter: (a, b) => {
        return new Date(a.created).getTime() - new Date(b.created).getTime()
      },
      filterSearch: true,
      onFilter: (value, record) => {
        return new Date(record.created).toLocaleDateString() === value
      },
      showSorterTooltip: { title: 'Sortera på när avvikelsen skapades' },
      render: (_, obj) => {
        return <>{new Date(obj.created).toLocaleDateString()}</>
      },
    },
    Table.SELECTION_COLUMN,
  ]

  return columns
}
