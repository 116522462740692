export const validatePersonnummer = (prn: string) => {
  const prnWithoutDash = prn.replaceAll('-', '')

  const twelveDigitPersonnummer = (() => {
    switch (prnWithoutDash.length) {
      case 10:
        return `19${prnWithoutDash}`
      case 12:
        return prnWithoutDash
      default:
        return null
    }
  })()
  if (!twelveDigitPersonnummer) {
    return null
  }
  // Alwayd return on the format YYYYMMDD-XXXX
  return `${twelveDigitPersonnummer.slice(0, 8)}-${twelveDigitPersonnummer.slice(8)}`
}
