import { Spin } from 'antd'
import moment from 'moment'
import React, { ComponentProps } from 'react'
import { Line } from 'react-chartjs-2'
import { useHomeCareHoursTrendValues } from '../../../../api/benchmarking'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { getFontSize } from '../../util/fontSize'
import { getLineColorByIndex } from '../../util/colors'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { NO_COMPARISON, STATDB_NAME } from '../../util/names'
import { EmptySelector } from '../../components/EmptySelector'

export const HomeCareHoursTrend = ({ text }: { text: React.ReactNode }) => {
  const { data, isLoading } = useHomeCareHoursTrendValues()

  if (isLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const graphOptions: ComponentProps<typeof Line>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${context.parsed.y.toFixed(1)} h`
          },
        },
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
    },
  }

  const graphData: ComponentProps<typeof Line>['data'] = {
    labels: data.map((item) => moment(item.date).format('YYYYMM')),
    datasets: [
      {
        label: 'Utförd tid',
        borderColor: getLineColorByIndex(0).border,
        data: data.map((item, index) => ({ y: item.performedHours, x: index })),
        pointRadius: 0,
      },
      {
        label: 'Beviljad tid',
        borderColor: getLineColorByIndex(1).border,
        data: data.map((item, index) => ({ y: item.orderedHours, x: index })),
        pointRadius: 0,
      },
    ],
  }
  const legend = (
    <BenchmarkingLegend
      items={[
        {
          text: 'Utförd tid',
          fillColor: getLineColorByIndex(0).fill,
          borderColor: getLineColorByIndex(0).border,
        },
        {
          text: 'Beviljad tid',
          fillColor: getLineColorByIndex(1).fill,
          borderColor: getLineColorByIndex(1).border,
        },
      ]}
    />
  )
  const title = 'Totalt antal timmar hemtjänst per månad och brukare'
  const graph: React.ReactNode = <Line options={graphOptions} data={graphData} />

  const dataSourceSelector = <EmptySelector value={STATDB_NAME} />
  const comparisonSelector = <EmptySelector value={NO_COMPARISON} />

  return <BenchmarkingContentRow graph={graph} text={text} title={title} legend={legend} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} />
}
