import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useApi } from '../../api/useApi'
import SingleObject from '../../components/singleObject'
import { setFlags } from '../../api/flagApi'

const SingleMedarbetare = () => {
  type Param = { id: string }
  const { id } = useParams<Param>()
  const dispatch = useDispatch()
  const api = useApi()

  useEffect(() => {
    setFlags(api, dispatch, 'medarbetare', id)
  }, [id, api, dispatch])

  return <SingleObject obj={{ namn: id }} heading={'Personal'} />
}

export default SingleMedarbetare
