import { Spin } from 'antd'
import React, { ComponentProps } from 'react'
import { Scatter } from 'react-chartjs-2'
import { useMunicipalityName, useVolumeQualityMixValues } from '../../../../api/benchmarking'
import { SCATTERPLOT_COMPARISON_COLOR, SCATTERPLOT_BASELINE_COLOR } from '../../util/colors'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { getFontSize } from '../../util/fontSize'
import { SCATTERPLOT_BASELINE_SIZE, SCATTERPLOT_COMPARISON_SIZE } from '../../util/constants'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { EmptySelector } from '../../components/EmptySelector'
import { KOLADA_NAME } from '../../util/names'

export const VolumeQualityMix = ({ text }: { text: React.ReactNode }) => {
  const { data: municipalityName, isLoading: isMunicipalityNameLoading } = useMunicipalityName()
  const { data, isLoading } = useVolumeQualityMixValues()

  if (isLoading || isMunicipalityNameLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const options: ComponentProps<typeof Scatter>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return [`Brukarbedömning: ${context.parsed.y}%`, `Beviljade timmar per brukare: ${Math.round(context.parsed.x)}`]
          },
        },
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: 'Genomsnittligt beviljade timmar hemtjänst per brukare, 65+',
          font: {
            size: getFontSize('md'),
          },
        },
        ticks: {
          callback: (value) => `${value}h`,
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Brukarbedömning hemtjänst, helhetssyn',
          font: {
            size: getFontSize('md'),
          },
        },
        ticks: {
          callback: (value) => `${value}%`,
          font: {
            size: getFontSize('md'),
          },
        },
      },
    },
  }
  const graphData: ComponentProps<typeof Scatter>['data'] = {
    datasets: [
      {
        fill: false,
        data: [data.baselineValues],
        borderColor: SCATTERPLOT_BASELINE_COLOR.border,
        backgroundColor: SCATTERPLOT_BASELINE_COLOR.fill,
        pointRadius: SCATTERPLOT_BASELINE_SIZE,
      },
      {
        fill: false,
        data: data.comparisonValues,
        borderColor: SCATTERPLOT_COMPARISON_COLOR.border,
        backgroundColor: SCATTERPLOT_COMPARISON_COLOR.fill,
        pointRadius: SCATTERPLOT_COMPARISON_SIZE,
      },
    ],
  }
  const legend = (
    <BenchmarkingLegend
      items={[
        { text: municipalityName, borderColor: SCATTERPLOT_BASELINE_COLOR.border, fillColor: SCATTERPLOT_BASELINE_COLOR.fill },
        { text: 'Övriga kommuner', borderColor: SCATTERPLOT_COMPARISON_COLOR.border, fillColor: SCATTERPLOT_COMPARISON_COLOR.fill },
      ]}
    />
  )

  const title = 'Brukarvolym och kvalitet'
  const graph: React.ReactNode = <Scatter options={options} data={graphData} />

  const dataSourceSelector = <EmptySelector value={KOLADA_NAME} />
  const comparisonSelector = <EmptySelector value={'Övriga kommuner'} />
  return <BenchmarkingContentRow graph={graph} text={text} title={title} legend={legend} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} />
}
