import { DownSquareOutlined, UpSquareOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import FlagListHeader from '../../components/header'
import { CreateUserTableConfig, IHandläggareFull } from '../../components/tablesConfig/userTableConfig'

// Antd
import { Button, Table } from 'antd'
import { FilterValue } from 'antd/lib/table/interface'
import { getHandläggare } from '../../store/organizationSlice'

type Props = {}

const ExpandedText = (record: IHandläggareFull) => {
  return (
    <>
      {record.personnummer}
      <br />
      {record.email}
      <br />
      {record.namn} ...
      <br />
      ... har rollen: {record.role}.
      <br />
      ... tillhör: {record.team.team_namn}.
      <br />
      ... har tillgång till att se ärenden för: {record.scope.map((t) => t.team_namn).join(', ')}.
      <br />
      ... är {record.active ? 'aktiv vilket betyder att hen kan logga in i Pejlen' : 'inaktiv vilket betyder att hen inte kan logga in i Pejlen.'}
      <br />
      ...{' '}
      {record.in_structure
        ? ' är med i strukturen, vilket betyder att hens ärenden visas i vyn Ärendefördelning'
        : ' är inte med i strukturen, vilket betyder att hens ärenden inte visas i vyn Ärendefördelning '}
    </>
  )
}

const Anvandare = (props: Props) => {
  const dispatch = useDispatch()
  const navigate = useHistory()

  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({
    status: ['Aktiv'],
  })
  const [renderTable, setRenderTable] = useState<ColumnsType<IHandläggareFull> | undefined>([])
  const history = useHistory()

  // Redux state
  const anvandareState = useSelector(getHandläggare)

  useEffect(() => {
    setRenderTable(CreateUserTableConfig(anvandareState, filters, history))
  }, [filters, anvandareState])

  return (
    <>
      <FlagListHeader heading="Användare" title={null} description={null} button={false} />
      <div className="add-user-button">
        <Button
          onClick={() => {
            navigate.push('/app/anvandare/ny')
          }}
        >
          Skapa användare
        </Button>
      </div>
      <Table
        columns={renderTable}
        rowKey={(record) => record.id}
        bordered
        expandable={{
          expandedRowRender: (record) => {
            return ExpandedText(record)
          },
          expandIcon: ({ expanded, onExpand, record }) => {
            return expanded ? <UpSquareOutlined onClick={(e) => onExpand(record, e)} /> : <DownSquareOutlined onClick={(e) => onExpand(record, e)} />
          },
        }}
        dataSource={anvandareState}
        rowClassName={'none-cursor'}
        pagination={false}
        size="small"
        scroll={{ x: 'calc(100wv - 260px)', y: 'calc(100vh - 250px)' }}
        onChange={(pagination, filters, sorter, { currentDataSource }) => {
          Object.keys(filters).map((key) => {
            if (filters[key]?.includes('Välj alla')) {
              if (key === 'namn') {
                filters[key] = Array.from(
                  new Set(
                    anvandareState.map((d) => {
                      return d.namn
                    })
                  )
                )
              } else if (key === 'roll') {
                filters[key] = Array.from(
                  new Set(
                    anvandareState.map((d) => {
                      return d.role
                    })
                  )
                )
              } else if (key === 'team') {
                filters[key] = Array.from(
                  new Set(
                    anvandareState.map((d) => {
                      return d.team.team_namn
                    })
                  )
                )
              } else if (key === 'behörighet') {
                filters[key] = Array.from(
                  new Set(
                    anvandareState
                      .map((d) => {
                        return d.scope.map((t) => t.team_namn)
                      })
                      .flat()
                  )
                )
              } else if (key === 'in_structure') {
                filters[key] = Array.from(
                  new Set(
                    anvandareState.map((d) => {
                      return d.in_structure ? 'Ja' : 'Nej'
                    })
                  )
                )
              } else if (key === 'status') {
                filters[key] = Array.from(
                  new Set(
                    anvandareState.map((d) => {
                      return d.active ? 'Aktiv' : 'Inaktiv'
                    })
                  )
                )
              }
            }
          })
          setFilters(filters)
        }}
      />
    </>
  )
}

export default Anvandare
