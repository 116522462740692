import { useHistory } from 'react-router-dom'

const ProtectedRoute = () => {
  const hydra_token = localStorage.getItem('hydra_access_token')
  const naviagte = useHistory()

  if (!hydra_token) {
    //naviagte.push("/")
  }
  return <></>
}

export default ProtectedRoute
