import { Button, Form, Input, Select, notification } from 'antd'
import { useHistory } from 'react-router-dom'
import { apiClient } from '../../api/useApi'
import BackButton from '../../components/buttons/backButton'
import FlagListHeader from '../../components/header'

const NewTag = () => {
  const navigate = useHistory()

  const onFinish = async (values: { name: string }) => {
    try {
      const res = await apiClient.createTag(localStorage.getItem('tenant_id') ?? '', values)
      navigate.push(`/app/taggar/${res.data.id}`)
      notification.success({
        message: 'Tagg skapad',
        description: 'Taggen har skapats',
      })
    } catch (e) {
      notification.error({
        message: 'Tagg kunde inte skapas',
        description: 'Taggen kunde inte skapas',
      })
    }
  }

  return (
    <>
      <FlagListHeader title="" heading="Skapa Tagg" description="Skapa en ny tagg genom att fylla i fälten nedan." button={false} />
      <div style={{ maxWidth: '600px' }}>
        <Form
          name="tag"
          initialValues={{
            name: '',
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item label="Namn" name="name" rules={[{ required: true, message: 'Vänligen fyll i hela namnet' }]}>
            <Input type={'text'} placeholder="Namn" />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', gap: '6px' }}>
              <BackButton display="inline" />
              <Button type="primary" htmlType="submit" style={{ display: 'inline-block', marginTop: '20px' }}>
                Skapa
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default NewTag
