import { Dispatch } from 'redux'
import { flagData } from '../../singleObject'
import { DataType } from '../../tablesConfig/filterconfig'

export const SET_FLAGS = 'SET_FLAGS'

export type SetFlagsAction = {
  type: typeof SET_FLAGS
  payload: {
    flags: DataType[]
  }
}

export const setFlagsAction = (type: string, data: DataType[], dispatch: Dispatch<any>): SetFlagsAction =>
  dispatch({
    type: SET_FLAGS,
    payload: {
      flags: data,
    },
  })

export type Actions = SetFlagsAction
