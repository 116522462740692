import { useQueryClient } from '@tanstack/react-query'
import { Button, Checkbox, Form, Spin, notification } from 'antd'
import { useAddAuthenticationMethod, useAllAuthenticationMethods, useAllowedAuthenticationMethods, useDeleteAuthenticationMethod } from '../../api/tenant'
import { useCallback } from 'react'

const AuthMethodSettings = () => {
  const queryClient = useQueryClient()

  const { data: allAuthenticationMethods, isLoading: allAuthenticationMethodsLoading } = useAllAuthenticationMethods()
  const { data: allowedAuthenticationMethods, isLoading: allowedAuthenticationMethodsLoading } = useAllowedAuthenticationMethods()
  const { mutate: deleteAuthenticationMethod, isLoading: deleteAuthenticationMethodLoading } = useDeleteAuthenticationMethod()
  const { mutate: addAuthenticationMethod, isLoading: addAuthenticationMethodLoading } = useAddAuthenticationMethod()

  if (allAuthenticationMethodsLoading || allowedAuthenticationMethodsLoading)
    return (
      <div className="flex items-center justify-center">
        <Spin spinning={true} />
      </div>
    )

  if (!allowedAuthenticationMethods || !allAuthenticationMethods) return null

  const onFinish = async (values: any) => {
    const authenticationMethods = values.authenticationMethods as string[]
    const toAdd = authenticationMethods.filter((method) => method !== undefined && !allowedAuthenticationMethods.includes(method))
    const toDelete = allowedAuthenticationMethods.filter((method) => method !== undefined && !authenticationMethods.includes(method))

    try {
      await Promise.all([...toAdd.map((method) => addAuthenticationMethod(method)), ...toDelete.map((method) => deleteAuthenticationMethod(method))])
      queryClient.invalidateQueries(['allowedAuthenticationMethods'])
      notification.success({
        message: 'Autentiseringsmetoder sparade',
      })
    } catch (e) {
      notification.error({
        message: 'Autentiseringsmetoder kunde inte sparas',
      })
    }
  }

  return (
    <>
      <Form
        name="authentication-methods"
        layout="vertical"
        initialValues={{
          authenticationMethods: allowedAuthenticationMethods,
        }}
        onFinish={onFinish}
      >
        <Form.Item name="authenticationMethods">
          <Checkbox.Group>
            {allAuthenticationMethods.map((method) => (
              <div key={method}>
                <Checkbox value={method}>{method}</Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={addAuthenticationMethodLoading || deleteAuthenticationMethodLoading}>
            Spara
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default AuthMethodSettings
