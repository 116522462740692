import { Button, Divider, Form, Input, Popconfirm, Select, Spin, Transfer, notification } from 'antd'
import { TransferDirection } from 'antd/lib/transfer'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useAddMunicipalitiesToTag, useEditTag, useMunicipalities, useTag } from '../../api/municipality'
import BackButton from '../../components/buttons/backButton'
import FlagListHeader from '../../components/header'
import { getTeam } from '../../store/organizationSlice'
import { useEffect, useState } from 'react'
import { apiClient } from '../../api/useApi'

export function getTeamNameById(id: number | undefined) {
  const teams = useSelector(getTeam)

  if (id) {
    let name = ''
    teams.forEach((item: { team_id: number; team_namn: string; avdelning: number }) => {
      if (item.team_id === id) {
        name = item.team_namn
      }
    })
    return name
  }
  return ''
}

const SingleTag = () => {
  const [chosenMunicipalities, setChosenMunicipalities] = useState<Set<string>>(new Set())
  type Param = { id: string }
  const { id } = useParams<Param>()

  const { data: tag, refetch: refetchTag, isLoading: isTagLoading } = useTag(id)
  const { data: municipalities, isLoading: isMunicipalitiesLoading } = useMunicipalities()
  const { mutateAsync: addMunicipalitiesToTag, isLoading: isAddMunicipalitiesLoading } = useAddMunicipalitiesToTag()
  const { mutateAsync: editTag } = useEditTag()

  const navigate = useHistory()

  useEffect(() => {
    if (!tag) return

    setChosenMunicipalities(new Set(tag.municipalities.map((item) => item.id)))
  }, [tag])

  interface RecordType {
    key: string
    title: string
    description: string
    chosen: boolean
  }

  const filterOption = (inputValue: string, option: RecordType) => {
    return option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  }

  const handleChange = async (newTargetKeys: string[]) => {
    try {
      await addMunicipalitiesToTag({ tagId: id, ids: newTargetKeys })
      await refetchTag()
      notification.success({
        message: 'Kommuner uppdaterade',
        description: 'Kommunerna har uppdaterats',
      })
    } catch (e) {
      notification.error({
        message: 'Kommuner kunde inte uppdateras',
        description: 'Kommunerna kunde inte uppdateras',
      })
    }
  }

  const deleteTag = async (id: string) => {
    try {
      await apiClient.deleteTag(localStorage.getItem('tenant_id') ?? '', id)
      navigate.push('/app/taggar')
      notification.success({
        message: 'Tagg raderad',
        description: 'Taggen har raderats',
      })
    } catch (e) {
      notification.error({
        message: 'Tagg kunde inte raderas',
        description: 'Taggen kunde inte raderas',
      })
    }
  }

  const onFinish = async (values: { name: string }) => {
    try {
      await editTag({ id, name: values.name })
      await refetchTag()
      notification.success({
        message: 'Tagg uppdaterad',
        description: 'Taggen har uppdaterats',
      })
    } catch (e) {
      notification.error({
        message: 'Tagg kunde inte uppdateras',
        description: 'Taggen kunde inte uppdateras',
      })
    }
  }

  return (
    <>
      <FlagListHeader
        title=""
        heading="Redigera Tagg"
        description="Redigera en tagg genom att ändra informationen i fälten nedan, klicka sedan på spara. För att bestämma vilka kommuner som ingår i gruppen, flytta dem till den högra listan med hjälp av pilarna."
        button={false}
      />
      <div className="grid grid-cols-[600px,1px,1fr] gap-6">
        {isTagLoading && <Spin />}
        {!isTagLoading && tag && (
          <Form
            name="tag"
            initialValues={{
              name: tag.name,
            }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item label="Namn" name="name" rules={[{ required: true, message: 'Vänligen fyll i hela namnet' }]}>
              <Input type={'text'} placeholder="Namn" />
            </Form.Item>
            <Form.Item>
              <div style={{ display: 'flex', gap: '6px' }}>
                <BackButton display="inline" />
                <Button type="primary" htmlType="submit" style={{ display: 'inline-block', marginTop: '20px' }}>
                  Spara
                </Button>
                <Popconfirm title="Säker att du vill radera denna tagg?" okText="Bekräfta" cancelText="Avbryt" onConfirm={() => deleteTag(id)}>
                  <Button type="primary" danger style={{ display: 'inline-block', marginTop: '20px' }}>
                    Radera
                  </Button>
                </Popconfirm>
              </div>
            </Form.Item>
          </Form>
        )}
        <Divider type="vertical" style={{ height: 'max(100%, 500px)' }} />

        {isMunicipalitiesLoading || isTagLoading || isAddMunicipalitiesLoading ? (
          <Spin />
        ) : (
          municipalities &&
          tag && (
            <Transfer
              dataSource={municipalities.map((item) => ({
                key: item.id,
                title: item.name,
                description: item.name,
                chosen: chosenMunicipalities.has(item.id),
              }))}
              showSearch
              filterOption={filterOption}
              targetKeys={tag.municipalities.map((item) => item.id)}
              onChange={handleChange}
              render={(item) => item.title}
              listStyle={{
                width: 250,
                height: 500,
              }}
              titles={['Tillgängliga', 'Valda']}
            />
          )
        )}
      </div>
    </>
  )
}

export default SingleTag
