import { Select, Spin } from 'antd'
import React, { ComponentProps, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useMunicipalityName, useResidencyProportionAgeGroupValues } from '../../../../api/benchmarking'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { getFontSize } from '../../util/fontSize'
import { BASELINE_COLOR, MEDIAN_COLOR, QUARTILE_1_COLOR, QUARTILE_3_COLOR } from '../../util/colors'
import { BORDER_WIDTH } from '../../util/constants'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { GetResidencyProportionAgeGroupValuesVariantEnum } from '../../../../gen/api'
import { EmptySelector } from '../../components/EmptySelector'
import { NO_COMPARISON } from '../../util/names'

export const ResidencyProportionAgeGroup = ({ text }: { text: React.ReactNode }) => {
  const { data: municipalityName, isLoading: isMunicipalityNameLoading } = useMunicipalityName()
  const { data, isLoading, mutate } = useResidencyProportionAgeGroupValues()
  const [variation, setVariation] = useState<GetResidencyProportionAgeGroupValuesVariantEnum>(GetResidencyProportionAgeGroupValuesVariantEnum.Statdb)

  useEffect(() => {
    mutate(undefined)
  }, [])

  if (isLoading || isMunicipalityNameLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const graphOptions: ComponentProps<typeof Bar>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${Math.round(context.parsed.y)}%`
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
          callback: (value) => {
            return `${value}%`
          },
        },
      },
    },
  }

  const graphData: ComponentProps<typeof Bar>['data'] = {
    labels: data.baselineValues.map((item) => item.ageRange),
    datasets: [
      {
        label: municipalityName,
        backgroundColor: BASELINE_COLOR.fill,
        borderColor: BASELINE_COLOR.border,
        data: data.baselineValues.map((item) => item.value ?? 0),
        borderWidth: BORDER_WIDTH,
      },
      {
        label: 'Undre kvartil',
        backgroundColor: QUARTILE_1_COLOR.fill,
        borderColor: QUARTILE_1_COLOR.border,
        data: data.comparisonValues.map((item) => item.quartile1 ?? 0),
        borderWidth: BORDER_WIDTH,
      },
      {
        label: 'Median',
        backgroundColor: MEDIAN_COLOR.fill,
        borderColor: MEDIAN_COLOR.border,
        data: data.comparisonValues.map((item) => item.median ?? 0),
        borderWidth: BORDER_WIDTH,
      },
      {
        label: 'Övre kvartil',
        backgroundColor: QUARTILE_3_COLOR.fill,
        borderColor: QUARTILE_3_COLOR.border,
        data: data.comparisonValues.map((item) => item.quartile3 ?? 0),
        borderWidth: BORDER_WIDTH,
      },
    ],
  }

  const legend = (
    <BenchmarkingLegend
      items={[
        { text: municipalityName, borderColor: BASELINE_COLOR.border, fillColor: BASELINE_COLOR.fill },
        { text: 'Undre kvartil', borderColor: QUARTILE_1_COLOR.border, fillColor: QUARTILE_1_COLOR.fill },
        { text: 'Median', borderColor: MEDIAN_COLOR.border, fillColor: MEDIAN_COLOR.fill },
        { text: 'Övre kvartil', borderColor: QUARTILE_3_COLOR.border, fillColor: QUARTILE_3_COLOR.fill },
      ]}
    />
  )

  const dataSourceSelector = (
    <Select
      value={variation}
      onChange={(value) => {
        setVariation(value)
        mutate(value)
      }}
      options={[
        { label: 'Verksamhetssystem', value: GetResidencyProportionAgeGroupValuesVariantEnum.Statdb },
        { label: 'Offentliga data', value: GetResidencyProportionAgeGroupValuesVariantEnum.Socialstyrelsen },
      ]}
      style={{ width: '100%' }}
    />
  )

  const title = 'Andel i särskilt boende i respektive åldersgrupp'
  const graph = <Bar options={graphOptions} data={graphData} />

  const comparisonSelector = <EmptySelector value={NO_COMPARISON} />

  return <BenchmarkingContentRow graph={graph} text={text} title={title} legend={legend} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} />
}
