// Antd
import { Space, Table, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'

export interface IHandläggareFull {
  id: number
  namn: string
  email: string
  personnummer: string
  active: boolean
  in_structure: boolean
  scope: {
    team_namn: string
    team_id: number
  }[]
  team: {
    team_namn: string
    team_id: number
  }
  role: string
}

export const CreateUserTableConfig = (data: IHandläggareFull[], filters: any, navigate: any) => {
  const columns: ColumnsType<IHandläggareFull> = [
    {
      title: 'Namn',
      dataIndex: 'namn',
      key: 'namn',
      width: 250,
      fixed: 'left',
      filteredValue: filters.namn,
      showSorterTooltip: false,
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(new Set(data.map((d) => d.namn)))
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => a.namn.localeCompare(b.namn),
      render: (_, obj) => obj.namn,
      onFilter: (value, record) => record.namn === value,
      filterSearch: true,
    },
    {
      title: 'Roll',
      dataIndex: 'roll',
      key: 'roll',
      filteredValue: filters.roll,
      showSorterTooltip: { title: 'Användarens roll styr vilka vyer som hen har tillgång till' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(new Set(data.map((d) => d.role)))
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => a.role.localeCompare(b.role),
      render: (_, obj) => obj.role,
      onFilter: (value, record) => record.role === value,
    },
    {
      title: 'Team',
      dataIndex: 'team',
      key: 'team',
      filteredValue: filters.team,
      showSorterTooltip: { title: 'Vilket team som användaren tillhör' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(new Set(data.map((d) => d.team.team_namn)))
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => a.team.team_namn.localeCompare(b.team.team_namn),
      render: (_, obj) => obj.team.team_namn,
      onFilter: (value, record) => record.team.team_namn === value,
    },
    {
      title: (
        <Tooltip title="Styr vilka team och därmed vilka handläggare och ärenden som användaren har tillgång till">
          <span>Behörighet</span>
        </Tooltip>
      ),
      dataIndex: 'behörighet',
      key: 'behörighet',
      filteredValue: filters.behörighet,
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(new Set(data.map((d) => d.scope.map((t) => t.team_namn)).flat()))
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      render: (_, obj) => `${obj.scope.length} teams(s)`,
      onFilter: (value, record) => record.scope.some((t) => t.team_namn === value),
    },
    {
      title: 'I struktur',
      dataIndex: 'in_structure',
      key: 'in_structure',
      filteredValue: filters.in_structure,
      showSorterTooltip: { title: 'Styr om användarens ärenden syns i vyn Ärendefördelning' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(new Set(data.map((d) => (d.in_structure ? 'Ja' : 'Nej'))))
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => (a.in_structure ? 1 : -1),
      render: (_, record) => (record.in_structure ? 'Ja' : 'Nej'),
      onFilter: (value, record) => (record.in_structure ? 'Ja' : 'Nej') === value,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filteredValue: filters.status,
      showSorterTooltip: { title: 'Styr om användaren kan logga in i Pejlen eller inte' },
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(new Set(data.map((d) => (d.active ? 'Aktiv' : 'Inaktiv'))))
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => (a.active ? 1 : -1),
      render: (_, record) => (record.active ? 'Aktiv' : 'Inaktiv'),
      onFilter: (value, record) => (record.active ? 'Aktiv' : 'Inaktiv') === value,
    },
    Table.EXPAND_COLUMN,
    {
      dataIndex: 'hantera',
      render: (_, record) => (
        <Space size="middle">
          <button className="table-edit-link" onClick={() => navigate.push(`/app/anvandare/${record.id}`)}>
            Redigera
          </button>
        </Space>
      ),
    },
  ]

  return columns
}
