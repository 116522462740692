import { round } from '../../Dashboard/graphConfig'

const canceledTableConfig = (data: any) => {
  const res: any[] = []
  const timeOfCanceledCases = data.timeOfCanceledCases || []
  const canceledCases = data.canceledCases || []
  const avgAgeCanceledCases = data.avgAgeCanceledCases || []

  var canceledAvgAge: {
    [key: string]: any
  } = { name: 'Genomsnittlig ålder' }
  var formattedAmountBrukareCanceledCases: {
    [key: string]: any
  } = { name: 'Antal brukare' }
  var formattedTimeOfCanceledCases: {
    [key: string]: any
  } = { name: 'Genomsnittlig beställd tid' }

  canceledCases.forEach((item: any, index: number) => {
    formattedAmountBrukareCanceledCases[index] = Math.round(item.amount)
    const utfordTidHours = timeOfCanceledCases[index] ? timeOfCanceledCases[index].hours : 0
    formattedTimeOfCanceledCases[index] = round(utfordTidHours, 1)
  })

  avgAgeCanceledCases.forEach((item: any, index: number) => {
    canceledAvgAge[index] = round(item.avgAge, 1)
  })

  res.push(formattedAmountBrukareCanceledCases)
  res.push(formattedTimeOfCanceledCases)
  res.push(canceledAvgAge)

  return res
}

export default canceledTableConfig
