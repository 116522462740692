import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, notification } from 'antd'
import React, { useState } from 'react'
import { Evaluation, archiveBeslutsstod, loadPersonsBeslutsstod } from '../../api/beslutsstod/beslutsstod'
import { useApi } from '../../api/useApi'
import { InfoCard } from './components/infoCard'
import { validatePersonnummer } from './utils/validatePersonnummer'

const api = useApi()

const SearchBeslutsstod = () => {
  const [prn, setPrn] = useState<string>('')
  const [processId, setProcessId] = useState<string>('')
  const [evaluations, setEvaluations] = useState<Evaluation[] | null>(null)
  const [notificationInstance, contextHolder] = notification.useNotification()
  const [previousParams, setPreviousParams] = useState<{ prn: string; processId: string } | null>(null)

  const onPrnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrn(event.target.value)
  }

  const onProcessIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProcessId(event.target.value)
  }

  const fetchData = async () => {
    if (!processId && !prn) {
      notificationInstance.error({
        message: 'Vänligen skriv en ett personnummer eller process id och försök igen',
      })
      return null
    }
    const validatedPrn = prn ? validatePersonnummer(prn) : ''

    if (validatedPrn === null) {
      notificationInstance.error({
        message: 'Personnumret är inte giltigt',
      })
      return null
    }

    try {
      const result = await loadPersonsBeslutsstod(api, processId, validatedPrn)
      setEvaluations(result)
      setPreviousParams({ prn: validatedPrn, processId })
    } catch (error) {
      notificationInstance.error({
        message: 'Något gick fel vid hämtning av beslutsstöd',
      })
    }
  }

  const archive = async (id: number) => {
    try {
      await archiveBeslutsstod(api, id)
      if (!previousParams) return
      notificationInstance.success({
        message: 'Beslutsstöd makulerat',
      })
      const result = await loadPersonsBeslutsstod(api, previousParams.processId, previousParams.prn)
      setEvaluations(result)
    } catch (error) {
      notificationInstance.error({
        message: 'Något gick fel vid makulering av beslutsstöd',
      })
    }
  }

  return (
    <>
      {contextHolder}
      <div style={{ paddingBottom: '20px', display: 'flex' }}>
        <Input placeholder="Personnummer" value={prn} onChange={onPrnChange} style={{ marginRight: '20px', width: '200px' }} />
        <Input placeholder="Process id" type="number" value={processId} onChange={onProcessIdChange} style={{ marginRight: '20px', width: '200px' }} />
        <Button type="primary" onClick={fetchData} icon={<SearchOutlined />} style={{ display: 'flex', alignItems: 'center' }}>
          Sök
        </Button>
      </div>
      <div>
        {evaluations && evaluations.length === 0 && <div>Inga träffar</div>}
        {evaluations &&
          evaluations.map((obj, index) => (
            <InfoCard
              archive={async () => {
                archive(obj.id)
              }}
              key={index}
              evaluation={obj}
            />
          ))}
      </div>
    </>
  )
}

export default SearchBeslutsstod
