import { CalculatorOutlined, SettingOutlined } from '@ant-design/icons'
import { Switch } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useMe } from '../../../api/permissions'
import { useApi } from '../../../api/useApi'
import FlagListHeader from '../../../components/header'
import IBICBeslutsstodCalculationView from './calculation/IBICBeslutsstodCalculationView'
import IBICBeslutsstodEditView from './config/IBICBeslutsstodEditView'
import { useDispatch } from 'react-redux'
import { useIBICMunicipalityData } from '../../../api/beslutsstod/ibic/useIBICMunicipalityData'
import { setMunicipality } from '../../../store/beslutsstod/municipalitySlice'
import { Description } from '../../../api/beslutsstod/beslutsstod'

type Props = {}

const IBICBeslutsstod: React.FC<Props> = (props) => {
  const [editMode, setEditMode] = useState(false)
  const { data: me } = useMe()
  const { data: savedMunicipalityData } = useIBICMunicipalityData()
  const [descriptions, setDescriptions] = useState<Description[]>([])

  const dispatch = useDispatch()

  const headingText = useMemo(() => {
    if (!editMode) {
      return 'Beräkningsstöd för tid i hemtjänsten'
    }
    return 'Beräkningsstöd för tid i hemtjänsten'
  }, [editMode])

  useEffect(() => {
    if (savedMunicipalityData) {
      let editableData = JSON.parse(JSON.stringify(savedMunicipalityData.config))
      setDescriptions(savedMunicipalityData.descriptions)
      dispatch(setMunicipality({ municipalityData: editableData }))
    }
  }, [savedMunicipalityData])

  return (
    <>
      <FlagListHeader title="" heading={headingText} description={null} button={false} />
      <div className="flex flex-col gap-2">
        {me?.permissions.includes('edit_ibic_beslutsstöd') && (
          <div className="flex gap-2">
            {editMode ? 'Redigera' : 'Beräkna'}
            <Switch
              checkedChildren={<SettingOutlined />}
              unCheckedChildren={<CalculatorOutlined />}
              checked={editMode}
              onChange={setEditMode}
              title={editMode ? 'Ändra till beräkna' : 'Ändra till redigera'}
            />
          </div>
        )}

        {editMode ? <IBICBeslutsstodEditView /> : <IBICBeslutsstodCalculationView />}
      </div>
    </>
  )
}

export default IBICBeslutsstod
