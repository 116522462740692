import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './store'

interface personalFilterSlice {
  data: any
}

const initialState = {} as personalFilterSlice

const personalFilterSlice = createSlice({
  name: 'personalFilter',
  initialState,
  reducers: {
    setPersonalFilter(state, action: PayloadAction<personalFilterSlice>) {
      return action.payload
    },
  },
})

export const getPersonalFilter = (state: AppState) => state.personalFilterReducer.data
export const { setPersonalFilter } = personalFilterSlice.actions
export default personalFilterSlice.reducer
