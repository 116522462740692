import { IBICResolverResult, ICIBResolutionContext } from '../types'

// Can be shared with any other task
export const resolveIBICAllRule = ({ previousResult, task, allTasks, tasksPerDay }: ICIBResolutionContext): IBICResolverResult => {
  const firstThatCanBeSharedWith = previousResult.findIndex((timepoint) => {
    return !timepoint.tasks.some((t) => t.name === task.name)
    // &&  timepoint.tasks.length < tasksPerDay
  })

  return firstThatCanBeSharedWith !== -1 ? firstThatCanBeSharedWith : null
}
