import { CalculationOutputUnit } from '../types/municipalityData'

export const createTimeString = (hours: number, unit: CalculationOutputUnit) => {
  if (unit === 'decimal') return `${Math.round(hours * 100) / 100}h`

  const totalMinutes = Math.round(hours * 60)
  const floorHours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  const totalMinutesString = `(${totalMinutes}min)`
  return `${floorHours}h ${minutes}min ${totalMinutesString}`
}
