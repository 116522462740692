import addThousandSeparator from '../../utils/thousandSeperator'

const nattHjalpTableConfig = (data: any) => {
  const res: any[] = []

  const nyaBrukare = data.beviljadeLarmNattInsatser || []
  const larmuttryckningar = data.utfordaNattinsatser

  var formattedNyaBrukare: {
    [key: string]: any
  } = { name: 'Antal brukare med natthjälp' }
  var formattedLarmUtryckningar: {
    [key: string]: any
  } = { name: 'Antal utförda nattinsatser' }

  nyaBrukare.forEach((item: any, index: number) => {
    formattedNyaBrukare[index] = addThousandSeparator(Math.round(item.amount))
  })

  larmuttryckningar.forEach((item: any, index: number) => {
    formattedLarmUtryckningar[index] = addThousandSeparator(Math.round(item.amount))
  })

  res.push(formattedNyaBrukare)
  res.push(formattedLarmUtryckningar)

  return res
}

export default nattHjalpTableConfig
