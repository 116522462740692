import { Checkbox, Input, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getMunicipality, setMunicipality } from '../../../store/beslutsstod/municipalitySlice'
import { FormSettings } from '../types/municipalityData'

const { Paragraph } = Typography

const EditableFormSettings = () => {
  const dispatch = useDispatch()
  const municipalityData = useSelector(getMunicipality)

  if (!municipalityData) return null
  let editableData = JSON.parse(JSON.stringify(municipalityData))

  const updateFormSettings = (formSettings: FormSettings) => {
    editableData.input.formSettings = formSettings
    dispatch(setMunicipality({ municipalityData: editableData }))
  }

  return (
    <>
      <div
        style={{
          marginBlock: '30px',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '10px',
        }}
      >
        <div>
          <Paragraph type="secondary">Namn på uteblivna veckor</Paragraph>
          <Input onChange={(e) => updateFormSettings({ ...municipalityData.input.formSettings, nameWeeksOff: e.target.value })} defaultValue={municipalityData.input.formSettings.nameWeeksOff} />
        </div>
        <div>
          <Paragraph type="secondary">Beskrivning för uteblivna veckor</Paragraph>
          <Input
            onChange={(e) => updateFormSettings({ ...municipalityData.input.formSettings, descriptionWeeksOff: e.target.value })}
            defaultValue={municipalityData.input.formSettings.descriptionWeeksOff}
          />
        </div>
        <div>
          <Paragraph type="secondary">Namn på delad vård</Paragraph>
          <Input onChange={(e) => updateFormSettings({ ...municipalityData.input.formSettings, nameShared: e.target.value })} defaultValue={municipalityData.input.formSettings.nameShared} />
        </div>
        <div>
          <Paragraph type="secondary">Beskrivning för delad vård</Paragraph>
          <Input
            onChange={(e) => updateFormSettings({ ...municipalityData.input.formSettings, descriptionShared: e.target.value })}
            defaultValue={municipalityData.input.formSettings.descriptionShared}
          />
        </div>
      </div>
      <div
        style={{
          marginBlock: '30px',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gap: '10px',
        }}
      >
        <div>
          <Paragraph type="secondary">Visa uteblivna veckor</Paragraph>
          <Checkbox
            onChange={(e) => updateFormSettings({ ...municipalityData.input.formSettings, showWeeksOff: e.target.checked })}
            defaultChecked={municipalityData.input.formSettings.showWeeksOff}
          />
        </div>
        <div>
          <Paragraph type="secondary">Visa delad vård</Paragraph>
          <Checkbox onChange={(e) => updateFormSettings({ ...municipalityData.input.formSettings, showShared: e.target.checked })} defaultChecked={municipalityData.input.formSettings.showShared} />
        </div>
        <div>
          <Paragraph type="secondary">Visa beskrivningar</Paragraph>
          <Checkbox
            onChange={(e) => updateFormSettings({ ...municipalityData.input.formSettings, showDescription: e.target.checked })}
            defaultChecked={municipalityData.input.formSettings.showDescription}
          />
        </div>
      </div>
    </>
  )
}

export default EditableFormSettings
