import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './store'
import { RootStateOrAny } from 'react-redux'
import { DataType } from '../components/tablesConfig/filterconfig'

export interface IMedarbetareFlag {
  key: number
  flagga: number
  flaggnamn: string
  id: number
  namn: string
  created: string
  från: string
  till: string
  nivå: number
}

export interface IBrukareFlag {
  key: number
  flagga: number
  flaggnamn: string
  id: number
  namn: string
  personnummer: string
  created: string
  från: string
  till: string
  nivå: number
}

export interface IUtförare {
  brukare_count: number
  namn: string
  brukare: IBrukareFlag[]
  medarbetare: IMedarbetareFlag[]
}

const initialState = [
  {
    brukare_count: 0,
    namn: '',
    brukare: [
      {
        key: 0,
        flagga: 0,
        flaggnamn: '',
        id: 0,
        namn: '',
        personnummer: '',
        created: '',
        från: '',
        till: '',
        nivå: 0,
      },
    ],
    medarbetare: [
      {
        key: 0,
        flagga: 0,
        flaggnamn: '',
        id: 0,
        namn: '',
        created: '',
        från: '',
        till: '',
        nivå: 0,
      },
    ],
  },
] as IUtförare[]

const utförareSlice = createSlice({
  name: 'utförare',
  initialState,
  reducers: {
    setUtförare(state, action: PayloadAction<IUtförare[]>) {
      return action.payload
    },
  },
})

export const getUtförare = (state: RootStateOrAny) => state.utförareReducer
export const selectUtförareByName = (state: RootStateOrAny, name: string) => state.utförareReducer.filter((item: IUtförare) => item.namn === name)
export const { setUtförare } = utförareSlice.actions
export default utförareSlice.reducer
