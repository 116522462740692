import { ContentColumn } from '../..'
import { CostQualityMix } from '../Volume/CostQualityMix'
import { SäboPopulationProportion } from '../Volume/ResidencyPopulationProportion'
import { NursingDementiaProportion } from './NursingDementiaProportion'
import { ResidencyCapacityAgeGroup } from './ResidencyCapacityAgeGroup'
import { ResidencyCapacityAgeGroupTrend } from './ResidencyCapacityAgeGroupTrend'
import { ResidencyProportionAgeGroup } from './ResidencyProportionAgeGroup'

export const benchmarkingSäbo: ContentColumn[] = [
  {
    onlyRenderIfBenchmark: 'population',
    content: (
      <SäboPopulationProportion
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Andel i särskilt boende för kommunen och jämförelsegrupper',
  },
  {
    onlyRenderIfBenchmark: 'residencyProportionAgeGroup',
    content: (
      <ResidencyProportionAgeGroup
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Andel i särskilt boende i respektive åldersgrupp',
  },
  {
    onlyRenderIfBenchmark: 'residencyCapacityAgeGroup',
    content: (
      <ResidencyCapacityAgeGroup
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Andel boende per åldersgrupp',
  },

  {
    onlyRenderIfBenchmark: 'residencyCapacityAgeGroupTrend',
    content: (
      <ResidencyCapacityAgeGroupTrend
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Andel boende per åldersgrupp, trend',
  },

  {
    onlyRenderIfBenchmark: 'nursingDementiaProportion',
    content: (
      <NursingDementiaProportion
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Fördelning mellan boende för omvårdnad respektive demens',
  },
  {
    onlyRenderIfBenchmark: 'costQualityMix',
    content: (
      <CostQualityMix
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Kvalitet och kostnader',
  },
]
