import { Table } from 'antd'
import tableHeaderYm from '../utils/TableConfig/tableHeaderYm'
import brukareFilter from './utils/brukareFilter'
import canceledTableConfig from './utils/canceledTableConfig'

const BrukareUnderlag = (data: any) => {
  const newCasesColumns = tableHeaderYm(data.canceledCases, 'Antal helt nya ärenden')
  const canceledCasesColumns = tableHeaderYm(data.canceledCases, 'Antal avslutade ärenden')
  const dataSource = brukareFilter(data)
  const canceledData = canceledTableConfig(data)

  return (
    <>
      <Table columns={newCasesColumns} dataSource={dataSource} pagination={false} scroll={{ x: 0 }} size="small" />

      <Table columns={canceledCasesColumns} dataSource={canceledData} pagination={false} scroll={{ x: 0 }} size="small" />
    </>
  )
}

export default BrukareUnderlag
