import * as actions from './actions'
import { Actions } from './actions'
import { DataType } from '../../../components/tablesConfig/filterconfig'

export type State = {
  data: DataType[]
  loading: boolean
}

const initialState: State = {
  data: [],
  loading: true,
}

const brukareReducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case actions.LOADING_BRUKARE:
      return (state = {
        ...state,
        loading: action.payload.loading,
      })
    case actions.SET_BRUKARE:
      return (state = {
        ...state,
        data: action.payload.data,
      })
    default:
      return state
  }
}

export default brukareReducer
