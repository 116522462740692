import React, { useState } from 'react'
import FlagListHeader from '../header'
import { useDispatch, useSelector } from 'react-redux'
import { selectFlags } from '../singleDeviation/store/selectors'
import { createSingleTableConfig } from '../tablesConfig/singleTableConfig'
import { setCaseAction } from '../../features/arende/store/caseReducer/actions'
import { useHistory } from 'react-router-dom'
import BackButton from '../buttons/backButton'

// Antd
import { Button, Divider, Table, Typography } from 'antd'
import { DataType } from '../tablesConfig/filterconfig'
const { Title } = Typography

export interface flagData {
  key: React.Key
  id: number
  flagga: number
  flaggnamn: string
  från: Date
  till: Date
  nivå: number
  created: Date
}

export interface IFlags {
  namn: string | undefined
  flaggor: DataType[]
}

interface singleObjInput {
  obj: { namn: string }
  heading?: string
}

const SingleObject: React.FC<singleObjInput> = ({ obj, heading }) => {
  const dispatch = useDispatch()
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
  const navigate = useHistory()

  // Redux states
  const flagsState = useSelector(selectFlags)

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      selectedRows.length > 0 ? setBtnDisabled(false) : setBtnDisabled(true)
      setCaseAction('SET_CASE', selectedRows, null, dispatch)
    },
  }

  return (
    <>
      {obj && <FlagListHeader heading={`Individuell översikt - ${obj.namn}`} title="" description={null} button={false} />}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title level={4}>Avvikelser</Title>
        <Button type="primary" onClick={() => navigate.push('/app/nytt-arende')} disabled={btnDisabled}>
          Initiera uppföljning
        </Button>
      </div>
      <Table
        columns={createSingleTableConfig(flagsState, 'medarbetare')}
        rowSelection={{ ...rowSelection }}
        dataSource={flagsState || []}
        rowClassName="table-row-cursor"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate.push({
                pathname: `/app/avvikelse/flag=${record.flagga}&id=${record.key}`,
                state: { flagname: record.flaggnamn, riskniva: record.nivå },
              })
            },
          }
        }}
      />
      <Divider />
      <BackButton />
    </>
  )
}
export default SingleObject
