import { Spin } from 'antd'
import { ComponentProps } from 'react'
import { Bar } from 'react-chartjs-2'
import { useMedianNursingDementiaStayLengthValues, useMunicipalityName } from '../../../../api/benchmarking'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { getFontSize } from '../../util/fontSize'
import { BASELINE_COLOR, MEDIAN_COLOR, QUARTILE_1_COLOR, QUARTILE_3_COLOR } from '../../util/colors'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { BORDER_WIDTH } from '../../util/constants'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { EmptySelector } from '../../components/EmptySelector'
import { STATDB_NAME } from '../../util/names'

export const MedianNursingDementiaTotalStayLength = ({ text }: { text: React.ReactNode }) => {
  const { data: municipalityName, isLoading: isMunicipalityNameLoading } = useMunicipalityName()
  const { data, isLoading } = useMedianNursingDementiaStayLengthValues()

  if (isLoading || isMunicipalityNameLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const options: ComponentProps<typeof Bar>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            return `Median, ${context[0].label}`
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
    },
  }

  const graphData: ComponentProps<typeof Bar>['data'] = {
    labels: ['Alla', 'Omvårdnad', 'Demens'],
    datasets: [
      {
        label: municipalityName,
        data: [data.baselineValues.total, data.baselineValues.nursing, data.baselineValues.dementia],
        borderColor: BASELINE_COLOR.border,
        backgroundColor: BASELINE_COLOR.fill,
        borderWidth: BORDER_WIDTH,
      },
      {
        label: 'Undre kvartil',
        data: [data.comparisonValues.quartile1.total, data.comparisonValues.quartile1.nursing, data.comparisonValues.quartile1.dementia],
        borderColor: QUARTILE_1_COLOR.border,
        backgroundColor: QUARTILE_1_COLOR.fill,
        borderWidth: BORDER_WIDTH,
      },
      {
        label: 'Median',
        data: [data.comparisonValues.median.total, data.comparisonValues.median.nursing, data.comparisonValues.median.dementia],
        borderColor: MEDIAN_COLOR.border,
        backgroundColor: MEDIAN_COLOR.fill,
        borderWidth: BORDER_WIDTH,
      },
      {
        label: 'Övre kvartil',
        data: [data.comparisonValues.quartile3.total, data.comparisonValues.quartile3.nursing, data.comparisonValues.quartile3.dementia],
        borderColor: QUARTILE_3_COLOR.border,
        backgroundColor: QUARTILE_3_COLOR.fill,
        borderWidth: BORDER_WIDTH,
      },
    ],
  }

  const legend = (
    <BenchmarkingLegend
      items={[
        { text: municipalityName, borderColor: BASELINE_COLOR.border, fillColor: BASELINE_COLOR.fill },
        { text: 'Undre kvartil', borderColor: QUARTILE_1_COLOR.border, fillColor: QUARTILE_1_COLOR.fill },
        { text: 'Median', borderColor: MEDIAN_COLOR.border, fillColor: MEDIAN_COLOR.fill },
        { text: 'Övre kvartil', borderColor: QUARTILE_3_COLOR.border, fillColor: QUARTILE_3_COLOR.fill },
      ]}
    />
  )

  const title = 'Median vistelsetid på särskilt boende per inriktning, avslutade beslut'
  const graph: React.ReactNode = <Bar options={options} data={graphData} />

  const dataSourceSelector = <EmptySelector value={STATDB_NAME} />
  const comparisonSelector = <EmptySelector value={'Fördelning'} />
  return <BenchmarkingContentRow graph={graph} text={text} title={title} legend={legend} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} />
}
