import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// Antd
import { Divider, Spin, Table } from 'antd'
import { setVolymuppföljningTab } from '../../store/tabSlice'
import { setAnsvarigPerson } from '../../../../store/ansvarigPersonSlice'
import { createArendeFordelningTableConfig, createTotalArendeFordelningTableConfig, findHandlaggareByName } from './tableConfig'
import { useEffect, useRef, useState } from 'react'
import { FilterValue } from 'antd/lib/table/interface'
import { getHandläggare } from '../../../../store/organizationSlice'
import { fecthDistribution } from '../../queries'
import { useQuery } from '@tanstack/react-query'
import { setActiveMenuItem } from '../../../../store/sideMenuSlice'

export interface IArendeFordelningTableEntry {
  key: React.Key
  id: number
  handläggare: { id: number; namn: string }
  antalHemtjänst: number
  antalSäbo: number
  beviljadeTimmar: number
  vårdtyngd: number
}

type Props = {}

const ArendeFordelningTable = (props: Props) => {
  const navigate = useHistory()
  const dispatch = useDispatch()
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({})
  const [dataSource, setDataSource] = useState<any>([])
  const anvandareState = useSelector(getHandläggare)
  const table1Ref = useRef<HTMLDivElement | null>(null)
  const table2Ref = useRef<HTMLDivElement | null>(null)

  const { isLoading, isError, data, error } = useQuery(['mycases'], fecthDistribution)

  if (!isLoading && isError) console.error(error)

  function updateDataSource(arenden: any) {
    const totalHemtjanst: number = arenden
      ?.filter((d: { ansvarig_namn: string; antal_hemtjänst: number }) => {
        if (filters && filters.team && filters.handlaggare) {
          return filters.team.includes(findHandlaggareByName(d.ansvarig_namn, anvandareState)?.team?.team_namn) && filters.handlaggare.includes(d.ansvarig_namn)
        } else if (filters && filters.team && !filters.handlaggare) {
          return filters.team.includes(findHandlaggareByName(d.ansvarig_namn, anvandareState)?.team?.team_namn)
        } else if (filters && filters.handlaggare && !filters.team) {
          return filters.handlaggare.includes(d.ansvarig_namn)
        } else {
          return true
        }
      })
      .reduce((sum: number, d: { antal_hemtjänst: number }) => sum + d.antal_hemtjänst, 0)

    const totalBeviljade = arenden
      ?.filter((d: any) => {
        if (filters && filters.team && filters.handlaggare) {
          return filters.team.includes(findHandlaggareByName(d.ansvarig_namn, anvandareState)?.team?.team_namn) && filters.handlaggare.includes(d.ansvarig_namn)
        }
        if (filters && filters.team && !filters.handlaggare) return filters.team.includes(findHandlaggareByName(d.ansvarig_namn, anvandareState)?.team?.team_namn)
        if (filters && filters.handlaggare && !filters.team) return filters.handlaggare.includes(d.ansvarig_namn)
        return true
      })
      .reduce((sum: number, d: any) => sum + d.beviljade_timmar, 0)

    const totalSäbo = arenden
      ?.filter((d: any) => {
        if (filters && filters.team && filters.handlaggare) {
          return filters.team.includes(findHandlaggareByName(d.ansvarig_namn, anvandareState)?.team?.team_namn) && filters.handlaggare.includes(d.ansvarig_namn)
        }
        if (filters && filters.team && !filters.handlaggare) return filters.team.includes(findHandlaggareByName(d.ansvarig_namn, anvandareState)?.team?.team_namn)
        if (filters && filters.handlaggare && !filters.team) return filters.handlaggare.includes(d.ansvarig_namn)
        return true
      })
      .reduce((sum: number, d: any) => sum + d.antal_säbo, 0)

    const totalRow = {
      title: '',
      ansvarig_namn: 'Total',
      ansvarig_personnummer: '',
      antal_hemtjänst: totalHemtjanst,
      antal_säbo: totalSäbo,
      beviljade_timmar: Math.round(totalBeviljade),
      vårdtyngd: Math.round((totalBeviljade / totalHemtjanst) * 10) / 10,
    }

    return [totalRow]
  }

  useEffect(() => {
    if (data) {
      setDataSource(updateDataSource(data))
    }
  }, [data, anvandareState])

  useEffect(() => {
    setDataSource(updateDataSource(data))
  }, [filters])

  return (
    <Spin spinning={isLoading}>
      <div style={{ overflowX: 'auto' }}>
        <div ref={table1Ref} style={{ overflow: 'hidden', minWidth: '1000px' }}>
          <Table
            size="small"
            columns={createArendeFordelningTableConfig(data?.sort((a, b) => (a.ansvarig_namn < b.ansvarig_namn ? -1 : 1)) || [], filters)}
            dataSource={data}
            rowClassName="table-row-cursor"
            loading={false}
            bordered
            scroll={{ x: undefined, y: 'calc(100vh - 300px)' }}
            pagination={false}
            onChange={(pagination, filters, sorter, { currentDataSource }) => {
              Object.keys(filters).map((key) => {
                if (filters[key]?.includes('Välj alla')) {
                  if (key === 'handlaggare') {
                    filters[key] = Array.from(
                      new Set(
                        (data || []).map((d) => {
                          return d.ansvarig_namn
                        })
                      )
                    )
                  } else if (key === 'team') {
                    filters[key] = Array.from(
                      new Set(
                        (data || []).map((d) => {
                          if (findHandlaggareByName(d.ansvarig_namn, anvandareState) !== null) {
                            return d.ansvarig_namn === '' ? null : findHandlaggareByName(d.ansvarig_namn, anvandareState).team.team_namn
                          }
                          return ''
                        })
                      )
                    )
                  } else if (key === 'hemtjänst') {
                    filters[key] = Array.from(
                      new Set(
                        (data || []).map((d) => {
                          return d.antal_hemtjänst
                        })
                      )
                    )
                  } else if (key === 'säbo') {
                    filters[key] = Array.from(
                      new Set(
                        (data || []).map((d) => {
                          return d.antal_säbo
                        })
                      )
                    )
                  } else if (key === 'beviljade') {
                    filters[key] = Array.from(
                      new Set(
                        (data || []).map((d) => {
                          return d.beviljade_timmar
                        })
                      )
                    )
                  } else if (key === 'vårdtyngd') {
                    filters[key] = Array.from(
                      new Set(
                        (data || []).map((d) => {
                          return d.vårdtyngd
                        })
                      )
                    )
                  }
                }
              })
              setFilters(filters)
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: async (event) => {
                  dispatch(setAnsvarigPerson({ ansvarigPersonId: record.ansvarig_personnummer, ansvarigPersonName: record.ansvarig_namn }))
                  dispatch(setActiveMenuItem('/app/mina-arenden'))
                },
              }
            }}
          />
        </div>
        <Divider style={{ margin: '0px' }} />
        <div ref={table2Ref} style={{ overflow: 'hidden', minWidth: '1000px' }}>
          <Table
            size="small"
            bordered
            columns={createTotalArendeFordelningTableConfig(data || [])}
            dataSource={dataSource}
            rowClassName="table-row-cursor"
            loading={false}
            scroll={{ x: undefined, y: 'calc(100vh - 300px)' }}
            pagination={false}
            showHeader={false}
          />
        </div>
      </div>
    </Spin>
  )
}

export default ArendeFordelningTable
