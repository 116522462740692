import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js'
import moment from 'moment'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)

interface TimeData {
  hours: number
  month: string
}

interface ChartData {
  fill: boolean
  label: string
  data: number[]
  borderColor: string
  backgroundColor: string
}

const labels: string[] = []

export function round(value: number, precision: number) {
  var multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export function formatDate(dateString: string) {
  const date = new Date(dateString)
  const monthsInSwedish = ['Jan', 'Feb', 'Mars', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
  const monthIndex = date.getMonth()
  const monthInSwedish = monthsInSwedish[monthIndex]
  const year = date.getFullYear().toString().substring(2)
  return `${monthInSwedish}-${year}`
}

export const antalBrukareBevlijadData = (data: any[]) => {
  return {
    labels: data.map((item: any) => formatDate(moment(item[Object.keys(item)[1]]).locale('sv').format('ll'))),
    datasets: [
      {
        fill: true,
        label: 'Antal brukare med beställd tid',
        data: data.map((item: any) => item[Object.keys(item)[0]]),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }
}

export const antalNyaArandendData = (data: any[]) => {
  return {
    labels: data.map((item: any) => formatDate(moment(item[Object.keys(item)[1]]).locale('sv').format('ll'))),
    datasets: [
      {
        fill: true,
        label: 'Antal helt nya ärenden',
        data: data.map((item: any) => Math.round(item[Object.keys(item)[0]])),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }
}

export const antalCanceledCasesData = (data: any[]) => {
  return {
    labels: data.map((item: any) => formatDate(moment(item[Object.keys(item)[1]]).locale('sv').format('ll'))),
    datasets: [
      {
        fill: true,
        label: 'Antal avslutade ärenden',
        data: data.map((item: any) => Math.round(item[Object.keys(item)[0]])),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }
}

export const beviljadTidPerBrukareData = (beviljadTid: any[], antalbrukare: any[]) => {
  let data: number[] = []
  if (beviljadTid.length === antalbrukare.length) {
    data = beviljadTid.map((t, i) => round(antalbrukare[i].hours / t[Object.keys(t)[0]], 1))
  }
  return {
    labels: beviljadTid.map((item: any) => formatDate(moment(item[Object.keys(item)[1]]).locale('sv').format('ll'))),
    datasets: [
      {
        fill: true,
        label: 'Beställd tid per brukare',
        data: data,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }
}

export const utfordTidData = (utfordTid: any[], bestalldTid: any[]) => {
  let data: any = []
  bestalldTid = bestalldTid.slice(0, utfordTid.length)
  if (utfordTid.length === bestalldTid.length) {
    data = calcUtfordTidData(utfordTid, bestalldTid, 2)
  }
  return {
    labels: utfordTid.map((item: any) => formatDate(moment(item.month).locale('sv').format('ll'))),
    datasets: [
      {
        fill: true,
        label: 'Utförandegrad (Utförd tid/Beställd tid)',
        data: data,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 100,
                callback: function (value: number) {
                  return value + '%'
                },
              },
              scaleLabel: {
                display: true,
                labelString: 'Percentage',
              },
            },
          ],
        },
      },
    ],
  }
}

export const BeviljadTidData = (data: any) => {
  return {
    labels: data.map((item: any) => formatDate(moment(item.month).locale('sv').format('ll'))),
    datasets: [
      {
        fill: true,
        label: 'Beställd tid',
        data: data.map((item: any) => Math.round(item.hours)),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }
}

export const omfattandeKaraktarData = (data: any) => {
  return {
    labels: data.map((item: any) => formatDate(moment(item.month).locale('sv').format('ll'))),
    datasets: [
      {
        fill: true,
        label: 'Antal ärenden av omfattande karaktär',
        data: data.map((item: any) => item.less_than_one_hundred_eighty + item.more_than_one_hundred_eighty),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }
}

export const nyaArandenData = {
  labels,
  datasets: [
    {
      fill: true,
      label: 'Antal nya ärenden',
      data: labels.map(() => Math.random() * (20 - 0) + 0),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
}

export const avslutadeArandenData = (data: any) => {
  return {
    labels: data.map((item: any) => formatDate(moment(item.month).locale('sv').format('ll'))),
    datasets: [
      {
        fill: true,
        label: 'Antal avslutande ärenden',
        data: labels.map(() => Math.random() * (20 - 0) + 0),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }
}

export const avgAgeData = (data: any) => {
  return {
    labels: data.map((item: any) => formatDate(moment(item.ym).locale('sv').format('ll'))),
    datasets: [
      {
        fill: true,
        label: 'Genomsnittlig ålder för brukare med beställd tid',
        data: data.map((item: any) => round(item.avgAge, 1)),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }
}

export const utforandegradData = {
  labels,
  datasets: [
    {
      fill: true,
      label: 'Utförandegrad (Utförd/bevlijad exkl. frånvarå)',
      data: null,
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
}

const calcUtfordTidData = (utfordTid: any[], beviljadTid: any[], decimal: number) => {
  return utfordTid.map((t, i) => round((t.hours / beviljadTid[i].hours) * 100, decimal))
}
