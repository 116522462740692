// Note: these use css hex colors with an alpha value, the last two characters
// are the alpha value in hexadecimal. 0x00 is fully transparent, 0xFF is fully
// opaque.
// Example: 80% transparent white: #FFFFFFCC
// Example: 50% transparent black: #00000080

export const BLUE = {
  fill: '#1E73A833',
  border: '#1E73A8FF',
} as const
export const GREEN = {
  fill: '#188F5033',
  border: '#188F50FF',
} as const
export const YELLOW = {
  fill: '#E1B30133',
  border: '#E1B301FF',
} as const
export const ORANGE = {
  fill: '#EE6B0333',
  border: '#EE6B03FF',
} as const
export const RED = {
  fill: '#FA081533',
  border: '#FA0815FF',
} as const
export const PURPLE = {
  fill: '#7C1CDA33',
  border: '#7C1CDAFF',
} as const
export const GRAY = {
  fill: '#384C6333',
  border: '#384C63FF',
} as const

export const CYAN = {
  fill: '#0B8B8533',
  border: '#0B8B85FF',
} as const

// Most graph colors will use a priority system, start with the first color and
// move down the list if more colors are needed.
export const COLORS = [BLUE, CYAN, GREEN, YELLOW, ORANGE, RED, PURPLE, GRAY] as const

export const getColorByIndex = (index: number) => {
  return COLORS[index % COLORS.length]
}

// In some situations, we want to use a specific color to represent a specific
// aggregation in a consistent way.

export const BASELINE_COLOR = BLUE
export const QUARTILE_1_COLOR = CYAN
export const MEDIAN_COLOR = GREEN
export const QUARTILE_3_COLOR = YELLOW

export const getComparisonColor = (aggregationVariant: string | undefined, aggregationQuantifier: number | undefined) => {
  if (aggregationVariant === 'median') return MEDIAN_COLOR
  if (aggregationVariant === 'percentile') {
    if (aggregationQuantifier === 0.25) return QUARTILE_1_COLOR
    if (aggregationQuantifier === 0.75) return QUARTILE_3_COLOR
  }
  return MEDIAN_COLOR
}

// Circle / Doughnut / Pie colors:
export const DOUGHNUT_COLORS = [BLUE, GREEN, YELLOW, ORANGE, RED] as const

export const getDoughnutColorByIndex = (index: number) => {
  return DOUGHNUT_COLORS[index % DOUGHNUT_COLORS.length]
}

// Line graphs have a different set of colors. The fill color will
// rarely be used since only one color is needed for the line.

export const LINE_COLOR_1 = {
  fill: '#4B658433',
  border: '#4B6584FF',
} as const
export const LINE_COLOR_2 = {
  fill: '#2D98DA33',
  border: '#2D98DAFF',
} as const
export const LINE_COLOR_3 = {
  fill: '#188F5033',
  border: '#188F50FF',
} as const
export const LINE_COLOR_4 = {
  fill: '#FED33033',
  border: '#FED33033',
} as const

export const LINE_COLORS = [LINE_COLOR_1, LINE_COLOR_2, LINE_COLOR_3, LINE_COLOR_4] as const

export const getLineColorByIndex = (index: number) => {
  return LINE_COLORS[index % LINE_COLORS.length]
}

export const LINE_BASELINE_COLOR = LINE_COLOR_1
export const LINE_QUARTILE_1_COLOR = LINE_COLOR_2
export const LINE_MEDIAN_COLOR = LINE_COLOR_3
export const LINE_QUARTILE_3_COLOR = LINE_COLOR_4

// Scatterplots only need one color for the municipality value and
// one color for the comparison value.

export const SCATTERPLOT_BASELINE_COLOR = {
  fill: '#20BF6BCC',
  border: '#20BF6BCC',
} as const
export const SCATTERPLOT_COMPARISON_COLOR = {
  fill: '#384C6333',
  border: '#384C6333',
} as const
