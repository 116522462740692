import { ContentColumn } from '../..'
import { CapacityInflowOutflowTrend } from './CapacityInflowOutflowTrend'
import { InflowAgeGroup } from './InflowAgeGroup'
import { InflowProportionAgeGroupTrend } from './InflowProportionAgeGroupTrend'
import { NursingDementiaTotalTrend } from './NursingDementiaTotalTrend'

export const benchmarkingSäboBrukareCount: ContentColumn[] = [
  {
    onlyRenderIfBenchmark: 'nursingDementiaTotalStayLength',
    content: (
      <NursingDementiaTotalTrend
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Antal boende för omvårdnad respektive demens, trend',
  },
  {
    onlyRenderIfBenchmark: 'capacityInflowOutflowTrend',
    content: (
      <CapacityInflowOutflowTrend
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Utveckling av antal boende över tid samt nya resp. avslutade beslut',
  },
  {
    onlyRenderIfBenchmark: 'inflowAgeGroup',
    content: (
      <InflowAgeGroup
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Andel nya beslut per åldersgrupp',
  },
  {
    onlyRenderIfBenchmark: 'inflowProportionAgeGroupTrend',
    content: (
      <InflowProportionAgeGroupTrend
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Andel nya beslut per åldersgrupp, trend',
  },
]
