import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Antd
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { FlagFilled } from '@ant-design/icons'
import { IUtförare } from '../../store/utförareSlice'

export const getColor = (risk: number) => {
  switch (risk) {
    case 1:
      return 'yellow'
    case 2:
      return 'orange'
    case 3:
      return 'red'
    case 4:
      return 'black'
    default:
      return 'green'
  }
}

export interface DataType {
  id?: number
  key: React.Key
  flagga: number
  flaggnamn: string
  type?: number
  nivå: number
  namn: string
  personnummer?: string
  från: Date
  till: Date
  created: Date
  utförare: {
    utförare_id: number
    utförare_namn: string
  }[]
}

export const CreateFilterConfig = (data: DataType[], path: string, deviation: string[], flagLevel: string[]) => {
  const columns: ColumnsType<DataType> = [
    {
      title: 'Namn',
      dataIndex: 'name',
      key: 'name',
      // filteredValue:
      //   (filterState.filter &&
      //     filterState.filter.name?.map((item: any) => item)) ||
      //   null,
      showSorterTooltip: { title: 'Sortera på namn' },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.namn
          })
        )
      )
        .map((name) => {
          return {
            value: name,
            text: name,
          }
        })
        .sort((a, b) => a.value.localeCompare(b.value)),
      render: (_, coworker) => {
        return path === 'brukare' ? (
          <Link
            to={{
              pathname: `/app/brukare/id=${coworker.personnummer}&namn=${coworker.namn}`,
            }}
          >
            {coworker.namn}
          </Link>
        ) : (
          <Link
            to={{
              pathname: `/app/medarbetare/${coworker.namn}`,
            }}
          >
            {coworker.namn}
          </Link>
        )
      },
      sorter: (a, b) => a.namn.localeCompare(b.namn),
      onFilter: (value: any, record) => {
        return record.namn.includes(value + '')
      },
      filterSearch: true,
    },
    {
      title: 'Avvikelse',
      dataIndex: 'deviation',
      key: 'deviation',
      defaultFilteredValue: deviation[0] !== 'null' ? deviation : null,
      // filteredValue:
      //   (filterState.filter &&
      //     filterState.filter.deviation?.map((item: number) => item)) ||
      //   null,
      showSorterTooltip: { title: 'Sortera på avvikelse' },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.flaggnamn
          })
        )
      )
        .map((name) => {
          return {
            value: name,
            text: name,
          }
        })
        .sort((a, b) => a.value.localeCompare(b.value)),
      sorter: (a, b) => a.flaggnamn.localeCompare(b.flaggnamn),
      render: (_, obj) => (
        <Link
          to={{
            pathname: `/app/avvikelse/flag=${obj.flagga}&id=${obj.key}`,
            state: { flagname: obj.flaggnamn, riskniva: obj.nivå },
          }}
          id="flagLink"
        >
          {obj.flaggnamn}
        </Link>
      ),
      onFilter: (value, record) => {
        return record.flaggnamn === value
      },
      filterSearch: true,
    },
    {
      title: 'Risknivå',
      dataIndex: 'risk',
      key: 'risk',
      defaultFilteredValue: flagLevel[0] !== 'null' ? flagLevel.map(Number) : null,
      // filteredValue:
      //   (filterState.filter &&
      //     filterState.filter.risk?.map((item: number) => item)) ||
      //   null,
      showSorterTooltip: { title: 'Sortera på risknivå' },
      sorter: (a, b) => a.nivå - b.nivå,
      render: (_, obj) => (
        <>
          {obj.nivå} <FlagFilled style={{ color: getColor(obj.nivå) }} />
        </>
      ),
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.nivå
          })
        )
      )
        .map((name) => {
          return {
            value: name,
            text: name,
          }
        })
        .sort((a, b) => a.value - b.value),
      onFilter: (value, record) => record.nivå === value,
      filterSearch: true,
    },
    {
      title: 'Period',
      dataIndex: 'periodStart',
      key: 'periodStart',
      width: 220,
      filterSearch: true,
      showSorterTooltip: { title: 'Sortera på period' },
      sorter: (a, b) => {
        const from = new Date(a.från)
        const to = new Date(b.från)
        return from.getTime() - to.getTime()
      },
      render: (_, obj) => {
        return (
          <>
            {path === 'brukare' ? (
              <>
                {obj.från} - {obj.till}
              </>
            ) : (
              <>
                {new Date(obj.från).toLocaleDateString()} - {new Date(obj.till).toLocaleDateString()}
              </>
            )}
          </>
        )
      },
    },
    {
      title: 'Skapad i körning',
      dataIndex: 'created',
      key: 'created',
      // filteredValue:
      //   (filterState.filter &&
      //     filterState.filter.created?.map((item: string) => item)) ||
      //   null,
      // filters: Array.from(
      //   new Set(
      //     data.map((d) => {
      //       return new Date(d.created).toLocaleDateString()
      //     })
      //   )
      // ).map((name) => {
      //   return {
      //     value: name,
      //     text: name,
      //   }
      // }),
      sorter: (a, b) => {
        return new Date(a.created).getTime() - new Date(b.created).getTime()
      },
      filterSearch: true,
      onFilter: (value, record) => {
        return new Date(record.created).toLocaleDateString() === value
      },
      showSorterTooltip: { title: 'Sortera på när avvikelsen skapades' },
      render: (_, obj) => {
        return <>{new Date(obj.created).toLocaleDateString()}</>
      },
    },
    Table.SELECTION_COLUMN,
  ]

  const personnummerColumn: ColumnsType<DataType> = [
    {
      title: 'Personnummer',
      dataIndex: 'personnummer',
      key: 'personnummer',
      // filteredValue:
      //   (filterState.filter &&
      //     filterState.filter.personnummer?.map((item: string) => item)) ||
      //   null,
      showSorterTooltip: { title: 'Sortera på personnummer' },
      filters: Array.from(
        new Set(
          data.map((d) => {
            return d.personnummer
          })
        )
      )
        .map((name) => {
          return {
            value: name ? name : '',
            text: name,
          }
        })
        .sort((a, b) => a.value.localeCompare(b.value)),
      render: (_, coworker) => coworker.personnummer && <>{coworker.personnummer}</>,
      sorter: (a, b) => a.personnummer!.localeCompare(b.personnummer!),
      onFilter: (value, record) => record.personnummer!.includes(value + ''),
      filterSearch: true,
    },
  ]

  const [addColumn, setAddColumn] = useState(false)
  const [newColumn, setNewColumn] = useState<ColumnsType<DataType>>()

  useEffect(() => {
    data.map((item) => 'personnummer' in item && setAddColumn(true))
    const oldArray = columns
    oldArray.splice(1, 0, personnummerColumn[0])
    setNewColumn(oldArray)
  }, [data])

  return addColumn ? newColumn : columns
}
