import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { IBICMunicipalityData } from '../../features/beslutsstod/types/municipalityData'
import { AppState } from '../store'
import { ensureBaseIntegrity } from './ensureBaseIntegrity'

interface IBICMunicipalityState {
  municipalityData: IBICMunicipalityData | null
}

const initialState: IBICMunicipalityState = {
  municipalityData: null,
}

// const defaultLifeAreas = [
//   {
//     name: 'Personlig vård',
//     tasks: ['Dusch', 'Dusch utanför annat besök', 'Klä sig', 'Sköta kroppen', 'Sköta toalettbehov', 'Tillsyn', 'Tvätta sig', 'Äta och dricka'],
//   },
//   {
//     name: 'Hemliv',
//     tasks: ['Bereda måltider', 'Hushållsarbete', 'Apotek och/eller apodos', 'Inköp', 'Lättstäd', 'Post- och bankärenden', 'Städa bostaden', 'Tvätt'],
//   },
//   {
//     name: 'Trygghet',
//     tasks: ['Social kontakt'],
//   },
//   {
//     name: 'Förflyttning',
//     tasks: ['Förflyttning', 'Promenad'],
//   },
//   {
//     name: 'Allmänna uppgifter och krav',
//     tasks: ['Planering och administration'],
//   },
// ]

const defaultTaskToLifeAreaMap: Record<string, string> = {
  Dusch: 'Personlig vård',
  'Dusch utanför annat besök': 'Personlig vård',
  'Klä sig': 'Personlig vård',
  'Sköta kroppen': 'Personlig vård',
  'Sköta toalettbehov': 'Personlig vård',
  Tillsyn: 'Personlig vård',
  'Tvätta sig': 'Personlig vård',
  'Äta och dricka': 'Personlig vård',
  'Bereda måltider': 'Hemliv',
  Hushållsarbete: 'Hemliv',
  'Apotek och/eller apodos': 'Hemliv',
  Inköp: 'Hemliv',
  Lättstäd: 'Hemliv',
  'Post- och bankärenden': 'Hemliv',
  'Städa bostaden': 'Hemliv',
  Tvätt: 'Hemliv',
  'Social kontakt': 'Trygghet',
  Förflyttning: 'Förflyttning',
  Promenad: 'Förflyttning',
  'Planering och administration': 'Allmänna uppgifter och krav',
}

const ibicMunicipalitySlice = createSlice({
  name: 'municipality',
  initialState,
  reducers: {
    setMunicipality(state, action: PayloadAction<IBICMunicipalityState>) {
      state.municipalityData = action.payload.municipalityData
      if (!state.municipalityData) return

      state.municipalityData = ensureBaseIntegrity(state.municipalityData)

      // Make sure all timepoint tasks have an IBIC calculation rule
      state.municipalityData.input.timepointTasks.forEach((task) => {
        if (task.ibicCalculationRules) return

        task.ibicCalculationRules = {}

        for (const frequency of task.frequencies ?? []) {
          if (!task.ibicCalculationRules[frequency.name]) {
            task.ibicCalculationRules[frequency.name] = {
              type: 'IBICUndefinedRule',
            }
          }
        }
      })

      // Give all timepoint tasks a timesPerDayOptions array
      state.municipalityData.input.timepointTasks.forEach((task) => {
        if (task.timesPerDayOptions) return

        task.timesPerDayOptions = new Array(9).fill(0).map((_, i) => i + 1)
      })

      if (!state.municipalityData.input.lifeAreas) {
        state.municipalityData.input.lifeAreas = Array.from(new Set(Object.values(defaultTaskToLifeAreaMap)))
      }

      // Init lifeArea connections
      state.municipalityData.input.timepointTasks.forEach((task) => {
        const mappedLifeArea = defaultTaskToLifeAreaMap[task.name]
        if (task.lifeArea || !mappedLifeArea) {
          return
        }
        task.lifeArea = mappedLifeArea
      })
      state.municipalityData.input.extraTasks.forEach((task) => {
        const mappedLifeArea = defaultTaskToLifeAreaMap[task.name]
        if (task.lifeArea || !mappedLifeArea) {
          return
        }
        task.lifeArea = mappedLifeArea
      })

      console.log(state.municipalityData?.input)
    },
  },
})

export const getIBICMunicipality = (state: AppState) => state.ibicMunicipalityReducer.municipalityData
export const { setMunicipality: setIBICMunicipality } = ibicMunicipalitySlice.actions
export default ibicMunicipalitySlice.reducer
