import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ConfigProvider, notification } from 'antd'
import { Provider } from 'react-redux'
import configureStore from './store/store'
import { init as sentryInit } from './sentry'
import 'antd/dist/antd.min.css'
import './styles/App.less'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import LocaleProvider from 'antd/es/locale-provider'
import sv_SE from 'antd/es/locale/sv_SE'

sentryInit()

const store = configureStore()
const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={sv_SE}>
        <Provider store={store}>
          <App />
        </Provider>
      </ConfigProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
