import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setBrukare } from '../../api/brukareApi'
import { useApi } from '../../api/useApi'
import FlagListHeader from '../../components/header'
import { CreateFilterConfig } from '../../components/tablesConfig/brukareConfig'
import {
  //CreateFilterConfig,
  DataType,
} from '../../components/tablesConfig/filterconfig'
import { setCaseAction } from '../arende/store/caseReducer/actions'
import { selectBruLoading, selectBrukare } from './store/selectors'

// Antd
import { Button, DatePicker, Table } from 'antd'
import type { TableProps } from 'antd/es/table'
import moment from 'moment'
import { getBrukareDateFrom, getBrukareDateTo, setBrukareDate } from '../../store/brukareDateSlice'
import { setBrukareFilter } from '../../store/brukareFilterSlice'

export type IBrukare = {
  id: number
  flagga: number
  flaggnamn: string
  nivå: number
  förnamn: string
  efternamn: string
  personnummer: string
  från: Date
  till: Date
  created: Date
  utförare: {
    utförare_id: number
    utförare_namn: string
  }[]
}

const Brukare: React.FC = () => {
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
  const dispatch = useDispatch()
  const api = useApi()
  const navigate = useHistory()
  const dispatchCallback = useCallback(dispatch, [dispatch])
  const [startDate, setStartDate] = useState(useSelector(getBrukareDateFrom))
  const [endDate, setEndDate] = useState(useSelector(getBrukareDateTo))
  const [datesPicked, setDatesPicked] = useState(false)

  // Redux states
  const brukareState = useSelector(selectBrukare)
  const loadingState = useSelector(selectBruLoading)
  const { RangePicker } = DatePicker

  const monthFormat = 'YYYY/MM'

  const handleChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
    dispatch(setBrukareFilter({ data: { filter: filters, sorter: sorter } }))
  }

  function handleDatesChange(range: any) {
    const start = range[0].startOf('month').format().split('T')[0]
    const end = range[1].endOf('month').format().split('T')[0]
    setStartDate(start)
    setEndDate(end)
  }

  function handleOpenChange(isOpen: boolean) {
    setDatesPicked(isOpen)
  }

  useEffect(() => {
    setBrukare(api, dispatchCallback, { from: startDate, to: endDate })
    dispatch(setBrukareDate({ from: startDate, to: endDate }))
  }, [datesPicked])

  useEffect(() => {
    if (loadingState) {
      setBrukare(api, dispatch, { from: startDate, to: endDate })
    }
  }, [loadingState, api, dispatch])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      selectedRows.length > 0 ? setBtnDisabled(false) : setBtnDisabled(true)
      setCaseAction('SET_CASE', selectedRows, null, dispatch)
    },
  }

  return (
    <>
      <FlagListHeader
        heading={'Brukare'}
        title={null}
        description={
          'Denna vy visar flaggor som skapats under vald tidsperiod. På denna sida visas flaggor som skapats baserat på data från brukaren. Flaggor indikerar att något inträffat som kan behöva följas upp. En flagga med högre risknivå är en starkare indikation än en flagga med lägre risknivå. Val av tidsperiod baseras på när flaggan skapades.'
        }
        button={false}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <RangePicker
          className="range-picker"
          style={{ minWidth: '20%', maxHeight: '40px', marginBottom: '20px', marginRight: '25px' }}
          picker="month"
          defaultValue={[moment(startDate, monthFormat), moment(endDate, monthFormat)]}
          onChange={handleDatesChange}
          onOpenChange={handleOpenChange}
        />
        <Button type="primary" onClick={() => navigate.push('/app/nytt-arende')} disabled={btnDisabled}>
          Initiera uppföljning
        </Button>
      </div>
      {brukareState && (
        <Table
          scroll={{ x: 1000, y: 'calc(100vh - 350px)' }}
          columns={CreateFilterConfig(brukareState)}
          rowSelection={{ ...rowSelection }}
          dataSource={brukareState}
          bordered
          pagination={false}
          onChange={handleChange}
          loading={loadingState}
          rowClassName="table-row-cursor"
          onRow={(record, rowIndex) => {
            return {
              // onClick: (event: any) => {
              //   event.target.id !== 'flagLink' &&
              //     navigate.push('/app/brukare/id='+record.personnummer + '&namn=' + record.namn )
              // },
            }
          }}
        />
      )}
    </>
  )
}

export default Brukare
