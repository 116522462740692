import { Select, Spin } from 'antd'
import { ComponentProps, useEffect } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useHomeCareVolumeAgeGroupValues, useMunicipalityName } from '../../../../api/benchmarking'
import { getDoughnutColorByIndex } from '../../util/colors'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { getFontSize } from '../../util/fontSize'
import { baseGraphOptions } from '../../util/baseGraphOptions'

export const HomeCareVolumeAgeGroup = ({ text }: { text: React.ReactNode }) => {
  const { data: municipalityName, isLoading: isMunicipalityNameLoading } = useMunicipalityName()
  const { data, isLoading, mutate } = useHomeCareVolumeAgeGroupValues()

  useEffect(() => {
    mutate({})
  }, [])

  if (isLoading || isMunicipalityNameLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const baselineOptions: ComponentProps<typeof Doughnut>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const total = context.dataset.data.reduce((a, b) => a + b, 0)
            const percentage = Math.round(((context.parsed as number) / total) * 100)
            return `${percentage}%`
          },
        },
      },
    },
  }

  const comparisonOptions: ComponentProps<typeof Doughnut>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const total = context.dataset.data.reduce((a, b) => a + b, 0)
            const percentage = Math.round(((context.parsed as number) / total) * 100)
            return `${percentage}%`
          },
        },
      },
    },
  }

  const baselineAgeRanges = [...new Set(data.baselineValues.map((item) => item.ageRange))]
  const baselineGraphData = {
    labels: baselineAgeRanges.map((ageRange) => `${ageRange} år:`),
    datasets: [
      {
        fill: false,
        label: 'Platser',
        data: data.baselineValues.map((item) => item.value),
        borderColor: data.baselineValues.map((_, index) => getDoughnutColorByIndex(index).border),
        backgroundColor: data.baselineValues.map((_, index) => getDoughnutColorByIndex(index).fill),
      },
    ],
  }
  const comparisonAgeRanges = [...new Set(data.comparisonValues.map((item) => item.ageRange))]
  const comparisonGraphData = {
    labels: comparisonAgeRanges.map((ageRange) => `${ageRange} år:`),
    datasets: [
      {
        fill: false,
        label: 'Platser',
        data: data.comparisonValues.map((item) => item.value),
        borderColor: data.comparisonValues.map((_, index) => getDoughnutColorByIndex(index).border),
        backgroundColor: data.comparisonValues.map((_, index) => getDoughnutColorByIndex(index).fill),
      },
    ],
  }

  const dataSourceSelector = (
    <Select
      value={data.currentDataSource}
      onChange={(value) => {
        mutate({ datasource: value, grouping: undefined })
      }}
      options={data.dataSources.map((ds) => ({
        label: ds,
        value: ds,
      }))}
      style={{ width: '100%' }}
    />
  )

  const comparisonSelector = (
    <Select
      value={data.currentGrouping}
      onChange={(value) => {
        mutate({ grouping: value, datasource: data.currentVariation.comparison?.datasource?.name })
      }}
      options={data.groupings.map((grouping) => ({
        label: grouping,
        value: grouping,
      }))}
      style={{ width: '100%' }}
    />
  )

  const graph: React.ReactNode = (
    <div className="flex w-full justify-evenly">
      <div className="max-h-[22cqb]">
        <h3 className="text-center" style={{ fontSize: getFontSize('md') }}>
          {municipalityName}
        </h3>
        <Doughnut options={baselineOptions} data={baselineGraphData} />
      </div>
      <div className="max-h-[22cqb]">
        <h3 className="text-center" style={{ fontSize: getFontSize('md') }}>
          Nationellt
        </h3>
        <Doughnut options={comparisonOptions} data={comparisonGraphData} />
      </div>
    </div>
  )
  const legend = (
    <BenchmarkingLegend items={baselineAgeRanges.map((ageRange, index) => ({ fillColor: getDoughnutColorByIndex(index).fill, borderColor: getDoughnutColorByIndex(index).border, text: ageRange }))} />
  )
  const title = 'Andel individer med hemtjänst per åldersgrupp'
  return <BenchmarkingContentRow graph={graph} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} text={text} title={title} legend={legend} />
}
