import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Switch, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useApi } from '../../api/useApi'
import BackButton from '../../components/buttons/backButton'
import FlagListHeader from '../../components/header'
import { IHandläggare, ITeam, getHandlaggare, getRoles, getTeam, updateHandläggare } from '../../store/organizationSlice'
import { selectAnvandare } from './store/selectors'
import { useMe, useRole } from '../../api/permissions'
const { Option } = Select

type Props = {}

export function getTeamNameById(id: number | undefined) {
  const teams = useSelector(getTeam)

  if (id) {
    let name = ''
    teams.forEach((item: { team_id: number; team_namn: string; avdelning: number }) => {
      if (item.team_id === id) {
        name = item.team_namn
      }
    })
    return name
  }
  return ''
}

const SingleAnvandare = (props: Props) => {
  const api = useApi()
  const [user, setUser] = useState<IHandläggare>()
  const dispatch = useDispatch()

  type Param = { id: string }
  const { id } = useParams<Param>()
  const getId = parseInt(id)
  const navigate = useHistory()

  // Redux state
  const anvandareState = useSelector(selectAnvandare)
  const handlaggare = useSelector(getHandlaggare).find((item: IHandläggare) => item.id === Number(id))
  const { data: me } = useMe()
  const { data: userRole } = useRole((me as any)?.role_id)
  console.log(userRole)
  const teams = useSelector(getTeam)

  const roles = useSelector(getRoles)

  function getScopeById(ids: number[] | undefined) {
    if (id) {
      let name: string[] = []
      teams.forEach((item: { team_id: number; team_namn: string; avdelning: number }) => {
        ids?.forEach((id: number) => {
          if (item.team_id === id) {
            name.push(item.team_namn)
          }
        })
      })
      return name
    }
    return []
  }

  useEffect(() => {
    const getUser = anvandareState.find((item: IHandläggare) => item.id === getId)
    setUser(getUser)
  }, [anvandareState, getId])

  const onFinish = async (values: any) => {
    // Send to DB
    var scopes: number[] = []
    teams.map((team: ITeam) => {
      if (values.scope.includes(team.team_namn)) {
        scopes.push(team.team_id)
      }
    })
    var team = null
    teams.map((item: ITeam) => {
      if (values.team.includes(item.team_namn)) {
        team = item.team_id
      }
    })

    const req: IHandläggare = {
      id: Number(id),
      namn: values.namn,
      email: values.email,
      personnummer: values.personnummer,
      active: values.active,
      team: team ? team : 0, // Ändra detta, I.E Fråga calle
      scope: scopes,
      permissions: values.permissions,
      modules: values.modules,
      in_structure: values.in_structure,
      role: values.role,
    }
    try {
      await api.put(`/v1/handlaggare/${localStorage.getItem('tenant_id')}/update`, req, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
        },
      })
      dispatch(updateHandläggare(req))
      navigate.goBack()
    } catch (e) {
      notification.error({
        message: 'Något gick fel',
        description: 'Kunde inte uppdatera användaren',
      })
      console.error(e)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <>
      <FlagListHeader title="" heading="Redigera Användare" description="Redigera en användare genom att ändra informationen i fälten nedan, klicka sedan på spara." button={false} />
      <Form
        name="new-user"
        initialValues={{
          ['namn']: handlaggare?.namn,
          ['personnummer']: handlaggare?.personnummer,
          ['email']: handlaggare?.email,
          ['team']: getTeamNameById(handlaggare?.team),
          ['scope']: getScopeById(handlaggare?.scope),
          ['in_structure']: handlaggare?.in_structure,
          ['active']: handlaggare?.active,
          ['role']: handlaggare?.role,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <div className="newuser-input-wrapper">
          <div className="input-wrapper-flex">
            <Form.Item label="Namn" name="namn" rules={[{ required: true, message: 'Vänligen fyll i hela namnet' }]}>
              <Input type={'text'} placeholder={handlaggare?.namn} />
            </Form.Item>
            <Form.Item
              label="Personnummer"
              name="personnummer"
              rules={[
                {
                  required: true,
                  message: 'Vänligen fyll i personnummer i formatet ÅÅÅÅMMDD-XXXX',
                  pattern: new RegExp(/^[0-9]{8}-[0-9]{4}$/),
                },
              ]}
            >
              <Input type="text" placeholder={handlaggare?.personnummer} />
            </Form.Item>
            <Form.Item
              label="E-postadress"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Vänligen fyll i e-postadress',
                  type: 'email',
                },
              ]}
            >
              <Input placeholder={handlaggare?.email} />
            </Form.Item>
          </div>

          <div className="input-wrapper-flex">
            <Form.Item
              label="Roll"
              name="role"
              tooltip={{ title: 'Användarens roll styr vilka vyer som hen har tillgång till.', icon: <InfoCircleOutlined /> }}
              rules={[
                {
                  required: true,
                  message: 'Vänligen välj en roll',
                },
              ]}
            >
              <Select placeholder={''} className="select">
                {userRole?.assignableRoles.map((v) => `${v.name}`)}
                {roles
                  .filter((item: any) => userRole?.assignableRoles.findIndex((role) => role.name === item.role_name) !== -1)
                  .map((item: { role_name: string; permissions: string[]; modules: string[] }) => {
                    return <Option value={item.role_name}>{item.role_name}</Option>
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Team"
              name="team"
              tooltip={{ title: 'Användarens team styr vilket team som användaren tillhör.', icon: <InfoCircleOutlined /> }}
              rules={[
                {
                  required: true,
                  message: 'Vänligen välj ett team',
                },
              ]}
            >
              <Select placeholder={getTeamNameById(handlaggare?.team)} className="select">
                {teams.map((item: { team_id: number; team_namn: string; avdelning: number }) => {
                  return <Option value={item.team_namn}>{item.team_namn}</Option>
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Behörighet"
              name="scope"
              tooltip={{ title: 'Användarens behörighet styr vilka team och därmed vilka handläggare och ärenden som användaren har tillgång till.', icon: <InfoCircleOutlined /> }}
              rules={[
                {
                  required: true,
                  message: 'Vänligen välj behörigheter',
                },
              ]}
            >
              <Select mode="multiple" placeholder={getScopeById(handlaggare?.scope)} className="select">
                {teams.map((item: { team_id: number; team_namn: string; avdelning: number }) => {
                  return <Option value={item.team_namn}>{item.team_namn}</Option>
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
        <Form.Item
          label="Status"
          name="active"
          tooltip={{
            title: 'Justera om användarens status ska vara aktiv eller inaktiv. Om användaren är aktiv kan hen logga in i Pejlen.',
            icon: <InfoCircleOutlined />,
          }}
        >
          <Switch defaultChecked={handlaggare?.active} />
        </Form.Item>
        <Form.Item
          label="I struktur "
          name="in_structure"
          tooltip={{
            title: 'Justera om användaren ska vara i strukturen eller inte genom att klicka på knappen. Om användaren är med i strukturen, visas hens ärenden under vyn Ärendefördelning.',
            icon: <InfoCircleOutlined />,
          }}
        >
          <Switch defaultChecked={handlaggare?.in_structure} />
        </Form.Item>
        <Form.Item>
          <div style={{ display: 'flex', gap: '6px' }}>
            <BackButton display="inline" />
            <Button type="primary" htmlType="submit" style={{ display: 'inline-block', marginTop: '20px' }}>
              Spara
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

export default SingleAnvandare
