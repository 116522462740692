import { IHandläggare } from '../store/organizationSlice'

/**
 *
 * @param permissions If this is set, and the user has any of the permissions, the menu will be shown regardless if the user has the module or not.
 * This is useful if there is a child item in the siderMenu which is not tied to the module of that menu item.
 * @returns Should this menu item be shown or not
 */
const siderMenuPermissions = (me: IHandläggare, module: string, permissions?: string[]) => {
  return me.modules.includes(module) || (permissions && permissions.some((permission) => me.permissions.includes(permission)))
}

export default siderMenuPermissions
