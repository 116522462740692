import { useQueryClient } from '@tanstack/react-query'
import { Button, Form, Input, Spin, Typography, notification } from 'antd'
import { useTokenLifespans, useUpdateTokenLifespans } from '../../api/auth'
import { useMaxAuthDuration, useRemoveMaxAuthDuration, useSetMaxAuthDuration } from '../../api/tenant'
import { useEffect, useState } from 'react'

const createLifespanString = (hours: number, minutes: number, seconds: number) => {
  return `${hours}h${minutes}m${seconds}s`
}

const TokenLifespanSettings = () => {
  const queryClient = useQueryClient()

  const { data: tokenLifespans, isLoading: isTokenLifespansLoading } = useTokenLifespans()
  const { data: maxAuthDuration, isLoading: isMaxAuthDurationLoading } = useMaxAuthDuration()
  const { mutateAsync: patchTokenLifespans, isLoading: isPatchTokenLifespansLoading } = useUpdateTokenLifespans()
  const { mutateAsync: setMaxAuthDuration, isLoading: isSetMaxAuthDurationLoading } = useSetMaxAuthDuration()
  const { mutateAsync: removeMaxAuthDuration, isLoading: isRemoveMaxAuthDurationLoading } = useRemoveMaxAuthDuration()
  const [showMaxAuthDuration, setShowMaxAuthDuration] = useState(false)

  useEffect(() => {
    console.log(maxAuthDuration)
    if (maxAuthDuration) {
      setShowMaxAuthDuration(true)
    }
  }, [maxAuthDuration])

  if (isTokenLifespansLoading || isMaxAuthDurationLoading)
    return (
      <div className="flex items-center justify-center">
        <Spin spinning={true} />
      </div>
    )

  if (!tokenLifespans) return null

  const onFinish = async (values: {
    accessTokenHours: number
    accessTokenMinutes: number
    accessTokenSeconds: number
    refreshTokenHours: number
    refreshTokenMinutes: number
    refreshTokenSeconds: number
  }) => {
    try {
      await patchTokenLifespans({
        accessToken: createLifespanString(values.accessTokenHours, values.accessTokenMinutes, values.accessTokenSeconds),
        refreshToken: createLifespanString(values.refreshTokenHours, values.refreshTokenMinutes, values.refreshTokenSeconds),
      })
      queryClient.invalidateQueries(['tokenLifespans'])
      notification.success({
        message: 'Inställningarna sparades',
      })
    } catch (e) {
      notification.error({
        message: 'Inställningarna kunde inte sparas',
      })
    }
  }

  const onMaxAuthDurationFinish = async (values: { hours: number; minutes: number; seconds: number }) => {
    try {
      await setMaxAuthDuration(values)
      queryClient.invalidateQueries(['maxAuthDuration'])

      notification.success({
        message: 'Inställningarna sparades',
      })
    } catch (e) {
      notification.error({
        message: 'Inställningarna kunde inte sparas',
      })
    }
  }

  return (
    <>
      <Form
        name="token-lifespans"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          accessTokenHours: tokenLifespans.accessToken.hours,
          accessTokenMinutes: tokenLifespans.accessToken.minutes,
          accessTokenSeconds: tokenLifespans.accessToken.seconds,
          refreshTokenHours: tokenLifespans.refreshToken.hours,
          refreshTokenMinutes: tokenLifespans.refreshToken.minutes,
          refreshTokenSeconds: tokenLifespans.refreshToken.seconds,
        }}
      >
        <p className="text-lg">Access Token</p>
        <span className="text-sm text-gray-500">Tiden innan användaren behöver skaffa en ny token, om användaren fortfarande har en refresh token händer detta i bakgrunden.</span>
        <p className="text-sm font-bold text-gray-500">OBS! Denna siffra bör inte vara för hög då det kan leda till säkerhetsproblem.</p>
        <div className="flex w-full gap-2">
          <FormItem label="Timmar" name="accessTokenHours" />
          <FormItem label="Minuter" name="accessTokenMinutes" />
          <FormItem label="Sekunder" name="accessTokenSeconds" />
        </div>
        <p className="text-lg">Refresh Token</p>
        <span className="text-sm text-gray-500">
          Hur länge en användare har på sig att skaffa en ny access token. Om denna siffra är längre än access token så kommer användaren bli tvungen att logga in igen då access token går ut.
        </span>
        <div className="flex w-full gap-2">
          <FormItem label="Timmar" name="refreshTokenHours" />
          <FormItem label="Minuter" name="refreshTokenMinutes" />
          <FormItem label="Sekunder" name="refreshTokenSeconds" />
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isPatchTokenLifespansLoading}>
            Spara
          </Button>
        </Form.Item>
      </Form>

      <Form
        name="max-auth-duration"
        layout="vertical"
        onFinish={onMaxAuthDurationFinish}
        initialValues={{
          hours: maxAuthDuration?.hours ?? 0,
          minutes: maxAuthDuration?.minutes ?? 0,
          seconds: maxAuthDuration?.seconds ?? 0,
        }}
      >
        <p className="text-lg">Max autentiseringslängd</p>
        <p className="text-sm text-gray-500">Användaren kan bara fortsätta byta ut refresh tokens tills denna tid har gått.</p>
        {showMaxAuthDuration && (
          <div className="flex w-full gap-2">
            <FormItem label="Timmar" name="hours" />
            <FormItem label="Minuter" name="minutes" />
            <FormItem label="Sekunder" name="seconds" />
          </div>
        )}
        {!showMaxAuthDuration && (
          <Form.Item>
            <Button type="primary" onClick={() => setShowMaxAuthDuration(true)}>
              Sätt max autentiseringslängd
            </Button>
          </Form.Item>
        )}

        <Form.Item>
          <div className="flex gap-2">
            <Button type="primary" htmlType="submit" loading={isSetMaxAuthDurationLoading}>
              Spara
            </Button>
            {showMaxAuthDuration && (
              <Button
                type="primary"
                danger
                onClick={async () => {
                  // We only have a value in the form, not in db
                  if (!maxAuthDuration) {
                    setShowMaxAuthDuration(false)
                    return
                  }
                  await removeMaxAuthDuration()
                  queryClient.invalidateQueries(['maxAuthDuration'])
                  setShowMaxAuthDuration(false)
                }}
                loading={isRemoveMaxAuthDurationLoading}
              >
                Ta bort max autentiseringslängd
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

const FormItem = ({ label, name }: { label: string; name: string }) => (
  <>
    <Form.Item name={name} label={label} style={{ display: 'inline-block', width: '100px' }} rules={[{ required: true }]} requiredMark={'optional'}>
      <Input type="number" />
    </Form.Item>
  </>
)

export default TokenLifespanSettings
