import { Spin } from 'antd'
import { ComponentProps } from 'react'
import { Bar } from 'react-chartjs-2'
import { useHomeCareOrderedHoursAgeGroupValues, useMunicipalityName } from '../../../../api/benchmarking'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { getFontSize } from '../../util/fontSize'
import { getColorByIndex } from '../../util/colors'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { BORDER_WIDTH } from '../../util/constants'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { EmptySelector } from '../../components/EmptySelector'
import { STATDB_NAME } from '../../util/names'

export const HomeCareOrderedHoursAgeGroup = ({ text }: { text: React.ReactNode }) => {
  const { data: municipalityName, isLoading: isMunicipalityNameLoading } = useMunicipalityName()
  const { data, isLoading } = useHomeCareOrderedHoursAgeGroupValues()

  if (isLoading || isMunicipalityNameLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const graphOptions: ComponentProps<typeof Bar>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label} år: ${Math.round(context.parsed.y)}%`
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        min: 0,
        max: 100,
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        stacked: true,
        min: 0,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
          font: {
            size: getFontSize('md'),
          },
        },
      },
    },
  }

  const graphData: ComponentProps<typeof Bar>['data'] = {
    labels: [municipalityName, 'Ovägt medel'],
    datasets: data.baselineValues.map((item, index) => ({
      label: item.ageRange,
      data: [item.value, data.comparisonValues[index].mean],
      backgroundColor: getColorByIndex(index).fill,
      borderColor: getColorByIndex(index).border,
      borderWidth: BORDER_WIDTH,
    })),
  }
  const legend = (
    <BenchmarkingLegend
      items={data.baselineValues.map((item, index) => ({
        text: item.ageRange,
        fillColor: getColorByIndex(index).fill,
        borderColor: getColorByIndex(index).border,
      }))}
    />
  )
  const title = 'Fördelning av totalt antal timmar beviljad hemtjänst per åldersgrupp'
  const graph: React.ReactNode = <Bar options={graphOptions} data={graphData} />

  const dataSourceSelector = <EmptySelector value={STATDB_NAME} />
  const comparisonSelector = <EmptySelector value={'Ovägt medel'} />
  return <BenchmarkingContentRow graph={graph} text={text} title={title} legend={legend} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} />
}
