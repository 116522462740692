import { AxiosInstance } from 'axios'
import { Dispatch } from 'redux'
import { NewCase } from '../features/arende/components/createCase/createCase'
import { Case } from '../features/arende'
import { INewLog } from '../features/arende/single'
import {
  IGetDistributionAction,
  IGetMyCasesAction,
  ISetNyligenUtgångnaAction,
  ISetUtanförTeamstrukturAction,
  setDistributionAction,
  setMyCasesAction,
  setNyligenUtgångaAction,
  setUtanförTeamstrukturAction,
  setVolumeAction,
} from '../features/arende/store/arendeReducer/actions'
import { IDistributionData } from '../features/arendeFordelning'
import moment from 'moment'
import { IVolumeData } from '../features/volymuppfoljning/tableConfig'
import { useApi } from './useApi'

interface IBeslutObject {
  giltigt_till: string
  giltigt_från: string
  beslut_id: number
  förlängning: boolean
}

interface IFormattedData {
  personnummer: string
  namn: string
  ansvarig_person: string
  utförare_dagverksamhet: Array<string>
  dagverksamhet: Array<IBeslutObject>
  utförare_trygghetslarm: Array<string>
  trygghetslarm: Array<IBeslutObject>
  utförare_korttidsboende: Array<string>
  korttidsboende: Array<IBeslutObject>
  utförare_hemtjänst: Array<string>
  hemtjänst: Array<IBeslutObject>
  omfattning_månad: number
  dubbelbemanning: boolean
  natthjälp: boolean
  utförare_säbo: Array<string>
  nästa_kvalitetsfrågor: string
  säbo_fv: string
  säbo_till: string
  ny_process: Array<string>
  medlemmar: Array<string>
  kontaktperson_tillfällen: number
  boendestöd: boolean
  hemtjänst_boende: boolean
}

export const setCaseDistribution = async (api: AxiosInstance, dispatch: Dispatch<IGetDistributionAction>, from: string, to: string) => {
  try {
    const distributionData = await api.get(`/v1/case/${localStorage.getItem('tenant_id')}/distribution?från=${from}&till=${to}&id_token=${localStorage.getItem('hydra_id_token')}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    if (distributionData.data === []) {
      setDistributionAction('SET_DISTRIBUTION', [], dispatch)
      return
    }

    const formattedData = distributionData.data.map((item: IDistributionData, index: number) => {
      return {
        ...item,
        vårdtyngd: Math.round(item.vårdtyngd * 10) / 10,
        beviljade_timmar: Math.round(item.beviljade_timmar * 10) / 10,
        key: index,
      }
    })

    return setDistributionAction('SET_DISTRIBUTION', formattedData, dispatch)
  } catch (err) {
    console.log(err)
  }
}

export const setMyCases = async (api: AxiosInstance, dispatch: Dispatch<IGetMyCasesAction>, date: string, personnummer: string) => {
  try {
    const myCasesData = await api.get(`/v1/case/${localStorage.getItem('tenant_id')}/other2?date=${date}&other_personnummer=${personnummer}&id_token=${localStorage.getItem('hydra_id_token')}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    if (myCasesData.data === []) {
      setMyCasesAction('SET_MY_CASES', [], dispatch)
      return
    }

    const formattedData = myCasesData.data.map((item: IFormattedData, index: number) => {
      const np = item.ny_process
      const ht = item.hemtjänst
        ? item.hemtjänst
            .sort((h1: IBeslutObject, h2: IBeslutObject) => {
              return moment(h1.giltigt_till).diff(h2.giltigt_till)
            })
            .map((h: IBeslutObject) => {
              return {
                beslutid: h.beslut_id,
                förlängning: h.förlängning,
                till: h.giltigt_till,
                från: h.giltigt_från,
              }
            })
        : null
      const tl = item.trygghetslarm
        ? item.trygghetslarm
            .sort((h1: IBeslutObject, h2: IBeslutObject) => {
              return moment(h1.giltigt_till).diff(h2.giltigt_till)
            })
            .map((t: IBeslutObject) => {
              return {
                beslutid: t.beslut_id,
                förlängning: t.förlängning,
                till: t.giltigt_till,
                från: t.giltigt_från,
              }
            })
        : null
      const kb = item.korttidsboende
        ? item.korttidsboende
            .sort((h1: IBeslutObject, h2: IBeslutObject) => {
              return moment(h1.giltigt_till).diff(h2.giltigt_till)
            })
            .map((k: IBeslutObject) => {
              return {
                beslutid: k.beslut_id,
                förlängning: k.förlängning,
                till: k.giltigt_till,
                från: k.giltigt_från,
              }
            })
        : null
      const dv = item.dagverksamhet
        ? item.dagverksamhet
            .sort((h1: IBeslutObject, h2: IBeslutObject) => {
              return moment(h1.giltigt_till).diff(h2.giltigt_till)
            })
            .map((d: IBeslutObject) => {
              return {
                beslutid: d.beslut_id,
                förlängning: d.förlängning,
                till: d.giltigt_till,
                från: d.giltigt_från,
              }
            })
        : null

      const res = {
        ...item,
        ny_process: np,
        hushåll: item.medlemmar ? item.medlemmar : [],
        hemtjänst: ht,
        trygghetslarm: tl,
        korttidsboende: kb,
        dagverksamhet: dv,
        key: index,
      }

      return res
    })

    setMyCasesAction('SET_MY_CASES', formattedData, dispatch)
    return formattedData
  } catch (err) {
    console.log(err)
  }
}

export const setVolumeCases = async (api: AxiosInstance, dispatch: Dispatch<IGetMyCasesAction>, from: string, to: string, other_personnummer: string, id_token: string) => {
  try {
    const volumeCasesData = await api.get(`/v1/case/${localStorage.getItem('tenant_id')}/volume2/month/other?från=${from}&till=${to}&other_personnummer=${other_personnummer}&id_token=${id_token}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    if (volumeCasesData.data === []) return []

    const formattedData = volumeCasesData.data.map((item: IVolumeData, index: number) => {
      return {
        ...item,
        key: index,
      }
    })

    setVolumeAction('SET_VOLUME', formattedData, dispatch)
    return formattedData
  } catch (err) {
    console.log(err)
  }
}

export const setUtanförTeamstruktur = async (api: AxiosInstance, dispatch: Dispatch<ISetUtanförTeamstrukturAction>, to: string) => {
  try {
    const data = await api.get(`/v1/case/${localStorage.getItem('tenant_id')}/outside_structure?to=${to}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    if (data.data === []) {
      setUtanförTeamstrukturAction('SET_UTANFÖR_TEAMSTRUKTUR', [], dispatch)
      return
    }

    return setUtanförTeamstrukturAction('SET_UTANFÖR_TEAMSTRUKTUR', data.data, dispatch)
  } catch (err) {
    console.error(err)
  }
}

export const setNyligenUtgånga = async (api: AxiosInstance, dispatch: Dispatch<ISetNyligenUtgångnaAction>, ansvarig_person: string, date_gap: number) => {
  try {
    const data = await api.get(`/v1/case/${localStorage.getItem('tenant_id')}/recently_expired?ansvarig_person=${ansvarig_person}&date_gap=${date_gap}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    if (data.data === []) {
      setNyligenUtgångaAction('SET_NYLIGEN_UTGÅNGNA', [], dispatch)
      return
    }

    setNyligenUtgångaAction('SET_NYLIGEN_UTGÅNGNA', data.data, dispatch)
    return data.data
  } catch (e) {
    console.error(e)
  }
}

export const getCases = async () => {
  const api = useApi()
  const arenden = await api.get<Case[]>(`/v1/case/${localStorage.getItem('tenant_id')}/all?id_token=${localStorage.getItem('hydra_id_token')}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return arenden.data.map((item) => {
    return {
      ...item,
      key: item.id,
      created: new Date(item.created),
      senastUppdaterad: new Date(item.senastUppdaterad),
    }
  })
}

export const postCase = async (api: AxiosInstance, data: NewCase) => {
  try {
    const response = await api.post(`/v1/case/${localStorage.getItem('tenant_id')}/skapa`, data, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    return response.data
  } catch (err) {
    console.log(err)
    return err
  }
}

export const updateCase = async (api: AxiosInstance, data: INewLog) => {
  try {
    const response = await api.put(`/v1/case/${localStorage.getItem('tenant_id')}/comment`, data, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    return response.data
  } catch (err) {
    console.log(err)
  }
}

export const closeCase = async (api: AxiosInstance, data: INewLog) => {
  try {
    const response = await api.put(`/v1/case/${localStorage.getItem('tenant_id')}/close`, data, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    })

    return response.data
  } catch (err) {
    console.log(err)
  }
}
