import { useMutation, useQuery } from '@tanstack/react-query'
import { apiClient } from './useApi'

export const useGuests = () => useQuery(['guests'], async () => await apiClient.getGuestUsers(localStorage.getItem('tenant_id') ?? '').then((res) => res.data))

export const useGuest = (username: string) => useQuery(['guest', username], async () => await apiClient.getGuestUser(localStorage.getItem('tenant_id') ?? '', username).then((res) => res.data))

export const useAddGuest = () =>
  useMutation(
    ['addGuest'],
    async ({ username, password, personNumber }: { username: string; password: string; personNumber: string }) =>
      await apiClient.createGuestUser(localStorage.getItem('tenant_id') ?? '', { username, password, personNumber })
  )

export const useEditGuest = () =>
  useMutation(
    ['editGuest'],
    async ({ username, password, personNumber }: { username: string; password?: string; personNumber?: string }) =>
      await apiClient.editGuestUser(localStorage.getItem('tenant_id') ?? '', username, { password, personNumber })
  )

export const useDeleteGuest = () => useMutation(['deleteGuest'], async (username: string) => await apiClient.deleteGuestUser(localStorage.getItem('tenant_id') ?? '', username))
