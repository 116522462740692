import { Button, Divider, Form, Switch, Typography, notification } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Description, editBeslutsstod, getMunicipalityData } from '../../../../api/beslutsstod/beslutsstod'
import { useApi } from '../../../../api/useApi'
import FlagListHeader from '../../../../components/header'
import { getIBICMunicipality, setIBICMunicipality } from '../../../../store/beslutsstod/ibicMunicipalitySlice'
import { setMunicipality } from '../../../../store/beslutsstod/municipalitySlice'
import EditableCalculationSettings from '../../components/editableCalculationSettings'
import EditableFormSettings from '../../components/editableFormSettings'
import EditableOther from '../../EditableOther'
import EditTimepointsTasks from '../../EditTimepointTasks'
import { useIBICMunicipalityData } from '../../../../api/beslutsstod/ibic/useIBICMunicipalityData'
import EditableLifeAreas from './EditableLifeAreas'
import { useUpdateIBICMunicipalityData } from '../../../../api/beslutsstod/ibic/useUpdateIBICMunicipalityData'
import { useMe } from '../../../../api/permissions'
import { SettingOutlined, CalculatorOutlined } from '@ant-design/icons'
import { IBICMunicipalityData } from '../../types/municipalityData'
const api = useApi()

type Props = {}

const IBICBeslutsstodEditView: React.FC<Props> = (props) => {
  const [form] = Form.useForm()
  const [editDescription, setEditDescription] = useState('')
  const { mutateAsync: updateConfiguration } = useUpdateIBICMunicipalityData()

  const dispatch = useDispatch()

  const municipalityData = useSelector(getIBICMunicipality)

  const [notificationInstance, contextHolder] = notification.useNotification()

  const saveEdit = async () => {
    if (!municipalityData) {
      return
    }

    try {
      await updateConfiguration({ municipalityData, description: editDescription })
      notificationInstance.success({
        message: 'Ändringarna har sparats',
      })
    } catch (error) {
      notificationInstance.error({
        message: 'Ändringarna kunde inte sparas',
      })
    }
  }
  if (!municipalityData) {
    return null
  }

  return (
    <>
      {contextHolder}
      <div className="flex items-center gap-1">
        <EditableLifeAreas onChange={(municipalityData) => dispatch(setIBICMunicipality({ municipalityData }))} municipalityData={municipalityData} />
      </div>
      {
        <>
          <Form form={form} name={'dynamic-form'} onFinish={() => null}>
            <>
              <EditTimepointsTasks municipalityData={municipalityData} onChange={(municipalityData) => dispatch(setIBICMunicipality({ municipalityData }))} />
              <EditableOther municipalityData={municipalityData} onChange={(municipalityData) => dispatch(setIBICMunicipality({ municipalityData }))} />
              <Typography.Title
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBlock: '40px',
                }}
                level={3}
              >
                Övriga Inställningar
              </Typography.Title>
              <EditableFormSettings />
              <EditableCalculationSettings />
              <TextArea
                value={editDescription}
                style={{ width: 500 }}
                onChange={(e) => setEditDescription(e.target.value)}
                placeholder="Förklara dina ändringar"
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
              <Button onClick={saveEdit} type="primary" style={{ marginLeft: '4px' }}>
                Spara ändringar
              </Button>
              <Divider />
            </>
          </Form>
        </>
      }
    </>
  )
}

export default IBICBeslutsstodEditView
