import { ContentColumn } from '../..'
import { HomeCarePerformedHours } from './HomeCarePerformedHours'
import { HomeCarePerformedHoursTrendMixed } from './HomeCarePerformedHoursTrendMixed'

export const benchmarkingHemtjänstPerformedHours: ContentColumn[] = [
  {
    onlyRenderIfBenchmark: 'homeCarePerformedHours',
    content: (
      <HomeCarePerformedHours
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Totalt antal timmar utförd hemtjänst per månad och brukare',
  },
  {
    onlyRenderIfBenchmark: 'homeCarePerformedHoursTrendMixed',
    content: (
      <HomeCarePerformedHoursTrendMixed
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Totalt antal timmar utförd hemtjänst per månad och brukare, trend',
  },
]
