import { Dispatch } from 'redux'
import { IHandläggare } from '../../../store/organizationSlice'

export const SET_ANVANDARE = 'SET_ANVANDARE'

export type ISetAnvandareAction = {
  type: typeof SET_ANVANDARE
  payload: {
    data: IHandläggare[]
  }
}

export const setAnvandareAction = (type: string, data: IHandläggare[], dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_ANVANDARE,
    payload: {
      data: data,
    },
  })
}

export type Actions = ISetAnvandareAction
