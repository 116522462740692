import { Button, Form, Input, Popconfirm, Spin, notification } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useDeleteGuest, useEditGuest, useGuest } from '../../api/guest'
import { apiClient } from '../../api/useApi'
import BackButton from '../../components/buttons/backButton'
import FlagListHeader from '../../components/header'
import { getTeam } from '../../store/organizationSlice'
import NoMatchingUser from './NoMatchingUser'

export function getTeamNameById(id: number | undefined) {
  const teams = useSelector(getTeam)

  if (id) {
    let name = ''
    teams.forEach((item: { team_id: number; team_namn: string; avdelning: number }) => {
      if (item.team_id === id) {
        name = item.team_namn
      }
    })
    return name
  }
  return ''
}

const SingleGuest = () => {
  type Param = { username: string }
  const { username } = useParams<Param>()

  const { data: guest, refetch: refetchUser, isLoading: useGuestLoading } = useGuest(username)
  const { mutateAsync: deleteGuestUser } = useDeleteGuest()
  const { mutateAsync: editUser, isLoading: useEditLoading } = useEditGuest()

  const navigate = useHistory()

  const deleteGuest = async (id: string) => {
    try {
      await deleteGuestUser(id)
      navigate.push('/app/gäster')
      notification.success({
        message: 'Gästanvändare raderad',
        description: 'Gästanvändaren har raderats',
      })
    } catch (e) {
      notification.error({
        message: 'Gästanvändare kunde inte raderas',
        description: 'Gästanvändaren kunde inte raderas',
      })
    }
  }

  const onFinish = async (values: { username: string; password: string; personNumber: string }) => {
    try {
      const passwordChanged = !!values.password
      const personNumberChanged = values.personNumber !== guest?.personNumber
      await editUser({
        username: values.username,
        password: passwordChanged ? values.password : undefined,
        personNumber: values.personNumber,
      })
      await refetchUser()
      notification.success({
        message: 'Gästanvändare uppdaterad',
        description: (
          <>
            {passwordChanged ? 'Lösenord ändrat' : ''}
            <br />
            {personNumberChanged ? 'Personnummer ändrat' : ''}
          </>
        ),
      })
    } catch (e) {
      notification.error({
        message: 'Gästanvändare kunde inte uppdateras',
        description: 'Gästanvändaren kunde inte uppdateras',
      })
    }
  }

  return (
    <>
      <FlagListHeader
        title=""
        heading="Redigera gästanvändare"
        description="Redigera en gästanvändare genom att ändra informationen i fälten nedan, klicka sedan på spara. Tänk på att spara lösenordet om det ändras. Om användarnamn måste uppdateras, skapa en ny användare och radera den gamla."
        button={false}
      />

      {!guest?.hasUser && guest?.personNumber && <NoMatchingUser personNumber={guest?.personNumber} />}
      {useGuestLoading && <Spin />}
      {!useGuestLoading && guest && (
        <Form
          name="guest-edit"
          initialValues={{
            username: guest.username,
            personNumber: guest.personNumber,
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          style={{ maxWidth: '600px' }}
        >
          <Form.Item label="Användarnamn" name="username">
            <Input type={'text'} placeholder="Användarnamn" disabled />
          </Form.Item>
          <Form.Item label="Lösenord" name="password" rules={[{ min: 4, message: 'Lösenordet måste vara minst 4 tecken' }]}>
            <Input type={'password'} placeholder="Byt lösenord" />
          </Form.Item>
          <Form.Item
            label="Personnummer"
            name="personNumber"
            rules={[
              {
                required: true,
                message: 'Vänligen fyll i personnummer i formatet ÅÅÅÅMMDD-XXXX',
                pattern: new RegExp(/^[0-9]{8}-[0-9]{4}$/),
              },
            ]}
          >
            <Input type="text" placeholder="ÅÅÅÅMMDD-XXXX" />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', gap: '6px' }}>
              <BackButton display="inline" />
              <Button type="primary" htmlType="submit" style={{ display: 'inline-block', marginTop: '20px' }} loading={useEditLoading}>
                Spara
              </Button>
              <Popconfirm title="Säker att du vill radera denna gästanvändare?" okText="Bekräfta" cancelText="Avbryt" onConfirm={() => deleteGuest(guest.username)}>
                <Button type="primary" danger style={{ display: 'inline-block', marginTop: '20px' }} loading={useGuestLoading}>
                  Radera
                </Button>
              </Popconfirm>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  )
}

export default SingleGuest
