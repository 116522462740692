import { round } from '../../Dashboard/graphConfig'

export interface DataObject {
  [key: string]: number | string | null
}

function divideIndexes(data1: DataObject[], data2: DataObject[]): any[] {
  const result: DataObject[] = []
  if (data1.length <= 1 || data2.length <= 1) return result
  for (let i = 0; i < data1.length; i++) {
    const obj1 = data1[i]
    const matchingObj2 = data2.find((obj2) => obj2?.name === obj1?.name)

    if (matchingObj2) {
      const dividedObj: DataObject = { name: obj1?.name }

      for (const key in obj1) {
        if (key !== 'name') {
          const value1 = obj1[key] as number
          const value = matchingObj2[key] as string
          if (!value) {
            dividedObj[key] = null
            continue
          }
          const value2 = parseFloat(value.replace(/\s/g, ''))

          if (typeof value1 === 'number' && typeof value2 === 'number') {
            dividedObj[key] = value1 !== 0 ? round(value2 / value1, 1) : null
          } else {
            dividedObj[key] = null
          }
        }
      }

      result.push(dividedObj)
    }
  }

  return result
}

export default divideIndexes
