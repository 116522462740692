import { Select, Spin } from 'antd'
import { ComponentProps, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { useHomeCareOrderedHoursPerPersonValues, useHomeCareProportionValues, useMunicipalityName, useVolumeProportionAgeGroupValues } from '../../../../api/benchmarking'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { getFontSize } from '../../util/fontSize'
import { BASELINE_COLOR, getColorByIndex, getComparisonColor, MEDIAN_COLOR, QUARTILE_1_COLOR, QUARTILE_3_COLOR } from '../../util/colors'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { BORDER_WIDTH } from '../../util/constants'
import { baseGraphOptions } from '../../util/baseGraphOptions'
import { NO_COMPARISON } from '../../util/names'
import { EmptySelector } from '../../components/EmptySelector'

export const HomeCareProportion = ({ text }: { text: React.ReactNode }) => {
  const { data: municipalityName, isLoading: isMunicipalityNameLoading } = useMunicipalityName()
  const { data, isLoading, mutate } = useHomeCareProportionValues()

  useEffect(() => {
    mutate({})
  }, [])

  if (isLoading || isMunicipalityNameLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  console.log(data)

  const graphOptions: ComponentProps<typeof Bar>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${Math.round(context.parsed.y * 10) / 10}%`
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
    },
  }

  const graphData: ComponentProps<typeof Bar>['data'] = {
    labels: [municipalityName, 'Undre kvartil', 'Median', 'Övre kvartil'],
    datasets: [
      {
        backgroundColor: [BASELINE_COLOR.fill, QUARTILE_1_COLOR.fill, MEDIAN_COLOR.fill, QUARTILE_3_COLOR.fill],
        borderColor: [BASELINE_COLOR.border, QUARTILE_1_COLOR.border, MEDIAN_COLOR.border, QUARTILE_3_COLOR.border],
        data: [data.baselineValues.value, data.comparisonValues.quartile1, data.comparisonValues.median, data.comparisonValues.quartile3],
        borderWidth: BORDER_WIDTH,
      },
    ],
  }

  const dataSourceSelector = (
    <Select
      value={data.currentDataSource}
      onChange={(value) => {
        mutate({ datasource: value, grouping: undefined })
      }}
      options={data.dataSources.map((ds) => ({
        label: ds,
        value: ds,
      }))}
      style={{ width: '100%' }}
    />
  )

  const comparisonSelector = <EmptySelector value={'Fördelning'} />

  const graph: React.ReactNode = <Bar options={graphOptions} data={graphData} />
  const legend = (
    <BenchmarkingLegend
      items={[
        { text: municipalityName, borderColor: BASELINE_COLOR.border, fillColor: BASELINE_COLOR.fill },
        { text: 'Undre kvartil', borderColor: QUARTILE_1_COLOR.border, fillColor: QUARTILE_1_COLOR.fill },
        { text: 'Median', borderColor: MEDIAN_COLOR.border, fillColor: MEDIAN_COLOR.fill },
        { text: 'Övre kvartil', borderColor: QUARTILE_3_COLOR.border, fillColor: QUARTILE_3_COLOR.fill },
      ]}
    />
  )

  const title = 'Andel med hemtjänst för kommunen och jämförelsegrupper, 80+'
  return <BenchmarkingContentRow graph={graph} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} text={text} title={title} legend={legend} />
}
