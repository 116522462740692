import React, { useMemo } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { benchmarkingLanding } from './benchmarks/BenchmarkingLanding'
import { benchmarkingVolume } from './benchmarks/Volume'
import BenchmarkingNavigation from './components/BenchmarkingNavigation'
import BenchmarkingPageSelector from './components/BenchmarkingPageSelector'
import { benchmarkingSäbo } from './benchmarks/Säbo'
import { benchmarkingSäboBrukareCount } from './benchmarks/SäboBrukareCount'
import { benchmarkingStayLength } from './benchmarks/SäboStayLength'
import { benchmarkingHemtjänst } from './benchmarks/Hemtjänst'
import { benchmarkingHemtjänstBrukareCount } from './benchmarks/HemtjänstBrukareCount'
import { benchmarkingHemtjänstHours } from './benchmarks/HemtjänstHours'
import { benchmarkingHemtjänstPerformedHours } from './benchmarks/HemtjänstPerformedHours'
import { benchmarkingHemtjänstOrderedHours } from './benchmarks/HemtjänstOrderedHours'
import { GetSupportedBenchmarks200Response } from '../../gen/api'
import { useSupportedBenchmarks } from '../../api/benchmarking'
import { Spin } from 'antd'

export interface ContentColumn {
  pageTitle: string
  content: React.ReactNode
  onlyRenderIfBenchmark?: keyof GetSupportedBenchmarks200Response
}

export interface BenchmarkingView {
  title: string
  contentColumns: ContentColumn[]
  children?: BenchmarkingView[]
  path: string
}
const views: BenchmarkingView = {
  path: '/app/benchmarking',
  title: 'Volym',
  contentColumns: benchmarkingVolume,
  children: [
    {
      path: '/app/benchmarking/hemtjänst',
      title: 'Hemtjänst',
      contentColumns: benchmarkingHemtjänst,
      children: [
        {
          path: '/app/benchmarking/hemtjänst/antal-brukare',
          title: 'Antal brukare',
          contentColumns: benchmarkingHemtjänstBrukareCount,
        },
        {
          path: '/app/benchmarking/hemtjänst/antal-timmar',
          title: 'Antal timmar',
          contentColumns: benchmarkingHemtjänstHours,
          children: [
            {
              path: '/app/benchmarking/hemtjänst/antal-timmar/beställda-timmar',
              title: 'Beställda timmar',
              contentColumns: benchmarkingHemtjänstOrderedHours,
            },
            {
              path: '/app/benchmarking/hemtjänst/antal-timmar/utförda-timmar',
              title: 'Utförda timmar',
              contentColumns: benchmarkingHemtjänstPerformedHours,
              children: [
                {
                  path: '/app/benchmarking/hemtjänst/antal-timmar/utförda-timmar/antal-besök',
                  title: 'Antal besök',
                  contentColumns: benchmarkingLanding,
                },
                {
                  path: '/app/benchmarking/hemtjänst/antal-timmar/utförda-timmar/besökslängd',
                  title: 'Besökslängd',
                  contentColumns: benchmarkingLanding,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/app/benchmarking/särskilt-boende',
      title: 'Särskilt boende',
      contentColumns: benchmarkingSäbo,
      children: [
        {
          path: '/app/benchmarking/särskilt-boende/demens/antal-brukare',
          title: 'Antal brukare',
          contentColumns: benchmarkingSäboBrukareCount,
        },
        {
          path: '/app/benchmarking/särskilt-boende/demens/vistelsetid',
          title: 'Vistelsetid',
          contentColumns: benchmarkingStayLength,
        },
      ],
    },
  ],
}

const getCurrentView = (path: string, views: BenchmarkingView): BenchmarkingView | undefined => {
  if (!path.includes(views.path)) {
    return undefined
  }

  const recursiveResult = views.children?.reduce<BenchmarkingView | undefined>((acc, child) => acc ?? getCurrentView(path, child), undefined)

  return recursiveResult ?? views
}

const BenchmarkingMainView = () => {
  const { data: supportedBenchmarks } = useSupportedBenchmarks()
  const router = useHistory()

  const view = getCurrentView(router.location.pathname, views)
  if (!view) {
    router.push('/app/benchmarking')
    return <></>
  }

  const handleScroll = (lastEntry: IntersectionObserverEntry) => {
    if (scrollingToTab.current !== null) {
      // We are not yet at the tab we are scrolling to
      if (scrollingToTab.current !== lastEntry.target.id) {
        return
      }
      // We have scrolled to the tab we are scrolling to
      scrollingToTab.current = null
    }

    // log the key of the last entry
    const key = parseInt(lastEntry.target.id)

    // Set hash without navigating or scrolling
    window.history.replaceState(undefined, '', `#${key}`)
    setActiveTab(key.toString())
  }
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined)

  const refs = useRef(new Map<number, HTMLDivElement | null>())
  useEffect(() => {
    const IntersectionObserver = new window.IntersectionObserver(
      (entries) => {
        const lastEntry = entries.filter((entry) => entry.isIntersecting).pop()
        if (lastEntry) {
          if (timeoutId) {
            clearTimeout(timeoutId)
          }
          setTimeoutId(
            window.setTimeout(() => {
              handleScroll(lastEntry)
            }, 100)
          )
        }
      },
      {
        threshold: 0.75,
      }
    )
    refs.current.forEach((ref, key) => {
      if (ref) {
        IntersectionObserver.observe(ref)
      }
    })
    return () => {
      IntersectionObserver.disconnect()
    }
  }, [refs.current.size])

  const scrollToElement = (id: number) => {
    const refElem = refs.current.get(id)
    refElem?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleRef = (id: number) => (ref: HTMLDivElement | null) => {
    refs.current.set(id, ref)
  }

  const [activeTab, setActiveTab] = useState('')
  const scrollingToTab = useRef<null | string>(null)

  const containerRef = useRef<HTMLDivElement | null>(null)
  const filteredColumns = useMemo(() => {
    if (!supportedBenchmarks) {
      return []
    }
    // Currently we ignore the onlyRenderIfBenchmark property but keep it for future use
    return view.contentColumns
    // .filter((column) => !column.onlyRenderIfBenchmark || supportedBenchmarks[column.onlyRenderIfBenchmark])
  }, [view.contentColumns, supportedBenchmarks])

  if (!supportedBenchmarks) {
    return (
      <div className="flex h-80 w-full items-center justify-center">
        <Spin spinning={!supportedBenchmarks} size="large" />
      </div>
    )
  }

  return (
    <>
      <div className="grid max-h-[calc(100vh-48px)] grid-rows-[160px,1fr] overflow-y-hidden">
        <div className="flex h-full items-start justify-between gap-6 border-b bg-white py-2">
          <BenchmarkingNavigation
            views={views}
            activeView={view}
            navigateToView={() => {
              if (window.location.hash !== `#1`) {
                activeTab !== '1' && setActiveTab('1')
                scrollingToTab.current = '1'
                window.history.replaceState(undefined, '', `#1`)
                refs.current.clear()
              }
              setTimeout(() => {
                containerRef.current?.scrollTo({ top: 0 })
              }, 300)
            }}
          />
          {filteredColumns.length > 1 && (
            <BenchmarkingPageSelector
              pages={filteredColumns.map((column, i) => ({ title: column.pageTitle ?? '', id: i + 1, scrollIntoView: () => scrollToElement(i + 1) }))}
              activeTabId={activeTab}
              onTabChange={(tab) => {
                if (window.location.hash !== `#${tab}`) {
                  scrollingToTab.current = tab
                }
                setActiveTab(tab)
              }}
            />
          )}
        </div>
        <div
          className="flex snap-y snap-mandatory flex-col overflow-y-auto"
          ref={containerRef}
          style={{
            height: 'calc(100vh-48px)',
          }}
        >
          {filteredColumns.map((column, i) => (
            <div ref={handleRef(i + 1)} key={`${column.pageTitle}-${i}`} id={(i + 1).toString()} className="snap-center" style={{ height: 'calc(100vh)' }}>
              {column.content}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default BenchmarkingMainView
