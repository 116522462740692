import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'
import { Button } from 'antd'
import { useLayoutEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { BenchmarkingView } from '..'

const LEFTMOST_VIEWS = [
  '/app/benchmarking',
  '/app/benchmarking/hemtjänst',
  '/app/benchmarking/hemtjänst/antal-brukare',
  '/app/benchmarking/hemtjänst/antal-timmar/beställda-timmar',
  '/app/benchmarking/hemtjänst/antal-timmar/utförda-timmar/antal-besök',
]

interface NavigationButtonProps {
  view: BenchmarkingView
  activeView: BenchmarkingView
  activeViewHeight: number
  currentDepth: number
  parent?: BenchmarkingView
  navigateToView?: (view: BenchmarkingView) => void
}

// Recursive component that renders the navigation buttons
const NavigationButton = ({ view, activeView, currentDepth, activeViewHeight, parent, navigateToView }: NavigationButtonProps) => {
  const router = useHistory()
  const ref = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (activeView.path === view.path && ref.current) {
      smoothScrollIntoView(ref.current, { block: 'start', behavior: 'smooth' })
    }
  }, [activeView.path])

  // Get the number of children (that are currently scrolled into view), at the lowest level. This is how many units of width we should take up
  const getChildrenWidth = (view: BenchmarkingView, depth = currentDepth): number => {
    // If we have no children, we take up 1 unit of width
    if (!view.children) return 1

    // Max depth reached, we take up 1 unit of width
    if (activeViewHeight > 0 && depth >= activeViewHeight + 1) return 1

    // Special case where root is active view, then there is 1 extra allowed depth since we show two levels below the root
    if (activeViewHeight === 0 && depth >= activeViewHeight + 2) return 1

    // Avoid recursion if the activeView has no children since no scroll was done
    if (!activeView.children && ((activeViewHeight > 0 && depth >= activeViewHeight) || (activeViewHeight === 0 && depth >= activeViewHeight + 1))) {
      return 1
    }

    return view.children.reduce((acc, child) => acc + getChildrenWidth(child, depth + 1), 0)
  }

  const getWidthValue = () => {
    const childrenWidth = getChildrenWidth(view)
    return childrenWidth * 135
  }

  return (
    <div
      className="benchmarking-navigation flex h-full flex-col justify-between transition-[width] duration-500"
      style={{
        width: LEFTMOST_VIEWS.includes(view.path) ? getWidthValue() + 1 : getWidthValue(),
        marginLeft: LEFTMOST_VIEWS.includes(view.path) && parent && !LEFTMOST_VIEWS.includes(parent?.path) ? -1 : 0,
      }}
    >
      <Button
        key={view.path}
        tabIndex={1}
        onClick={() => {
          router.push(view.path)
          navigateToView?.(view)
        }}
        className="!hover:border-[#d9d9d9] w-full !rounded-none"
        style={{
          // Messy styling but idea is to have single borders everwhere, similar to a grid instead of every button having a border
          scrollMarginTop: view.children?.length ? '33px' : '65px',
          backgroundColor: activeView.path === view.path ? '#e6f7ff' : 'white',
          color: activeView.path === view.path ? '#1890ff' : 'black',
          textShadow: activeView.path === view.path ? 'none' : '',
          borderLeft: activeView.path === view.path ? '1px solid #1890ff' : LEFTMOST_VIEWS.includes(view.path) ? '1px solid #d9d9d9' : 'none',
          borderTop: activeView.path === view.path ? '1px solid #1890ff' : !parent ? '1px solid #d9d9d9' : 'none',
          borderBottom: activeView.path === view.path ? '1px solid #1890ff' : '1px solid #d9d9d9',
          borderRight: activeView.path === view.path ? '1px solid #1890ff' : '1px solid #d9d9d9',
          boxShadow: 'none',
        }}
        ref={ref}
      >
        {view.title}
      </Button>
      <div className="flex">
        {view.children?.map((child, i) => (
          <NavigationButton
            view={child}
            activeView={activeView}
            key={child.path + i}
            currentDepth={currentDepth + 1}
            activeViewHeight={activeViewHeight}
            parent={view}
            navigateToView={navigateToView}
          />
        ))}
      </div>
    </div>
  )
}

interface BenchmarkingNavigationProps {
  views: BenchmarkingView
  activeView: BenchmarkingView
  isChild?: boolean
  navigateToView?: (view: BenchmarkingView) => void
}

const BenchmarkingNavigation = ({ views, activeView, navigateToView }: BenchmarkingNavigationProps) => {
  const getActiveViewHeight = (view: BenchmarkingView, activeView: BenchmarkingView): number => {
    // This means we are the active view or a child is the active view
    if (activeView.path.includes(view.path)) {
      // We are the active view
      if (!view.children) {
        return 1
      }
      return view.children.reduce((acc, child) => acc + getActiveViewHeight(child, activeView), 0) + 1
    }
    return 0
  }
  const activeViewHeight = getActiveViewHeight(views, activeView)

  const getMaxHeight = (view: BenchmarkingView, activeView: BenchmarkingView, activeViewHeight: number) => {
    // If height is 97px, the container is 1 px too high (only at the top of scrolling), these cases reduce
    if (activeViewHeight < 3 || (activeViewHeight === 3 && !activeView.children)) {
      return '96px'
    }
    return '97px'
  }

  return (
    <div>
      <div className="overflow-y-hidden pl-1" style={{ maxHeight: getMaxHeight(views, activeView, activeViewHeight) }}>
        <NavigationButton view={views} activeView={activeView} currentDepth={0} activeViewHeight={activeViewHeight - 1} navigateToView={navigateToView} />
      </div>
    </div>
  )
}

export default BenchmarkingNavigation
