import { Col, Row } from 'antd'
import { Line } from 'react-chartjs-2'
import dashBoardOptions from '../utils/TableConfig/dashBoardOptions'
import { antalBrukareBevlijadData, antalNyaArandendData, avgAgeData, BeviljadTidData, beviljadTidPerBrukareData, omfattandeKaraktarData, utforandegradData, utfordTidData } from './graphConfig'
import { ComponentProps } from 'react'

const Dashboard = (data: any) => {
  return (
    <>
      <div>
        <Row gutter={[10, 16]}>
          <WrappedGraph options={dashBoardOptions({ title: 'Antal brukare med beställd tid', grace: '30%', integer: true })} data={antalBrukareBevlijadData(data.brukareBestalldTid)} />

          <WrappedGraph options={dashBoardOptions({ title: 'Beställd tid', grace: '30%', integer: true })} data={BeviljadTidData(data.approvedTime)} />

          <WrappedGraph options={dashBoardOptions({ title: 'Beställd tid per brukare', grace: '30%' })} data={beviljadTidPerBrukareData(data.brukareBestalldTid, data.approvedTime)} />

          <WrappedGraph options={dashBoardOptions({ title: 'Antal helt nya ärenden', grace: '5%', integer: true })} data={antalNyaArandendData(data.newCases)} />

          <WrappedGraph options={dashBoardOptions({ title: 'Antal avslutade ärenden', grace: '5%', integer: true })} data={antalBrukareBevlijadData(data.canceledCases)} />

          <WrappedGraph options={dashBoardOptions({ title: 'Genomsnittlig ålder', grace: '30%' })} data={avgAgeData(data.avgAge)} />

          <WrappedGraph options={dashBoardOptions({ title: 'Utförandegrad', showPercent: true, grace: '30%' })} data={utfordTidData(data.utfordTid, data.approvedTime)} />

          <WrappedGraph options={dashBoardOptions({ title: 'Antal beslut av omfattande karaktär', grace: '5%', integer: true })} data={omfattandeKaraktarData(data.amountOrderedTime)} />
        </Row>
      </div>
    </>
  )
}

export default Dashboard
type WrappedGraphProps = {
  options: ComponentProps<typeof Line>['options']
  data: ComponentProps<typeof Line>['data']
}
const WrappedGraph = ({ options, data }: WrappedGraphProps) => {
  return (
    <Col span={8} className="h-[calc((100vh-174px)/3)]">
      <Line options={options} data={data} />
    </Col>
  )
}
