import { Checkbox, Input, Select, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getMunicipality, setMunicipality } from '../../../store/beslutsstod/municipalitySlice'
import { CalculationOutputSettings } from '../types/municipalityData'

const { Paragraph } = Typography

const EditableCalculationSettings = () => {
  const dispatch = useDispatch()
  const municipalityData = useSelector(getMunicipality)

  if (!municipalityData) return null
  let editableData = JSON.parse(JSON.stringify(municipalityData))

  const updateCalculationSettings = (calculationSettings: CalculationOutputSettings) => {
    editableData.input.calculationOutputSettings = calculationSettings
    dispatch(setMunicipality({ municipalityData: editableData }))
  }

  return (
    <>
      <div
        style={{
          marginBlock: '30px',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gap: '10px',
        }}
      >
        <div>
          <Paragraph type="secondary">Enhet på utdata</Paragraph>
          <Select
            defaultValue={municipalityData.input.calculationOutputSettings.unit}
            onChange={(value) => updateCalculationSettings({ ...municipalityData.input.calculationOutputSettings, unit: value })}
          >
            <Select.Option value="hours_and_minutes">Timmar och minuter</Select.Option>
            <Select.Option value="decimal">Timmar (decimalt)</Select.Option>
          </Select>
        </div>
        <div>
          <Paragraph type="secondary">Veckor per månad för övriga insatser</Paragraph>
          <Select
            defaultValue={municipalityData.input.calculationOutputSettings.weeksPerMonth}
            onChange={(value) => updateCalculationSettings({ ...municipalityData.input.calculationOutputSettings, weeksPerMonth: value })}
            style={{ width: '100px' }}
          >
            <Select.Option value={4.35}>4.35</Select.Option>
            <Select.Option value={4}>4</Select.Option>
          </Select>
        </div>
      </div>
    </>
  )
}

export default EditableCalculationSettings
