import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setMedarbetare } from '../../api/medarbetareApi'
import { useApi } from '../../api/useApi'
import FlagListHeader from '../../components/header'
import { CreateFilterConfig } from '../../components/tablesConfig/filterTableConfig'
import { DataType } from '../../components/tablesConfig/filterconfig'
import { setCaseAction } from '../arende/store/caseReducer/actions'
import { selectMedLoading, selectMedarbetare } from './store/selectors'

// Antd
import { DatePicker, Table } from 'antd'
import type { TableProps } from 'antd/es/table'
import moment from 'moment'
import { getPersonalDateFrom, getPersonalDateTo, setPersonalDate } from '../../store/personalDateSlice'
import { setPersonalFilter } from '../../store/personalFilterSlice'

export type IMedarbetare = {
  id: number
  flagga: number
  flaggnamn: string
  nivå: number
  namn: string
  från: Date
  till: Date
  created: Date
  utförare: {
    utförare_id: number
    utförare_namn: string
  }[]
}

const Medarbetare: React.FC = () => {
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
  const dispatch = useDispatch()
  const dispatchCallback = useCallback(dispatch, [dispatch])
  const [startDate, setStartDate] = useState(useSelector(getPersonalDateFrom))
  const [endDate, setEndDate] = useState(useSelector(getPersonalDateTo))
  const [datesPicked, setDatesPicked] = useState(false)
  const api = useApi()
  const navigate = useHistory()
  const { RangePicker } = DatePicker

  // Redux states
  const medarbetareState = useSelector(selectMedarbetare)
  const loadingState = useSelector(selectMedLoading)

  const monthFormat = 'YYYY/MM'

  const handleChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
    dispatch(setPersonalFilter({ data: { filter: filters, sorter: sorter } }))
  }

  function handleDatesChange(range: any) {
    const start = range[0].startOf('month').format().split('T')[0]
    const end = range[1].endOf('month').format().split('T')[0]
    setStartDate(start)
    setEndDate(end)
  }

  function handleOpenChange(isOpen: boolean) {
    setDatesPicked(isOpen)
  }

  useEffect(() => {
    setMedarbetare(api, dispatchCallback, { from: startDate, to: endDate })
    dispatch(setPersonalDate({ from: startDate, to: endDate }))
  }, [datesPicked])

  useEffect(() => {
    if (loadingState) {
      setMedarbetare(api, dispatch, { from: startDate, to: endDate })
    }
  }, [loadingState, api, dispatch])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      selectedRows.length > 0 ? setBtnDisabled(false) : setBtnDisabled(true)
      setCaseAction('SET_CASE', selectedRows, null, dispatch)
    },
  }

  return (
    <>
      <FlagListHeader
        heading={'Personal'}
        title={null}
        description={
          'Denna vy visar flaggor som skapats under vald tidsperiod. På denna sida visas flaggor som skapats baserat på data från enskilda medarbetare hos utförare. Flaggor indikerar att något inträffat som kan behöva följas upp. En flagga med högre risknivå är en starkare indikation än en flagga med lägre risknivå. Val av tidsperiod baseras på när flaggan skapades.'
        }
        button={true}
        disabled={btnDisabled}
      />
      <RangePicker
        className="range-picker"
        style={{ minWidth: '20%', maxHeight: '40px', marginBottom: '20px', marginRight: '25px' }}
        picker="month"
        defaultValue={[moment(startDate, monthFormat), moment(endDate, monthFormat)]}
        onChange={handleDatesChange}
        onOpenChange={handleOpenChange}
      />
      {medarbetareState && (
        <Table
          scroll={{ x: 1000, y: 'calc(100vh - 400px)' }}
          columns={CreateFilterConfig(medarbetareState)}
          rowSelection={{ ...rowSelection }}
          dataSource={medarbetareState}
          bordered
          size="small"
          onChange={handleChange}
          pagination={false}
          loading={loadingState}
          rowClassName="table-row-cursor"
        />
      )}
    </>
  )
}

export default Medarbetare
