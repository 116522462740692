import { createStore, combineReducers, applyMiddleware, Middleware, compose } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension'
import anvandareReducer from '../features/anvandare/store'
import arendenReducer from '../features/arende/store/arendeReducer'
import caseReducer from '../features/arende/store/caseReducer'
import brukareReducer from '../features/brukare/store'
import medarbetareReducer from '../features/medarbetare/store'
import { createReduxEnhancer as sentryReduxEnhancer } from '../sentry'
import tableFilterReducer from '../components/tablesConfig/store'
import flagReducer from '../components/singleDeviation/store'
import tabSlice from '../features/arendeFordelning/store/tabSlice'
import userReducer from './userStore'
import ansvarigPersonSlice from './ansvarigPersonSlice'
import utförareSlice from './utförareSlice'
import organizationSlice from './organizationSlice'
import personalDateSlice from './personalDateSlice'
import brukareDateSlice from './brukareDateSlice'
import personalFilterSlice from './personalFilterSlice'
import brukareFilterSlice from './brukareFilterSlice'
import municipalitySlice from './beslutsstod/municipalitySlice'
import utforareFilterSlice from './utforareFilterSlice'
import sideMenuSlice from './sideMenuSlice'
import ibicMunicipalitySlice from './beslutsstod/ibicMunicipalitySlice'

const rootReducer = combineReducers({
  flagReducer: flagReducer,
  medarbetareReducer: medarbetareReducer,
  brukareReducer: brukareReducer,
  caseReducer: caseReducer,
  anvandareReducer: anvandareReducer,
  arendenReducer: arendenReducer,
  tableFilterReducer: tableFilterReducer,
  tabReducer: tabSlice,
  userReducer: userReducer,
  ansvarigPersonReducer: ansvarigPersonSlice,
  utförareReducer: utförareSlice,
  organizationReducer: organizationSlice,
  personalDateReducer: personalDateSlice,
  brukareDateReducer: brukareDateSlice,
  personalFilterReducer: personalFilterSlice,
  brukareFilterReducer: brukareFilterSlice,
  municipalityReducer: municipalitySlice,
  ibicMunicipalityReducer: ibicMunicipalitySlice,
  utforareFilterReducer: utforareFilterSlice,
  sideMenuReducer: sideMenuSlice,
})

export type AppState = ReturnType<typeof rootReducer>

export default function configureStore() {
  const middlewares: Middleware[] = []
  const middleWareEnhancer = applyMiddleware(...middlewares)

  const store = createStore(
    rootReducer,
    compose(
      middleWareEnhancer,
      sentryReduxEnhancer(),
      devToolsEnhancer({
        trace: true,
      })
    )
  )

  return store
}
