import { IBICTaskWithRule } from '../calculation/IBICBeslutsstodCalculationView'

export const canBePlannedWith = (task: IBICTaskWithRule, taskToPlanWith: IBICTaskWithRule) => {
  switch (task.ibicCalculationRule.type) {
    case 'IBICUndefinedRule':
    case 'IBICAllRule':
      return true
    case 'IBICPartialRule':
      return task.ibicCalculationRule.canBeSharedWith.some((sharedWith) => {
        return sharedWith.taskName === taskToPlanWith.name && sharedWith.levels.includes(taskToPlanWith.level ?? '')
      })
    case 'IBICNoneRule':
      return false
  }
}

export const isBothCoPlannable = (task1: IBICTaskWithRule, task2: IBICTaskWithRule) => {
  return canBePlannedWith(task1, task2) && canBePlannedWith(task2, task1)
}
