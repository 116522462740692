import { ContentColumn } from '../..'
import { HomeCareResidencyCostMix } from './HomeCareResidencyCostMix'
import { VolumeAgeGroup } from './VolumeAgeGroup'
import { VolumeDistribution } from './VolumeDistribution'
import { VolumeInsatsMix } from './VolumeInsatsMix'
import { VolumeProportionAgeGroup } from './VolumeProportionAgeGroup'
import { VolumeQualityMix } from './VolumeQualityMix'

export const benchmarkingVolume: ContentColumn[] = [
  {
    onlyRenderIfBenchmark: 'volume',
    content: (
      <VolumeDistribution
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Diagramen illustrerar fördelningen av brukare och kostnader mellan ordinärt boende och särskilt boende. 
            <br />
            <br />
            <b>Bakgrund och syfte</b>
            <br />
            Måttet beskriver om kommunen har en verksamhet som är tung mot insatser i ordinärt boende eller särskilt boende. Fördelningen spelar ofta stor roll för kostnadseffektivitet. Med väl 
            avvägda beslut om särskilt boende får kommunen hanterbara brukarvolymer på särskilt boende.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Över lag sker en utveckling där äldre i högre utsträckning önskar bo kvar hemma, och insatserna i ordinärt boende förbättras. En tyngdpunkt mot ordinärt boende 
            förutsätter ofta bättre fungerande hemtjänst och hemsjukvård som utgör basen i äldreomsorgen. En förskjutning mot högre andel i särskilt boende tenderar 
            att driva kostnaderna på totalen. Detta eftersom boendeplatser över hela vistelsetiden tenderar att kosta mer per brukare än hemtjänst.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Fördelning av volymer och kostnader inom äldreomsorgen',
  },
  {
    onlyRenderIfBenchmark: 'volumeAgeGroup',
    content: (
      <VolumeAgeGroup
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Andelen äldre med stöd från äldreomsorgen per fem års åldersintervall. 
            <br />
            <br />
            <b>Bakgrund och syfte</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra kommuner med olika åldersdemografi (inte bara 65+ och 80+). Insatser från äldreomsorgen 
            ökar kraftigt med åldern, med en ungefärlig dubblering vart femte år för särskilt boende.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Måttet ger en känsla för om kommunen är frikostig eller restriktiv med insatser. Grafen ska tolkas med viss försiktighet då hög volym ofta drivs av att 
            kommunen har många äldre med relativt små insatser i ordinärt boende. Detta kan vara av godo om det går att säkerställa att dessa insatser är förebyggande. Titta gärna vidare på 
            motsvarande grafer uppdelat för ordinärt boende, respektive särskilt boende för att få en bättre förståelse på djupet. 
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Andel med insatser från äldreomsorgen i respektive åldersgrupp',
  },

  {
    onlyRenderIfBenchmark: 'volumeProportionAgeGroup',
    content: (
      <VolumeProportionAgeGroup
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Fördelningen av insatser mellan ordinärt och särskilt boende per fem års åldersintervall. 
            <br />
            <br />
            <b>Bakgrund och syfte</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra kommuner med olika åldersdemografi (inte bara 65+ och 80+).
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Fördelningen ger en känsla för om kommunen är tung mot särskilt boende eller ordinärt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Fördelning mellan insatser i ordinärt resp. särskilt boende per åldersgrupp',
  },
  {
    onlyRenderIfBenchmark: 'volumeInsatsMix',
    content: (
      <VolumeInsatsMix
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Andel 80+ i särskilt boende (y-axel) och genomsnitt antal beviljade timmar i hemtjänst (x-axel).
            <br />
            <br />
            <b>Bakgrund och syfte</b>
            <br />
            Diagrammet ska ge er en känsla för var ni placerar er i förhållande till andra kommuner. Måtten har valts för att se var ni placerar er avseende 
            genomsnittlig volym i hemtjänst och volym i särskilt boende.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Hög volym på båda eller någon av axlarna bör följas upp och förklaras mer på djupet då det kan signalera att det finns möjlighet att förbättra verksamheten.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Brukarvolym och insatsmix',
  },
  {
    onlyRenderIfBenchmark: 'volumeInsatsMix',
    content: (
      <HomeCareResidencyCostMix
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Kostnad särskilt boende 80+ i särskilt boende (y-axel) och genomsnitt antal beviljade timmar i hemtjänst (x-axel).
            <br />
            <br />
            <b>Bakgrund och syfte</b>
            <br />
            Diagrammet ska ge er en känsla för var ni placerar er i förhållande till andra kommuner. Måtten har valts för att se var ni placerar 
            er avseende genomsnittlig volym i hemtjänst och volym i särskilt boende.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Hög volym på någon eller båda av axlarna bör följas upp och förklaras mer på djupet då det kan signalera att det finns möjlighet att förbättra resurseffektiviteten i verksamheten.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Insatsmix, kostnader',
  },
]
