const amountOmfattandeFilter = (amountBrukare: any, amountOrderedTime: any) => {
  const res: any[] = []
  var hundredFifty: {
    [key: string]: any
  } = { name: '150-180 h' }
  var hundredEighty: {
    [key: string]: any
  } = { name: '180+ h' }
  var total: {
    [key: string]: any
  } = { name: 'Total' }

  amountOrderedTime.forEach((item: any, index: number) => {
    hundredFifty[index] = item.less_than_one_hundred_eighty
    hundredEighty[index] = item.more_than_one_hundred_eighty
    total[index] = item.less_than_one_hundred_eighty + item.more_than_one_hundred_eighty
  })

  res.push(hundredFifty)
  res.push(hundredEighty)
  res.push(total)

  return res
}

export default amountOmfattandeFilter
